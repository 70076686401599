<template>
  <!--  the bar-->
  <!--  Add padding to div below-->
  <top-navigation
    :title="$t('pages.event.edit')"
    :disabled="!validated"
    :loading="status.submitting"
    :button-name="$t('global.inputs.labels.save')"
    @submit-click="update()"
  ></top-navigation>

  <div class="mx-auto min-h-screen pt-24">
    <div class="mb-4 w-full">
      <text-input
        v-model="name"
        :placeholder="$t('global.inputs.placeholders.event')"
        :label="$t('global.inputs.labels.name_event')"
        :has-error="validationErrors.name"
        :error-message="$t('global.inputs.errors.name_event')"
      />
    </div>
    <div class="mb-4 w-full">
      <text-area-input
        v-model="description"
        :has-error="validationErrors.description"
        :error-message="$t('global.inputs.errors.description_event')"
        :label="$t('global.inputs.labels.description_event')"
        :placeholder="$t('global.inputs.placeholders.event_description')"
      />
    </div>
    <div class="mb-4 w-full">
      <p class="text-sm font-medium italic dark:text-slate-200">
        {{ $t("global.inputs.labels.date_time") }}*
      </p>
      <p v-show="validationErrors.timestamp" class="mt-1 text-sm text-red-600">
        {{ $t("global.inputs.errors.date_time") }}
      </p>
      <div class="flex w-full flex-col items-center">
        <date-picker
          v-model.range="range"
          range
          is24hr
          mode="dateTime"
          class="mx-auto border-2 border-black-dark shadow-teal-hard-sm dark:bg-kekkle-dark-light"
          show-iso-weeknumbers
          expanded
          :is-dark="darkModeEnabled"
        ></date-picker>
      </div>
    </div>
    <div class="mb-4 w-full">
      <default-button
        color="red"
        :loading="status.deleting"
        @click="openDeleteConformation = true"
        ><p>
          {{ $t("global.inputs.labels.delete_event") }}
        </p></default-button
      >
    </div>
  </div>
  <conformation-modal
    :danger="true"
    :confirm-text="$t('pages.event.confirm_delete')"
    :loading="status.deleting"
    :cancel-text="$t('pages.event.confirm_close')"
    :visible="openDeleteConformation"
    @cancel="openDeleteConformation = false"
    @confirm="this.delete()"
  >
    <p class="my-8 text-center font-medium">
      {{ $t("pages.event.confirm_delete_text") }}
    </p>
  </conformation-modal>
</template>
<script>
import { DatePicker } from "v-calendar";
import {
  setSuccessToast,
  setWarningToast,
} from "@/helpers/notifications/toast";
import { handleFirestoreException } from "@/helpers/exceptions/handler";
import TopNavigation from "../components/TopNavigation.vue";
import DefaultButton from "../components/Buttons/DefaultButton.vue";
import TextInput from "../components/Inputs/TextInput.vue";
import TextAreaInput from "../components/Inputs/TextAreaInput.vue";
import { IS_ADMIN } from "@/store/modules/groups";
import { GET_AUTH_UUID } from "@/store/modules/auth";
import ConformationModal from "@/components/Modals/ConformationModal.vue";
import { globals } from "@/helpers/globals/globals";
import {
  deleteEventById,
  updateEventById,
} from "@/helpers/firestore/EventService";
import { GET_ACTIVE_GROUP } from "@/store/modules/user";

export default {
  name: "EditEventView",
  components: {
    ConformationModal,
    TextAreaInput,
    TextInput,
    DefaultButton,
    TopNavigation,
    DatePicker,
  },
  data() {
    return {
      status: {
        deleting: false,
        submitting: false,
      },
      name: null,
      description: null,
      begin_timestamp: null,
      end_timestamp: null,
      range: {
        start: null,
        end: null,
      },
      openDeleteConformation: false,
    };
  },
  computed: {
    darkModeEnabled() {
      return !!globals.darkModeEnabled;
    },
    nameValid() {
      return !!(this.name && this.name.length > 0);
    },
    descriptionValid() {
      return !!(this.description && this.description.length > 0);
    },
    timestampValid() {
      return !!(
        this.range.start &&
        this.range.end &&
        this.range.start.getTime() < this.range.end.getTime()
      );
    },
    validationErrors() {
      let errors = {};
      errors.name = !this.nameValid;
      errors.description = !this.descriptionValid;
      errors.timestamp = !this.timestampValid;
      return errors;
    },
    validated() {
      return !Object.values(this.validationErrors).some(
        (value) => value === true,
      );
    },
    canEdit() {
      return (
        this.$store.getters[IS_ADMIN] ||
        this.$store.getters.getSelectedCalendarEventDoc.data.created_by ===
          this.$store.getters[GET_AUTH_UUID]
      );
    },
  },
  created() {
    if (!this.$store.getters.getHasSelectedCalendarEvent || !this.canEdit) {
      this.$router.push({ name: "Agenda" });
      return;
    }
    this.name = this.$store.getters.getSelectedCalendarEventDoc.data.name;
    this.description =
      this.$store.getters.getSelectedCalendarEventDoc.data.description;
    this.range.start =
      this.$store.getters.getSelectedCalendarEventDoc.data.begin_timestamp.toDate();
    this.range.end =
      this.$store.getters.getSelectedCalendarEventDoc.data.end_timestamp.toDate();
  },
  methods: {
    //TODO also delete comments
    async delete() {
      try {
        this.status.deleting = true;
        await deleteEventById(
          this.$store.state.Events.selectedCalendarEventId,
          this.$store.getters[GET_ACTIVE_GROUP],
        );
        setSuccessToast({
          message: this.$t("global.toast.deleted_successfully"),
        });
        this.$store.commit("clearEvents");
        this.$router.push({ name: "Agenda" });
      } catch (e) {
        handleFirestoreException({ e: e });
      } finally {
        this.status.deleting = false;
      }
    },
    async update() {
      if (!this.validated) {
        setWarningToast({
          message: this.$t("global.toast.check_all_fields"),
        });
        return;
      }
      try {
        this.status.submitting = true;
        await updateEventById(
          this.$store.state.Events.selectedCalendarEventId,
          this.$store.getters[GET_ACTIVE_GROUP],
          {
            name: this.name,
            description: this.description,
            begin_timestamp: this.range.start,
            end_timestamp: this.range.end,
          },
        );
        setSuccessToast({
          message: this.$t("global.toast.event_saved"),
        });
        this.$router.push({ name: "Agenda" });
      } catch (e) {
        handleFirestoreException({ e: e });
      } finally {
        this.status.submitting = false;
      }
    },
  },
};
</script>

<template>
  <top-navigation previous-route="Group" :title="$t('pages.settings.title')" />

  <div class="mx-auto min-h-screen pt-24">
    <div
      class="mb-2 flex w-full items-end justify-end gap-2 dark:text-slate-200"
    >
      <p
        class="dark:text-slate-200"
        :class="currentLangauge === 'nl' ? 'underline' : ''"
        @click="setLanguage('nl')"
      >
        🇳🇱Nederlands
      </p>
      <p>{{ $t("auth.or") }}</p>
      <p
        class="dark:text-slate-200"
        :class="currentLangauge === 'en' ? 'underline' : ''"
        @click="setLanguage('en')"
      >
        English 🇬🇧
      </p>
    </div>
    <information-text
      v-if="!pwaInstalled"
      :information="$t('pages.settings.pwa_notification')"
    ></information-text>

    <h1 class="my-4 mt-8 text-xl font-medium dark:text-slate-200">
      {{ $t("pages.setting.profile_settings") }}
    </h1>
    <p
      class="mt-4 cursor-pointer border-b border-gray-200 dark:border-gray-600 dark:text-slate-200"
      @click="$router.push({ name: 'defaultUserProfileSettings' })"
    >
      {{ $t("pages.setting.default_profile") }}
    </p>
    <p
      class="mt-4 cursor-pointer border-b border-gray-200 dark:border-gray-600 dark:text-slate-200"
      @click="$router.push({ name: 'groupUserProfileSettings' })"
    >
      {{ $t("pages.setting.profile_current_group") }}
    </p>

    <h1
      class="my-4 mt-8 cursor-pointer text-xl font-medium dark:text-slate-200"
    >
      {{ $t("pages.setting.group_settings") }}
    </h1>
    <p
      class="mt-4 cursor-pointer border-b border-gray-200 dark:border-gray-600 dark:text-slate-200"
      @click="$router.push({ name: 'groupProfileSettings' })"
    >
      {{ $t("pages.setting.group_profile_settings") }}
    </p>
    <p
      class="mt-4 cursor-pointer border-b border-gray-200 dark:border-gray-600 dark:text-slate-200"
      @click="$router.push({ name: 'memberSettings' })"
    >
      {{ $t("pages.setting.member_settings") }}
    </p>
    <p
      class="mt-4 cursor-pointer border-b border-gray-200 dark:border-gray-600 dark:text-slate-200"
      @click="$router.push({ name: 'memberPermissions' })"
    >
      {{ $t("pages.setting.role_settings") }}
    </p>
    <p
      class="dark-text-blue-300 mt-4 cursor-pointer border-b border-gray-200 text-blue-500 dark:border-gray-600"
      @click="$router.push({ name: 'leaveGroup' })"
    >
      {{ $t("pages.setting.leave_group") }}
    </p>
    <p
      v-if="isAdmin"
      class="mt-4 cursor-pointer border-b border-gray-200 text-red-600 dark:border-gray-600 dark:text-red-400"
      @click="$router.push({ name: 'deleteGroup' })"
    >
      {{ $t("pages.setting.delete_group") }}
    </p>
    <p
      v-if="!isRunningInFlutter()"
      class="mt-4 cursor-pointer border-b border-gray-200 dark:border-gray-600 dark:text-white"
      @click="downloadQuotesExport"
    >
      {{
        downloadingQuotes
          ? $t("pages.setting.downloading_quotes")
          : $t("pages.setting.download_quotes")
      }}
    </p>
    <h1
      class="my-4 mt-8 cursor-pointer text-xl font-medium dark:text-slate-200"
    >
      {{ $t("pages.setting.device_settings") }}
    </h1>
    <p
      class="mt-4 cursor-pointer border-b border-gray-200 dark:border-gray-600 dark:text-slate-200"
      @click="$router.push({ name: 'notificationSettingsView' })"
    >
      {{ $t("pages.setting.notifications_in_group") }}
    </p>
    <p
      class="mt-4 cursor-pointer border-b border-gray-200 dark:border-gray-600 dark:text-slate-200"
      @click="toggleMode"
    >
      {{ $t("pages.setting.dark_mode") }}
      <span v-if="mode === 'dark'" class="font-bold">
        {{ $t("pages.setting.on") }}</span
      >
      <span v-if="mode === 'light'" class="font-bold">
        {{ $t("pages.setting.off") }}</span
      >
      <span v-if="mode === 'auto'" class="font-bold">
        {{ $t("pages.setting.system_preference") }}</span
      >
    </p>
    <h1 class="my-4 mt-8 text-xl font-medium dark:text-slate-200">
      {{ $t("pages.settings.your_account") }}
    </h1>
    <p class="mt-4 dark:text-slate-200">
      {{ $t("pages.settings.email") }}
    </p>

    <p class="italic text-gray-700 dark:text-slate-300">
      {{ userEmail.email }}
    </p>
    <p
      v-if="hasLinkedGoogle"
      class="flex items-center gap-x-2 italic text-gray-600 dark:text-slate-400"
    >
      <img class="w-4" src="/img/google/g_logo.svg" alt="google-logo" />
      {{ $t("pages.setting.linked_to_google") }}
    </p>
    <p
      v-if="hasLinkedApple"
      class="flex items-center gap-x-2 italic text-gray-600 dark:text-slate-400"
    >
      <img
        class="inline-block w-5"
        src="/img/apple/apple-logo.png"
        alt="apple-logo"
      />
      {{ $t("pages.setting.linked_to_apple") }}
    </p>
    <p
      class="my-4 mb-8 cursor-pointer border-b border-gray-200 font-medium text-kekkle-red dark:border-gray-600"
      @click="$router.push({ name: 'deleteAccountInfo' })"
    >
      {{ $t("pages.setting.delete_account") }}
    </p>
    <p
      class="mx-auto my-4 w-fit max-w-xs cursor-pointer rounded border border-red-500 p-2 text-center text-red-500 transition duration-300 hover:bg-red-500 hover:text-white"
      @click="logout()"
    >
      {{ $t("global.inputs.labels.logout") }}
    </p>
    <div class="my-4 text-sm italic text-gray-800 dark:text-slate-300">
      <p class="my-2 cursor-pointer underline" @click="openTwitter">
        {{ $t("pages.setting.questions_twitter_to") }} @kekkle_app
      </p>
      <p class="my-4">
        <a
          target="_blank"
          class="my-4 underline"
          href="https://docs.google.com/forms/d/e/1FAIpQLScPgun75QZ56gdguzXNlYw000iWlUEZ7TOCWYE622IKiJ1RBA/viewform?usp=sf_link"
        >
          {{ $t("pages.setting.feedback_and_bugs") }}</a
        >
      </p>
      <p class="my-4">
        <router-link class="my-4 underline" :to="{ name: 'PrivacyPolicy' }">{{
          $t("auth.terms.privacyPolicy")
        }}</router-link>
      </p>
      <p class="my-4">
        <router-link class="my-4 underline" :to="{ name: 'Eula' }">{{
          $t("auth.terms.eula")
        }}</router-link>
      </p>
      <p class="my-4">{{ $t("pages.setting.group_id") }}: {{ groupDocId }}</p>
      <p class="my-4">{{ $t("pages.setting.user_id") }}: {{ userId }}</p>
    </div>
    <a
      v-if="!isRunningInFlutter()"
      target="_blank"
      href="https://ko-fi.com/Z8Z4F69X2"
      class="text-md text-center font-light text-white"
    >
      <img
        alt="kofi image"
        class="mx-auto mb-8 w-full max-w-[16rem]"
        src="/img/kofi-dark.png"
      />
    </a>
  </div>
</template>
<script>
import TopNavigation from "../components/TopNavigation.vue";
import {
  GET_GROUP_DATA,
  GET_GROUP_DOC_ID,
  IS_ADMIN,
} from "@/store/modules/groups";
("../store/modules/user");
import { logout } from "@/helpers/data/logout";
import { GET_AUTH_USER, GET_AUTH_UUID } from "@/store/modules/auth";
import { dark, light, auto } from "@/helpers/darkmode";
import InformationText from "../components/InformationText.vue";
import { httpsCallable } from "firebase/functions";
import {
  firebaseAnalytics,
  firebaseFunctions,
} from "@/helpers/firebase/firebase";
import { setErrorToast } from "@/helpers/notifications/toast";
import * as Sentry from "@sentry/vue";
import { logEvent } from "firebase/analytics";
import { isRunningInFlutter } from "@/helpers/flutter/FlutterInit";
import { setLanguage } from "@/i18n/vueI18n";

export default {
  name: "SettingsView",
  components: { InformationText, TopNavigation },
  data() {
    return {
      mode: "",
      pwaInstalled: true,
      downloadingQuotes: false,
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters[IS_ADMIN];
    },
    currentLangauge() {
      return this.$i18n.locale;
    },
    groupDocId() {
      return this.$store.getters[GET_GROUP_DOC_ID];
    },
    userId() {
      return this.$store.getters[GET_AUTH_UUID];
    },
    userEmail() {
      if (this.$store.getters[GET_AUTH_USER]) {
        return this.$store.getters[GET_AUTH_USER];
      }
      return "";
    },
    hasLinkedGoogle() {
      let providers = this.$store.getters[GET_AUTH_USER]?.providerData;
      if (providers) {
        for (let i = 0; i < providers.length; i++) {
          if (providers[i]["providerId"] === "google.com") {
            return true;
          }
        }
      }
      return false;
    },
    hasLinkedApple() {
      let providers = this.$store.getters[GET_AUTH_USER]?.providerData;
      if (providers) {
        for (let i = 0; i < providers.length; i++) {
          if (providers[i]["providerId"] === "apple.com") {
            return true;
          }
        }
      }
      return false;
    },
  },
  created() {
    this.pwaInstalled = !!(
      window.navigator.standalone ||
      window.matchMedia("(display-mode: standalone)").matches ||
      document.referrer.includes("android-app://nl.kekkle.app.twa") ||
      isRunningInFlutter()
    );

    if (localStorage.theme === "light") {
      this.mode = "light";
    } else if (localStorage.theme === "dark") {
      this.mode = "dark";
    } else {
      this.mode = "auto";
    }
  },
  methods: {
    isRunningInFlutter,
    setLanguage,
    async downloadQuotesExport() {
      this.downloadingQuotes = true;
      try {
        const downloadQuotesCsv = await httpsCallable(
          firebaseFunctions,
          "downloadQuotesCsv",
        );
        let result = await downloadQuotesCsv({
          groupId: this.$store.getters[GET_GROUP_DOC_ID],
        });
        let csv = result.data;
        let hiddenElement = document.createElement("a");
        hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
        hiddenElement.target = "_blank";
        hiddenElement.download = `Quotes van ${
          this.$store.getters[GET_GROUP_DATA]?.name ?? ""
        }.csv`;
        hiddenElement.click();
      } catch (e) {
        Sentry.captureException(e);
        setErrorToast({ message: "Kan geen export maken" });
      } finally {
        this.downloadingQuotes = false;
      }
    },
    openTwitter() {
      window.open("https://twitter.com/Kekkle_app", "_blank");
    },
    logout() {
      logout();
    },
    toggleMode() {
      switch (this.mode) {
        case "light":
          dark();
          this.mode = "dark";
          break;
        case "dark":
          auto();
          this.mode = "auto";
          break;
        case "auto":
          light();
          this.mode = "light";
          break;
      }
      logEvent(firebaseAnalytics, "settings", {
        name: "toggle_dark_mode",
        mode: this.mode,
      });
    },
  },
};
</script>

import { timeAgo } from "./time-ago";
import { dateAgo } from "./date-ago";

export function dateTimeAgo(date = new Date()) {
  let currentDate = new Date();

  let secondsAgo = (currentDate.getTime() - date.getTime()) / 1000;

  secondsAgo = Math.abs(secondsAgo);

  //check if smaller than a day
  if (secondsAgo < 60 * 60 * 24) {
    return timeAgo(date);
  } else {
    return dateAgo(date);
  }
}

export function dateAndTimeAgo(date = new Date()) {
  return timeAgo(date) + " " + dateAgo(date);
}

<template>
  <!--  the bar-->
  <div class="fixed left-0 top-0 z-40 flex w-full justify-center">
    <div
      class="w-full max-w-xl border-b-2 border-black-dark bg-white px-2 dark:border-kekkle-dark-light dark:bg-kekkle-dark-dark sm:px-0"
    >
      <div
        class="mx-auto w-full max-w-lg flex-nowrap content-center items-center justify-between py-4 sm:py-6"
        :class="
          buttonName === 'button' ? 'flex justify-evenly' : 'grid grid-cols-3'
        "
      >
        <!--      button left-->
        <div
          class="w-6 justify-self-start"
          :class="loading ? 'invisible' : ''"
          @click.prevent="back"
        >
          <arrow-left-icon class="w-full dark:text-slate-200"></arrow-left-icon>
        </div>
        <!--      center text-->

        <p
          class="mx-1 w-full text-center text-base font-medium dark:text-slate-200 sm:text-lg"
        >
          {{ title }}
        </p>

        <!--      button right-->
        <div class="justify-self-end">
          <default-button
            v-if="buttonName !== 'button'"
            :percentage="percentage"
            :disabled="disabled"
            :loading="loading"
            @click.prevent="emitClick()"
            ><p class="text-sm sm:text-base">
              {{ buttonName }}
            </p></default-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ArrowLeftIcon } from "@heroicons/vue/outline";
import defaultButton from "./Buttons/DefaultButton.vue";

export default {
  name: "TopNavigation",
  components: { ArrowLeftIcon, defaultButton },
  props: {
    percentage: {
      type: Number,
      default: null,
    },
    previousRoute: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default() {
        return "";
      },
    },
    buttonName: {
      type: String,
      default() {
        return "button";
      },
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },

  methods: {
    emitClick() {
      this.$emit("submitClick");
    },
    back() {
      if (this.previousRoute) {
        this.$router.push({ name: this.previousRoute });
      } else if (window.history.state.back === null) {
        this.$router.push({ name: "Group" });
      } else {
        this.$router.back();
      }
    },
  },
};
</script>

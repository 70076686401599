<template>
  <CheckboxInput
    v-model="store.state.WordleLeaderBoard.onlyKnownUsers"
    class="my-4 font-thin"
    :label="t('pages.wordle.only_known_users_checkbox')"
  ></CheckboxInput>
  <h1 class="text-center text-xl font-bold text-kekkle-dark dark:text-white">
    🏆 {{ t("pages.wordle.leaderboard") }}:
  </h1>

  <spinner
    v-if="
      store.getters.getFetchingLeaderBoard &&
      !store.getters.getLeaderBoard.length
    "
    class="mx-auto mt-16 w-8 animate-spin"
    style="color: black"
  ></spinner>

  <div
    v-else
    class="w-full rounded border-2 border-black-dark bg-white p-4 text-kekkle-dark shadow-yellow-hard dark:bg-kekkle-dark-light dark:text-white"
  >
    <div
      v-for="score in scores"
      :key="score.data.user_id"
      class="relative mb-2 items-center gap-2 border-b border-black-dark p-2"
    >
      <div class="mb-2 flex items-center gap-3">
        <p class="text-3xl font-bold">
          <span
            v-if="store.getters[GET_AUTH_UUID] === score.data.user_id"
            class="pointer text-xl"
            >👉</span
          >

          <span v-if="score.position === 1"> 🥇 </span>
          <span v-else-if="score.position === 2"> 🥈 </span>
          <span v-else-if="score.position === 3"> 🥉 </span>
          <span v-else> {{ score.position }}</span>
        </p>
        <div>
          <p class="">
            {{ t("pages.wordle.name") }}
            <span class="font-bold">{{ score.data.username }}</span>
          </p>
          <p v-if="groupMembers[score.data.user_id]" class="">
            {{ t("pages.wordle.nick_name") }}
            <span class="font-bold">{{
              groupMembers[score.data.user_id].name
            }}</span>
          </p>
          <p class="">
            {{ t("pages.wordle.score") }}
            <span class="font-bold">{{ score.data.new_score }}</span>
          </p>

          <div class="flex gap-4">
            <p class="">
              {{ t("pages.wordle.played") }}
              <span class="font-bold">{{
                score.data.matches_played.length
              }}</span>
            </p>
            <p class="">
              {{ t("pages.wordle.wins") }}
              <span class="font-bold">{{ score.data.has_won }}</span>
            </p>
            <p class="">
              {{ t("pages.wordle.loses") }}
              <span class="font-bold">{{ score.data.has_lost }}</span>
            </p>
          </div>
        </div>
        <div></div>
      </div>
      <matches-graph :matches="score.data.match_tries"></matches-graph>
    </div>
  </div>
</template>
<script setup>
import { useStore } from "vuex";
import Spinner from "@/components/Spinner.vue";
import { GET_AUTH_UUID } from "@/store/modules/auth";
import { computed, watch } from "vue";
import { GET_GROUP_MEMBERS } from "@/store/modules/groups";
import MatchesGraph from "@/views/games/wordle/components/MatchesGraph.vue";

const store = useStore();

store.dispatch("fetchLeaderBoard");
const groupMembers = computed(() => store.getters[GET_GROUP_MEMBERS]);

watch(
  () => store.state.WordleLeaderBoard.onlyKnownUsers,
  (newValue) => {
    store.dispatch("fetchLeaderBoard");
    store.commit("setOnlyKnownUsersWorldeLeaderboard", newValue);
    store.commit("setOnlyKnownUsersWordleScores", newValue);
    store.commit("setOnlyKnownUsersWordleMonthlyScores", newValue);
  },
);

import { useI18n } from "vue-i18n";
import CheckboxInput from "@/components/Inputs/CheckboxInput.vue";
const { t } = useI18n({ useScope: "global" });

const scores = computed(() => {
  let scores = store.getters.getLeaderBoard;
  let position = 0;
  let lastScore = null;

  if (!scores[0]?.data?.new_score) {
    return [];
  }
  scores.sort((a, b) => b.data.new_score - a.data.new_score);

  return scores.map((score) => {
    if (score.data.new_score !== lastScore) {
      position = position + 1;
      lastScore = score.data.new_score;
    }
    return {
      data: score.data,
      position: position,
    };
  });
});
</script>

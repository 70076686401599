<template>
  <div
    class="mb-4 flex items-center rounded-xl border-2 border-solid border-black-dark bg-white p-2 shadow-yellow-hard dark:bg-kekkle-dark-light"
  >
    <div class="w-24 shrink-0">
      <img
        v-if="profilePicture"
        class="mx-auto max-h-[50vh] rounded-lg border-2 border-kekkle-dark object-contain shadow-red-hard-sm"
        :src="profilePicture"
        alt="Profile picture"
        draggable="false"
      />
    </div>
    <h1 class="ml-4 text-xl font-medium dark:text-slate-200">
      {{ name ?? "" }} {{ $t("components.birthday.is_birthday") }}
    </h1>
  </div>
</template>
<script>
import {
  DOWNLOAD_GROUP_MEMBER_PROFILE_PICTURE_DATA,
  GET_GROUP_DATA,
  GET_GROUP_MEMBERS_PROFILE_PICTURE_DATA,
} from "@/store/modules/groups";
import { HD } from "@/constants/image-sizes";
import { watch } from "vue";

export default {
  name: "Birthday",
  props: {
    uuid: {
      type: String,
      required: true,
    },
  },
  computed: {
    groupData() {
      return this.$store.getters[GET_GROUP_DATA];
    },
    user() {
      return this.groupData?.members?.[this.uuid];
    },
    name() {
      return this.user?.name;
    },
    profilePicture() {
      return this.$store.getters[GET_GROUP_MEMBERS_PROFILE_PICTURE_DATA]?.[
        this.uuid
      ]?.[HD]?.url;
    },
  },
  watch: {
    uuid() {
      if (!this.profilePicture) {
        this.$store.dispatch(DOWNLOAD_GROUP_MEMBER_PROFILE_PICTURE_DATA, {
          uuid: this.uuid,
          format: HD,
        });
      }
    },
  },
  created() {
    if (!this.profilePicture) {
      this.$store.dispatch(DOWNLOAD_GROUP_MEMBER_PROFILE_PICTURE_DATA, {
        uuid: this.uuid,
        format: HD,
      });
    }
  },
};
</script>

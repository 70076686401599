<template>
  <div class="mx-auto mb-4 flex w-full flex-nowrap gap-x-2">
    <div v-if="!simple" class="flex flex-col justify-center gap-y-2">
      <tiny-user-thumb v-for="author in authors" :key="author" :uuid="author" />
    </div>
    <div
      class="relative flex w-full flex-col justify-center overflow-hidden rounded-lg border-2 border-black-dark bg-white p-4 py-4 shadow-red-hard dark:bg-kekkle-dark-light md:p-8"
    >
      <div
        v-if="quoteData.reported_by?.length > 0"
        class="left-0 top-0 flex h-full w-full items-center justify-center bg-white p-4 dark:bg-kekkle-dark-light dark:text-slate-200"
      >
        <p class="text-center font-medium">
          {{ $t("components.report.hasBeenReportedBy") }}
          {{ reporterNames }}.
          {{ $t("components.report.adminsWillDetermine") }}
        </p>
      </div>
      <pencil-alt-icon
        v-if="canEdit && !simple && !quoteData.reported_by?.length > 0"
        class="absolute left-2 top-2 w-6 transform duration-300 hover:-translate-y-1 dark:text-slate-200"
        @click="goToEditView()"
      />
      <dots-vertical-icon
        class="absolute right-2 top-2 w-6 align-bottom text-gray-600 duration-300 hover:-translate-y-1 dark:text-slate-200"
        @click="reportModalIsOpen = !reportModalIsOpen"
      ></dots-vertical-icon>

      <div v-if="!quoteData.reported_by?.length > 0">
        <div class="px-2 text-center italic dark:text-slate-200">
          {{ timeAgo }}
        </div>
        <div
          class="px-2 pb-2 text-center text-xs italic text-gray-500 dark:text-slate-400"
        >
          {{ dateAgo }}
        </div>

        <h2
          translate="no"
          class="mb-2 mt-4 break-words text-center text-xl font-semibold dark:text-slate-200"
        >
          {{ quote }}
        </h2>
        <h3
          v-if="context"
          class="my-2 cursor-pointer text-center italic text-gray-800 dark:text-slate-200"
          @click="toggleContext"
        >
          {{ contextVisible ? context : $t("components.quote.context") }}
        </h3>
        <p
          v-for="(name, index) in authorNames"
          :key="index"
          class="text-right dark:text-slate-200"
        >
          {{ index === userAuthUid ? $t("components.quote.you") : ""
          }}{{ name }}
        </p>
        <p v-if="randomAuthor" class="text-right dark:text-slate-200">
          {{ randomAuthor }}
        </p>
      </div>
    </div>
    <div
      v-if="!simple && !quoteData.reported_by"
      class="flex flex-col justify-center"
      @click="goToComments()"
    >
      <chat-alt2-icon
        class="w-6 transform duration-300 hover:-translate-y-2 dark:text-slate-200"
      />
      <p class="text-center text-lg font-medium dark:text-slate-200">
        {{ numberOfComments }}
      </p>
    </div>
  </div>

  <conformation-modal
    safe-mode
    :visible="reportModalIsOpen"
    :confirm-text="$t('components.report.report')"
    :cancel-text="$t('components.report.close')"
    :loading="reporting"
    @cancel="reportModalIsOpen = false"
    @confirm="reportQuote"
  >
    <h3 class="text-xl font-bold dark:text-slate-200">
      {{ $t("components.report.reportTitle") }}
    </h3>
    <p class="my-2">
      {{ $t("components.report.reportText") }}
    </p>
    <information-text
      class="my-2"
      color="orange"
      loa
      :information="$t('components.report.reportInformation')"
    ></information-text>
  </conformation-modal>
</template>
<script>
import { ChatAlt2Icon } from "@heroicons/vue/outline";
import { PencilAltIcon, DotsVerticalIcon } from "@heroicons/vue/outline";
import TinyUserThumb from "./TinyUserThumb.vue";
import conformationModal from "@/components/Modals/ConformationModal.vue";
import {
  GET_GROUP_DATA,
  GET_GROUP_DOC_ID,
  GET_GROUP_MEMBERS_PROFILE_PICTURE_DATA,
  IS_ADMIN,
  IS_ORGANISER,
} from "@/store/modules/groups";
import { SD } from "@/constants/image-sizes";
import { GET_AUTH_UUID } from "@/store/modules/auth";
import { logEvent } from "firebase/analytics";
import { firebaseAnalytics, firestore } from "@/helpers/firebase/firebase";
import { timeAgo } from "@/helpers/dates/time-ago";
import { dateAgo } from "@/helpers/dates/date-ago";
import InformationText from "@/components/InformationText.vue";
import { setErrorToast, setSuccessToast } from "@/helpers/notifications/toast";
import {
  arrayUnion,
  collection,
  doc,
  serverTimestamp,
  writeBatch,
} from "firebase/firestore";
import { SET_SELECTED_QUOTE } from "@/store/modules/quotes";

export default {
  name: "Quote",
  components: {
    InformationText,
    PencilAltIcon,
    TinyUserThumb,
    ChatAlt2Icon,
    DotsVerticalIcon,
    conformationModal,
  },
  props: {
    simple: {
      type: Boolean,
      default: false,
    },
    quoteData: {
      type: Object,
      required: true,
    },
    quoteId: {
      required: true,
    },
  },
  emits: ["goToComments"],
  data() {
    return {
      dateTimeAgo: "",
      timeAgo: "",
      dateAgo: "",
      SD: SD,
      contextVisible: false,
      reportModalIsOpen: false,
      reporting: false,
    };
  },
  computed: {
    hasReported() {
      return this.quoteData?.reported_by?.includes(
        this.$store.getters[GET_AUTH_UUID],
      );
    },
    quote() {
      return this.quoteData.quote ? this.quoteData.quote : "";
    },
    context() {
      return this.quoteData.context ? this.quoteData.context : "";
    },
    authors() {
      return this.quoteData.authors ? this.quoteData.authors : [];
    },
    numberOfComments() {
      return this.quoteData.number_of_comments
        ? this.quoteData.number_of_comments
        : 0;
    },
    date() {
      return this.quoteData.timestamp
        ? this.quoteData.timestamp.toDate()
        : null;
    },
    userAuthUid() {
      return this.$store.getters[GET_AUTH_UUID];
    },
    reporterNames() {
      let names = "";
      if (!this.quoteData.reported_by) {
        return names;
      }
      this.quoteData.reported_by.forEach((uuid) => {
        if (this.groupData.members[uuid] && this.groupData.members[uuid].name) {
          names = names + this.groupData.members[uuid].name + ", ";
        }
        if (!this.groupData.members[uuid]) {
          names = names + this.$t("global.deleted_user") + ", ";
        }
      });
      return names;
    },
    authorNames() {
      let names = {};
      if (!this.quoteData.authors) {
        return names;
      }
      this.quoteData.authors.forEach((uuid) => {
        if (this.groupData.members[uuid] && this.groupData.members[uuid].name) {
          names[uuid] = this.groupData.members[uuid].name;
        }
        if (!this.groupData.members[uuid]) {
          names[uuid] = this.$t("global.deleted_user");
        }
      });
      return names;
    },
    randomAuthor() {
      return this.quoteData.custom_author ? this.quoteData.custom_author : "";
    },
    groupData() {
      return this.$store.getters[GET_GROUP_DATA];
    },
    membersProfilePicturesData() {
      return this.$store.getters[GET_GROUP_MEMBERS_PROFILE_PICTURE_DATA];
    },
    canEdit() {
      return this.$store.getters[IS_ADMIN] || this.$store.getters[IS_ORGANISER];
    },
  },
  created() {
    if (this.date) {
      this.timeAgo = timeAgo(this.date);
      this.dateAgo = dateAgo(this.date);
    }
  },
  methods: {
    toggleContext() {
      this.contextVisible = !this.contextVisible;
      logEvent(firebaseAnalytics, "quote_interaction", {
        name: "toggle_context",
        visible: this.contextVisible,
      });
    },
    goToComments() {
      this.$emit("goToComments", {
        quoteId: this.quoteId,
        groupId: this.$store.getters[GET_GROUP_DOC_ID],
      });
    },
    async reportQuote() {
      try {
        this.reporting = true;
        let batch = writeBatch(firestore);
        let quoteRef = doc(
          collection(
            firestore,
            `groups/${this.$store.getters[GET_GROUP_DOC_ID]}/quotes`,
          ),
          this.quoteId,
        );

        batch.update(quoteRef, {
          reported_by: arrayUnion(this.$store.getters[GET_AUTH_UUID]),
        });

        batch.set(doc(collection(firestore, `reports`)), {
          group_id: this.$store.getters[GET_GROUP_DOC_ID],
          type: "quote",
          document_id: this.quoteId,
          created_at: serverTimestamp(),
          created_by: this.$store.getters[GET_AUTH_UUID],
          solved: !!this.hasReported,
          solved_at: null,
        });

        await batch.commit();
        setSuccessToast({
          message: this.$t("components.report.toastReported"),
        });

        // eslint-disable-next-line vue/no-mutating-props
        this.quoteData["reported_by"] = [this.$store.getters[GET_AUTH_UUID]];
      } catch (e) {
        setErrorToast({
          message: this.$t("components.report.toastNotReported"),
        });
      } finally {
        this.reportModalIsOpen = false;
        this.reporting = false;
      }
    },
    goToEditView() {
      this.$store.commit(SET_SELECTED_QUOTE, this.quoteId);
      this.$router.push({
        name: "editQuote",
      });
    },
  },
};
</script>

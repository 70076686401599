import { GET_ACTIVE_GROUP, GET_USER_DATA } from "@/store/modules/user";
import store, { clearAllStoreData } from "../../store/store";
import {
  GET_AUTH_USER,
  GET_AUTH_UUID,
  SET_AUTH_ID_TOKEN,
} from "@/store/modules/auth";
import { getIdToken, getIdTokenResult } from "firebase/auth";

import {
  CLEAR_GROUP_DATA,
  DOWNLOAD_GROUP_PICTURE_DATA,
} from "@/store/modules/groups";
import { SD, THUMB } from "@/constants/image-sizes";
import { updateUserDoc } from "@/helpers/firestore/UserService";
import router from "@/router/router";

export async function refreshUserData() {
  const userUuid = store.getters[GET_AUTH_UUID];
  if (!userUuid) {
    return;
  }
  await store.dispatch("refreshUser", { userUuid });
  if (store.getters[GET_USER_DATA]?.new_auth_claim === true) {
    await store.getters[GET_AUTH_USER].reload();
    let token = await getIdTokenResult(store.getters[GET_AUTH_USER], true);
    store.commit(SET_AUTH_ID_TOKEN, token);
    await updateUserDoc(userUuid, { new_auth_claim: false });
  }
}

export async function refreshCurrentGroupData() {
  const groupId = store.getters[GET_ACTIVE_GROUP];
  if (!groupId) {
    return;
  }
  await store.dispatch("refreshSingleGroup", { groupId: groupId });
  await store.dispatch(DOWNLOAD_GROUP_PICTURE_DATA, { format: SD });
  await store.dispatch(DOWNLOAD_GROUP_PICTURE_DATA, { format: THUMB });
}

export async function refreshAllUserGroupsData() {
  const userUuid = store.getters[GET_AUTH_UUID];
  if (!userUuid) {
    return;
  }
  if (!store.getters[GET_USER_DATA]?.groups) {
    return;
  }
  await store.dispatch("refreshUserGroups", {
    groupIds: store.getters[GET_USER_DATA].groups,
  });
  await store.dispatch(DOWNLOAD_GROUP_PICTURE_DATA, { format: SD });
  await store.dispatch(DOWNLOAD_GROUP_PICTURE_DATA, { format: THUMB });
  //check if the active group is not in the list with groups
  if (
    store.getters[GET_ACTIVE_GROUP] &&
    !store.getters[GET_USER_DATA]?.groups.includes(
      store.getters[GET_ACTIVE_GROUP],
    )
  ) {
    await updateUserDoc(store.getters[GET_AUTH_UUID], {
      active_group: "",
    });
    await refreshUserData();
    store.commit(CLEAR_GROUP_DATA);
    await router.push({ name: "GroupSelector" });
  }
}

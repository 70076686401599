<template>
  <div
    :class="[
      hidden
        ? 'bg-gray-500 text-white'
        : isCorrect && isLocked
          ? 'bg-green-700 text-white'
          : isPresent && isLocked
            ? 'bg-kekkle-yellow-dark text-kekkle-dark'
            : isWrong && isLocked
              ? 'bg-gray-500 text-white'
              : isEmpty
                ? 'bg-gray-300'
                : 'text-black animate-scale border border-black-dark bg-white',
    ]"
    class="flex aspect-square h-full w-4 items-center justify-center rounded border border-black-dark text-xs font-medium capitalize transition-all"
  >
    {{ hidden ? "?" : letter.character }}
  </div>
</template>
<script setup>
import { computed } from "vue";

const props = defineProps({
  letter: Object,
  index: Number,
  hidden: {
    type: Boolean,
    default: false,
  },
});

const isCorrect = computed(() => {
  return props.letter.isCorrect;
});

const isPresent = computed(() => {
  return props.letter.isPresent;
});

const isEmpty = computed(() => {
  return props.letter.character === "";
});

const isLocked = computed(() => {
  return props.letter.isLocked;
});

const isWrong = computed(() => {
  return (
    !props.letter.isCorrect && !props.letter.isPresent && props.letter.isLocked
  );
});
</script>

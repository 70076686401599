const namespace = "photos";

export const SET_PHOTOS = `${namespace}/SET_PHOTOS`;
export const SET_SELECTED_PHOTO = `${namespace}/SET_SELECTED_PHOTO`;

export const GET_SELECTED_PHOTO = `${namespace}/GET_SELECTED_PHOTO`;
export const HAS_SELECTED_PHOTO = `${namespace}/HAS_SELECTED_PHOTO`;

export const modulePhotos = {
  state: () => ({
    photos: [],
    selectedPhoto: {},
  }),
  mutations: {
    [SET_PHOTOS](state, photos) {
      state.photos = photos;
    },
    [SET_SELECTED_PHOTO](state, photo) {
      state.selectedPhoto = photo;
    },
    clearPhotos(state) {
      state.photos = [];
      state.selectedPhoto = {};
    },
  },
  getters: {
    [GET_SELECTED_PHOTO](state) {
      return state.selectedPhoto;
    },
    [HAS_SELECTED_PHOTO](state) {
      return Object.keys(state.selectedPhoto).length > 0;
    },
  },
};

<script setup>
import { useStore } from "vuex";
import WorldeBoardCell from "@/views/games/wordle/components/WorldeBoardCell.vue";

const store = useStore();
</script>
<template>
  <div class="relative flex w-full flex-col gap-2">
    <transition name="opacity">
      <p
        v-if="store.getters.getInvalidWord"
        style="transform: translate(-50%, -50%)"
        class="absolute left-1/2 z-10 w-max rounded bg-kekkle-dark-dark px-2 py-2 text-sm text-white"
      >
        Geen goed woord
      </p>
    </transition>

    <div
      v-for="row in store.getters.getGameState.length"
      :key="row"
      class="flex w-full flex-nowrap justify-center gap-2"
      :class="
        store.getters.getInvalidWord && store.getters.getCurrentRow === row - 1
          ? 'shake'
          : ''
      "
    >
      <WorldeBoardCell
        v-for="(cell, index) in store.getters.getGameState[row - 1].length"
        :key="'cell-' + cell"
        :letter="store.getters.getGameState[row - 1][cell - 1]"
        :index="index"
      >
      </WorldeBoardCell>
    </div>
  </div>
</template>
<style scoped>
@keyframes horizontal-shaking {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}

.shake {
  animation: horizontal-shaking 0.5s;
}

.opacity-enter-active {
  transition: opacity 0.2s ease;
}
.opacity-leave-active {
  transition-delay: 1s;
  transition: opacity 1s ease;
}

.opacity-enter-from,
.opacity-leave-to {
  opacity: 0;
}
</style>

<template>
  <div
    class="relative"
    @click="
      $router.push({
        name: 'memberView',
        params: {
          groupId: activeGroupId,
          memberId: uuid,
        },
      })
    "
  >
    <transition name="opacity">
      <img
        v-if="pictureUrl"
        alt="Profile Picture"
        draggable="false"
        class="h-16 w-16 rounded-lg border-2 border-black-dark object-cover"
        :class="shadow"
        :src="pictureUrl"
      />
      <div
        v-else
        class="h-16 w-16 rounded-lg border-2 border-black-dark bg-gradient-to-br from-orange to-pink shadow-yellow-hard-sm"
      ></div>
    </transition>
  </div>
</template>
<script>
import { THUMB } from "@/constants/image-sizes";
import {
  DOWNLOAD_GROUP_MEMBER_PROFILE_PICTURE_DATA,
  GET_GROUP_MEMBERS_PROFILE_PICTURE_DATA,
} from "@/store/modules/groups";
import { GET_ACTIVE_GROUP } from "@/store/modules/user";
import { getObjectPathValue } from "@/helpers/utils/ObjectPath";

export default {
  name: "SmallUserThumb",
  props: {
    uuid: {
      type: String,
      required: true,
    },
    color: {
      required: false,
      type: String,
      default: "orange",
    },
  },
  data() {
    return {
      THUMB: THUMB,
    };
  },
  computed: {
    activeGroupId() {
      return this.$store.getters[GET_ACTIVE_GROUP];
    },
    shadow() {
      switch (this.color) {
        case "orange":
          return "shadow-yellow-hard-sm";
        case "green":
          return "shadow-green-hard-sm";
        case "red":
          return "shadow-red-hard-sm";
        default:
          return "shadow-blue-hard-sm";
      }
    },
    membersProfilePicturesData() {
      return this.$store.getters[GET_GROUP_MEMBERS_PROFILE_PICTURE_DATA];
    },
    pictureUrl() {
      return getObjectPathValue(this.membersProfilePicturesData, [
        this.uuid,
        this.THUMB,
        "url",
      ]);
    },
  },
  created() {
    //check if there already is an url
    if (!this.pictureUrl) {
      this.$store.dispatch(DOWNLOAD_GROUP_MEMBER_PROFILE_PICTURE_DATA, {
        uuid: this.uuid,
        format: this.THUMB,
      });
    }
  },
};
</script>
<style scoped lang="scss">
.opacity-enter-active {
  transition: all 0.5s;
  transition-timing-function: ease;
}

.opacity-leave-active {
  position: absolute;
  transition: all 0.5s;
  transition-timing-function: ease;
}
.opacity-enter, .opacity-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

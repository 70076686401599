<template>
  <span class="absolute right-0 top-0 flex h-3 w-3">
    <span
      class="absolute inline-flex h-full w-full animate-ping rounded-full bg-kekkle-yellow"
    ></span>
    <span
      class="relative inline-flex h-3 w-3 rounded-full bg-kekkle-yellow"
    ></span>
  </span>
</template>
<script setup></script>

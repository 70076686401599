import { translate } from "@/i18n/vueI18n";
export function getStorageExceptionMessage(e) {
  let message = e;
  if (e.code) {
    message = e.code;
  }
  switch (message) {
    case "storage/unauthenticated":
      return translate("expectations.storage.unauthenticated");
    case "storage/object-not-found":
      return translate("expectations.storage.object_not_found");
    case "storage/unauthorized":
      return translate("expectations.storage.unauthorized");
    case "storage/retry-limit-exceeded":
      return translate("expectations.storage.retry_limit_exceeded");
    case "storage/canceled":
      return translate("expectations.storage.canceled");
    default:
      return translate("expectations.storage.default");
  }
}

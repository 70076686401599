<template>
  <button
    :class="buttonClasses"
    :disabled="disabled"
    type="button"
    @click="$emit('click', $event)"
  >
    <div v-if="loading" class="absolute left-0 top-0 h-full w-full">
      <div class="flex h-full flex-nowrap items-center justify-center">
        <p v-if="percentage" class="font-medium">
          {{ Math.floor(percentage) }}%
        </p>
        <Spinner class="w-4 animate-spin" />
      </div>
    </div>
    <div :class="{ invisible: loading }">
      <slot></slot>
    </div>
  </button>
</template>

<script>
import Spinner from "../Spinner.vue";

export default {
  name: "DefaultButton",
  components: { Spinner },
  props: {
    percentage: {
      type: Number,
      default: null,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    color: {
      type: String,
      required: false,
      default: "blue",
    },
  },
  emits: ["click"],
  computed: {
    buttonClasses() {
      const baseClasses = [
        "mx-auto",
        "flex",
        "transform",
        "cursor-pointer",
        "items-center",
        "rounded-lg",
        "border-2",
        "border-black-dark",
        "px-2",
        "py-1",
        "font-bold",
        "text-kekkle-dark-dark",
        "transition-all",
        "duration-500",
        "hover:translate-y-1",
        "hover:shadow-none",
        "hover:dark:shadow-none",
        "sm:px-4",
        "sm:py-2",
      ];

      const shadowClasses = {
        blue: "shadow-blue-hard-sm",
        red: "shadow-red-hard-sm",
        // Add more colors if necessary
      };

      const bgClasses = {
        blue: "bg-kekkle-blue-light hover:bg-kekkle-blue",
        red: "bg-kekkle-red-light hover:bg-kekkle-red",
        // Add more colors if necessary
      };

      const bgDarkClasses = {
        blue: "bg-kekkle-blue-dark",
        red: "bg-kekkle-red-dark",
        // Add more colors if necessary
      };

      const loadingClasses = this.loading
        ? [bgDarkClasses[this.color], "shadow-none", "dark:shadow-none"]
        : [shadowClasses[this.color]];

      const disabledClasses = this.disabled
        ? [
            "bg-gray-400",
            "text-gray-200",
            "cursor-not-allowed",
            "translate-y-1",
            "dark:shadow-none",
          ]
        : [bgClasses[this.color]];

      return [...baseClasses, ...loadingClasses, ...disabledClasses];
    },
  },
};
</script>

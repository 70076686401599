<template>
  <div class="container mx-auto min-h-screen pb-24">
    <div class="mx-auto p-2">
      <p
        class="cursor-pointer py-2 text-right underline dark:text-slate-200"
        @click="openPopup = true"
      >
        {{ $t("pages.event.export_calendar") }}
      </p>
      <DatePicker
        v-model="selectedDate"
        style="border: solid 2px black"
        translate="no"
        class="mx-auto border-2 border-solid border-black-dark shadow-blue-hard-sm dark:bg-kekkle-dark-light"
        show-iso-weeknumbers
        expanded
        :is-dark="darkModeEnabled"
        :rows="1"
        :attributes="attributes"
        @did-move="updateDate"
      />
    </div>
    <div class="p-2">
      <h1
        v-if="selectedDate"
        class="text-center font-medium dark:text-slate-200"
      >
        {{ $t("pages.event.selected_day") }}:
        {{ selectedDate.toLocaleDateString() }}
      </h1>
      <h1
        v-if="!selectedDate && monthInView"
        class="text-center font-medium dark:text-slate-200"
      >
        {{ $t("pages.event.selected_month") }}:
        {{ getMonth(monthInView) }}
      </h1>
      <h1
        v-if="!selectedDate && !monthInView"
        class="text- text-center font-medium dark:text-slate-200"
      >
        {{ $t("pages.event.no_day_selected") }}
      </h1>
      <birthday
        v-for="(birthday, index) in getActiveBirthdays"
        :key="index"
        :uuid="birthday.uuid"
      ></birthday>
      <div v-if="getCurrentDayEvents.length === 0 && selectedDate">
        <p class="text-center dark:text-slate-200">
          {{ $t("pages.event.no_events_day") }}
        </p>
      </div>
      <div v-if="getCurrentDayEvents.length > 0 && selectedDate">
        <div
          v-for="(agendaItem, index) in getCurrentDayEvents"
          :key="index"
          class="mb-6"
        >
          <p class="dark:text-slate-200">
            {{ index + 1 }} / {{ getCurrentDayEvents.length }}
          </p>
          <agenda-item :item="agendaItem" />
        </div>
      </div>
      <div
        v-if="
          !selectedDate && getCurrentMonthEvents.length === 0 && monthInView
        "
      >
        <p class="text-center dark:text-slate-200">
          {{ $t("pages.event.no_events_month") }}
        </p>
      </div>
      <div
        v-if="getCurrentMonthEvents.length > 0 && !selectedDate && monthInView"
      >
        <div
          v-for="(agendaItem, index) in getCurrentMonthEvents"
          :key="index"
          class="mb-6"
        >
          <p class="dark:text-slate-200">
            {{ index + 1 }} / {{ getCurrentMonthEvents.length }}
          </p>
          <agenda-item :item="agendaItem" />
        </div>
      </div>
    </div>
  </div>
  <conformation-modal
    :cancel-text="$t('pages.photo.close')"
    :visible="openPopup"
    :confirm-text="$t('pages.photo.copy_link')"
    @cancel="openPopup = false"
    @confirm="copyCalendarLink()"
  >
    <div class="mb-4">
      <information-text
        class="mb-2"
        :information="$t('pages.event.24_hours')"
      ></information-text>
      <p>
        {{ $t("pages.event.import_ics_text") }}
      </p>
      <a
        href="https://support.google.com/calendar/answer/37100?hl=en&ref_topic=10510447"
        target="_blank"
        class="mb-4 text-blue-500 underline dark:text-white"
        >{{ $t("pages.event.ice_google_text") }}</a
      >
      <p
        v-if="icalLink"
        class="my-4 select-text break-words rounded-md border-2 border-gray-900 bg-gray-200 p-2 text-gray-500"
      >
        {{ icalLink }}
      </p>
    </div>
  </conformation-modal>
</template>

<script>
import { DatePicker } from "v-calendar";

import AgendaItem from "../components/AgendaItem.vue";
import { GET_BIRTHDAYS, GET_GROUP_DATA } from "@/store/modules/groups";
import Birthday from "../components/Birthday.vue";
import ConformationModal from "../components/Modals/ConformationModal.vue";
import { setSuccessToast } from "@/helpers/notifications/toast";
import InformationText from "../components/InformationText.vue";
import { getMonth } from "@/helpers/dates/get-month";
import { firebaseAnalytics } from "@/helpers/firebase/firebase";
import { globals } from "@/helpers/globals/globals";
import { logEvent } from "firebase/analytics";
import { copyToClipboardFlutter } from "@/helpers/flutter/FlutterClipboard";
import { isRunningInFlutter } from "@/helpers/flutter/FlutterInit";

export default {
  name: "Agenda",
  components: {
    InformationText,
    ConformationModal,
    Birthday,
    AgendaItem,
    DatePicker,
  },
  data() {
    return {
      openPopup: false,
      eventCollection: null,
    };
  },
  computed: {
    selectedDate: {
      get() {
        return this.$store.state.Events.selectedCalendarDate;
      },
      set(value) {
        this.$store.commit("setSelectedCalendarDate", value);
      },
    },
    monthInView: {
      get() {
        return this.$store.state.Events.calendarMonth;
      },
      set(value) {
        this.$store.commit("setCalendarMonthDate", value);
      },
    },
    darkModeEnabled() {
      return !!globals.darkModeEnabled;
    },
    icalLink() {
      if (this.$store.getters.getGroupIcalToken) {
        return (
          import.meta.env.VITE_FUNCTIONS_URL +
          "getEventsIcs?token=" +
          this.$store.getters.getGroupIcalToken
        );
      }
      return null;
    },
    groupData() {
      return this.$store.getters[GET_GROUP_DATA];
    },
    getCurrentMonthEvents() {
      return this.$store.getters.getCurrentMonthEvents;
    },
    getCurrentDayEvents() {
      return this.$store.getters.getCurrentDayEvents;
    },
    eventDates() {
      return this.$store.getters.getEventDates;
    },
    rangedEventDates() {
      return this.$store.getters.getRangedEventDates;
    },
    getBirthdays() {
      return this.$store.getters[GET_BIRTHDAYS];
    },
    getActiveBirthdays() {
      if (!this.$store.state.Events.selectedCalendarDate) {
        return [];
      }
      return this.getBirthdays.filter(
        (userData) =>
          userData.birthday.getMonth() ===
            this.$store.state.Events.selectedCalendarDate.getMonth() &&
          userData.birthday.getDate() ===
            this.$store.state.Events.selectedCalendarDate.getDate(),
      );
    },
    getBirthdaysDates() {
      if (!this.monthInView) {
        return [];
      }
      return this.getBirthdays
        .filter(
          (userData) =>
            userData.birthday.getMonth() === this.monthInView.getMonth(),
        )
        .map((userData) => {
          let copyOfBirthday = new Date(userData.birthday.getTime());
          copyOfBirthday.setFullYear(this.monthInView.getFullYear());
          return copyOfBirthday;
        });
    },
    attributes() {
      return [
        {
          key: "today",
          highlight: {
            fillMode: "light",
            color: "orange",
          },
          dates: new Date(),
        },
        {
          key: "events",
          highlight: {
            color: "orange",
            fillMode: "solid",
          },
          dot: "orange",
          color: "orange",
          dates: this.eventDates,
        },
        {
          key: "rangedEvents",
          highlight: {
            start: { fillMode: "solid", color: "orange" },
            base: { fillMode: "light", color: "orange" },
            end: { fillMode: "solid", color: "orange" },
          },
          dot: "orange",
          dates: this.rangedEventDates,
        },
        {
          key: "birthdays",
          dot: {
            class: "birthday-content",
          },
          dates: this.getBirthdaysDates,
        },
      ];
    },
  },
  created() {
    this.$store.dispatch("fetchCurrentMonthEvents");
  },
  methods: {
    getMonth(date) {
      return getMonth(date);
    },
    copyCalendarLink() {
      if (isRunningInFlutter()) {
        copyToClipboardFlutter(this.icalLink, "pages.event.link_copied");
      } else {
        navigator.clipboard.writeText(this.icalLink);
        setSuccessToast({ message: this.$t("pages.event.link_copied") });
      }
      logEvent(firebaseAnalytics, "agenda_interaction", {
        name: "copied_link",
      });
    },
    updateDate(e) {
      let newDate = new Date(this.$store.state.Events.selectedCalendarDate);
      newDate.setFullYear(e[0].year);
      newDate.setMonth(e[0].month - 1);
      this.$store.commit("setCalendarMonthDate", newDate);
      this.$store.commit("setSelectedCalendarDate", null);
      this.$store.dispatch("fetchCurrentMonthEvents");
      logEvent(firebaseAnalytics, "agenda_interaction", {
        name: "pressed_arrow",
      });
    },
  },
};
</script>
<style lang="scss">
.birthday-content {
  &.vc-dot {
    background-color: transparent;
    border: none;
  }
  &:before {
    font-size: 30px;
    content: "🎂" !important;
    text-align: center;
    position: absolute;
    bottom: -5px;
    transform: translateX(-50%);
  }
}
</style>

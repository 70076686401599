export function dateObjectToDateString(date, outputFormat) {
  switch (outputFormat) {
    case "dd-mm-yyyy":
      return getDate(date) + "-" + getMonth(date) + "-" + date.getFullYear();
    case "dd-mm-yyyy hh:mm":
      return (
        getDate(date) +
        "-" +
        getMonth(date) +
        "-" +
        date.getFullYear() +
        " " +
        getHours(date) +
        ":" +
        getMinutes(date)
      );
    case "dd-mm-yyyy hh:mm:ss":
      return (
        getDate(date) +
        "-" +
        getMonth(date) +
        "-" +
        date.getFullYear() +
        " " +
        getHours(date) +
        ":" +
        getMinutes(date) +
        ":" +
        getSeconds(date)
      );
    case "yyyy-mm-dd":
      return date.getFullYear() + "-" + getMonth(date) + "-" + getDate(date);
    case "yyyy-mm-dd hh:mm":
      return (
        date.getFullYear() +
        "-" +
        getMonth(date) +
        "-" +
        getDate(date) +
        " " +
        getHours(date) +
        ":" +
        getMinutes(date)
      );
    case "yyyy-mm-ddThh:mm":
      return (
        date.getFullYear() +
        "-" +
        getMonth(date) +
        "-" +
        getDate(date) +
        "T" +
        getHours(date) +
        ":" +
        getMinutes(date)
      );
    case "yyyy-mm-dd hh:mm:ss":
      return (
        date.getFullYear() +
        "-" +
        getMonth(date) +
        "-" +
        getDate(date) +
        " " +
        getHours(date) +
        ":" +
        getMinutes(date) +
        ":" +
        getSeconds(date)
      );
    case "hh:mm":
      return getHours(date) + ":" + getMinutes(date);
    case "hh:mm:ss":
      return getHours(date) + ":" + getMinutes(date) + ":" + getSeconds(date);
    default:
      throw new Error("Invalid date format");
  }
}

export function dateStringToDateObject(string, inputFormat) {
  switch (inputFormat) {
    case "dd-mm-yyyy":
      return new Date(
        string.split("-")[2],
        string.split("-")[1] - 1,
        string.split("-")[0],
      );
    case "dd-mm-yyyy hh:mm":
      return new Date(
        string.split(" ")[0].split("-")[2],
        string.split(" ")[0].split("-")[1] - 1,
        string.split(" ")[0].split("-")[0],
        string.split(" ")[1].split(":")[0],
        string.split(" ")[1].split(":")[1],
      );
    case "dd-mm-yyyy hh:mm:ss":
      return new Date(
        string.split(" ")[0].split("-")[2],
        string.split(" ")[0].split("-")[1] - 1,
        string.split(" ")[0].split("-")[0],
        string.split(" ")[1].split(":")[0],
        string.split(" ")[1].split(":")[1],
        string.split(" ")[1].split(":")[2],
      );
    case "yyyy-mm-dd":
      return new Date(
        string.split("-")[0],
        string.split("-")[1] - 1,
        string.split("-")[2],
      );
    case "yyyy-mm-dd hh:mm":
      return new Date(
        string.split(" ")[0].split("-")[0],
        string.split(" ")[0].split("-")[1] - 1,
        string.split(" ")[0].split("-")[2],
        string.split(" ")[1].split(":")[0],
        string.split(" ")[1].split(":")[1],
      );
    case "yyyy-mm-dd hh:mm:ss":
      return new Date(
        string.split(" ")[0].split("-")[0],
        string.split(" ")[0].split("-")[1] - 1,
        string.split(" ")[0].split("-")[2],
        string.split(" ")[1].split(":")[0],
        string.split(" ")[1].split(":")[1],
        string.split(" ")[1].split(":")[2],
      );
    default:
      throw new Error("Invalid date format");
  }
}

export function secondsToHms(seconds) {
  seconds = Number(seconds);
  let h = Math.floor(seconds / 3600);
  let m = Math.floor((seconds % 3600) / 60);
  let s = Math.floor((seconds % 3600) % 60);

  return [h, m, s];
}

export function secondsToString(seconds) {
  let h = Math.floor(seconds / 3600);
  if (h < 10) {
    h = "0" + h;
  }
  let m = Math.floor((seconds % 3600) / 60);
  if (m < 10) {
    m = "0" + m;
  }
  return h + ":" + m;
}

export function getMonth(date) {
  let month = date.getMonth() + 1;
  if (month < 10) {
    month = "0" + month;
  }
  return month;
}

export function getDate(date) {
  let d = date.getDate();
  if (d < 10) {
    d = "0" + d;
  }
  return d;
}

function getHours(date) {
  let hours = date.getHours();
  if (hours < 10) {
    hours = "0" + hours;
  }
  return hours;
}

function getMinutes(date) {
  let minutes = date.getMinutes();
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  return minutes;
}

function getSeconds(date) {
  let seconds = date.getSeconds();
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return seconds;
}

<template>
  <div v-if="!groupData" class="mx-auto min-h-screen overflow-hidden pb-24">
    <div
      class="m-4 h-32 animate-pulse rounded bg-gray-300 dark:bg-kekkle-dark-light"
    ></div>
    <div
      class="m-4 h-16 animate-pulse rounded bg-gray-300 dark:bg-kekkle-dark-light"
    ></div>
    <div
      class="m-4 h-32 animate-pulse rounded bg-gray-300 dark:bg-kekkle-dark-light"
    ></div>
    <div
      class="m-4 h-16 animate-pulse rounded bg-gray-300 dark:bg-kekkle-dark-light"
    ></div>
    <div
      class="m-4 h-32 animate-pulse rounded bg-gray-300 dark:bg-kekkle-dark-light"
    ></div>
  </div>
  <div v-else class="container mx-auto min-h-screen pb-24" @click="closePopups">
    <div class="h-12"></div>
    <div
      class="fixed top-0 z-40 flex h-12 w-full max-w-xl flex-row items-center justify-between bg-white px-2 py-2 dark:bg-kekkle-dark-dark"
    >
      <p
        translate="no"
        class="flex transform flex-row items-center whitespace-nowrap font-medium duration-300 dark:text-slate-200"
        @click.stop="handlePopup"
      >
        {{ groupData.name }}
        <chevron-down-icon
          class="max-h-8 h-8 w-full transform p-2 duration-300 hover:rotate-45 dark:text-slate-200"
        ></chevron-down-icon>
      </p>
      <div
        v-if="groupPopup"
        ref="groupPopup"
        class="absolute top-14 z-50 w-3/4 overflow-y-scroll rounded border-2 border-black-dark bg-white p-4 shadow-purple-hard-sm dark:bg-kekkle-dark"
        @click.stop
      >
        <div class="mb-8 max-h-[40vh] overflow-y-scroll">
          <h2 class="text-md font-bold dark:text-slate-200">
            {{ $t("pages.group.your_groups") }}:
          </h2>
          <p
            v-for="group in $store.state.Group.userGroups"
            :key="group.id"
            translate="no"
            class="mb-2 cursor-pointer rounded border-2 border-black-dark p-2 underline shadow-yellow-hard-sm dark:bg-kekkle-dark-light dark:text-slate-200"
            @click="selectGroup(group.id)"
          >
            {{ group.data.name }}
          </p>
        </div>
        <div v-if="userData.groups.length < 20" class="mb-4">
          <h2 class="text-md font-bold dark:text-slate-200">
            {{ $t("pages.group.join_existing_group") }}
          </h2>
          <text-input
            v-model="joinToken"
            class="my-2"
            :placeholder="$t('global.inputs.placeholders.group_token')"
            :label="$t('global.inputs.labels.group_token') + '*'"
            :has-error="!tokenValid"
            :error-message="$t('global.inputs.errors.group_token')"
          >
          </text-input>
          <default-button
            :disabled="!tokenValid || status.creatingNewGroup"
            @click="joinGroup()"
            >{{ $t("global.inputs.labels.join_group") }}</default-button
          >
        </div>
        <div v-if="userData.groups.length < 20">
          <h2 class="text-md font-bold dark:text-slate-200">
            {{ $t("global.inputs.labels.make_group") }}
          </h2>
          <text-input
            v-model="newGroupName"
            class="my-2"
            :label="$t('global.inputs.labels.group_name') + '*'"
            :placeholder="$t('global.inputs.placeholders.group_name')"
            :error-message="$t('global.inputs.errors.group_name')"
            :has-error="!newGroupNameValidated"
          ></text-input>
          <default-button
            :loading="status.creatingNewGroup"
            :disabled="!newGroupNameValidated"
            @click="saveGroup"
            ><p>
              {{ $t("global.inputs.labels.make_group") }}
            </p></default-button
          >
        </div>
      </div>
      <div class="flex flex-row items-center gap-2">
        <div class="relative"></div>
        <div v-if="!pwaInstalled && hasInstallPrompt" class="relative">
          <download-icon
            class="max-h-8 h-8 transform animate-pulse dark:text-slate-200"
            @click="installPrompt.prompt()"
          >
          </download-icon>
          <span class="absolute right-0 top-0 flex h-3 w-3">
            <span
              class="absolute inline-flex h-full w-full animate-ping rounded-full bg-orange opacity-75"
            ></span>
            <span
              class="relative inline-flex h-3 w-3 rounded-full bg-orange"
            ></span>
          </span>
        </div>

        <cog-icon
          class="max-h-8 h-8 transform duration-300 hover:rotate-45 dark:text-slate-200"
          @click="$router.push({ name: 'settings' })"
        ></cog-icon>
      </div>
    </div>
    <group-header-thumb></group-header-thumb>
    <div
      class="mt-2 grid grid-cols-4 items-center justify-items-center p-2 text-center"
    >
      <div class="max-h-20">
        <p class="text-xl font-bold dark:text-slate-200">
          {{ groupData.general_data.number_of_members }}
        </p>
        <p class="text-sm dark:text-slate-200">
          {{ $t("global.member", groupData.general_data.number_of_members) }}
        </p>
      </div>
      <div class="max-h-20">
        <p
          class="text-xl font-bold dark:text-slate-200"
          @click="$router.push({ name: 'Quotes' })"
        >
          {{ groupData.general_data.number_of_quotes }}
        </p>
        <p class="text-sm dark:text-slate-200">
          {{ $t("global.quote", groupData.general_data.number_of_quotes) }}
        </p>
      </div>
      <div class="max-h-20">
        <p
          class="text-xl font-bold dark:text-slate-200"
          @click="$router.push({ name: 'Agenda' })"
        >
          {{ groupData.general_data.number_of_events }}
        </p>
        <p class="text-sm dark:text-slate-200">
          {{ $t("global.event", groupData.general_data.number_of_events) }}
        </p>
      </div>
      <div class="max-h-20">
        <p
          class="text-xl font-bold dark:text-slate-200"
          @click="$router.push({ name: 'Photos' })"
        >
          {{ groupData.general_data.number_of_photos ?? 0 }}
        </p>
        <p class="text-sm dark:text-slate-200">
          {{
            $t("global.photos", groupData.general_data.number_of_photos ?? 0)
          }}
        </p>
      </div>
    </div>
    <div v-if="groupData.description" class="p-2 dark:text-slate-200">
      <h1 class="font-medium dark:text-slate-200">
        {{ $t("pages.group.description") }}:
      </h1>
      <p class="">{{ groupData.description }}</p>
    </div>
    <!-- leden-->

    <p class="p-2 font-medium dark:text-slate-200">
      {{ $t("pages.group.members") }}:
    </p>
    <div class="flex flex-wrap items-center p-2">
      <div
        v-for="member in groupMembersSorted"
        :key="currentGroup + '/' + member.uuid"
        class="mx-2 w-16 text-center"
      >
        <small-user-thumb
          :uuid="member.uuid"
          :name="member.name ? member.name : ''"
        ></small-user-thumb>
        <p class="my-2 truncate text-sm font-medium dark:text-slate-200">
          {{ member.uuid === userUid ? $t("components.quote.you") : ""
          }}{{ member.name }}
        </p>
      </div>
      <div
        class="mx-2 flex-shrink-0 pb-10 text-center"
        @click="$router.push({ name: 'InviteMembers' })"
      >
        <!--Invite button-->
        <div
          :class="groupMembersSorted.length < 2 ? '' : 'w-14'"
          class="flex h-14 items-center justify-center overflow-hidden rounded-lg border-2 border-black-dark bg-white px-2 shadow-blue-hard-sm dark:bg-kekkle-dark-light dark:shadow-teal-hard-sm"
        >
          <user-add-icon
            class="h-6 text-kekkle-blue-dark dark:text-slate-200"
          ></user-add-icon>
          <p
            v-if="groupMembersSorted.length < 2"
            class="ml-2 font-medium text-kekkle-dark dark:text-white"
          >
            {{ $t("pages.invite.invite_button") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ChevronDownIcon,
  DownloadIcon,
  CogIcon,
  UserAddIcon,
} from "@heroicons/vue/outline";
import {
  CLEAR_GROUP_DATA,
  DOWNLOAD_GROUP_PICTURE_DATA,
  GET_GROUP_DATA,
  GET_GROUP_MEMBERS_PROFILE_PICTURE_DATA,
  GET_GROUP_MEMBERS_SORTED,
  GET_GROUP_PICTURE_DATA,
} from "@/store/modules/groups";
import {
  firebaseFunctions,
  usersCollection,
} from "@/helpers/firebase/firebase";
import { httpsCallable } from "firebase/functions";
import { SD, HD, THUMB } from "@/constants/image-sizes";
import SmallUserThumb from "../components/SmallUserThumb.vue";
import { GET_ACTIVE_GROUP, GET_USER_DATA } from "@/store/modules/user";
import { doc, updateDoc } from "firebase/firestore";
import { setErrorToast } from "@/helpers/notifications/toast";
import GroupHeaderThumb from "../components/GroupHeaderThumb.vue";
import * as Sentry from "@sentry/vue";
import DefaultButton from "../components/Buttons/DefaultButton.vue";
import TextInput from "../components/Inputs/TextInput.vue";
import { GET_AUTH_UUID } from "@/store/modules/auth";
import { GET_PWA_INSTALL_PROMPT } from "@/store/modules/prompts";
import { registerMessageToken } from "@/helpers/messaging-token";
import { isSupported } from "firebase/messaging";
import {
  refreshAllUserGroupsData,
  refreshCurrentGroupData,
  refreshUserData,
} from "@/helpers/data/fetchers";
import { globals } from "@/helpers/globals/globals";
import { askFcmTokenFlutter } from "@/helpers/flutter/FlutterFcm";
import { isRunningInFlutter } from "@/helpers/flutter/FlutterInit";
import store from "@/store/store";
import { updateUserDoc } from "@/helpers/firestore/UserService";

export default {
  name: "GroupView",
  components: {
    TextInput,
    DefaultButton,
    GroupHeaderThumb,
    SmallUserThumb,
    CogIcon,
    ChevronDownIcon,
    DownloadIcon,
    UserAddIcon,
  },
  data() {
    return {
      status: {
        creatingNewGroup: false,
      },
      pwaInstalled: true,
      SD: SD,
      HD: HD,
      groupPopup: false,
      newGroupName: "",
      joinToken: "",
    };
  },
  computed: {
    currentGroup() {
      return this.$store.getters[GET_ACTIVE_GROUP];
    },
    userUid() {
      return this.$store.getters[GET_AUTH_UUID];
    },
    userData() {
      return this.$store.getters[GET_USER_DATA];
    },
    groupData() {
      return this.$store.getters[GET_GROUP_DATA];
    },
    groupPictureData() {
      return this.$store.getters[GET_GROUP_PICTURE_DATA];
    },
    groupMembersSorted() {
      return this.$store.getters[GET_GROUP_MEMBERS_SORTED];
    },
    groupMembersProfilePictureData() {
      return this.$store.getters[GET_GROUP_MEMBERS_PROFILE_PICTURE_DATA];
    },
    newGroupNameValidated() {
      return (
        this.newGroupName &&
        this.newGroupName.length < 100 &&
        this.newGroupName.length > 2
      );
    },
    tokenValid() {
      return this.joinToken && this.joinToken.length >= 6;
    },
    hasInstallPrompt() {
      return this.$store.getters[GET_PWA_INSTALL_PROMPT] !== null;
    },
    installPrompt() {
      return this.$store.getters[GET_PWA_INSTALL_PROMPT];
    },
  },
  async created() {
    refreshUserData();
    refreshCurrentGroupData();
    window.scrollTo({ top: 0, left: 0 });
    this.pwaInstalled = !!(
      window.navigator.standalone ||
      window.matchMedia("(display-mode: standalone)").matches ||
      isRunningInFlutter()
    );

    //only fetch once per session.
    if (!globals.hasFetched) {
      //flutter
      if (isRunningInFlutter()) {
        askFcmTokenFlutter();
        globals.hasFetched = true;
      }
      //not in flutter
      else {
        if (await isSupported()) {
          try {
            await Notification.requestPermission();
          } catch (e) {
            //
          }
          try {
            await registerMessageToken();
            globals.hasFetched = true;
          } catch (e) {
            //
          }
        }
      }
    }
  },
  methods: {
    GET_ACTIVE_GROUP() {
      return GET_ACTIVE_GROUP;
    },
    async joinGroup() {
      store.commit("setAuthorFilterUuid", null);
      globals.fetched = false;
      await this.$router.push({
        name: "InviteLinkFollowup",
        query: { token: this.joinToken },
      });
    },
    closePopups() {
      this.groupPopup = false;
    },
    handlePopup() {
      this.groupPopup = !this.groupPopup;
    },
    async selectGroup(groupId) {
      globals.hasFetched = false;
      this.$store.commit("setAuthorFilterUuid", null);
      this.$store.commit("clearQuotes");
      this.$store.commit("clearPhotos");
      this.$store.commit("clearPhotos");
      try {
        await updateUserDoc(this.$store.getters[GET_AUTH_UUID], {
          active_group: groupId,
        });
        this.groupPopup = false;
        await refreshUserData();
        await store.dispatch(DOWNLOAD_GROUP_PICTURE_DATA, {
          format: THUMB,
        });
      } catch (error) {
        setErrorToast({ message: "Er is iets fout gegaan" });
      } finally {
        this.groupPopup = false;
      }
    },
    async saveGroup() {
      if (!this.newGroupNameValidated) {
        setErrorToast({ message: "group name not complete" });
      }
      this.status.creatingNewGroup = true;
      try {
        const createGroupFunction = await httpsCallable(
          firebaseFunctions,
          "createGroup",
        );
        await createGroupFunction({ name: this.newGroupName });
        globals.fetched = false;
        try {
          await refreshUserData();
          this.$store.commit(CLEAR_GROUP_DATA);
          await refreshAllUserGroupsData();
          this.closePopups();
          this.newGroupName = "";
          try {
            await registerMessageToken();
          } catch (e) {
            //
          }
        } catch (e) {
          Sentry.captureException(e);
        }
      } catch (e) {
        setErrorToast({ message: "Could not create group" });
      } finally {
        this.status.creatingNewGroup = false;
      }
    },
  },
};
</script>

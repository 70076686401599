<template>
  <top-navigation previous-route="Photos" title="Comments" />
  <div class="mx-auto min-h-screen pb-12 pt-24">
    <div
      v-if="photoDoc"
      class="sticky top-24 z-20 w-full origin-top transition transition-all duration-500"
    >
      <photo :simple="true" :photo-doc="photoDoc"></photo>
    </div>

    <div v-if="photoDoc">
      <comment
        v-for="(comment, index) in comments"
        :key="comment"
        :author-uuid="comment.author"
        :previous-date="
          index > 0
            ? comments[index - 1].created_at instanceof Date
              ? comments[index - 1].created_at
              : comments[index - 1].created_at.toDate()
            : null
        "
        :date="
          comment.created_at instanceof Date
            ? comment.created_at
            : comment.created_at.toDate()
        "
        :comment="comment.comment"
      />
    </div>
  </div>

  <div class="fixed bottom-0 left-0 flex w-full justify-center">
    <div class="w-full max-w-xl bg-white px-2 pb-4 dark:bg-slate-900">
      <comment-input
        :posting-comment="postingComment"
        @post-comment="postComment"
      ></comment-input>
    </div>
  </div>
</template>
<script setup>
import { ref, defineProps } from "vue";
import TopNavigation from "@/components/TopNavigation.vue";
import Photo from "@/views/Photo.vue";
import { useStore } from "vuex";
import { GET_USER_DATA } from "@/store/modules/user";
import router from "@/router/router";
import {
  collection,
  doc,
  getDoc,
  increment,
  serverTimestamp,
  updateDoc,
  writeBatch,
} from "firebase/firestore";

import {
  firebaseAnalytics,
  firestore,
  usersCollection,
} from "@/helpers/firebase/firebase";
import Comment from "@/components/Comment.vue";
import CommentInput from "@/components/CommentInput.vue";
import * as Sentry from "@sentry/vue";
import { v1 as uuidv1 } from "uuid";
import { GET_AUTH_UUID } from "@/store/modules/auth";
import { handleFirestoreException } from "@/helpers/exceptions/handler";
import { GET_GROUP_DOC_ID } from "@/store/modules/groups";
import { logEvent } from "firebase/analytics";
import {
  refreshAllUserGroupsData,
  refreshUserData,
} from "@/helpers/data/fetchers";
import { updateUserDoc } from "@/helpers/firestore/UserService";
const store = useStore();
let photoDoc = ref(null);
let comments = ref([]);
let postingComment = ref(false);

const props = defineProps(["photoId", "groupId"]);

boot();

async function boot() {
  if (store.getters[GET_USER_DATA]["groups"].includes(props.groupId)) {
    if (store.getters[GET_GROUP_DOC_ID] !== props.groupId) {
      await updateUserDoc(store.getters[GET_AUTH_UUID], {
        active_group: props.groupId,
      });
      await refreshUserData();
      await refreshAllUserGroupsData();
    }
    //we do not have to check for legit quote id, as without the comment will not post
    await fetchComments();
    await fetchPhoto();
  } else {
    await router.push({ name: "Group" });
  }
}

async function fetchComments() {
  try {
    let commentsDoc = await getDoc(
      doc(
        collection(firestore, `groups/${props.groupId}/photos_comments`),
        props.photoId,
      ),
    );
    if (!commentsDoc.exists()) {
      return;
    }
    let commentData = commentsDoc.data();
    delete commentData["affected_field"];

    let arrayWithComments = Object.values(commentData);
    arrayWithComments.sort((a, b) => {
      return a.created_at.toDate().getTime() - b.created_at.toDate().getTime();
    });
    comments.value = arrayWithComments;
  } catch (e) {
    //
  }
}

async function fetchPhoto() {
  try {
    photoDoc.value = await getDoc(
      doc(
        collection(firestore, `groups/${props.groupId}/photos`),
        props.photoId,
      ),
    );
  } catch (e) {
    //
  }
}

async function postComment(e) {
  logEvent(firebaseAnalytics, "added_comment", { for: "photo" });
  let comment = e.comment;
  try {
    postingComment.value = true;
    let batch = writeBatch(firestore);
    let commentDoc = doc(
      collection(firestore, `groups/${props.groupId}/photos_comments`),
      props.photoId,
    );
    let id = uuidv1();
    batch.set(
      commentDoc,
      {
        [id]: {
          comment: comment,
          author: store.getters[GET_AUTH_UUID],
          created_at: serverTimestamp(),
        },
        affected_field: id,
      },
      { merge: true },
    );
    let photoRef = doc(
      collection(firestore, `groups/${props.groupId}/photos`),
      props.photoId,
    );
    batch.update(photoRef, { number_of_comments: increment(1) });
    await batch.commit();
    comments.value.push({
      comment: comment,
      author: store.getters[GET_AUTH_UUID],
      created_at: new Date(),
    });
    setTimeout(() => {
      window.scrollTo({
        behavior: "smooth",
        left: 0,
        top: document.body.scrollHeight,
      });
    }, 20);
  } catch (e) {
    Sentry.captureException(e);
    handleFirestoreException({ e: e });
  } finally {
    postingComment.value = false;
  }
}
</script>

/**
 * Checks the current row of the game state against the correct word.
 *
 * @param {Array} gameState - The current state of the game.
 * @param {string} correctWord - The correct word to be guessed.
 * @param {number} currentRow - The index of the current row in the game state.
 * @returns {Array} The updated game state.
 */
export function checkRow(gameState, correctWord, currentRow) {
  // If the current row is -1, return the game state as is.
  if (currentRow === -1) {
    return gameState;
  }
  const row = gameState[currentRow];
  // If not all cells in the row have a character, return the game state as is.
  if (!row.every((cell) => cell.character !== "")) {
    return gameState;
  }

  // Count the number of times each character appears in the correct word
  const correctWordCounts = {};
  for (let i = 0; i < correctWord.length; i++) {
    const char = correctWord[i];
    correctWordCounts[char] = (correctWordCounts[char] || 0) + 1;
  }

  // First pass: Mark cells as correct and update markedCounts for correct guesses
  const markedCounts = {};
  for (let i = 0; i < row.length; i++) {
    const cell = row[i];
    // If the cell character matches the corresponding character in the correct word, mark the cell as correct and locked.
    if (cell.character === correctWord[i]) {
      markedCounts[cell.character] = (markedCounts[cell.character] || 0) + 1;
      cell.isCorrect = true;
      cell.isLocked = true;
    } else {
      // If the cell character does not match, mark the cell as locked.
      cell.isLocked = true;
    }
  }

  // Second pass: Mark cells as present if they are in the word and not over-counted
  for (let i = 0; i < row.length; i++) {
    const cell = row[i];
    // If the cell character is in the correct word and not over-counted, mark the cell as present.
    if (!cell.isCorrect && correctWord.includes(cell.character)) {
      markedCounts[cell.character] = (markedCounts[cell.character] || 0) + 1;
      if (markedCounts[cell.character] <= correctWordCounts[cell.character]) {
        cell.isPresent = true;
      }
    }
  }

  return gameState;
}

/**
 * Adds a letter to the current cell in the game state.
 *
 * @param {Array} gameState - The current state of the game.
 * @param {number} currentRow - The index of the current row in the game state.
 * @param {number} currentColumn - The index of the current column in the game state.
 * @param {string} key - The letter to be added.
 * @returns {Array} The updated game state.
 */
export function addLetter(gameState, currentRow, currentColumn, key) {
  // If the current cell already has a character, return the game state as is.
  if (gameState[currentRow][currentColumn].character !== "") {
    return gameState;
  }
  // Add the letter to the current cell and mark it as not correct and not locked.
  gameState[currentRow][currentColumn] = {
    character: key,
    isCorrect: false,
    isLocked: false,
  };
  return gameState;
}

/**
 * Removes a letter from the current cell in the game state.
 *
 * @param {Array} gameState - The current state of the game.
 * @param {number} currentRow - The index of the current row in the game state.
 * @param {number} currentColumn - The index of the current column in the game state.
 * @returns {Array} The updated game state.
 */
export function removeLetter(gameState, currentRow, currentColumn) {
  if (currentColumn >= 0) {
    // If the current cell is already empty, move to the previous cell
    if (gameState[currentRow][currentColumn].character === "") {
      if (currentColumn > 0) {
        currentColumn--;
      }
    }
    // Remove the character from the current cell and mark it as not correct, not present and not locked.
    gameState[currentRow][currentColumn] = {
      character: "",
      isCorrect: false,
      isPresent: false,
      isLocked: false,
    };
  }
  return gameState;
}

/**
 * Resets the game state to its initial state.
 *
 * @returns {Array} The initial game state.
 */
export function resetWordle() {
  // Return a 5x6 array of cells, each with an empty character and marked as not correct, not present and not locked.
  return Array.from({ length: 6 }, () =>
    Array.from({ length: 5 }, () => ({
      character: "",
      isCorrect: false,
      isPresent: false,
      isLocked: false,
    })),
  );
}

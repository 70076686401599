<template>
  <!--  the bar-->
  <!--  Add padding to div below-->
  <top-navigation
    :title="$t('pages.quotes.add_quote')"
    :disabled="!validated"
    :loading="status.submitting"
    :button-name="$t('global.inputs.labels.save')"
    @submit-click="submit()"
  />
  <div class="mx-auto min-h-screen pt-24">
    <div class="mb-4 w-full">
      <text-area-input
        v-model="quote"
        :has-error="validationErrors.quote"
        :error-message="$t('global.inputs.errors.quote_mandatory')"
        :label="$t('global.inputs.labels.quote') + '*'"
        :placeholder="$t('global.inputs.placeholders.quote')"
      />
    </div>

    <div class="mb-4 w-full">
      <p class="font-medium dark:text-slate-200">
        {{ $t("pages.quotes.who_said_it") }}
      </p>
      <p v-if="validationErrors.authors" class="mt-1 text-sm text-red-600">
        {{ $t("pages.quotes.one_author_minimum") }}
      </p>
      <multi-selector
        v-model="authors"
        label-key="name"
        index-key="uuid"
        :options="groupMembersSorted"
      />
    </div>
    <div class="mb-4 w-full">
      <text-input
        v-model="customAuthor"
        :label="$t('global.inputs.labels.quote_custom_author')"
        :placeholder="$t('global.inputs.placeholders.quote_custom_author')"
      />
    </div>
    <div class="mb-4 w-full">
      <text-area-input
        v-model="context"
        :has-error="validationErrors.description"
        error-message=""
        :label="$t('global.inputs.labels.quote_additional_context')"
        :placeholder="$t('global.inputs.placeholders.quote_additional_context')"
      />
    </div>

    <div class="mb-4 w-full">
      <p class="font-medium dark:text-slate-200">
        {{ $t("global.inputs.labels.date_time") }} *
      </p>
      <p v-show="validationErrors.timestamp" class="mt-1 text-sm text-red-600">
        {{ $t("global.inputs.errors.date_time") }}
      </p>
      <div class="flex w-full flex-col items-center">
        <date-input v-model="timestamp" :date-time="true"></date-input>
      </div>
    </div>
  </div>
</template>
<script>
import { DatePicker } from "v-calendar";
import { GET_GROUP_MEMBERS_SORTED } from "@/store/modules/groups";
import {
  setSuccessToast,
  setWarningToast,
} from "@/helpers/notifications/toast";
import { handleFirestoreException } from "@/helpers/exceptions/handler";
import TopNavigation from "../components/TopNavigation.vue";
import TextInput from "@/components/Inputs/TextInput.vue";
import TextAreaInput from "@/components/Inputs/TextAreaInput.vue";
import { GET_GROUP_DATA } from "@/store/modules/groups";
import MultiSelector from "../components/Inputs/MultiSelector.vue";
import { GET_AUTH_UUID } from "@/store/modules/auth";
import { globals } from "@/helpers/globals/globals";
import DateInput from "@/components/Inputs/DateInput.vue";
import { dateObjectToDateString } from "@/helpers/converts/date-converter";
import { addQuote } from "@/helpers/firestore/QuoteService";
import { GET_ACTIVE_GROUP } from "@/store/modules/user";

export default {
  name: "AddQuoteView",
  components: {
    DateInput,
    MultiSelector,
    TextAreaInput,
    TextInput,
    TopNavigation,
  },

  data() {
    return {
      status: {
        submitting: false,
      },
      context: null,
      quote: null,
      timestamp: null,
      customAuthor: null,
      authors: [],
    };
  },
  computed: {
    darkModeEnabled() {
      return !!globals.darkModeEnabled;
    },
    groupData() {
      return this.$store.getters[GET_GROUP_DATA];
    },
    groupMembers() {
      return this.groupData.members ? this.groupData.members : {};
    },
    groupMembersSorted() {
      return this.$store.getters[GET_GROUP_MEMBERS_SORTED];
    },
    groupMemberOptions() {
      let options = [];
      for (const [key, value] of Object.entries(this.groupMembers)) {
        let memberObject = {
          uuid: key,
          name: value.name,
        };
        options.push(memberObject);
      }
      return options;
    },
    timestampValid() {
      return this.timestamp;
    },
    quoteValid() {
      if (!this.quote) {
        return false;
      }
      let trimmedQuote = this.quote;
      trimmedQuote = trimmedQuote.replace(" ", "");
      return (
        trimmedQuote && trimmedQuote.length > 0 && trimmedQuote.length < 350
      );
    },
    contextValid() {
      if (this.context) {
        return this.context.length < 350;
      } else {
        return true;
      }
    },
    authorsValid() {
      if (this.authors.length === 0 && !this.customAuthor) {
        return false;
      } else if (this.authors.length === 0 && this.customAuthor) {
        return this.customAuthor.length > 1 && this.customAuthor.length < 350;
      }
      return true;
    },
    validationErrors() {
      let errors = {};
      errors.quote = !this.quoteValid;
      errors.context = !this.contextValid;
      errors.timestamp = !this.timestampValid;
      errors.authors = !this.authorsValid;
      return errors;
    },
    validated() {
      return !Object.values(this.validationErrors).some(
        (value) => value === true,
      );
    },
  },
  created() {
    this.timestamp = dateObjectToDateString(new Date(), "yyyy-mm-ddThh:mm");
  },
  methods: {
    async submit() {
      this.status.submitting = true;
      if (!this.validated) {
        setWarningToast({
          message: this.$t("global.toast.check_all_fields"),
        });
        return;
      }
      let trigrams = {};
      let trimmedQuote = this.quote;
      trimmedQuote = trimmedQuote.replaceAll(" ", "").toLowerCase();
      if (trimmedQuote.length >= 3) {
        for (let i = 0; i < trimmedQuote.length - 2; i++) {
          let trigram = trimmedQuote.slice(i, i + 3);
          trigrams[trigram] = true;
        }
      }
      addQuote(this.$store.getters[GET_ACTIVE_GROUP], {
        quote: this.quote,
        timestamp: new Date(this.timestamp),
        authors: this.authors ? this.authors : [],
        custom_author: this.customAuthor ? this.customAuthor : "",
        context: this.context ? this.context : "",
        created_by: this.$store.getters[GET_AUTH_UUID],
        trigrams: trigrams,
      })
        .then(() => {
          setSuccessToast({
            message: this.$t("global.toast.quote_saved"),
          });
        })
        .catch((e) => {
          handleFirestoreException({ e: e });
        });
      this.$router.push({ name: "Quotes" });
    },
  },
};
</script>

import * as Sentry from "@sentry/vue";

export function hasSupportForFlutterShare() {
  return !!window.flutter && !!window.nativeShareSupported;
}
export function shareWithFlutter({ title: title, subject = "" }) {
  try {
    // eslint-disable-next-line no-undef
    FlutterShare.postMessage(
      JSON.stringify({
        title: title,
        subject: subject,
      }),
    );
  } catch (e) {
    Sentry.captureException(e);
  }
}

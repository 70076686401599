<template>
  <top-navigation
    v-if="eventData"
    :title="$t('pages.event.title')"
    :button-name="canEdit ? $t('global.inputs.labels.change') : 'button'"
    @submit-click="goToEditView()"
  ></top-navigation>
  <div v-if="eventData" class="mx-auto min-h-screen">
    <div class="pt-24 dark:text-slate-200">
      <div class="mb-4 w-full">
        <p class="text-center text-2xl font-medium">
          {{ eventData.name }}
        </p>
      </div>
      <div class="mb-4 w-full">
        <p class="italic">
          {{ $t("global.from") }}:
          <span class="not-italic">{{ from }} </span>
        </p>
        <p class="italic">
          {{ $t("global.till") }}:
          <span class="not-italic"> {{ till }}</span>
        </p>
      </div>
      <div class="w-full border-b border-gray-400"></div>
      <div class="mb-4 mt-4 w-full">
        <p class="text-lg font-normal">{{ $t("global.description") }}:</p>
        <p class="text-base">{{ eventData.description }}</p>
      </div>
      <div class="w-full border-b border-gray-400"></div>
      <div class="w-full">
        <p class="text-lg font-normal">
          {{ $t("components.agenda_items.present") }}:
        </p>
        <div
          v-if="eventData.available && eventData.available.length > 0"
          class="flex flex-wrap"
        >
          <div
            v-for="uuid in eventData.available"
            :key="uuid"
            class="mr-2 w-16 text-center"
          >
            <small-user-thumb color="green" :uuid="uuid" />
            <p class="my-2 truncate font-medium">
              {{
                groupData.members[uuid].name ? groupData.members[uuid].name : ""
              }}
            </p>
          </div>
        </div>
        <div v-else>
          <p class="py-1">
            {{ $t("components.agenda_items.nobody_present") }}:
          </p>
        </div>
      </div>
      <div class="w-full">
        <p class="text-lg font-normal">
          {{ $t("components.agenda_items.maybe") }}:
        </p>
        <div
          v-if="
            eventData.maybe_available && eventData.maybe_available.length > 0
          "
          class="flex flex-wrap"
        >
          <div
            v-for="uuid in eventData.maybe_available"
            :key="uuid"
            class="mr-2 w-16 text-center"
          >
            <small-user-thumb color="orange" :uuid="uuid" />
            <p class="my-2 truncate font-medium">
              {{
                groupData.members[uuid].name ? groupData.members[uuid].name : ""
              }}
            </p>
          </div>
        </div>
        <div v-else>
          <p>{{ $t("components.agenda_items.nobody_maybe") }}</p>
        </div>
      </div>
      <div class="mb-4 w-full">
        <p class="text-lg font-normal">
          {{ $t("components.agenda_items.absent") }}:
        </p>
        <div
          v-if="eventData.non_available && eventData.non_available.length > 0"
          class="flex flex-wrap"
        >
          <div
            v-for="uuid in eventData.non_available"
            :key="uuid"
            class="mr-2 w-16 text-center"
          >
            <small-user-thumb color="red" :uuid="uuid" />
            <p class="my-2 truncate font-medium">
              {{
                groupData.members[uuid].name ? groupData.members[uuid].name : ""
              }}
            </p>
          </div>
        </div>
        <div v-else>
          <p>{{ $t("components.agenda_items.nobody_absent") }}</p>
        </div>
      </div>
    </div>
    <event-comments :event-id="eventId" :group-id="groupId"></event-comments>
  </div>
</template>
<script>
import TopNavigation from "../components/TopNavigation.vue";
import SmallUserThumb from "../components/SmallUserThumb.vue";
import { GET_GROUP_DATA, IS_ADMIN } from "@/store/modules/groups";
import { SD } from "@/constants/image-sizes";
import { GET_AUTH_UUID } from "@/store/modules/auth";
import { dateAgo } from "@/helpers/dates/date-ago";
import EventComments from "../components/EventComments.vue";

export default {
  name: "ViewEventView",
  components: { SmallUserThumb, TopNavigation, EventComments },
  props: {
    groupId: {
      required: true,
    },
    eventId: {
      required: true,
    },
  },
  data() {
    return {
      SD: SD,
    };
  },
  computed: {
    eventData() {
      return this.$store.getters.getSelectedCalendarEventDoc?.data;
    },
    from() {
      return dateAgo(this.eventData.begin_timestamp.toDate());
    },
    till() {
      return dateAgo(this.eventData.end_timestamp.toDate());
    },
    canEdit() {
      return (
        this.$store.getters[IS_ADMIN] ||
        this.eventData.created_by === this.$store.getters[GET_AUTH_UUID]
      );
    },
    groupData() {
      return this.$store.getters[GET_GROUP_DATA];
    },
  },
  async created() {
    this.$store.commit("setSelectedCalendarEventId", this.$props.eventId);
    if (!this.$store.getters.getHasSelectedCalendarEvent) {
      await this.$router.push({ name: "Agenda" });
    }
  },
  methods: {
    goToEditView() {
      this.$router.push({
        name: "editEvent",
      });
    },
  },
};
</script>

import { HD, SD } from "@/constants/image-sizes";
import * as Sentry from "@sentry/vue";
import { GET_AUTH_ID_TOKEN_CLAIMS, GET_AUTH_UUID } from "./auth";
import { GET_ACTIVE_GROUP } from "./user";
import { Timestamp } from "firebase/firestore";
import { getGroupPictureFromFirestorage } from "@/helpers/firestorage/GroupPictureService";
import { getGroupMemberProfilePictureFromFirestorage } from "@/helpers/firestorage/GroupMemberPictureService";
import { fetchGroupsById } from "@/helpers/firestore/GroupService";
import { handleFirestoreException } from "@/helpers/exceptions/handler";
import {
  getObjectPathValue,
  setObjectPathValue,
} from "@/helpers/utils/ObjectPath";
import { setLocalStorageItem } from "../../helpers/utils/local-storage";

const namespace = "groups/";

//mutations
export const SET_USER_GROUPS = namespace + "SET_USER_GROUPS";
const SET_SINGLE_GROUP = namespace + "SET_SINGLE_GROUP";
export const CLEAR_GROUP_DATA = namespace + "clear_group_data";

const SET_GROUP_PICTURE_DATA = namespace + "SET_GROUP_PICTURE_DATA";
const SET_GROUP_MEMBERS_PROFILE_PICTURE_DATA =
  namespace + "SET_GROUP_MEMBERS_PROFILE_PICTURE_DATA";

const SET_GROUP_MEMBERS_PROFILE_PICTURE_LOADING =
  namespace + "SET_GROUP_MEMBERS_PROFILE_PICTURE_LOADING";

const SET_GROUP_PICTURE_LOADING = namespace + "SET_GROUP_PICTURE_LOADING";

export const CLEAR_GROUP_MEMBER_PROFILE_PICTURE_DATA =
  namespace + "CLEAR_GROUP_MEMBER_PROFILE_PICTURE_DATA";

export const CLEAR_GROUP_PROFILE_PICTURE_DATA =
  namespace + "CLEAR_GROUP_PROFILE_PICTURE_DATA";
//getters
export const GET_GROUP_DATA = namespace + "get_group_data";
export const GET_GROUP_MEMBERS_PROFILE_PICTURE_DATA =
  namespace + "GET_GROUP_MEMBERS_PROFILE_PICTURE_DATA";
export const GET_GROUP_PICTURE_DATA = namespace + "GET_GROUP_PICTURE_DATA";
export const GET_BIRTHDAYS = namespace + "get_birthdays";

//action
export const DOWNLOAD_GROUP_MEMBER_PROFILE_PICTURE_DATA =
  namespace + "download_group_members_profile_picture_data";
export const DOWNLOAD_GROUP_PICTURE_DATA =
  namespace + "DOWNLOAD_GROUP_PICTURE_DATA";

export const IS_ADMIN = namespace + "IS_ADMIN";
export const IS_ORGANISER = namespace + "IS_ORGANISER";
export const IS_MEMBER = namespace + "IS_MEMBER";

export const GET_GROUP_MEMBERS = namespace + "GET_GROUP_MEMBERS";
export const GET_GROUP_MEMBERS_SORTED = namespace + "GET_GROUP_MEMBERS_SORTED";

export const GET_GROUP_DOC_ID = namespace + "GET_GROUP_DOC_ID";
export const GET_CHECKED_GROUP_ID = namespace + "GET_CHECKED_GROUP_ID";

export const moduleGroup = {
  namespaced: false,
  state: () => ({
    userGroups: JSON.parse(localStorage.getItem("userGroups")) ?? [],
    groupPictureData: {
      GroupId: {
        blurhash: "",
        original: {
          url: "the url",
          loading: false,
        },
      },
    },
    groupMembersProfilePictureData: {
      GroupId: {
        uuid: {
          original: {
            url: "the url",
            loading: false,
          },
        },
      },
    },
  }),
  mutations: {
    [CLEAR_GROUP_DATA](state) {
      state.groupId = null;
      setLocalStorageItem("groupId", JSON.stringify(null));
      state.groupData = null;
      setLocalStorageItem("groupData", JSON.stringify(null));
      state.userGroups = null;
      setLocalStorageItem("userGroups", JSON.stringify(null));
      state.groupPictureData = {
        original: {
          url: "the url",
          blob: "the data blob",
        },
      };
      state.groupMembersProfilePictureData = {
        uuid: {
          original: {
            url: "the url",
            blob: "the data blob",
          },
        },
      };
    },
    [CLEAR_GROUP_PROFILE_PICTURE_DATA](state) {
      state.groupPictureData = {
        original: {
          url: "the url",
          blob: "the data blob",
        },
      };
    },
    [SET_SINGLE_GROUP](state, { group, groupId }) {
      state.userGroups[groupId] = group;
      setLocalStorageItem("userGroups", JSON.stringify(state.userGroups));
      state.userGroups.forEach((group) => {
        setObjectPathValue(
          state.groupPictureData,
          [group.id, "blurhash"],
          group?.data?.group_picture_blurhash,
        );
      });
    },
    [SET_USER_GROUPS](state, userGroups) {
      state.userGroups = userGroups;
      setLocalStorageItem("userGroups", JSON.stringify(userGroups));
      userGroups.forEach((group) => {
        setObjectPathValue(
          state.groupPictureData,
          [group.id, "blurhash"],
          group?.data?.group_picture_blurhash,
        );
      });
    },
    [SET_GROUP_PICTURE_DATA](state, { format, url, blob, groupId }) {
      setObjectPathValue(state.groupPictureData, [groupId, format], {
        url: url,
        blob: blob,
      });
    },
    [SET_GROUP_PICTURE_LOADING](state, { format, loading, groupId }) {
      setObjectPathValue(
        state.groupPictureData,
        [groupId, format, "loading"],
        loading,
      );
    },
    [SET_GROUP_MEMBERS_PROFILE_PICTURE_LOADING](
      state,
      { uuid, format, loading, groupId },
    ) {
      setObjectPathValue(
        state.groupMembersProfilePictureData,
        [groupId, uuid, format, "loading"],
        loading,
      );
    },
    [SET_GROUP_MEMBERS_PROFILE_PICTURE_DATA](
      state,
      { uuid, url, format, groupId },
    ) {
      setObjectPathValue(
        state.groupMembersProfilePictureData,
        [groupId, uuid, format],
        { url: url, loading: false },
      );
    },
    [CLEAR_GROUP_MEMBER_PROFILE_PICTURE_DATA](state, { uuid, groupId }) {
      setObjectPathValue(
        state.groupMembersProfilePictureData,
        [groupId, uuid],
        {},
      );
    },
  },
  actions: {
    async refreshSingleGroup({ commit }, { groupId }) {
      try {
        let userGroups = await fetchGroupsById([groupId]);
        let groupData = userGroups.find((group) => group.id === groupId);
        if (!groupData) {
          return;
        }
        commit(SET_SINGLE_GROUP, userGroups);
      } catch (e) {
        Sentry.captureException(e);
        handleFirestoreException({ e: e });
      }
    },
    async refreshUserGroups({ commit }, { groupIds }) {
      try {
        let userGroups = await fetchGroupsById(groupIds);
        commit(SET_USER_GROUPS, userGroups);
      } catch (e) {
        Sentry.captureException(e);
        handleFirestoreException({ e: e });
      }
    },
    async [DOWNLOAD_GROUP_PICTURE_DATA](
      { commit, state, getters },
      { format = HD },
    ) {
      const groupId = getters[GET_ACTIVE_GROUP];
      if (state.groupPictureData?.[groupId]?.[format]?.loading === true) {
        return;
      }
      if (!getters[GET_GROUP_DATA]?.group_picture) {
        return;
      }
      commit(SET_GROUP_PICTURE_LOADING, {
        loading: true,
        groupId: groupId,
        format: format,
      });
      try {
        const result = await getGroupPictureFromFirestorage(
          groupId,
          getters[GET_GROUP_DATA].group_picture,
          format,
        );
        commit(SET_GROUP_PICTURE_DATA, {
          format: result.format,
          blob: result.blob,
          url: result.url,
          groupId: groupId,
        });
      } catch (e) {
        Sentry.captureException(e);
      } finally {
        commit(SET_GROUP_PICTURE_LOADING, {
          loading: false,
          groupId: groupId,
          format: format,
        });
      }
    },
    [DOWNLOAD_GROUP_MEMBER_PROFILE_PICTURE_DATA](
      { commit, state, getters },
      { uuid, format = SD },
    ) {
      const groupId = getters[GET_ACTIVE_GROUP];
      if (
        state.groupMembersProfilePictureData[groupId]?.[uuid]?.[format]
          ?.loading === true
      ) {
        return;
      }
      commit(SET_GROUP_MEMBERS_PROFILE_PICTURE_LOADING, {
        uuid: uuid,
        format: format,
        loading: true,
        groupId: groupId,
      });
      getGroupMemberProfilePictureFromFirestorage(groupId, uuid, format)
        .then((result) => {
          commit(SET_GROUP_MEMBERS_PROFILE_PICTURE_DATA, {
            uuid: uuid,
            data: result.blob,
            url: result.url,
            format: format,
            groupId: groupId,
          });
        })
        .catch((error) => {
          Sentry.captureException(error);
        })
        .finally(() => {
          commit(SET_GROUP_MEMBERS_PROFILE_PICTURE_LOADING, {
            uuid: uuid,
            format: format,
            loading: false,
            groupId: groupId,
          });
        });
    },
  },
  getters: {
    [GET_GROUP_PICTURE_DATA](state, getters) {
      return getObjectPathValue(state.groupPictureData, [
        getters[GET_ACTIVE_GROUP],
      ]);
    },
    [GET_GROUP_MEMBERS_PROFILE_PICTURE_DATA](state, getters) {
      return getObjectPathValue(state.groupMembersProfilePictureData, [
        getters[GET_ACTIVE_GROUP],
      ]);
    },
    [GET_GROUP_DATA](state, getters) {
      if (!state.userGroups) {
        return undefined;
      }
      return state.userGroups.find(
        (group) => group.id === getters[GET_ACTIVE_GROUP],
      )?.data;
    },
    getGroupIcalToken(state, getters) {
      return getters[GET_GROUP_DATA]?.ical_token;
    },
    [GET_GROUP_MEMBERS](state, getters) {
      return getters[GET_GROUP_DATA]?.members;
    },
    allGroupMembersUuids(state, getters) {
      return Object.keys(getters.allGroupMembers);
    },
    allGroupMembers(state) {
      const members = {};
      state.userGroups.forEach((group) => {
        Object.entries(group?.data?.members).forEach(([uuid, member]) => {
          members[uuid] = {
            ...member,
            groupId: group.id,
            uuid: uuid,
          };
        });
      });
      return members;
    },
    [GET_GROUP_MEMBERS_SORTED](state, getters) {
      const members = getters[GET_GROUP_MEMBERS] || {};
      return Object.entries(members)
        .map(([uuid, member]) => ({ ...member, uuid }))
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    [GET_BIRTHDAYS](state, getters) {
      const members = getters[GET_GROUP_MEMBERS] || {};
      const birthdays = [];
      for (const [uuid, member] of Object.entries(members)) {
        if (member.birthday) {
          const birthday = new Timestamp(
            member.birthday?.seconds,
            member.birthday?.nanoseconds,
          ).toDate();
          birthdays.push({ uuid, birthday, name: member.name });
        }
      }
      return birthdays;
    },
    [GET_GROUP_DOC_ID](state, getters) {
      return getters[GET_ACTIVE_GROUP];
    },
    [GET_CHECKED_GROUP_ID](state, getters) {
      return getters[GET_ACTIVE_GROUP];
    },
    [IS_MEMBER](state, getters) {
      let uuid = getters[GET_AUTH_UUID];
      return (
        getters[GET_GROUP_MEMBERS]?.[uuid]?.permission === "member" &&
        getters[GET_AUTH_ID_TOKEN_CLAIMS]?.[getters[GET_ACTIVE_GROUP]] ===
          "member"
      );
    },
    [IS_ADMIN](state, getters) {
      let uuid = getters[GET_AUTH_UUID];
      return (
        getters[GET_GROUP_MEMBERS]?.[uuid]?.permission === "admin" &&
        getters[GET_AUTH_ID_TOKEN_CLAIMS]?.[getters[GET_ACTIVE_GROUP]] ===
          "admin"
      );
    },
    [IS_ORGANISER](state, getters) {
      let uuid = getters[GET_AUTH_UUID];
      return (
        getters[GET_GROUP_MEMBERS]?.[uuid]?.permission === "organiser" &&
        getters[GET_AUTH_ID_TOKEN_CLAIMS]?.[getters[GET_ACTIVE_GROUP]] ===
          "organiser"
      );
    },
  },
};

import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  Timestamp,
  where,
} from "firebase/firestore";
import { firestore } from "@/helpers/firebase/firebase";
import { resetWordle } from "@/logic/wordleLogic";
import { docsConverter } from "./FirestoreService";

/**
 * Saves the game state for a user
 * @param {String} matchId
 * @param {String} userUuid
 * @param {Object} payload
 * @returns {Promise<void>}
 */
export async function saveGameState(matchId, userUuid, payload) {
  if (!matchId || !userUuid) {
    throw new Error("MatchId or userUuid not set");
  }
  await setDoc(
    doc(
      collection(firestore, "wordle_games", matchId, "game_states"),
      userUuid,
    ),
    payload,
  );
}

/**
 * Fetches the game state for a user
 * @param matchId
 * @param userUuid
 */
export async function fetchGameState(matchId, userUuid) {
  let stateDoc = docsConverter(
    await getDoc(
      doc(
        collection(firestore, "wordle_games", matchId, "game_states"),
        userUuid,
      ),
    ),
  );
  if (stateDoc.exists) {
    return JSON.parse(stateDoc.data.game_state);
  } else {
    return resetWordle();
  }
}

/**
 * Fetches the newest game from Firestore
 * @param {String} language
 * @returns {Promise<Object>}
 */
export async function fetchNewestGame(language = "NL") {
  let matchQuery = query(
    collection(firestore, "wordle_games"),
    orderBy("date", "desc"),
    where("language", "==", language),
    limit(1),
  );
  let wordleGame = docsConverter(await getDocs(matchQuery));
  if (wordleGame.length === 0) {
    throw new Error("No game found");
  }
  return wordleGame[0];
}

/**
 * Fetches the game for a specific date
 * @param {Date} date
 * @param {String} language
 * @returns {Promise <null | Object>}
 */
export async function fetchGamePerDate(date, language = "NL") {
  // Create a new Date object for the start of the day
  const startOfDay = new Date(date.setHours(0, 0, 0, 0));
  // Create a new Date object for the end of the day
  const endOfDay = new Date(date.setHours(23, 59, 59, 999));

  // Convert JavaScript Date objects to Firestore Timestamps
  const startTimestamp = Timestamp.fromDate(startOfDay);
  const endTimestamp = Timestamp.fromDate(endOfDay);

  let matchQuery = query(
    collection(firestore, "wordle_games"),
    where("date", ">=", startTimestamp),
    where("date", "<=", endTimestamp),
    where("language", "==", language),
    orderBy("date", "desc"),
    limit(1),
  );
  const wordleGame = docsConverter(await getDocs(matchQuery));
  if (wordleGame.length === 0) {
    return null;
  }
  return wordleGame[0];
}

/**
 * Return the id of the game for a specific date
 * @param {Date} date
 * @param {String} language
 * @returns {Promise<String>}
 */
export async function fetchGameIdPerDate(date, language = "NL") {
  let gameDoc = await fetchGamePerDate(date, language);
  if (gameDoc) {
    return gameDoc.id;
  } else {
    return null;
  }
}

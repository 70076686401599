import { months } from "@/i18n/vueI18n";

export function dateAgo(date = new Date()) {
  let minutes = date.getMinutes().toString();
  let hours = date.getHours().toString();

  if (minutes.length === 1) {
    minutes = "0" + date.getMinutes();
  }
  if (hours.length === 1) {
    hours = "0" + hours;
  }
  return (
    date.getDate() +
    " " +
    months()[date.getMonth()] +
    " " +
    date.getFullYear() +
    " " +
    hours +
    ":" +
    minutes
  );
}

<template>
  <div
    class="mx-auto mb-4 max-w-md rounded-xl border-4 border-black-dark bg-white shadow-yellow-hard dark:bg-kekkle-dark"
  >
    <div v-if="groups && Object.entries(groups).length !== 0" class="p-6">
      <h1 class="text-xl font-bold dark:text-slate-200">
        {{ $t("pages.createselectgroup.select_group") }}
      </h1>
      <div
        v-for="group in groups"
        :key="group.id"
        class="m-2 cursor-pointer rounded border-2 border-black-dark p-4 shadow-purple-hard-sm duration-300 hover:translate-y-1 hover:bg-gray-300 hover:shadow-none"
        @click="selectGroup(group.id)"
      >
        <p class="text-center dark:text-slate-200">{{ group.name }}</p>
      </div>
    </div>
    <div class="p-6">
      <h1 class="text-xl font-bold dark:text-slate-200">
        {{ $t("pages.createselectgroup.no_selected_group") }}
      </h1>

      <p class="dark:text-slate-200">
        {{ $t("pages.createselectgroup.join_existing_group") }}
      </p>

      <text-input
        v-model="joinToken"
        class="my-2"
        :placeholder="$t('global.inputs.placeholders.group_token')"
        :label="$t('global.inputs.labels.group_token') + '*'"
        :has-error="!tokenValid"
        :error-message="$t('global.inputs.errors.group_token')"
      >
      </text-input>
      <default-button
        :disabled="!tokenValid || status.submitting"
        @click="joinGroup()"
        >{{ $t("global.inputs.labels.join_group") }}</default-button
      >

      <!--        <br />-->

      <!--        <information-text-->
      <!--          :information="this.$t('pages.createselectgroup.link_reminder_text')"-->
      <!--        />-->
    </div>

    <div class="p-6">
      <h1 class="text-xl font-bold dark:text-slate-200">
        {{ $t("pages.createselectgroup.make_group_question") }}
      </h1>

      <p class="dark:text-slate-200">
        {{ $t("pages.createselectgroup.only_name_needed") }}
      </p>
      <text-input
        v-model="groupName"
        class="my-2"
        :placeholder="$t('global.inputs.placeholders.group_name')"
        :label="$t('global.inputs.labels.group_name') + '*'"
        :has-error="!nameValid"
        :error-message="$t('global.inputs.errors.group_name_karakters')"
      >
      </text-input>

      <default-button
        :disabled="!nameValid"
        :loading="status.submitting"
        @click="saveGroup()"
        >{{ $t("global.inputs.labels.make_group") }}</default-button
      >
      <div v-if="status.error" class="rounded border bg-red-400 p-2">
        <p class="text-white dark:text-slate-200">
          {{ $t("global.inputs.errors.group_error_connection") }}
        </p>
      </div>
    </div>
  </div>

  <default-button color="red" @click="logout()">{{
    $t("global.inputs.placeholders.logout")
  }}</default-button>

  <p
    v-if="(!groups || groups.length === 0) && !status.fetchingGroups"
    class="mt-8 rounded-lg border-2 border-kekkle-dark bg-kekkle-purple-light p-2 text-center font-medium text-kekkle-dark shadow-purple-hard-sm"
    @click="$router.push({ name: 'DeleteAccount' })"
  >
    ⚠️ {{ $t("auth.want_to_delete_account") }} ⚠️
  </p>
</template>
<script>
import { firebaseFunctions } from "@/helpers/firebase/firebase";
import { logout } from "@/helpers/data/logout";
import { httpsCallable } from "firebase/functions";
import DefaultButton from "@/components/Buttons/DefaultButton.vue";

import TextInput from "../components/Inputs/TextInput.vue";
import {
  refreshAllUserGroupsData,
  refreshUserData,
} from "@/helpers/data/fetchers";
import router from "@/router/router";
import { updateUserDoc } from "@/helpers/firestore/UserService";
import { GET_AUTH_UUID } from "@/store/modules/auth";

export default {
  name: "CreateSelectGroup",
  components: { TextInput, DefaultButton },
  data: function () {
    return {
      status: {
        submitting: false,
        error: false,
        fetchingGroups: false,
      },
      groupName: "",
      joinToken: "",
    };
  },

  computed: {
    groups() {
      return this.$store.state.Group.userGroups;
    },
    tokenValid() {
      return this.joinToken && this.joinToken.length >= 6;
    },
    nameValid() {
      return (
        this.groupName &&
        this.groupName.length < 100 &&
        this.groupName.length > 2
      );
    },
  },
  async created() {
    if (window.localStorage.getItem("joinToken")) {
      let joinToken = window.localStorage.getItem("joinToken");
      //reset token;
      window.localStorage.removeItem("joinToken");
      await this.$router.push({
        name: "InviteLinkFollowup",
        query: { token: joinToken },
      });
    } else {
      this.status.fetchingGroups = true;
      await refreshAllUserGroupsData();
      await refreshAllUserGroupsData();
      this.status.fetchingGroups = false;
    }
  },
  methods: {
    router() {
      return router;
    },
    logout() {
      logout();
    },
    async selectGroup(groupId) {
      await updateUserDoc(this.$store.getters[GET_AUTH_UUID], {
        active_group: groupId,
      });
      await refreshUserData();
      await refreshAllUserGroupsData();
      await this.$router.push({ name: "Group" });
    },
    async joinGroup() {
      await this.$router.push({
        name: "InviteLinkFollowup",
        query: { token: this.joinToken },
      });
    },
    async saveGroup() {
      this.status.error = false;
      if (this.nameValid) {
        this.status.submitting = true;
        try {
          const createGroupFunction = await httpsCallable(
            firebaseFunctions,
            "createGroup",
          );
          await createGroupFunction({ name: this.groupName });
          await refreshUserData();
          await refreshAllUserGroupsData();

          this.$router.push({ name: "Group" });
          this.name = "";
        } catch (e) {
          this.status.error = true;
        } finally {
          this.status.submitting = false;
        }
      }
    },
  },
};
</script>

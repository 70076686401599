<template>
  <div
    class="flex w-full max-w-md flex-col rounded-xl border-4 border-black-dark bg-white px-8 pb-4 pt-6 shadow-red-hard dark:bg-kekkle-dark"
  >
    <!--    The STEPS-->
    <div v-if="currentStep === steps.welcome">
      <h1 class="break-words text-xl font-bold dark:text-slate-200">
        {{ $t("pages.complete.welcome", [userEmail]) }}
      </h1>
      <p class="dark:text-slate-200">
        {{ $t("pages.complete.new_user") }}
      </p>
      <p class="dark:text-slate-200">
        {{ $t("pages.complete.more_information") }}
      </p>
      <div class="mt-2">
        <default-button @click="nextStep()"><p>Lets go!</p></default-button>
      </div>
    </div>
    <div v-if="currentStep === steps.name">
      <h1 class="text-xl font-bold dark:text-slate-200">
        {{ $t("global.inputs.labels.your_name") }}
      </h1>
      <p class="dark:text-slate-200">
        {{ $t("pages.complete.your_name_explained") }}
      </p>
      <div class="my-4 w-full">
        <text-input
          v-model="profileData.name"
          :placeholder="$t('global.inputs.placeholders.your_name')"
          :label="$t('global.inputs.labels.your_name')"
          :has-error="validationErrors.name"
          :error-message="$t('global.inputs.errors.name')"
        />
      </div>
      <default-button
        :disabled="validationErrors.name"
        :loading="status.savingDocument"
        @click="saveName()"
        ><p>
          {{ $t("global.inputs.placeholders.next") }}
        </p></default-button
      >
    </div>
    <div v-if="currentStep === steps.birthday">
      <h1 class="text-xl font-bold dark:text-slate-200">
        {{ $t("global.inputs.labels.birthday") }}
      </h1>
      <p class="dark:text-slate-200">
        {{ $t("pages.complete.we_will_add_you_birthday_to_calendar") }}
      </p>

      <div class="my-4">
        <date-input v-model="profileData.birthday"></date-input>
        <p v-show="validationErrors.birthday" class="mt-1 text-sm text-red-600">
          {{ $t("global.inputs.errors.date") }}
        </p>
      </div>
      <default-button :loading="status.savingDocument" @click="saveBirthday()"
        ><p>{{ $t("global.inputs.labels.next") }}</p></default-button
      >
      <p class="my-4 text-sm dark:text-slate-200">
        {{ $t("pages.complete.you_can_skip_your_birthday") }}
      </p>
    </div>
    <div v-if="currentStep === steps.profilePicture">
      <h1 class="text-xl font-bold dark:text-slate-200">
        {{ $t("global.inputs.labels.profile_picture") }}
      </h1>
      <p class="dark:text-slate-200">
        {{ $t("pages.complete.default_picture_in_groups") }}
      </p>
      <div class="">
        <div
          class="m-2 mx-auto h-32 w-32 rounded-full bg-kekkle-red text-center shadow"
          @click="$refs.profile_picture_input.click()"
        >
          <img
            v-if="profileData.profile_picture_url"
            alt="profilePicture"
            :src="profileData.profile_picture_url"
            class="h-full w-full cursor-pointer rounded-full object-cover object-center p-1 hover:bg-white hover:opacity-30"
          />
          <div
            v-if="!profileData.profile_picture_url"
            class="flex h-full w-full cursor-pointer content-center items-center justify-center rounded-full align-middle text-white hover:bg-white hover:bg-opacity-30"
          >
            <p>{{ $t("global.inputs.labels.choose") }}</p>
          </div>
        </div>
        <p
          v-if="validationErrors.profilePicture && status.hasClickedUploadImage"
          class="mt-1 text-center text-sm text-red-600"
        >
          {{ $t("global.inputs.errors.try_smaller_image") }}
        </p>
        <p
          v-if="profileData.profile_picture_url"
          class="text-center font-medium dark:text-slate-200"
        >
          {{ $t("pages.complete.you_look_good") }}
        </p>
        <default-button
          :disabled="validationErrors.profilePicture"
          :loading="status.uploading.profilePicture || status.savingDocument"
          @click="saveProfile()"
          ><p>
            {{ $t("global.inputs.labels.save") }}
          </p></default-button
        >

        <input
          id="profile_picture_input"
          ref="profile_picture_input"
          accept="image/gif, image/jpeg, image/png"
          type="file"
          class="hidden"
          @change="fileChanged"
        />
      </div>
    </div>
    <div v-if="currentStep === steps.complete">
      <h1 class="text-xl font-bold dark:text-slate-200">
        {{ $t("pages.complete.finished") }}
      </h1>
      <p class="dark:text-slate-200">
        {{ $t("pages.complete.complete") }}
      </p>
      <div class="mt-2">
        <default-button @click="toDashboard()"
          ><p class="dark:text-slate-200">
            {{ $t("pages.complete.dashboard") }}
          </p>
        </default-button>
      </div>
    </div>
  </div>
  <p
    class="my-8 cursor-pointer text-center font-medium text-black-dark underline dark:text-slate-200"
    @click="logout()"
  >
    {{ $t("global.inputs.labels.logout") }}
  </p>
</template>

<script>
import { DatePicker } from "v-calendar";
import {
  firebaseAnalytics,
  firebaseStorage,
  usersCollection,
} from "@/helpers/firebase/firebase";
import { uploadBytes, ref } from "firebase/storage";
import { setDoc, doc } from "firebase/firestore";
import DefaultButton from "../components/Buttons/DefaultButton.vue";
import textInput from "../components/Inputs/TextInput.vue";
import {
  setSuccessToast,
  setWarningToast,
} from "@/helpers/notifications/toast";

import {
  handleFirestoreException,
  handleFirestorageException,
} from "@/helpers/exceptions/handler";
import { GET_AUTH_USER, GET_AUTH_UUID } from "@/store/modules/auth";
import { logout } from "@/helpers/data/logout";
import imageCompression from "browser-image-compression";
import {
  refreshAllUserGroupsData,
  refreshUserData,
} from "@/helpers/data/fetchers";
import { globals } from "@/helpers/globals/globals";
import { logEvent } from "firebase/analytics";
import DateInput from "@/components/Inputs/DateInput.vue";

export default {
  name: "CompleteProfile",
  components: { DateInput, DefaultButton, textInput },
  data: function () {
    return {
      currentStep: "WELCOME",
      steps: {
        welcome: "WELCOME",
        name: "NAME",
        birthday: "BIRTHDAY",
        profilePicture: "PROFILE_PICTURE",
        complete: "COMPLETE",
      },
      profileData: {
        name: null,
        profile_picture_file: null,
        profile_picture_url: null,
        birthday: null,
      },
      status: {
        uploading: {
          profilePicture: false,
        },
        savingDocument: false,
        hasClickedUploadImage: false,
      },
    };
  },
  computed: {
    darkModeEnabled() {
      return !!globals.darkModeEnabled;
    },
    uuid() {
      return this.$store.getters[GET_AUTH_UUID];
    },
    currentUser() {
      return this.$store.getters[GET_AUTH_USER];
    },
    userEmail: function () {
      if (this.currentUser && this.currentUser.email) {
        return this.currentUser.email;
      } else {
        return "";
      }
    },
    nameValid() {
      return (
        this.profileData.name &&
        this.profileData.name.length < 100 &&
        this.profileData.name.length > 2
      );
    },
    profilePictureValid() {
      return !!(
        this.profileData.profile_picture_url &&
        this.profileData.profile_picture_file
      );
    },
    validationErrors() {
      let errors = {};
      errors.name = !this.nameValid;
      errors.profilePicture = !this.profilePictureValid;
      return errors;
    },
    validated() {
      return !Object.values(this.validationErrors).some(
        (value) => value === true,
      );
    },
  },
  created() {
    if (this.$store.getters[GET_AUTH_USER].displayName) {
      this.profileData.name = this.$store.getters[GET_AUTH_USER].displayName;
    }
    if (this.$store.getters[GET_AUTH_USER].photoURL) {
      fetch(this.$store.getters[GET_AUTH_USER].photoURL)
        .then(async (result) => {
          let blob = await result.blob();
          let file = new File([blob], "image.jpg", {
            type: blob.type,
          });
          this.profileData.profile_picture_file = file;
          this.profileData.profile_picture_url = URL.createObjectURL(file);
        })
        .catch((e) => {});
    }
  },
  methods: {
    logout() {
      logout();
    },
    toDashboard() {
      this.$router.push({ name: "Group" });
    },
    async saveName() {
      if (this.status.savingDocument || this.validationErrors.name) {
        setWarningToast({ message: "Controleer je naam" });
        return;
      }
      this.profileData.name = this.profileData.name.trim();
      this.nextStep();
    },
    async saveBirthday() {
      if (this.status.savingDocument || this.validationErrors.birthday) {
        setWarningToast({ message: "Controleer de datum" });
        return;
      }
      this.nextStep();
    },
    nextStep() {
      switch (this.currentStep) {
        case this.steps.welcome:
          this.currentStep = this.steps.name;
          break;
        case this.steps.name:
          this.currentStep = this.steps.birthday;
          break;
        case this.steps.birthday:
          this.currentStep = this.steps.profilePicture;
          break;
        case this.steps.profilePicture:
          this.currentStep = this.steps.complete;
          break;
      }
    },
    async saveProfile() {
      if (this.validationErrors.profilePicture) {
        setWarningToast({ message: "Double check your image" });
        return;
      }
      this.status.uploading.profilePicture = true;
      let userStorageRef = ref(
        firebaseStorage,
        "users/" + this.uuid + "/profile_pictures/" + this.uuid,
      );
      //try uploading
      try {
        let compressedFile = await imageCompression(
          this.profileData.profile_picture_file,
          {
            maxSizeMB: 0.5,
            maxWidthOrHeight: 1920,
            useWebWorker: false,
          },
        );
        await uploadBytes(userStorageRef, compressedFile);
        try {
          this.status.savingDocument = true;
          let userDocRef = doc(usersCollection, this.uuid);
          if (this.profileData.birthday) {
            let birthDayDate = new Date(this.profileData.birthday);
            await setDoc(userDocRef, {
              birthday: birthDayDate,
              name: this.profileData.name,
              profile_picture: userStorageRef.fullPath,
            });
          } else {
            await setDoc(userDocRef, {
              name: this.profileData.name,
              profile_picture: userStorageRef.fullPath,
            });
          }
          this.currentStep = this.steps.complete;
          setSuccessToast({
            message: this.$t("global.toast.saved_successfully"),
          });
          await refreshUserData();
          await refreshAllUserGroupsData();
          await this.$router.push({ name: "Group" });
        } catch (e) {
          handleFirestoreException({ e: e });
        } finally {
          this.status.savingDocument = false;
        }
      } catch (e) {
        handleFirestorageException({ e: e });
      } finally {
        this.status.uploading.profilePicture = false;
      }
      logEvent(firebaseAnalytics, "completed_profile", {
        name: "completed_profile",
      });
    },
    async fileChanged(event) {
      this.status.hasClickedUploadImage = true;
      //upload image;
      this.profileData.profile_picture_url = URL.createObjectURL(
        event.target.files[0],
      );
      this.profileData.profile_picture_file = event.target.files[0];
    },
  },
};
</script>

<template>
  <!--  the bar-->
  <!--  Add padding to div below-->
  <top-navigation
    :title="$t('pages.photo.add')"
    :disabled="!validated"
    :loading="status.submitting"
    :percentage="percentage"
    :button-name="$t('global.inputs.labels.save')"
    @submit-click="submit()"
  />
  <div class="mx-auto min-h-screen pt-24">
    <div class="mb-4 w-full">
      <profile-picture-input
        :label="$t('global.inputs.labels.photo') + '*'"
        :large="true"
        :square="true"
        :disabled="status.submitting"
        :url="picture_file_url ? picture_file_url : ''"
        @file-changed="fileChanged"
      ></profile-picture-input>
      <p v-show="validationErrors.photo" class="mt-1 text-sm text-red-600">
        {{ $t("global.inputs.errors.photo") }}
      </p>
    </div>
    <div class="mb-4 w-full">
      <text-area-input
        v-model="caption"
        :has-error="validationErrors.caption"
        :error-message="$t('global.inputs.errors.photo_caption')"
        :label="$t('global.inputs.labels.photo_description') + '*'"
        :placeholder="$t('global.inputs.placeholders.photo_description')"
      />
    </div>

    <div v-if="picture_file">
      <p class="dark:text-slate-200">
        {{ $t("pages.photo.date_time_of_picture") }}
        {{ timestamp ? dateTimeAgo(new Date(timestamp)) : "" }}
      </p>
      <p
        class="cursor-pointer underline dark:text-slate-200"
        @click="customDateTime = !customDateTime"
      >
        {{ $t("pages.photo.change_date_time_of_picture") }}
      </p>
    </div>

    <div v-if="customDateTime" class="mb-4 w-full">
      <p class="font-medium dark:text-slate-200">
        {{ $t("global.inputs.labels.date_time") }} *
      </p>
      <p v-show="validationErrors.timestamp" class="mt-1 text-sm text-red-600">
        {{ $t("global.inputs.errors.date_time") }}
      </p>
      <div class="flex w-full flex-col items-center">
        <date-input v-model="timestamp" :date-time="true"></date-input>
      </div>
    </div>
  </div>
</template>
<script>
import { firebaseStorage, firestore } from "@/helpers/firebase/firebase";
import { collection, doc, increment, writeBatch } from "firebase/firestore";
import { ref, uploadBytesResumable } from "firebase/storage";
import { GET_GROUP_DOC_ID } from "@/store/modules/groups";
import {
  setSuccessToast,
  setWarningToast,
} from "@/helpers/notifications/toast";
import { handleFirestoreException } from "@/helpers/exceptions/handler";
import TopNavigation from "../components/TopNavigation.vue";

import TextAreaInput from "@/components/Inputs/TextAreaInput.vue";
import { GET_GROUP_DATA } from "@/store/modules/groups";

import { GET_AUTH_UUID } from "@/store/modules/auth";
import ProfilePictureInput from "../components/Inputs/ProfilePicutreInput.vue";
import { dateTimeAgo } from "@/helpers/dates/date-time-ago";
import imageCompression from "browser-image-compression";
import { encodeImageToBlurhash } from "@/helpers/blurhash/encodeImageToBlurhash";
import { globals } from "@/helpers/globals/globals";
import DateInput from "@/components/Inputs/DateInput.vue";
import { dateObjectToDateString } from "@/helpers/converts/date-converter";

export default {
  name: "AddPhotoView",
  components: {
    DateInput,
    ProfilePictureInput,
    TextAreaInput,
    TopNavigation,
  },

  data() {
    return {
      status: {
        submitting: false,
      },
      caption: "",
      timestamp: null,
      picture_file: null,
      picture_file_url: null,
      customDateTime: false,
      percentage: 0,
    };
  },
  computed: {
    darkModeEnabled() {
      return !!globals.darkModeEnabled;
    },
    groupData() {
      return this.$store.getters[GET_GROUP_DATA];
    },
    timestampValid() {
      return this.timestamp;
    },
    captionValid() {
      return this.caption.length < 350 && this.caption.length > 2;
    },
    groupDocId() {
      return this.$store.getters[GET_GROUP_DOC_ID];
    },
    validationErrors() {
      let errors = {};
      errors.photo = !this.picture_file;
      errors.caption = !this.captionValid;
      errors.timestamp = !this.timestampValid;
      return errors;
    },
    validated() {
      return !Object.values(this.validationErrors).some(
        (value) => value === true,
      );
    },
  },
  created() {
    this.timestamp = dateObjectToDateString(new Date(), "yyyy-mm-ddThh:mm");
  },
  methods: {
    dateTimeAgo(date) {
      if (date) {
        return dateTimeAgo(date);
      } else {
        return "";
      }
    },
    fileChanged(file) {
      if (file.size / 1020 / 1024 < 50) {
        this.picture_file = file;
        this.picture_file_url = URL.createObjectURL(file);
        if (file.lastModified && file.lastModified > 0) {
          this.timestamp = dateObjectToDateString(
            new Date(file.lastModified),
            "yyyy-mm-ddThh:mm",
          );
        } else {
          this.timestamp = dateObjectToDateString(
            new Date(),
            "yyyy-mm-ddThh:mm",
          );
        }
      } else {
        setWarningToast({
          message: this.$t("global.toast.file_too_big", ["50"]),
        });
      }
    },
    async submit() {
      this.status.submitting = true;
      if (!this.validated) {
        setWarningToast({
          message: this.$t("global.toast.check_all_fields"),
        });
        return;
      }
      let trigrams = {};
      let trimmedCaption = this.caption;
      trimmedCaption = trimmedCaption.replaceAll(" ", "").toLowerCase();
      if (trimmedCaption.length >= 3) {
        for (let i = 0; i < trimmedCaption.length - 2; i++) {
          let trigram = trimmedCaption.slice(i, i + 3);
          trigrams[trigram] = true;
        }
      }
      try {
        let batch = writeBatch(firestore);
        let groupPhotos = collection(
          firestore,
          "groups/" + this.$store.getters[GET_GROUP_DOC_ID] + "/photos",
        );
        let newPhotoRef = doc(groupPhotos);
        let fileId = newPhotoRef.id;
        let photosRef = ref(
          firebaseStorage,
          "groups/" + this.groupDocId + "/pictures/" + fileId,
        );
        let compressedFile = await imageCompression(this.picture_file, {
          maxSizeMB: 5,
          maxWidthOrHeight: 3840,
          useWebWorker: false,
        });
        let superCompressedFile = await imageCompression(compressedFile, {
          maxWidthOrHeight: 20,
          useWebWorker: false,
        });
        let blurHash = await encodeImageToBlurhash(
          URL.createObjectURL(superCompressedFile),
        );
        await new Promise((resolve, reject) => {
          let task = uploadBytesResumable(photosRef, compressedFile);
          task.on(
            "state_changed",
            (snapshot) => {
              this.percentage = Math.max(
                Math.min(
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
                  95,
                ),
                5,
              );
            },
            () => {
              reject();
              // Handle unsuccessful uploads
            },
            () => {
              resolve();
            },
          );
        });

        await batch.set(newPhotoRef, {
          absolute_picture_url:
            "groups/" + this.groupDocId + "/pictures/" + fileId,
          picture_url: "pictures/" + fileId,
          caption: this.caption,
          timestamp: new Date(this.timestamp),
          created_by: this.$store.getters[GET_AUTH_UUID],
          trigrams: trigrams,
          blurhash: blurHash,
        });
        let groupCollection = collection(firestore, "groups/");
        let groupRef = doc(
          groupCollection,
          this.$store.getters[GET_GROUP_DOC_ID],
        );
        batch.update(groupRef, {
          "general_data.number_of_photos": increment(1),
        });
        await batch.commit();
        setSuccessToast({
          message: this.$t("global.toast.photo_saved"),
        });
        this.$router.push({ name: "Photos" });
      } catch (e) {
        handleFirestoreException({ e: e });
      } finally {
        this.status.submitting = false;
      }
    },
  },
};
</script>

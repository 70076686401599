<template>
  <div>
    <h1 class="font-medium dark:text-slate-200">{{ label }}</h1>
    <div
      class="relative mx-auto cursor-pointer bg-kekkle-red text-center shadow"
      :class="[
        url ? '' : 'animate-pulse',
        square ? 'rounded-lg' : 'rounded-full',
        large ? 'h-64 w-full' : 'h-32 w-32',
      ]"
      @click="$refs.profile_picture_input.click()"
    >
      <img
        v-if="url"
        :src="url"
        class="h-full w-full object-cover p-1"
        :class="square ? 'rounded-lg' : 'rounded-full'"
        alt="profile_picture"
      />
      <div
        class="absolute top-0 flex h-full w-full cursor-pointer content-center items-center justify-center align-middle text-white hover:bg-black-dark hover:bg-opacity-40"
        :class="square ? 'rounded-lg' : 'rounded-full'"
      >
        <p v-if="!url">{{ $t("global.inputs.labels.choose") }}</p>
        <p v-else>{{ $t("global.inputs.labels.change") }}</p>
      </div>
      <input
        id="profile_picture_input"
        ref="profile_picture_input"
        :disabled="disabled"
        accept="image/*"
        type="file"
        class="hidden"
        @change="onChange"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "ProfilePictureInput",
  props: {
    disabled: { type: Boolean, default: false },
    camera: { type: Boolean, default: false },
    url: String,
    label: {
      required: true,
      default: "Label",
    },
    square: { type: Boolean, default: false },
    large: { type: Boolean, default: false },
  },
  methods: {
    async onChange(event) {
      if (!event?.target?.files?.[0]) {
        return;
      }
      if (
        event.target.files[0].type === "image/heic" ||
        event.target.files[0].type === "image/heif"
      ) {
        const heic2any = await import("heic2any");
        let converted = await heic2any.default({
          blob: new Blob([event.target.files[0]]),
          toType: "image/jpeg",
        });
        let file = new File([converted], event.target.files[0].name, {
          type: converted.type,
          lastModified: event.target.files[0].lastModified,
        });
        this.$emit("fileChanged", file);
      } else {
        this.$emit("fileChanged", event.target.files[0]);
      }
    },
  },
};
</script>

<template>
  <div
    class="flex items-center"
    @click="emit('update:modelValue', !modelValue)"
  >
    <div
      :class="modelValue ? 'bg-kekkle-purple' : 'bg-white'"
      class="inline-block h-5 w-5 rounded border-2 border-kekkle-dark-dark dark:border-white"
    ></div>
    <label :for="label" class="ml-2 text-kekkle-dark dark:text-slate-200">{{
      label
    }}</label>
  </div>
</template>

<script setup>
import { defineEmits } from "vue";
const props = defineProps({
  modelValue: { type: [Array, Boolean] },
  label: { type: String },
});
const emit = defineEmits(["update:modelValue"]);
</script>

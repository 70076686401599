<script setup>
import TopNavigation from "@/components/TopNavigation.vue";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/vue/outline";
import { ref } from "vue";
import SingleDice from "@/components/SingleDice.vue";
import DefaultButton from "@/components/Buttons/DefaultButton.vue";
import { logEvent } from "firebase/analytics";
import { firebaseAnalytics } from "@/helpers/firebase/firebase";

let numberOfDices = ref(1);

let trigger = ref(false);

function incrementDices() {
  logEvent(firebaseAnalytics, "dice_interaction", { name: "decrease" });
  numberOfDices.value = numberOfDices.value + 1;
}

function decreaseDices() {
  logEvent(firebaseAnalytics, "dice_interaction", { name: "increase" });
  if (numberOfDices.value > 1) {
    numberOfDices.value = numberOfDices.value - 1;
  }
}
</script>
<template>
  <top-navigation :title="$t('components.dice.name')" button-name="button" />
  <div class="mx-auto min-h-screen max-w-md pt-24">
    <div>
      <p class="mb-4 text-center text-xl font-medium dark:text-slate-200">
        {{ $t("components.dice.how_many_dice_question") }}
      </p>
      <div class="flex flex-nowrap items-center justify-center">
        <minus-circle-icon
          class="w-12 cursor-pointer dark:text-slate-200"
          @click="decreaseDices()"
        />
        <p class="mx-8 text-5xl font-medium dark:text-slate-200">
          {{ numberOfDices }}
        </p>
        <plus-circle-icon
          class="w-12 cursor-pointer dark:text-slate-200"
          @click="incrementDices()"
        />
      </div>
    </div>
    <p class="my-2 text-center dark:text-slate-200">
      {{ $t("components.dice.click_on_dice_to_roll") }}
    </p>
    <div class="my-16 flex flex-wrap justify-center gap-16">
      <single-dice
        v-for="index in numberOfDices"
        :key="index"
        :trigger="trigger"
      >
      </single-dice>
    </div>
    <default-button @click="trigger = !trigger">{{
      $t("components.dice.roll_all")
    }}</default-button>
  </div>
</template>

<template>
  <div
    class="flex w-full max-w-md flex-col rounded-xl border-4 border-black-dark bg-white px-4 pb-4 pt-4 shadow-purple-hard dark:bg-kekkle-dark"
  >
    <div v-if="status.emailSend">
      <h1 class="text-xl font-bold dark:text-slate-200">
        {{ $t("auth.email_send_to") }} {{ email }}!
      </h1>
      <p class="dark:text-slate-200">
        {{ $t("auth.follow_link_to_restore_password") }}
      </p>
      <p class="text-sm text-orange">
        {{ $t("auth.check_spam") }}
      </p>
    </div>

    <div v-if="loginForm && !status.emailSend">
      <div class="mt-2 flex flex-col items-center justify-center">
        <text-input
          v-model="email"
          class="mb-4 w-full"
          autocomplete="email"
          :placeholder="$t('global.inputs.placeholders.email')"
          :label="$t('global.inputs.labels.email')"
          :error-message="$t('global.inputs.errors.email')"
          :has-error="!emailValid"
        ></text-input>
        <text-input
          v-model="password"
          type="password"
          class="mb-4 w-full"
          autocomplete="password"
          :placeholder="$t('global.inputs.placeholders.password')"
          :label="$t('global.inputs.labels.password')"
          :error-message="$t('global.inputs.errors.password_not_strong')"
          :has-error="!passwordValid"
        ></text-input>

        <default-button
          :disabled="!loginValid"
          :loading="status.loginin"
          @click="login()"
        >
          <p>{{ $t("auth.login") }}</p>
        </default-button>
        <p class="my-6 text-sm text-gray-700 dark:text-slate-200">
          {{ $t("auth.terms.agreementText") }}
          <router-link class="underline" :to="{ name: 'PrivacyPolicy' }">{{
            $t("auth.terms.privacyPolicy")
          }}</router-link>
          {{ $t("auth.terms.and") }}
          <router-link class="underline" :to="{ name: 'Eula' }">{{
            $t("auth.terms.eula")
          }}</router-link
          >.
        </p>
        <p
          class="mt-8 cursor-pointer underline dark:text-slate-200"
          @click="goToReset"
        >
          {{ $t("auth.forgot_password_question") }}
        </p>

        <p
          class="my-4 cursor-pointer underline dark:text-slate-200"
          @click="goToRegister"
        >
          {{ $t("auth.no_account_register") }}
        </p>
      </div>
      <div v-if="!status.loginin" class="mt-6">
        <p
          class="my-2 cursor-pointer text-center underline dark:text-slate-200"
          @click="$router.push({ name: 'Login' })"
        >
          {{ $t("auth.login_with_socials") }}
        </p>
      </div>
    </div>
    <div v-if="registerForm && !status.emailSend">
      <div class="mt-2 flex flex-col items-center justify-center">
        <text-input
          v-model="email"
          class="mb-4 w-full"
          autocomplete="email"
          :placeholder="$t('global.inputs.placeholders.email')"
          :label="$t('global.inputs.labels.email')"
          :error-message="$t('global.inputs.errors.email')"
          :has-error="!emailValid"
        ></text-input>
        <text-input
          v-model="password"
          type="password"
          class="mb-4 w-full"
          autocomplete="password"
          :placeholder="$t('global.inputs.placeholders.password')"
          :label="$t('global.inputs.labels.password')"
          :error-message="$t('global.inputs.errors.password_not_strong')"
          :has-error="!passwordHasMinimumLength"
        ></text-input>
        <text-input
          v-model="passwordRepeat"
          type="password"
          class="mb-4 w-full"
          autocomplete="password"
          :placeholder="$t('global.inputs.placeholders.repeat_password')"
          :label="$t('global.inputs.labels.repeat_password')"
          :error-message="$t('global.inputs.errors.password_not_same')"
          :has-error="!passwordRepeatValid"
        ></text-input>

        <p
          v-if="passwordHasMinimumLength"
          class="w-full text-left text-green-500"
        >
          ✅ {{ $t("auth.minimum_8_chars") }}
        </p>
        <p
          v-if="!passwordHasMinimumLength"
          class="w-full text-left text-red-500"
        >
          ❌{{ $t("auth.no_minimum_8_chars") }}
        </p>
        <p v-if="passwordHasNumber" class="w-full text-left text-green-500">
          ✅{{ $t("auth.contains_number") }}
        </p>
        <p v-if="!passwordHasNumber" class="w-full text-left text-red-500">
          ❌ {{ $t("auth.contains_no_number") }}
        </p>
        <p
          v-if="passwordHasSpecialChar"
          class="w-full text-left text-green-500"
        >
          ✅ {{ $t("auth.contains_special_char") }}
        </p>
        <p v-if="!passwordHasSpecialChar" class="w-full text-left text-red-500">
          ❌{{ $t("auth.does_not_contain_special_char") }}
        </p>
        <p
          v-if="passwordRepeatValid"
          class="mb-4 w-full text-left text-green-500"
        >
          ✅ {{ $t("auth.password_same") }}
        </p>
        <p
          v-if="!passwordRepeatValid"
          class="mb-4 w-full text-left text-red-500"
        >
          ❌ {{ $t("auth.password_not_same") }}
        </p>

        <default-button
          :disabled="!registerValid"
          :loading="status.registering"
          @click="register()"
        >
          <p>{{ $t("auth.register") }}</p>
        </default-button>

        <p class="my-6 text-sm text-gray-700 dark:text-slate-200">
          {{ $t("auth.terms.agreementTextRegister") }}
          <router-link class="underline" :to="{ name: 'PrivacyPolicy' }">{{
            $t("auth.terms.privacyPolicy")
          }}</router-link>
          {{ $t("auth.terms.and") }}
          <router-link class="underline" :to="{ name: 'Eula' }">{{
            $t("auth.terms.eula")
          }}</router-link
          >.
        </p>

        <p
          class="my-4 cursor-pointer underline dark:text-slate-200"
          @click="goToLogin"
        >
          {{ $t("already_account_login") }}
        </p>
      </div>
    </div>
    <div v-if="passwordResetForm && !status.emailSend">
      <div class="mt-2 flex flex-col items-center justify-center">
        <text-input
          v-model="email"
          class="mb-4 w-full"
          :placeholder="$t('global.inputs.placeholders.email')"
          :label="$t('global.inputs.labels.email')"
          :error-message="$t('global.inputs.errors.email')"
          :has-error="!emailValid"
        ></text-input>

        <default-button
          :disabled="!resetValid"
          :loading="status.resetting"
          @click="reset()"
        >
          <p>{{ $t("auth.ask_new_password_button") }}</p>
        </default-button>

        <p
          class="my-4 cursor-pointer underline dark:text-slate-200"
          @click="goToLogin"
        >
          {{ $t("auth.go_back_to_login") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {
  setErrorToast,
  setSuccessToast,
  setWarningToast,
} from "@/helpers/notifications/toast";
import DefaultButton from "@/components/Buttons/DefaultButton.vue";
import TextInput from "../components/Inputs/TextInput.vue";
import { GET_AUTH, GET_AUTH_USER, GET_AUTH_UUID } from "@/store/modules/auth";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
} from "firebase/auth";
import {
  refreshAllUserGroupsData,
  refreshUserData,
} from "@/helpers/data/fetchers";
import { firebaseAuth } from "@/helpers/firebase/firebase";

export default {
  name: "LoginRegisterView",
  components: { TextInput, DefaultButton },
  data: function () {
    return {
      loginForm: true,
      registerForm: false,
      passwordResetForm: false,
      email: "",
      password: "",
      passwordRepeat: "",
      status: {
        loginin: false,
        registering: false,
        resetting: false,
        emailSend: false,
      },
    };
  },
  computed: {
    emailValid() {
      const emailRegex = RegExp(
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      );
      return emailRegex.test(this.email);
    },
    passwordValid() {
      return this.email.length > 1;
    },
    passwordHasMinimumLength() {
      return this.password.length > 7;
    },
    passwordRepeatValid() {
      return this.password === this.passwordRepeat && this.password.length > 0;
    },
    passwordHasNumber() {
      return /\d/.test(this.password);
    },
    passwordHasSpecialChar() {
      const specialChars = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
      return specialChars.test(this.password);
    },
    loginValid() {
      return this.emailValid && this.passwordValid;
    },
    registerValid() {
      return (
        this.passwordRepeatValid &&
        this.emailValid &&
        this.passwordHasMinimumLength &&
        this.passwordHasNumber &&
        this.passwordHasSpecialChar
      );
    },
    resetValid() {
      return this.emailValid;
    },
  },

  methods: {
    goToLogin() {
      this.loginForm = true;
      this.registerForm = false;
      this.passwordResetForm = false;
    },
    goToRegister() {
      this.loginForm = false;
      this.registerForm = true;
      this.passwordResetForm = false;
    },
    goToReset() {
      this.loginForm = false;
      this.registerForm = false;
      this.passwordResetForm = true;
    },
    async login() {
      try {
        this.status.loginin = true;
        await signInWithEmailAndPassword(
          firebaseAuth,
          this.email,
          this.password,
        );
        await refreshUserData();
        await refreshAllUserGroupsData();
        await this.$router.push({ name: "Group" });
      } catch (e) {
        if (e.code === "auth/user-not-found") {
          setWarningToast({ message: "Geen account gevonden" });
        } else if (
          e.code === "auth/invalid-credential" ||
          e.code === "auth/wrong-password"
        ) {
          setErrorToast({
            message: this.$t("global.toast.password_incorrect"),
          });
        } else {
          setErrorToast({
            message: this.$t("global.toast.could_not_login"),
          });
        }

        this.password = "";
      } finally {
        this.status.loginin = false;
      }
    },
    async register() {
      try {
        this.status.registering = true;
        await createUserWithEmailAndPassword(
          firebaseAuth,
          this.email,
          this.password,
        );
        await sendEmailVerification(this.$store.getters[GET_AUTH_USER]);
        setSuccessToast({
          message: this.$t("global.toast.account_made"),
        });
        await refreshUserData();
        await this.$router.push({ name: "Group" });
        this.$router.push({ name: "Group" });
      } catch (e) {
        if (
          e.code === "auth/email-already-in-use" ||
          e.code === "auth/credential-already-in-use"
        ) {
          setErrorToast({
            message: this.$t("global.toast.email_has_already_an_account"),
          });
          this.goToLogin();
        } else {
          setWarningToast({
            message: this.$t("global.toast.acount_creation_failure"),
          });
        }
      } finally {
        this.status.registering = false;
      }
    },
    async reset() {
      try {
        this.status.resetting = true;
        await sendPasswordResetEmail(firebaseAuth, this.email);
        this.status.emailSend = true;
      } catch (e) {
        if (e.code === "auth/user-not-found") {
          setWarningToast({
            message: this.$t("global.toast.account_not_found"),
          });
        } else {
          setErrorToast({
            message: this.$t("global.toast.reset_email_not_send"),
          });
        }
      } finally {
        this.status.resetting = false;
      }
    },
  },
};
</script>

<template>
  <div class="bg-white bg-kekkle-pattern bg-repeat">
    <div
      class="flex-col bg-white bg-opacity-[97%] dark:bg-kekkle-dark dark:bg-opacity-[99%]"
    >
      <router-view />
    </div>
  </div>
  <navigation-bar v-if="groupData"></navigation-bar>
</template>

<script>
import NavigationBar from "../components/NavigationBar.vue";
import { GET_GROUP_DATA } from "@/store/modules/groups";
export default {
  name: "NavigationLayout",
  components: { NavigationBar },
  computed: {
    groupData() {
      return this.$store.getters[GET_GROUP_DATA];
    },
  },
};
</script>

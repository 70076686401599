<template>
  <top-navigation></top-navigation>
  <div
    class="flex min-h-screen w-full flex-col bg-kekkle-yellow dark:bg-kekkle-dark-light"
  >
    <top-navigation class="invisible relative"></top-navigation>
    <iframe
      v-if="currentLang === 'nl'"
      class="w-full grow scrollbar-hide"
      src="https://kekkle.nl/privacy"
    />
    <iframe
      v-else
      class="w-full grow scrollbar-hide"
      src="https://kekkle.nl/privacy-en"
    />
  </div>
</template>
<script>
import topNavigation from "@/components/TopNavigation.vue";

export default {
  name: "PrivacyPolicyView",
  components: { topNavigation },
  computed: {
    currentLang() {
      return this.$i18n.locale;
    },
  },
};
</script>

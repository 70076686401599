import { firebaseMessaging, firestore } from "@/helpers/firebase/firebase";
import store from "../../store/store.js";
import { GET_USER_DATA, SET_PARTIAL_USER_DATA } from "@/store/modules/user";
import { GET_AUTH_UUID } from "@/store/modules/auth";
import { collection, updateDoc, doc, Timestamp } from "firebase/firestore";
import { getToken } from "firebase/messaging";
import * as Sentry from "@sentry/vue";
import { globals } from "@/helpers/globals/globals";
import { isRunningInFlutter } from "@/helpers/flutter/FlutterInit";
import { updateUserDoc } from "@/helpers/firestore/UserService";

export async function registerMessageToken(token = null) {
  let messageTokens = store.getters[GET_USER_DATA]?.message_tokens;
  let groups = store?.getters?.[GET_USER_DATA]?.groups;
  if (!groups) {
    return;
  }
  if (!messageTokens) {
    messageTokens = [];
  }

  if (token === null && !isRunningInFlutter()) {
    token = await getToken(firebaseMessaging, {
      vapidKey: import.meta.env.VITE_WEB_PUSH_TOKEN,
    });
    globals.hasFetched = true;
    if (!token) {
      return;
    }
  }
  globals.hasFcmToken = true;
  // Add the token to the messageTokens array
  messageTokens.push({ token, updated_at: new Date() });

  for (let i = 0; i < messageTokens.length; i++) {
    //convert dates to JS object
    if (
      messageTokens[i].updated_at?.nanoseconds &&
      messageTokens[i].updated_at?.seconds
    ) {
      messageTokens[i].updated_at = new Timestamp(
        messageTokens[i].updated_at.seconds,
        messageTokens[i].updated_at.nanoseconds,
      ).toDate();
    } else if (messageTokens[i].updated_at?.toDate?.()) {
      messageTokens[i].updated_at = messageTokens[i].updated_at.toDate();
    } else {
      //The updated_at is a date string
      messageTokens[i].updated_at = new Date(messageTokens[i].updated_at);
    }
  }
  // Remove duplicate tokens
  const tokenObject = {};
  messageTokens.forEach(({ token, updated_at }) => {
    if (!tokenObject[token] || tokenObject[token] < updated_at) {
      tokenObject[token] = updated_at;
    }
  });

  // Create the cleanTokens array from the unique tokens
  let cleanTokens = Object.entries(tokenObject).map(([token, updated_at]) => ({
    token,
    updated_at,
  }));

  //Only keep newest 5;
  if (cleanTokens.length > 5) {
    cleanTokens.sort((a, b) => (a.updated_at < b.updated_at ? 1 : -1));
    cleanTokens = cleanTokens.slice(0, 5);
  }

  try {
    await updateUserDoc(store.getters[GET_AUTH_UUID], {
      message_tokens: cleanTokens,
    });
    store.commit(SET_PARTIAL_USER_DATA, { message_tokens: cleanTokens });
  } catch (e) {
    Sentry.captureException(e);
  }
  for (let i = 0; i < groups.length; i++) {
    try {
      let settingsCollection = collection(
        firestore,
        "groups/" + groups[i] + "/settings",
      );
      await updateDoc(doc(settingsCollection, "push"), {
        ["tokens." + store.getters[GET_AUTH_UUID]]: cleanTokens,
      });
    } catch (e) {
      Sentry.captureException(e);
    }
  }
}

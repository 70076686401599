<template>
  <div
    class="flex flex-col overflow-hidden rounded border-2 border-kekkle-dark-dark"
  >
    <div class="flex">
      <span class="h-3 w-3 bg-green-500"></span>
      <span class="h-3 w-3 bg-gray-200"></span>
      <span class="h-3 w-3 bg-yellow-500"></span>
    </div>
    <div class="flex">
      <span class="h-3 w-3 bg-green-500"></span>
      <span class="h-3 w-3 bg-green-500"></span>
      <span class="h-3 w-3 bg-gray-200"></span>
    </div>
    <div class="flex">
      <span class="h-3 w-3 bg-green-500"></span>
      <span class="h-3 w-3 bg-green-500"></span>
      <span class="h-3 w-3 bg-green-500"></span>
    </div>
  </div>
</template>

import * as Sentry from "@sentry/vue";

export function saveFileFlutter(data, fileName, fileFolder, successMessage) {
  try {
    // eslint-disable-next-line no-undef
    FlutterSaveFile.postMessage(
      JSON.stringify({
        data: data,
        fileName: fileName,
        fileFolder: fileFolder,
        successMessage: successMessage,
      }),
    );
  } catch (e) {
    Sentry.captureMessage("SaveFileChannel not available");
  }
}

<template>
  <div v-if="previousDateIsOtherDay" class="mb-4">
    <p
      class="mx-auto w-max rounded border border-black-dark p-1 text-xs shadow-teal-hard-sm dark:bg-kekkle-dark-light dark:text-slate-200"
    >
      {{ dateString2.slice(0, -5) }}
    </p>
  </div>
  <div class="mb-4">
    <div
      class="flex flex-nowrap items-end justify-start gap-x-2"
      :class="isAuthor ? 'flex-row-reverse' : ''"
    >
      <tiny-user-thumb :uuid="authorUuid"></tiny-user-thumb>
      <p
        :class="isAuthor ? 'text-right' : 'text-left'"
        class="break-words rounded-lg border-2 border-black-dark p-2 shadow-teal-hard-sm dark:bg-kekkle-dark-light dark:text-slate-200"
      >
        <span class="text-xs text-teal">{{ authorName }} </span>
        <br />
        {{ comment }}
        <br />
        <span class="text-xs">
          {{ getTime(date) }}
        </span>
      </p>
    </div>
  </div>
</template>
<script setup>
import TinyUserThumb from "@/components/TinyUserThumb.vue";
import { computed, defineProps, onBeforeUnmount, ref } from "vue";
import { useStore } from "vuex";
import { dateTimeAgo } from "@/helpers/dates/date-time-ago";
import { GET_AUTH_UUID } from "@/store/modules/auth";
import { GET_GROUP_DATA } from "@/store/modules/groups";
import { translate } from "@/i18n/vueI18n";
import { dateAgo } from "@/helpers/dates/date-ago";
import { getTime } from "../helpers/dates/get-time";

const store = useStore();
const props = defineProps({
  comment: {
    type: String,
    required: true,
  },
  previousDate: {
    type: Date,
    required: false,
  },
  date: {
    type: Date,
    default: new Date(),
  },
  authorUuid: {
    type: String,
    required: true,
  },
});

let dateString = ref("");
let dateString2 = ref("");

updateTime();
let interval = setInterval(updateTime, 1000);

onBeforeUnmount(() => {
  if (interval) {
    clearInterval(interval);
  }
});

function updateTime() {
  dateString.value = dateTimeAgo(props.date);
  dateString2.value = dateAgo(props.date);
}

let isAuthor = computed(() => {
  return props.authorUuid === store.getters[GET_AUTH_UUID];
});

let authorName = computed(() => {
  return (
    store.getters[GET_GROUP_DATA]?.members?.[props.authorUuid]?.name ??
    translate("global.deleted_user")
  );
});

let previousDateIsOtherDay = computed(() => {
  if (props.previousDate) {
    return (
      props.previousDate.getDate() !== props.date.getDate() ||
      props.previousDate.getMonth() !== props.date.getMonth() ||
      props.previousDate.getFullYear() !== props.date.getFullYear()
    );
  } else {
    return true;
  }
});
</script>

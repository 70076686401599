<script setup>
import Keyboard from "@/views/games/wordle/components/Keyboard.vue";
import TopNavigation from "@/components/TopNavigation.vue";
import WorldeBoard from "@/views/games/wordle/components/WorldeBoard.vue";

import { useStore } from "vuex";
import { onMounted, onUnmounted, ref, watch } from "vue";
import EndGameStats from "@/views/games/wordle/components/EndGameStats.vue";
import TodayLeaderBoard from "@/views/games/wordle/components/TodayLeaderBoard.vue";
import TotalLeaderBoard from "@/views/games/wordle/components/TotalLeaderBoard.vue";
import { globals } from "@/helpers/globals/globals";
const store = useStore();
import { useI18n } from "vue-i18n";
const { t } = useI18n({ useScope: "global" });
import { InformationCircleIcon } from "@heroicons/vue/outline";
import { CalendarIcon } from "@heroicons/vue/solid";
import InformationModal from "@/components/Modals/InformationModal.vue";
import WordleLogo from "@/views/games/wordle/components/WordleLogo.vue";
import MonthlyLeaderBoard from "@/views/games/wordle/components/MonthlyLeaderBoard.vue";
import Ping from "@/components/Ping.vue";
import MedalIcon from "@/components/Icons/MedalIcon.vue";
import LeaderboardIcon from "@/components/Icons/LeaderboardIcon.vue";
import { setLocalStorageItem } from "../../../helpers/utils/local-storage";

store.dispatch("initWorlde");

const darkModeEnabled = !!globals.darkModeEnabled;

const viewStates = ref({
  board: "board",
  leaderboard: "leaderboard",
  statistics: "statistics",
  monthlyScores: "monthly-scores",
});

const viewState = ref(viewStates.value.board);
const showInformationModal = ref(false);

watch(
  () => viewState.value,
  () => {
    store.dispatch("fetchGameState", { force: true });
  },
);

function keyClicked(key) {
  store.dispatch("keyPressed", key);
}

let hasNotSeenInformation = ref(false);
let hasNotSeenMonthlyScore = ref(false);
let refreshInterval = null;

onMounted(() => {
  window.scrollTo(0, 0);
  if (!localStorage.getItem("hasNotSeenInformation")) {
    hasNotSeenInformation.value = true;
  }
  if (!localStorage.getItem("hasNotSeenMonthlyScore")) {
    hasNotSeenMonthlyScore.value = true;
  }

  //TODO Change this to a document listener
  refreshInterval = setInterval(() => {
    if (store.getters.getHasFinished) {
      clearInterval(refreshInterval);
    }
    if (store.getters.getMatchId) {
      store.dispatch("fetchGameState", { matchId: store.getters.getMatchId });
    } else {
      store.dispatch("fetchGameState");
    }
  }, 4000);
});

onUnmounted(() => {
  clearInterval(refreshInterval);
});

function setHasSeenMonthlyScore() {
  hasNotSeenMonthlyScore.value = false;
  setLocalStorageItem("hasNotSeenMonthlyScore", "true");
}

function setHasSeenInformation() {
  hasNotSeenInformation.value = false;
  setLocalStorageItem("hasNotSeenInformation", "true");
}
</script>
<template>
  <information-modal
    :visible="showInformationModal"
    @close="showInformationModal = false"
  >
    <div
      class="prose text-kekkle-dark dark:prose-invert dark:text-white"
      v-html="t('pages.wordle.additional_information')"
    ></div>
  </information-modal>
  <div class="min-h-screen pb-4">
    <top-navigation
      :title="t('pages.games.wordle_title')"
      button-name="button"
    />
    <top-navigation
      class="relative opacity-0"
      :title="t('pages.games.wordle_title')"
      button-name="button"
    />

    <div
      class="my-2 flex justify-between gap-4 border-b-2 border-gray-400 p-2 pt-0"
    >
      <div class="flex items-center gap-x-4">
        <button @click="viewState = viewStates.board">
          <wordle-logo></wordle-logo>
        </button>
        <div
          class="relative"
          @click="
            () => {
              showInformationModal = true;
              setHasSeenInformation();
            }
          "
        >
          <InformationCircleIcon
            class="relative h-6"
            :class="darkModeEnabled ? 'text-white' : 'text-gray-600'"
          ></InformationCircleIcon>
          <ping v-if="hasNotSeenInformation"></ping>
        </div>
      </div>
      <div class="flex flex-wrap items-center gap-4">
        <p @click="viewState = viewStates.leaderboard">
          <MedalIcon class="h-7 w-7 dark:fill-white"></MedalIcon>
        </p>
        <p
          class="relative"
          @click="
            () => {
              viewState = viewStates.monthlyScores;
              setHasSeenMonthlyScore();
            }
          "
        >
          <CalendarIcon class="h-8 dark:text-white"></CalendarIcon>
          <ping v-if="hasNotSeenMonthlyScore"></ping>
        </p>
        <p @click="viewState = viewStates.statistics">
          <leaderboard-icon class="h-8 w-8 dark:fill-white"></leaderboard-icon>
        </p>
      </div>
    </div>
    <div class="mx-auto max-w-md">
      <template v-if="viewState === viewStates.leaderboard">
        <total-leader-board></total-leader-board>
      </template>
      <template v-if="viewState === viewStates.monthlyScores">
        <monthly-leader-board></monthly-leader-board>
      </template>
      <template v-if="viewState === viewStates.statistics">
        <TodayLeaderBoard></TodayLeaderBoard>
      </template>
      <transition name="list">
        <div v-if="viewState === viewStates.board && store.getters.getMatchId">
          <h1 class="mb-2 text-center text-lg font-medium text-gray-500">
            {{ store.getters.getMatchDate.toDate().toLocaleDateString() }}
          </h1>
          <WorldeBoard></WorldeBoard>
          <!--    The board-->
          <div v-if="!store.getters.getHasFinished">
            <Keyboard class="mt-8" @click-key="keyClicked"></Keyboard>
          </div>
          <EndGameStats v-else class="mt-8"></EndGameStats>
        </div>
      </transition>
    </div>
  </div>
</template>
<style scoped>
.list-enter-active {
  transition: all 0.5s ease;
}
.list-leave-active {
  transition: none;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>

import { SD } from "@/constants/image-sizes";
import { firebaseStorage } from "@/helpers/firebase/firebase";
import { getBlob, ref } from "firebase/storage";

export async function getUserProfilePictureFromFirestorage(
  userUuid,
  format = SD,
) {
  if (!userUuid) {
    throw new Error("userUuid is missing");
  }
  let reference = ref(
    firebaseStorage,
    `users/${userUuid}/profile_pictures/${userUuid}${format}`,
  );
  try {
    const blob = await getBlob(reference);
    return {
      blob: blob,
      format: format,
      url: URL.createObjectURL(blob),
    };
  } catch (e) {
    if (e.code == "storage/object-not-found") {
      //could be that the image has not been resized yet
      const originalReference = ref(
        firebaseStorage,
        `users/${userUuid}/profile_pictures/${userUuid}`,
      );
      const blob = await getBlob(originalReference);
      return {
        blob: blob,
        format: format,
        url: URL.createObjectURL(blob),
      };
    } else {
      throw e;
    }
  }
}

<template>
  <div
    ref="scrollContainer"
    class="mb-16 h-96 overflow-y-scroll rounded-2xl border scrollbar-hide dark:border-slate-600 dark:bg-kekkle-dark-light"
  >
    <comment
      v-for="(comment, index) in comments"
      :key="comment"
      :author-uuid="comment.author"
      :date="
        comment.created_at instanceof Date
          ? comment.created_at
          : comment.created_at.toDate()
      "
      :previous-date="
        index > 0
          ? comments[index - 1].created_at instanceof Date
            ? comments[index - 1].created_at
            : comments[index - 1].created_at.toDate()
          : null
      "
      :comment="comment.comment"
    />
  </div>

  <div class="fixed bottom-0 left-0 flex w-full justify-center">
    <div class="w-full max-w-xl bg-white px-2 pb-4 dark:bg-kekkle-dark">
      <comment-input
        :posting-comment="status.postingComment"
        @post-comment="postNewComment"
      ></comment-input>
    </div>
  </div>
</template>
<script>
import {
  collection,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { firebaseAnalytics, firestore } from "@/helpers/firebase/firebase";
import * as Sentry from "@sentry/vue";
import { v1 as uuidv1 } from "uuid";
import { GET_AUTH_UUID } from "@/store/modules/auth";
import { handleFirestoreException } from "@/helpers/exceptions/handler";
import CommentInput from "@/components/CommentInput.vue";
import Comment from "@/components/Comment.vue";
import { logEvent } from "firebase/analytics";
export default {
  name: "EventComments",
  components: { Comment, CommentInput },
  props: {
    groupId: {
      required: true,
    },
    eventId: {
      required: true,
    },
  },
  data() {
    return {
      comments: [],
      comment: null,
      status: {
        postingComment: false,
        fetchingComments: false,
      },
    };
  },
  computed: {
    validated() {
      return !!this.comment;
    },
  },
  created() {
    //Clear the quotes from the
    this.fetchComments();
  },
  methods: {
    async fetchComments() {
      this.status.fetchingComments = true;
      try {
        let commentsDoc = await getDoc(
          doc(
            collection(firestore, `groups/${this.groupId}/events_comments`),
            this.eventId,
          ),
        );

        if (!commentsDoc.exists()) {
          return;
        }

        //remove the 'affected_field' field
        let commentData = commentsDoc.data();
        delete commentData["affected_field"];

        let arrayWithComments = Object.values(commentData);

        arrayWithComments.sort((a, b) => {
          return (
            a.created_at.toDate().getTime() - b.created_at.toDate().getTime()
          );
        });

        this.comments = arrayWithComments;
      } catch (e) {
        Sentry.captureException(e);
      } finally {
        this.status.fetchingComments = false;
        this.$nextTick(() => {
          this.$refs.scrollContainer.scrollTop =
            this.$refs.scrollContainer.scrollHeight;
        });
      }
    },
    async postNewComment(e) {
      logEvent(firebaseAnalytics, "added_comment", { for: "event" });
      let comment = e.comment;
      try {
        this.status.postingComment = true;

        let commentDoc = doc(
          collection(firestore, `groups/${this.groupId}/events_comments`),
          this.eventId,
        );

        let id = uuidv1();

        await setDoc(
          commentDoc,
          {
            [id]: {
              comment: comment,
              author: this.$store.getters[GET_AUTH_UUID],
              created_at: serverTimestamp(),
            },
            affected_field: id,
          },
          { merge: true },
        );

        this.comments.push({
          comment: comment,
          author: this.$store.getters[GET_AUTH_UUID],
          created_at: new Date(),
        });
        this.comment = null;
      } catch (e) {
        Sentry.captureException(e);
        handleFirestoreException({ e: e });
      } finally {
        this.status.postingComment = false;
        this.$nextTick(() => {
          this.$refs.scrollContainer.scrollTop =
            this.$refs.scrollContainer.scrollHeight;
        });
      }
    },
  },
};
</script>

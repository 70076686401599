<template>
  <div class="fixed bottom-0 flex h-auto w-full max-w-xl justify-center">
    <div
      class="absolute bottom-20 mr-5 h-auto transform rounded-lg border-2 border-kekkle-blue-dark bg-kekkle-blue-light p-1 shadow-blue-hard-sm transition-all duration-500 ease-in-out hover:translate-y-1 hover:bg-kekkle-blue hover:shadow-none"
      :class="
        $route.name === 'Agenda'
          ? 'right-0 opacity-100'
          : 'right-0 translate-x-full opacity-0'
      "
      @click="goToAddEvents()"
    >
      <div
        class="align-center flex flex-row content-center items-center justify-center gap-x-2 px-2 py-1"
      >
        <p class="text-md h-min text-kekkle-blue-dark">
          {{ $t("components.navigation.add_event") }}
        </p>
        <plus-icon class="w-5 text-kekkle-blue-dark"></plus-icon>
      </div>
    </div>
    <div
      class="absolute bottom-20 mr-5 h-auto transform rounded-lg border-2 border-kekkle-red-dark bg-kekkle-red-light p-1 shadow-red-hard-sm transition-all duration-500 ease-in-out hover:translate-y-1 hover:bg-kekkle-red hover:shadow-none"
      :class="
        $route.name === 'Quotes'
          ? 'right-0 opacity-100'
          : 'right-0 translate-x-full opacity-0'
      "
      @click="goToAddQuotes()"
    >
      <div
        class="align-center flex flex-row content-center items-center justify-center gap-x-2 px-2 py-1"
      >
        <p class="text-md h-min text-kekkle-red-dark">
          {{ $t("components.navigation.add_quote") }}
        </p>
        <plus-icon class="w-5 text-kekkle-red-dark"></plus-icon>
      </div>
    </div>
    <div
      class="absolute bottom-20 mr-5 h-auto transform rounded-lg border-2 border-kekkle-purple-dark bg-kekkle-purple-light p-1 shadow-purple-hard-sm transition-all duration-500 ease-in-out hover:translate-y-1 hover:bg-kekkle-purple hover:shadow-none"
      :class="
        $route.name === 'Photos'
          ? 'right-0 opacity-100'
          : 'right-0 translate-x-full opacity-0'
      "
      @click="goToAddPhotos()"
    >
      <div
        class="align-center flex flex-row content-center items-center justify-center gap-x-2 px-2 py-1"
      >
        <p class="text-md h-min text-kekkle-purple-dark">
          {{ $t("components.navigation.add_photo") }}
        </p>
        <plus-icon class="w-5 text-kekkle-purple-dark"></plus-icon>
      </div>
    </div>

    <!--  the bar-->
    <div
      class="flex w-full flex-nowrap items-center justify-between gap-x-2 border-t-2 border-kekkle-dark bg-white px-6 pb-4 pt-2 dark:border-kekkle-dark-light dark:bg-kekkle-dark-dark"
    >
      <router-link
        :to="{ name: 'Agenda' }"
        :class="
          $route.name === 'Agenda'
            ? 'w-12 translate-y-1 bg-kekkle-blue p-1 text-white shadow-none dark:text-kekkle-dark-dark'
            : 'w-10 p-2 text-kekkle-blue shadow-blue-hard-sm'
        "
        class="h-10 rounded-lg border-2 border-kekkle-blue transition-all duration-200"
        exact
      >
        <div class="w-30 flex h-full items-center justify-center gap-x-2">
          <CalendarIcon class="h-full" />
        </div>
      </router-link>
      <router-link
        :to="{ name: 'Quotes' }"
        :class="
          $route.name === 'Quotes'
            ? 'w-12 translate-y-1 bg-kekkle-red p-1 text-white shadow-none dark:text-kekkle-dark-dark'
            : 'w-10 p-2 text-kekkle-red shadow-red-hard-sm'
        "
        class="h-10 rounded-lg border-2 border-kekkle-red transition-all duration-200"
        exact
      >
        <div class="w-30 flex h-full items-center justify-center gap-x-2">
          <SpeakerphoneIcon class="h-full" />
        </div>
      </router-link>
      <router-link
        :to="{ name: 'Photos' }"
        :class="
          $route.name === 'Photos'
            ? 'w-12 translate-y-1 bg-kekkle-purple p-1 text-white shadow-none dark:text-kekkle-dark-dark'
            : 'w-10 p-2 text-kekkle-purple shadow-purple-hard-sm'
        "
        class="h-10 rounded-lg border-2 border-kekkle-purple transition-all duration-200"
        exact
      >
        <div class="w-30 flex h-full items-center justify-center gap-x-2">
          <CameraIcon class="h-full" />
        </div>
      </router-link>
      <router-link
        :to="{ name: 'Games' }"
        :class="
          $route.name === 'Games'
            ? 'w-12 translate-y-1 bg-kekkle-yellow p-1 text-white shadow-none dark:text-kekkle-dark-dark'
            : 'w-10 p-2 text-kekkle-yellow shadow-yellow-hard-sm'
        "
        class="h-10 rounded-lg border-2 border-kekkle-yellow transition-all duration-200"
        exact
      >
        <div class="w-30 flex h-full items-center justify-center gap-x-2">
          <PuzzleIcon class="h-full" />
        </div>
      </router-link>

      <router-link
        :to="{ name: 'Group' }"
        class="relative h-10 rounded-lg border-2 border-kekkle-dark bg-kekkle-red shadow-dark-hard-sm transition-all duration-300"
        :class="
          $route.name === 'Group'
            ? 'shadow-none-important w-12 translate-y-1'
            : 'w-10'
        "
      >
        <transition name="opacity"
          ><img
            v-if="pictureUrl"
            alt="group picture"
            class="h-full w-full rounded object-cover"
            :src="pictureUrl"
          />
          <img
            v-else-if="!pictureUrl && blurUrl"
            alt="group picture blur"
            draggable="false"
            class="z-20 mx-auto h-full rounded object-contain"
            :src="blurUrl"
          />
        </transition>
      </router-link>
    </div>
  </div>
</template>

<script>
import {
  CalendarIcon,
  PlusIcon,
  SpeakerphoneIcon,
  CameraIcon,
  PuzzleIcon,
} from "@heroicons/vue/outline";
import { THUMB } from "@/constants/image-sizes";
import {
  DOWNLOAD_GROUP_PICTURE_DATA,
  GET_GROUP_PICTURE_DATA,
} from "@/store/modules/groups";
import { decodeBlurhashToUrl } from "@/helpers/blurhash/decodeBlurhashToUrl";

export default {
  name: "NavigationBar",
  components: {
    CalendarIcon,
    PlusIcon,
    SpeakerphoneIcon,
    CameraIcon,
    PuzzleIcon,
  },
  data() {
    return {
      THUMB: THUMB,
      blurUrl: null,
    };
  },
  computed: {
    groupPictureData() {
      return this.$store.getters[GET_GROUP_PICTURE_DATA];
    },
    pictureUrl() {
      if (
        this.groupPictureData &&
        this.groupPictureData[this.THUMB] &&
        this.groupPictureData[this.THUMB]["url"]
      ) {
        return this.groupPictureData[this.THUMB]["url"];
      } else {
        return null;
      }
    },
  },
  created() {
    //check if there already is an url
    if (!this.pictureUrl) {
      this.$store.dispatch(DOWNLOAD_GROUP_PICTURE_DATA, {
        format: this.THUMB,
      });
    }
    this.blurUrl = decodeBlurhashToUrl(this.groupPictureData?.blurhash);
  },
  methods: {
    goToAddEvents() {
      if (this.$route.name === "Agenda") {
        this.$router.push({ name: "addEvent" });
      }
    },
    goToAddQuotes() {
      if (this.$route.name === "Quotes") {
        this.$router.push({ name: "addQuote" });
      }
    },
    goToAddPhotos() {
      if (this.$route.name === "Photos") {
        this.$router.push({ name: "addPhoto" });
      }
    },
  },
};
</script>

<style lang="scss">
.opacity-enter-active {
  transition: all 1s;
  transition-timing-function: ease;
}

.opacity-leave-active {
  position: absolute;
  transition: all 1s;
  transition-timing-function: ease;
}
.opacity-enter, .opacity-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transition: all 1s;
}
</style>

<template>
  <div class="min-h-screen w-full bg-white bg-kekkle-pattern bg-repeat">
    <div
      class="flex min-h-screen w-full flex-col items-center justify-center bg-white bg-opacity-[95%] px-4 py-8 dark:bg-kekkle-dark-dark dark:bg-opacity-[90%]"
    >
      <img
        alt="Kekkle Logo"
        class="mb-4 w-24"
        src="/img/logo/logo-color-trans.svg"
      />
      <img
        alt="Kekkle wordmark"
        class="mb-4 w-36"
        :src="
          darkModeEnabled
            ? '/img/wordmark/wordmark-white.svg'
            : '/img/wordmark/wordmark-black.svg'
        "
      />

      <router-view />
    </div>
  </div>

  <div
    class="absolute right-2 top-2 flex w-full items-end items-center justify-end gap-2 dark:text-slate-200"
  >
    <p
      class="dark:text-slate-200"
      :class="currentLangauge === 'nl' ? 'underline' : ''"
      @click="setLanguage('nl')"
    >
      🇳🇱Nederlands
    </p>
    <p>{{ $t("auth.or") }}</p>
    <p
      class="dark:text-slate-200"
      :class="currentLangauge === 'en' ? 'underline' : ''"
      @click="setLanguage('en')"
    >
      English 🇬🇧
    </p>
  </div>
</template>

<script>
import { globals } from "@/helpers/globals/globals";
import { setLocalStorageItem } from "../helpers/utils/local-storage";
import { setLanguage } from "@/i18n/vueI18n";

export default {
  name: "AuthenticationLayout",
  computed: {
    currentLangauge() {
      return this.$i18n.locale;
    },
    darkModeEnabled() {
      return !!globals.darkModeEnabled;
    },
  },
  methods: {
    setLanguage,
  },
};
</script>

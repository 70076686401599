/**
 * @param {DocumentSnapshot | QueryDocumentSnapshot} doc
 * @returns {Object}
 */

function convertDoc(doc) {
  return {
    id: doc.id,
    ref: doc.ref,
    exists: doc.exists(),
    data: doc.data(),
    _firebase_document: doc,
  };
}

/**
 *
 * @param {QuerySnapshot | DocumentSnapshot } docs
 * @returns {Array | Object}
 */

export function docsConverter(docs) {
  if (docs.id) {
    return convertDoc(docs);
  }

  if (docs.docs) {
    return docs.docs.map(convertDoc);
  }

  return docs.map(convertDoc);
}

<template>
  <top-navigation
    :title="$t('settings.default_profile_settings')"
    :button-name="$t('global.inputs.labels.save')"
    :disabled="!validated"
    :loading="status.updating"
    @submit-click="update()"
  >
  </top-navigation>
  <div class="mx-auto min-h-screen pt-24">
    <information-text
      :information="$t('settings.default_profile_settings_information')"
    ></information-text>
    <div class="my-4 w-full">
      <profile-picture-input
        :label="$t('settings.profile_picture')"
        :url="
          profile_picture_file ? profile_picture_url : currentProfilePictureUrl
        "
        @file-changed="fileChanged"
      />
      <p v-show="validationErrors.profile_picture"></p>
    </div>
    <div class="my-4 w-full">
      <text-input
        v-model="name"
        :placeholder="$t('global.inputs.placeholders.your_name')"
        :label="$t('global.inputs.labels.name') + '*'"
      />
      <p v-show="validationErrors.name" class="mt-1 text-sm text-red-600">
        {{ $t("global.inputs.errors.name") }}
      </p>
    </div>
    <div class="my-4">
      <h1 class="font-medium dark:text-slate-200">
        {{ $t("global.inputs.labels.birthday") }}
      </h1>
      <date-input v-model="birthday"></date-input>
      <p v-show="validationErrors.birthday" class="mt-1 text-sm text-red-600">
        {{ $t("global.inputs.errors.date") }}
      </p>
    </div>
  </div>
</template>
<script>
import TopNavigation from "../../components/TopNavigation.vue";
import InformationText from "../../components/InformationText.vue";
import {
  CLEAR_USER_PROFILE_PICTURE_DATA,
  DOWNLOAD_USER_PROFILE_PICTURE_DATA,
  GET_USER_DATA,
  GET_USER_PROFILE_PICTURE_DATA,
} from "@/store/modules/user";
import TextInput from "../../components/Inputs/TextInput.vue";
import ProfilePictureInput from "../../components/Inputs/ProfilePicutreInput.vue";

import {
  setErrorToast,
  setSuccessToast,
  setWarningToast,
} from "@/helpers/notifications/toast";
import { SD } from "@/constants/image-sizes";
import { handleFirestoreException } from "@/helpers/exceptions/handler";
import { collection, doc, updateDoc, Timestamp } from "firebase/firestore";
import { ref, uploadBytes } from "firebase/storage";
import {
  firebaseStorage,
  firestore,
  usersCollection,
} from "@/helpers/firebase/firebase";
import imageCompression from "browser-image-compression";
import { GET_AUTH_UUID } from "@/store/modules/auth";
import { globals } from "@/helpers/globals/globals";
import DateInput from "@/components/Inputs/DateInput.vue";
import { updateUserDoc } from "@/helpers/firestore/UserService";
export default {
  name: "DefaultUserProfileSettingsView",
  components: {
    DateInput,
    ProfilePictureInput,
    TextInput,
    InformationText,
    TopNavigation,
  },
  data: function () {
    return {
      SD: SD,
      status: {
        updating: false,
      },
      oldName: "",
      name: "",
      oldBirthday: "",
      birthday: "",
      profile_picture_url: "",
      profile_picture_file: null,
    };
  },
  computed: {
    darkModeEnabled() {
      return !!globals.darkModeEnabled;
    },
    currentProfilePictureUrl() {
      if (
        this.$store.getters[GET_USER_PROFILE_PICTURE_DATA] &&
        this.$store.getters[GET_USER_PROFILE_PICTURE_DATA][SD] &&
        this.$store.getters[GET_USER_PROFILE_PICTURE_DATA][SD]["url"]
      ) {
        return this.$store.getters[GET_USER_PROFILE_PICTURE_DATA][SD]["url"];
      } else {
        return "";
      }
    },
    nameValid() {
      return !!(this.name && this.name.length > 1);
    },
    birthdayValid() {
      return true;
    },
    validationErrors() {
      let errors = {};
      errors.name = !this.nameValid;
      errors.birthday = !this.birthdayValid;
      return errors;
    },
    validated() {
      return !Object.values(this.validationErrors).some(
        (value) => value === true,
      );
    },
  },
  created() {
    this.refresh();

    this.$store.dispatch(DOWNLOAD_USER_PROFILE_PICTURE_DATA, {
      format: SD,
      force: true,
    });

    this.oldName = this.$store.getters[GET_USER_DATA].name;
    this.name = this.$store.getters[GET_USER_DATA].name;

    if (this.$store.getters[GET_USER_DATA].birthday) {
      this.birthday = new Timestamp(
        this.$store.getters[GET_USER_DATA].birthday?.seconds,
        this.$store.getters[GET_USER_DATA].birthday?.nanoseconds,
      )
        .toDate()
        .toISOString()
        .split("T")[0];
      this.oldBirthday = new Timestamp(
        this.$store.getters[GET_USER_DATA].birthday?.seconds,
        this.$store.getters[GET_USER_DATA].birthday?.nanoseconds,
      )
        .toDate()
        .toISOString()
        .split("T")[0];
    }
  },
  methods: {
    async refresh() {
      await this.$store.dispatch("refreshUser", {
        userUuid: this.$store.getters[GET_AUTH_UUID],
      });
      this.$store.dispatch(DOWNLOAD_USER_PROFILE_PICTURE_DATA, {
        format: SD,
        force: true,
      });

      this.oldName = this.$store.getters[GET_USER_DATA].name;
      this.name = this.$store.getters[GET_USER_DATA].name;

      if (this.$store.getters[GET_USER_DATA].birthday) {
        this.birthday = new Timestamp(
          this.$store.getters[GET_USER_DATA].birthday?.seconds,
          this.$store.getters[GET_USER_DATA].birthday?.nanoseconds,
        )
          .toDate()
          .toISOString()
          .split("T")[0];

        this.oldBirthday = new Timestamp(
          this.$store.getters[GET_USER_DATA].birthday?.seconds,
          this.$store.getters[GET_USER_DATA].birthday?.nanoseconds,
        )
          .toDate()
          .toISOString()
          .split("T")[0];
      }
    },
    async update() {
      if (!this.validated) {
        setWarningToast({
          message: this.$t("global.toast.check_all_fields"),
        });
        return;
      }
      if (this.profile_picture_file) {
        try {
          this.status.updating = true;
          let userStorageRef = ref(
            firebaseStorage,
            "users/" +
              this.$store.getters[GET_AUTH_UUID] +
              "/profile_pictures/" +
              this.$store.getters[GET_AUTH_UUID],
          );
          let compressedFile = await imageCompression(
            this.profile_picture_file,
            {
              maxSizeMB: 1,
              maxWidthOrHeight: 3840,
              useWebWorker: false,
            },
          );
          await uploadBytes(userStorageRef, compressedFile);
          setSuccessToast({
            message: this.$t("global.toast.profile_picture_saved"),
          });
          this.$store.commit(CLEAR_USER_PROFILE_PICTURE_DATA);
          await this.$store.dispatch("refreshUser", {
            userUuid: this.$store.getters[GET_AUTH_UUID],
          });
        } catch (e) {
          handleFirestoreException({ e: e });
        } finally {
          this.status.updating = false;
        }
      }

      if (this.oldBirthday !== this.birthday || this.oldName !== this.name) {
        try {
          this.status.updating = true;
          if (this.birthday) {
            await updateUserDoc(this.$store.getters[GET_AUTH_UUID], {
              name: this.name,
              birthday: new Date(this.birthday),
            });
          } else {
            await updateUserDoc(this.$store.getters[GET_AUTH_UUID], {
              name: this.name,
              birthday: null,
            });
          }

          setSuccessToast({
            message: this.$t("global.toast.profile_saved"),
          });
          await this.$store.dispatch("refreshUser", {
            userUuid: this.$store.getters[GET_AUTH_UUID],
          });
        } catch (e) {
          handleFirestoreException({ e: e });
        } finally {
          this.status.updating = false;
        }
      }

      //Update birthday in other groups
      if (this.oldBirthday !== this.birthday) {
        try {
          this.status.updating = true;
          for (
            let i = 0;
            i < this.$store.getters[GET_USER_DATA].groups.length;
            i++
          ) {
            let groupCollection = collection(firestore, "groups");
            let groupDocRef = doc(
              groupCollection,
              this.$store.getters[GET_USER_DATA].groups[i],
            );
            if (this.birthday) {
              await updateDoc(groupDocRef, {
                [`members.${this.$store.getters[GET_AUTH_UUID]}.birthday`]:
                  new Date(this.birthday),
              });
            } else {
              await updateDoc(groupDocRef, {
                [`members.${this.$store.getters[GET_AUTH_UUID]}.birthday`]:
                  null,
              });
            }
          }
        } catch (e) {
          handleFirestoreException({ e: e });
          setErrorToast({
            message: this.$t("global.toast.birthday_not_saved_all_groups"),
          });
        } finally {
          this.status.updating = false;
        }
      }
      this.$router.push({ name: "settings" });
    },
    fileChanged(file) {
      if (file.size / 1020 / 1024 < 100) {
        this.profile_picture_file = file;
        this.profile_picture_url = URL.createObjectURL(file);
      } else {
        setWarningToast({
          message: this.$t("global.toast.file_too_big", ["100"]),
        });
      }
    },
  },
};
</script>

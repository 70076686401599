import * as Sentry from "@sentry/vue";

export function copyToClipboardFlutter(data, successMessage) {
  try {
    // eslint-disable-next-line no-undef
    FlutterClipboard.postMessage(
      JSON.stringify({
        data: data,
        successMessage: successMessage,
      }),
    );
  } catch (e) {
    Sentry.captureException(e);
  }
}

<script setup>
import { computed, onMounted, onUnmounted, ref } from "vue";
import { useStore } from "vuex";
import BackspaceIcon from "@/views/games/wordle/components/BackspaceIcon.vue";
const store = useStore();

const emits = defineEmits(["click"]);
const props = defineProps({
  keyboardKey: String,
});

const isCorrect = computed(() => {
  return store.getters.getCorrectCharacters.includes(props.keyboardKey);
});

const isPresent = computed(() => {
  return store.getters.getPresentCharacters.includes(props.keyboardKey);
});

const isWrong = computed(() => {
  return store.getters.getWrongCharacters.includes(props.keyboardKey);
});

const isTouching = ref(false);

let keydownListener;
onMounted(() => {
  keydownListener = window.addEventListener("keydown", (e) => {
    if (e.key.toLowerCase() === props.keyboardKey) {
      clicked();
    }
  });
});
onUnmounted(() => {
  window.removeEventListener("keydown", keydownListener);
});

const timeOut = ref(null);
function setClickEffect() {
  if (timeOut.value) clearTimeout(timeOut.value);
  isTouching.value = true;
  timeOut.value = setTimeout(() => {
    isTouching.value = false;
    timeOut.value = null;
  }, 300);
}

function clicked() {
  setClickEffect();
  emits("click", props.keyboardKey);
}
</script>
<template>
  <div
    :class="[
      isTouching ? 'scale-105 border border-black-dark dark:border-white' : '',
      isCorrect
        ? 'bg-green-600 fill-white text-white'
        : isPresent
          ? 'bg-yellow-600 fill-white text-white'
          : isWrong
            ? 'bg-gray-500 text-white'
            : 'bg-gray-200',
      ['enter', 'backspace'].includes(keyboardKey) ? 'w-12' : 'w-8',
    ]"
    class="text-md flex h-14 cursor-pointer touch-manipulation justify-center rounded py-4 align-middle font-medium capitalize text-black-dark transition-all duration-75"
    @click="clicked"
  >
    <backspace-icon v-if="keyboardKey === 'backspace'"></backspace-icon>
    <template v-else> {{ keyboardKey }} </template>
  </div>
</template>

<template>
  <div
    class="rounded border-2 border-gray-300 px-3 text-gray-500 transition-all dark:border-kekkle-dark-dark dark:bg-kekkle-dark"
    :class="collapsed ? 'py-1' : 'py-3'"
    @click="collapsed = !collapsed"
  >
    <div class="flex items-center justify-between">
      <p class="dark:text-gray-300">
        {{ t("pages.wordle.score_graph") }}
      </p>
      <ChevronDownIcon
        class="h-6 w-6 transition-transform duration-500"
        :class="collapsed ? '' : 'rotate-180'"
      />
    </div>

    <div
      class="transition-height flex w-full flex-col flex-nowrap gap-1 overflow-hidden transition-all duration-500"
      :class="collapsed ? 'max-h-0' : 'max-h-[300px]'"
    >
      <div class="mt-2 flex flex-nowrap">
        <p class="mr-2 text-xs">1</p>
        <div
          class="rounded bg-green-700 px-2 text-right text-xs text-white"
          :style="`width: ${getPercentage(matches[1])}`"
        >
          {{ matches[1] }}
        </div>
      </div>
      <div class="flex flex-nowrap">
        <p class="mr-2 text-xs">2</p>
        <div
          class="rounded bg-green-700 px-2 text-right text-xs text-white"
          :style="`width: ${getPercentage(matches[2])}`"
        >
          {{ matches[2] }}
        </div>
      </div>
      <div class="flex flex-nowrap">
        <p class="mr-2 text-xs">3</p>
        <div
          class="rounded bg-green-700 px-2 text-right text-xs text-white"
          :style="`width: ${getPercentage(matches[3])}`"
        >
          {{ matches[3] }}
        </div>
      </div>
      <div class="flex flex-nowrap">
        <p class="mr-2 text-xs">4</p>
        <div
          class="rounded bg-green-700 px-2 text-right text-xs text-white"
          :style="`width: ${getPercentage(matches[4])}`"
        >
          {{ matches[4] }}
        </div>
      </div>
      <div class="flex flex-nowrap">
        <p class="mr-2 text-xs">5</p>
        <div
          class="rounded bg-green-700 px-2 text-right text-xs text-white"
          :style="`width: ${getPercentage(matches[5])}`"
        >
          {{ matches[5] }}
        </div>
      </div>
      <div class="flex flex-nowrap">
        <p class="mr-2 text-xs">6</p>
        <div
          class="rounded bg-green-700 px-2 text-right text-xs text-white"
          :style="`width: ${getPercentage(matches[6])}`"
        >
          {{ matches[6] }}
        </div>
      </div>
      <div class="flex flex-nowrap">
        <p class="mr-2 text-xs">😭</p>
        <div
          class="rounded bg-kekkle-dark-light px-2 text-right text-xs text-white"
          :style="`width: ${getPercentage(matches[-1])}`"
        >
          {{ matches[-1] }}
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, ref } from "vue";
import { ChevronDownIcon } from "@heroicons/vue/outline";
import { useI18n } from "vue-i18n";
const { t } = useI18n({ useScope: "global" });

const collapsed = ref(true);

const props = defineProps({
  matches: Object,
});

const totalMatchesPlayed = computed(() => {
  let number = 0;
  Object.values(props.matches).forEach((item) => {
    number = number + item;
  });
  return number;
});

function getPercentage(tries) {
  let percentage = Math.floor(
    Math.max(0, Math.min(100, tries / totalMatchesPlayed.value)) * 100,
  );
  return percentage + "%";
}
</script>

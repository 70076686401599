<template>
  <top-navigation :title="$t('pages.group.members')"> </top-navigation>
  <div class="mx-auto min-h-screen pt-24">
    <div v-for="member in membersSorted" :key="member.uuid">
      <member-item
        :uuid="member.uuid"
        :member="member"
        @delete-member="openDeleteModal(member.uuid, member)"
      />
    </div>
  </div>
  <conformation-modal
    confirm-text="confirm"
    :loading="status.deleting"
    cancel-text="cancel"
    :visible="openDeleteConformation"
    @cancel="cancel"
    @confirm="deleteMember"
  >
    <p class="my-8 text-center font-medium">
      {{ conformationText }}
    </p>
  </conformation-modal>
</template>
<script>
import TopNavigation from "../../components/TopNavigation.vue";
import {
  GET_GROUP_DATA,
  GET_GROUP_DOC_ID,
  GET_GROUP_MEMBERS_SORTED,
  IS_ADMIN,
} from "@/store/modules/groups";
import MemberItem from "../../components/MemberItem.vue";
import ConformationModal from "../../components/Modals/ConformationModal.vue";
import { httpsCallable } from "firebase/functions";
import { firebaseFunctions } from "@/helpers/firebase/firebase";
import { setErrorToast } from "@/helpers/notifications/toast";
import * as Sentry from "@sentry/vue";
import { refreshAllUserGroupsData } from "@/helpers/data/fetchers";
export default {
  name: "MemberSettingsView",
  components: {
    ConformationModal,
    MemberItem,
    TopNavigation,
  },
  data: function () {
    return {
      status: {
        deleting: false,
      },
      openDeleteConformation: false,
      uuidToDelete: "",
      conformationText: "",
    };
  },
  computed: {
    membersSorted() {
      return this.$store.getters[GET_GROUP_MEMBERS_SORTED];
    },
    members() {
      return this.$store.getters[GET_GROUP_DATA] &&
        this.$store.getters[GET_GROUP_DATA].members
        ? this.$store.getters[GET_GROUP_DATA].members
        : [];
    },
    isAdmin() {
      return this.$store.getters[IS_ADMIN];
    },
    groupId() {
      return this.$store.getters[GET_GROUP_DOC_ID];
    },
  },
  created() {},
  methods: {
    openDeleteModal(uuid, member) {
      this.openDeleteConformation = true;
      this.uuidToDelete = uuid;
      this.conformationText =
        this.$t("settings.are_you_sure_you_want_to_delete_member") +
        member.name;
    },
    cancel() {
      this.openDeleteConformation = false;
      this.uuidToDelete = "";
    },
    async deleteMember() {
      if (!this.isAdmin) {
        return;
      }
      if (!this.uuidToDelete) {
        return;
      }
      try {
        this.status.deleting = true;
        const deleteMemberFunction = await httpsCallable(
          firebaseFunctions,
          "deleteMember",
        );
        await deleteMemberFunction({
          uuid: this.uuidToDelete,
          groupId: this.groupId,
        });
        await refreshAllUserGroupsData();
      } catch (e) {
        switch (e.message) {
          case "User has not enough permissions":
            setErrorToast({
              message: "Je moet hiervoor een admin zijn",
            });
            break;
          case "Cannot delete admin":
            setErrorToast({
              message: "Je kunt geen admins verwijderen",
            });
            break;
          default:
            setErrorToast({
              message: "Er is iets niet goed gegaan",
            });
            Sentry.captureException(e);
        }
      } finally {
        this.status.deleting = false;
        this.openDeleteConformation = false;
        this.uuidToDelete = false;
      }
    },
  },
};
</script>

<template>
  <top-navigation :title="$t('settings.delete')"> </top-navigation>
  <div
    class="mx-auto flex min-h-screen flex-col items-center justify-center pt-24"
  >
    <information-text
      v-if="!isAdmin"
      color="green"
      :information="$t('settings.only_admin_delete_group')"
    ></information-text>
    <information-text
      v-if="isAdmin && disabled"
      class="mb-2"
      :information="$t('settings.delete_one_member_left')"
    ></information-text>
    <information-text
      v-if="isAdmin"
      color="red"
      class="mb-16"
      :information="$t('deleting_group_is_permanent')"
    ></information-text>
    <default-button
      v-if="!disabled"
      color="red"
      :loading="status.deleting"
      @click="openModal"
      ><p>{{ $t("settings.delete") }}: {{ groupName }}</p></default-button
    >
  </div>
  <conformation-modal
    :loading="status.deleting"
    :visible="openPopup"
    :confirm-text="$t('delete_group')"
    :cancel-text="$t('dont_delete_group')"
    @cancel="closeModal()"
    @confirm="deleteGroup()"
  >
    <div class="mb-8">
      <h1>
        {{ $t("settings.sure_modal_delete_group") }}
        <span class="font-medium italic text-blue-500">{{
          groupDataToDelete.name
        }}</span>
      </h1>
      <p>
        {{ groupDataToDelete.general_data.number_of_quotes }}
        {{ $t("settings.quotes_will_be_deleted") }}
      </p>
      <p>
        {{ groupDataToDelete.general_data.number_of_events }}
        {{ $t("settings.events_will_be_deleted") }}
      </p>
      <information-text
        color="red"
        :information="$t('settings.it_is_not_possible_to_restore')"
      />
    </div>
  </conformation-modal>
</template>
<script>
import TopNavigation from "../../components/TopNavigation.vue";
import InformationText from "../../components/InformationText.vue";

import { httpsCallable } from "firebase/functions";
import { firebaseFunctions } from "@/helpers/firebase/firebase";
import {
  GET_GROUP_DATA,
  GET_GROUP_DOC_ID,
  IS_ADMIN,
} from "@/store/modules/groups";

import { setSuccessToast } from "@/helpers/notifications/toast";
import DefaultButton from "../../components/Buttons/DefaultButton.vue";
import ConformationModal from "../../components/Modals/ConformationModal.vue";
import {
  refreshAllUserGroupsData,
  refreshUserData,
} from "@/helpers/data/fetchers";
export default {
  name: "DeleteGroupView",
  components: {
    ConformationModal,
    DefaultButton,
    InformationText,
    TopNavigation,
  },
  data: function () {
    return {
      status: {
        deleting: false,
      },
      openPopup: false,
      groupDataToDelete: null,
      groupIdToDelete: "",
    };
  },
  computed: {
    groupData() {
      return this.$store.getters[GET_GROUP_DATA];
    },
    groupName() {
      return this.groupData?.name;
    },
    numberOfMembers() {
      return this.groupData?.members_uuids?.length;
    },
    isAdmin() {
      return this.$store.getters[IS_ADMIN];
    },
    disabled() {
      return !this.$store.getters[IS_ADMIN] || this.numberOfMembers !== 1;
    },
  },
  methods: {
    openModal() {
      this.openPopup = true;
      this.groupDataToDelete = this.$store.getters[GET_GROUP_DATA];
      this.groupIdToDelete = this.$store.getters[GET_GROUP_DOC_ID];
    },
    closeModal() {
      this.openPopup = false;
      this.groupDataToDelete = null;
      this.groupIdToDelete = "";
    },
    async deleteGroup() {
      this.status.deleting = true;
      const deleteGroupFunction = await httpsCallable(
        firebaseFunctions,
        "deleteGroup",
      );
      try {
        await deleteGroupFunction({ groupId: this.groupIdToDelete });
        setSuccessToast({
          message: this.$t("global.toast.group_deleted"),
        });
        await refreshUserData();
        await refreshAllUserGroupsData();
        window.location.reload();
      } catch (e) {
        //
      } finally {
        this.status.deleting = false;
      }
    },
  },
};
</script>

import { createI18n } from "vue-i18n";
import messages from "@/languages/languages";
import { setLocalStorageItem } from "@/helpers/utils/local-storage";
import { logEvent } from "firebase/analytics";
import { firebaseAnalytics } from "@/helpers/firebase/firebase";
import store from "@/store/store";
import { GET_USER_DATA } from "@/store/modules/user";

let languages = ["en", "nl"];
let vueI18nInstance = null;

function getMatchingLanguage() {
  let match = "";
  languages.forEach((lang) => {
    if (navigator.language.includes(lang)) {
      match = lang;
    }
  });
  if (match) {
    return match;
  }
  return "en";
}

function isI18nReady() {
  return (
    vueI18nInstance &&
    vueI18nInstance.global &&
    typeof vueI18nInstance.global.t === "function"
  );
}

// Export an initialization function instead of executing logic immediately
export function initializeI18n() {
  let userLocale = store.getters[GET_USER_DATA]?.language;

  if (userLocale) {
    setLocalStorageItem("locale", userLocale);
  } else {
    userLocale = localStorage.getItem("locale") || getMatchingLanguage();
    setLanguage(userLocale);
  }

  // Initialize vueI18n with the determined locale
  vueI18nInstance = createI18n({
    locale: userLocale,
    fallbackLocale: "en",
    messages: messages,
    legacy: false,
  });

  return vueI18nInstance;
}

export function months() {
  if (!isI18nReady()) {
    return [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
  }
  return [
    vueI18nInstance.global.t("global.month.january"),
    vueI18nInstance.global.t("global.month.february"),
    vueI18nInstance.global.t("global.month.march"),
    vueI18nInstance.global.t("global.month.april"),
    vueI18nInstance.global.t("global.month.may"),
    vueI18nInstance.global.t("global.month.june"),
    vueI18nInstance.global.t("global.month.july"),
    vueI18nInstance.global.t("global.month.august"),
    vueI18nInstance.global.t("global.month.september"),
    vueI18nInstance.global.t("global.month.october"),
    vueI18nInstance.global.t("global.month.november"),
    vueI18nInstance.global.t("global.month.december"),
  ];
}

export function translate(key, number = 1) {
  if (isI18nReady()) {
    return vueI18nInstance.global.t(key, number);
  } else {
    console.warn("vueI18n is not initialized yet.");
    return key;
  }
}

export function setLanguage(key) {
  setLocalStorageItem("locale", key);
  store.dispatch("postUserLanguage", key);
  logEvent(firebaseAnalytics, "settings", {
    name: "change_language",
    to: key,
  });
  if (!isI18nReady()) {
    return;
  }
  vueI18nInstance.global.locale.value = key;
}

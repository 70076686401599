import { setLocalStorageItem } from "../../helpers/utils/local-storage";

const namespace = "auth";

export const SET_AUTH = `${namespace}/SET_AUTH`;
export const SET_AUTH_USER = `${namespace}/SET_AUTH_USER`;
export const SET_AUTH_ID_TOKEN = `${namespace}/'SET_AUTH_ID_TOKEN`;
export const GET_AUTH_ID_TOKEN = `${namespace}/'GET_AUTH_ID_TOKEN`;
export const GET_AUTH_ID_TOKEN_CLAIMS = `${namespace}/'GET_AUTH_ID_TOKEN_CLAIMS`;
export const GET_AUTH = `${namespace}/GET_AUTH`;
export const GET_AUTH_UUID = `${namespace}/GET_AUTH_UUID`;
export const GET_AUTH_USER = `${namespace}/GET_AUTH_USER`;

export const CLEAR_AUTH = `${namespace}/CLEAR_AUTH`;

export const moduleAuth = {
  namespaced: false,
  state: () => ({
    loadingGoogleAuth: false,
    loadingAppleAuth: false,
    firebaseAuthUser: JSON.parse(
      localStorage.getItem("firebaseAuthUser") ?? "null",
    ),
    firebaseAuthIdToken: JSON.parse(
      localStorage.getItem("firebaseAuthIdToken") ?? "null",
    ),
  }),
  mutations: {
    cancelAuth(state) {
      state.loadingGoogleAuth = false;
      state.loadingAppleAuth = false;
    },
    setGoogleAuthLoading(state, loading) {
      state.loadingGoogleAuth = loading;
    },
    setAppleAuthLoading(state, loading) {
      state.loadingAppleAuth = loading;
    },
    [SET_AUTH_USER](state, user) {
      state.firebaseAuthUser = user;
      setLocalStorageItem("firebaseAuthUser", JSON.stringify(user));
    },
    [SET_AUTH_ID_TOKEN](state, token) {
      state.firebaseAuthIdToken = token;
      setLocalStorageItem("firebaseAuthIdToken", JSON.stringify(token));
    },
    [CLEAR_AUTH](state) {
      state.firebaseAuth = null;
      state.firebaseAuthUser = null;
      state.firebaseAuthIdToken = null;
      setLocalStorageItem("firebaseAuth", JSON.stringify(null));
      setLocalStorageItem("firebaseAuthUser", JSON.stringify(null));
      setLocalStorageItem("firebaseAuthIdToken", JSON.stringify(null));
    },
  },
  actions: {},
  getters: {
    loadingAuth: (state) => state.loadingGoogleAuth || state.loadingAppleAuth,
    [GET_AUTH_USER](state) {
      return state.firebaseAuthUser;
    },
    [GET_AUTH_UUID](state) {
      return state.firebaseAuthUser?.uid;
    },
    [GET_AUTH_ID_TOKEN](state) {
      return state.firebaseAuthIdToken;
    },
    [GET_AUTH_ID_TOKEN_CLAIMS](state) {
      return state.firebaseAuthIdToken?.claims;
    },
  },
};

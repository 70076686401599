import { SD } from "@/constants/image-sizes";
import { firebaseStorage } from "@/helpers/firebase/firebase";
import { getBlob, ref } from "firebase/storage";

export async function getGroupMemberProfilePictureFromFirestorage(
  groupId,
  userUuid,
  format = SD,
) {
  if (!groupId || !userUuid) {
    throw new Error("groupId or userUuid is missing");
  }

  let reference = ref(
    firebaseStorage,
    `groups/${groupId}/profile_pictures/${userUuid}${format}`,
  );

  try {
    const blob = await getBlob(reference);
    return {
      blob: blob,
      format: format,
      url: URL.createObjectURL(blob),
    };
  } catch (e) {
    if (e.code == "storage/object-not-found") {
      //could be that the image has not been resized yet
      const originalReference = ref(
        firebaseStorage,
        `groups/${groupId}/profile_pictures/${userUuid}`,
      );
      const blob = await getBlob(originalReference);
      return {
        blob: blob,
        format: format,
        url: URL.createObjectURL(blob),
      };
    } else {
      throw e;
    }
  }
}

const namespace = "prompts/";

export const SET_PWA_INSTALL_PROMPT = namespace + "SET_PWA_INSTALL_PROMPT";
export const GET_PWA_INSTALL_PROMPT = namespace + "GET_PWA_INSTALL_PROMPT";
export const CLEAR_PWA_INSTALL_PROMPT = namespace + "CLEAR_PWA_INSTALL_PROMPT";

export const modulePrompts = {
  namespaced: false,
  state: () => ({
    pwaInstallPrompt: null,
  }),
  mutations: {
    [SET_PWA_INSTALL_PROMPT](state, prompt) {
      state.pwaInstallPrompt = prompt;
    },
    [CLEAR_PWA_INSTALL_PROMPT](state) {
      state.pwaInstallPrompt = null;
    },
  },
  actions: {},
  getters: {
    [GET_PWA_INSTALL_PROMPT](state) {
      return state.pwaInstallPrompt;
    },
  },
};

import { translate } from "@/i18n/vueI18n";
import router from "@/router/router";
import {
  setErrorToast,
  setInfoToast,
  setSuccessToast,
  setWarningToast,
} from "@/helpers/notifications/toast";

export function flutterToast(type, translationKey, clickLink = "") {
  let translation = translate(translationKey);
  const toastOptions = {
    message: translation,
    click: () => {
      if (clickLink) {
        router.push(clickLink);
      }
    },
  };
  switch (type) {
    case "success":
      setSuccessToast(toastOptions);
      break;
    case "warning":
      setWarningToast(toastOptions);
      break;
    case "info":
      setInfoToast(toastOptions);
      break;
    case "error":
      setErrorToast(toastOptions);
      break;
    default:
      setInfoToast(toastOptions);
  }
}

export const globals = {
  hasFetched: false,
  darkModeEnabled: false,
  hasFcmToken: false,
  hasMobileNotificationPermission: false,
};

export function clearGlobals() {
  globals.hasFetched = false;
  globals.darkModeEnabled = false;
  globals.hasFcmToken = false;
  globals.hasMobileNotificationPermission = false;
}

<template>
  <top-navigation :title="member.name ?? ''" />

  <div class="mx-auto min-h-screen pt-24 dark:text-slate-200">
    <div class="mb-8 flex flex-wrap justify-center gap-x-4">
      <div
        style="max-height: 30vh"
        class="max-h-1/3 mb-4 w-48 overflow-hidden rounded-xl border-4 border-black-dark shadow-teal-hard"
      >
        <transition name="opacity">
          <img
            v-if="pictureUrl || thumbUrl"
            draggable="false"
            alt="Profile Picture"
            class="h-full w-full object-cover"
            :src="thumbUrl ? thumbUrl : pictureUrl"
          />
        </transition>
      </div>
    </div>

    <div class="mb-8">
      <p class="w-full font-medium">
        {{ member.name }}
        <span class="w-min rounded-3xl px-2 py-1 text-sm" :class="memberClass">
          {{ memberText }}</span
        >
      </p>
      <p v-if="birthdayDate">🎂 {{ birthdayString }}</p>
    </div>
    <div v-if="latestQuote !== null" class="mb-8">
      <h2 class="text font-medium">
        {{ i18n.t("pages.memberView.newest_quote") }}
      </h2>
      <h3 class="font-patrick-hand text-3xl">"{{ latestQuote?.quote }}"</h3>
    </div>

    <div class="mb-8 flex flex-wrap justify-center gap-4">
      <div
        class="relative flex h-32 w-32 flex-col items-center justify-center overflow-hidden rounded-lg border-2 border-black-dark p-2 shadow-red-hard-sm dark:bg-kekkle-dark-light"
        @click="
          () => {
            if (numberOfQuotes > 0) {
              router.push({
                name: 'QuotesWithAuthor',
                params: { author: member.uuid },
              });
            }
          }
        "
      >
        <p class="absolute text-8xl opacity-20">📣</p>
        <h2 class="relative text-3xl font-bold">
          {{ numberOfQuotes }}
        </h2>
        <p class="relative capitalize">
          {{ i18n.t("global.quote", numberOfQuotes) }}
        </p>
      </div>
      <div
        class="relative flex h-32 w-32 flex-col items-center justify-center overflow-hidden rounded-lg border-2 border-black-dark p-2 dark:bg-kekkle-dark-light"
      >
        <p class="absolute text-8xl opacity-20">📷</p>
        <h2 class="relative text-3xl font-bold">
          {{ numberOfPhotos }}
        </h2>
        <p class="relative capitalize">
          {{ i18n.t("global.photos", numberOfPhotos) }}
        </p>
      </div>
      <div
        class="relative flex h-32 w-32 flex-col items-center justify-center overflow-hidden rounded-lg border-2 border-black-dark p-2 dark:bg-kekkle-dark-light"
      >
        <p class="absolute text-8xl opacity-20">📅</p>
        <h2 class="relative text-3xl font-bold">
          {{ numberOfEvents }}
          <span class="text-lg font-normal">/{{ totalNumberOfEvents }}</span>
        </h2>
        <p class="relative capitalize">
          {{ i18n.t("pages.memberView.presence") }}
        </p>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useI18n } from "vue-i18n";
const i18n = useI18n();
import { computed, defineProps, ref } from "vue";
import router from "@/router/router";
import { GET_ACTIVE_GROUP } from "@/store/modules/user";
import store from "@/store/store";
import {
  DOWNLOAD_GROUP_MEMBER_PROFILE_PICTURE_DATA,
  GET_GROUP_DATA,
  GET_GROUP_DOC_ID,
  GET_GROUP_MEMBERS_PROFILE_PICTURE_DATA,
  GET_GROUP_MEMBERS_SORTED,
} from "@/store/modules/groups";
import TopNavigation from "@/components/TopNavigation.vue";
import { HD, THUMB } from "@/constants/image-sizes";
import {
  collection,
  query,
  Timestamp,
  where,
  getCountFromServer,
  orderBy,
  limit,
  getDocs,
} from "firebase/firestore";
import { firestore } from "@/helpers/firebase/firebase";
import { getMonth } from "@/helpers/dates/get-month";

const props = defineProps({
  groupId: {
    type: String,
    required: true,
  },
  memberId: {
    type: String,
    required: true,
  },
});

if (!props.groupId || !props.memberId) {
  router.push({ name: "Group" });
}
if (store.getters[GET_ACTIVE_GROUP] !== props.groupId) {
  router.push({ name: "Group" });
}

let member = store.getters[GET_GROUP_MEMBERS_SORTED].filter(
  (member) => member.uuid === props.memberId,
);

if (member.length !== 1) {
  router.push({ name: "Group" });
}

member = member[0];

const numberOfQuotes = ref(0);
const numberOfEvents = ref(0);
const numberOfPhotos = ref(0);
const latestQuote = ref(null);

getQuoteCount();
getPhotoCount();
getEventCount();
getLatestQuote();

const thumbUrl = computed(() => {
  return store.getters[GET_GROUP_MEMBERS_PROFILE_PICTURE_DATA]?.[
    props.memberId
  ]?.[THUMB]?.["url"];
});

const pictureUrl = computed(() => {
  return store.getters[GET_GROUP_MEMBERS_PROFILE_PICTURE_DATA]?.[
    props.memberId
  ]?.[HD]?.["url"];
});

if (!thumbUrl.value) {
  store.dispatch(DOWNLOAD_GROUP_MEMBER_PROFILE_PICTURE_DATA, {
    uuid: props.memberId,
    format: THUMB,
  });
}

if (!pictureUrl.value) {
  store.dispatch(DOWNLOAD_GROUP_MEMBER_PROFILE_PICTURE_DATA, {
    uuid: props.memberId,
    format: HD,
  });
}

const birthdayDate = computed(() => {
  if (member.birthday) {
    return new Timestamp(
      member.birthday?.seconds,
      member.birthday?.nanoseconds,
    ).toDate();
  }
  return null;
});

const birthdayString = computed(() => {
  return (
    birthdayDate.value.getDate() +
    " " +
    getMonth(birthdayDate.value) +
    " " +
    birthdayDate.value.getFullYear()
  );
});

const memberClass = computed(() => {
  switch (member.permission) {
    case "admin":
      return "bg-green-200 text-green-600";
    case "organiser":
      return "bg-orange-200 text-orange-600";
    case "member":
      return "bg-purple-200 text-purple-600";
    default:
      return "";
  }
});

const memberText = computed(() => {
  switch (member.permission) {
    case "admin":
      return i18n.t("global.roles.admin");
    case "organiser":
      return i18n.t("global.roles.organiser");
    case "member":
      return i18n.t("global.roles.member");
    default:
      return "";
  }
});

const totalNumberOfEvents = computed(() => {
  return store.getters[GET_GROUP_DATA]?.general_data?.number_of_events;
});

function getQuoteCount() {
  const quotesCollection = collection(
    firestore,
    `groups/${store.getters[GET_GROUP_DOC_ID]}/quotes`,
  );
  const q = query(
    quotesCollection,
    where("authors", "array-contains", member.uuid),
  );
  getCountFromServer(q).then((result) => {
    numberOfQuotes.value = result.data().count;
  });
}
function getPhotoCount() {
  const photosCollection = collection(
    firestore,
    `groups/${store.getters[GET_GROUP_DOC_ID]}/photos`,
  );
  const q = query(photosCollection, where("created_by", "==", member.uuid));
  getCountFromServer(q).then((result) => {
    numberOfPhotos.value = result.data().count;
  });
}

function getEventCount() {
  const eventsCollection = collection(
    firestore,
    `groups/${store.getters[GET_GROUP_DOC_ID]}/events`,
  );
  const q = query(
    eventsCollection,
    where("available", "array-contains", member.uuid),
  );
  getCountFromServer(q).then((result) => {
    numberOfEvents.value = result.data().count;
  });
}

function getLatestQuote() {
  const quotesCollection = collection(
    firestore,
    `groups/${store.getters[GET_GROUP_DOC_ID]}/quotes`,
  );
  const q = query(
    quotesCollection,
    where("authors", "array-contains", member.uuid),
    orderBy("timestamp", "desc"),
    limit(1),
  );
  getDocs(q).then((result) => {
    result.forEach((doc) => {
      latestQuote.value = doc.data();
    });
  });
}
</script>

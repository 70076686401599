<template>
  <div
    class="relative min-h-screen overflow-y-scroll bg-white dark:bg-kekkle-dark"
  >
    <div class="px-2">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: "SinglePageLayout",
};
</script>

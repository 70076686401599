export const firebaseConfig = {
  apiKey: import.meta.env.VITE_API_KEY,
  authDomain: import.meta.env.VITE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_MESSAGIN_SENDER_ID,
  appId: import.meta.env.VITE_APP_ID,
};

export const recaptchaKey = import.meta.env.VITE_RECAPTCHA_KEY;
export const defaultBucket = import.meta.env.VITE_DEFAULT_BUCKET;
export const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  url: import.meta.env.VITE_ACTION_CODE_SETTINGS_URL,
  // This must be true.
  handleCodeInApp: true,
};

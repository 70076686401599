<template>
  <simple-modal :visible="visible || loading" @close="cancel">
    <div @click.stop>
      <slot></slot>
    </div>
    <div class="flex flex-nowrap items-center justify-between" @click.stop>
      <p v-if="!loading" class="cursor-pointer capitalize" @click="cancel">
        {{ cancelText }}
      </p>
      <p v-if="loading">
        <spinner
          style="color: rgb(128, 128, 128) !important"
          class="h-6 w-6 animate-spin"
        ></spinner>
      </p>
      <div>
        <default-button
          :disabled="!canConfirm"
          :color="danger ? 'red' : 'blue'"
          :loading="loading"
          @click="confirm"
          ><p class="capitalize">{{ confirmText }}</p></default-button
        >
      </div>
    </div>
  </simple-modal>
</template>
<script>
import DefaultButton from "../Buttons/DefaultButton.vue";
import Spinner from "@/components/Spinner.vue";
import SimpleModal from "@/components/Modals/SimpleModal.vue";
export default {
  name: "ConformationModal",
  components: { SimpleModal, Spinner, DefaultButton },
  props: {
    visible: Boolean,
    confirmText: String,
    cancelText: String,
    loading: Boolean,
    danger: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["cancel", "confirm"],
  data() {
    return {
      canConfirm: true,
    };
  },
  mounted() {
    if (this.danger) {
      this.canConfirm = false;
      setTimeout(() => {
        this.canConfirm = true;
      }, 2000);
    }
  },
  methods: {
    cancel() {
      if (!this.loading) {
        this.$emit("cancel");
      }
    },
    confirm() {
      this.$emit("confirm");
    },
  },
};
</script>

import { flutterToast } from "@/helpers/flutter/FlutterToast";
import {
  cancelAppleAuth,
  cancelGoogleAuth,
  signInWithCustomAuthToken,
} from "@/helpers/flutter/FlutterAuth";
import {
  registerFcmToken,
  setNotificationPermissionStatus,
} from "@/helpers/flutter/FlutterFcm";

export function initFlutterFunctions() {
  window.flutterFunctions = {};
  window.flutterFunctions.setToast = flutterToast;
  window.flutterFunctions.cancelAppleAuth = cancelAppleAuth;
  window.flutterFunctions.cancelGoogleAuth = cancelGoogleAuth;
  window.flutterFunctions.setCustomIdToken = signInWithCustomAuthToken;
  window.flutterFunctions.setNotificationPermissionStatus =
    setNotificationPermissionStatus;
  window.flutterFunctions.postFcmToken = registerFcmToken;
}

export function isRunningInFlutter() {
  return !!window.flutter;
}

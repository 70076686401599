import { collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { firestore } from "@/helpers/firebase/firebase";
import { docsConverter } from "./FirestoreService";

/**
 * Return the users collection reference
 * @returns {CollectionReference}
 */
function userCollection() {
  return collection(firestore, "users");
}

/**
 * Get user document from firestore
 * @param userUuid
 * @returns {Promise<Object>}
 */
export async function fetchUserDoc(userUuid) {
  return docsConverter(await getDoc(doc(userCollection(), userUuid)));
}

/**
 * Update user document
 * @param userUuid
 * @param data
 * @return {Promise<void>}
 */
export async function updateUserDoc(userUuid, data) {
  let docReference = doc(userCollection(), userUuid);
  await updateDoc(
    docReference,
    {
      ...data,
    },
    { merge: true },
  );
}

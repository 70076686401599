import { fetchLeaderBoard } from "@/helpers/firestore/WordleLeaderBoardService";
import { setLocalStorageItem } from "../../helpers/utils/local-storage";

export const moduleWordleLeaderboard = {
  state: {
    leaderBoard: JSON.parse(localStorage.getItem("wordleLeaderBoard") ?? "[]"),
    fetchingLeaderBoard: false,
    onlyKnownUsers: JSON.parse(
      localStorage.getItem("onlyKnownUsersWordleLeaderboard") ?? "true",
    ),
  },
  mutations: {
    setOnlyKnownUsersWorldeLeaderboard(state, onlyKnownUsers) {
      state.onlyKnownUsers = onlyKnownUsers;
      setLocalStorageItem(
        "onlyKnownUsersWordleLeaderboard",
        JSON.stringify(onlyKnownUsers),
      );
    },
    setLeaderBoard(state, docs) {
      state.leaderBoard = docs;
      setLocalStorageItem("wordleLeaderBoard", JSON.stringify(docs));
    },
    setFetchingLeaderBoard(state, fetching) {
      state.fetchingLeaderBoard = fetching;
    },
    clearWorldeLeaderBoard(state) {
      state.leaderBoard = [];
      setLocalStorageItem("wordleLeaderBoard", "[]");
      state.onlyKnownUsers = true;
      setLocalStorageItem("onlyKnownUsersWordleLeaderboard", "true");
    },
  },
  actions: {
    async fetchLeaderBoard({ state, commit, getters }) {
      commit("setFetchingLeaderBoard", true);
      let userFilter = [];
      if (state.onlyKnownUsers) {
        userFilter = getters.allGroupMembersUuids;
      }
      let boards = await fetchLeaderBoard(userFilter);
      commit("setLeaderBoard", boards);
      commit("setFetchingLeaderBoard", false);
    },
  },
  getters: {
    getLeaderBoard(state) {
      return state.leaderBoard;
    },
    getFetchingLeaderBoard(state) {
      return state.fetchingLeaderBoard;
    },
  },
};

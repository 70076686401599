import { fetchMonthlyScores } from "@/helpers/firestore/WordleMonthlyScoreService";
import { setLocalStorageItem } from "../../helpers/utils/local-storage";

export const moduleWordleMonthlyScores = {
  state: {
    selectedMonth: new Date().getMonth() + 1,
    selectedYear: new Date().getFullYear(),
    monthlyScores: JSON.parse(
      localStorage.getItem("wordleMonthlyScores") ?? "{}",
    ),
    monthlyScoresOnlyKnownUsers: JSON.parse(
      localStorage.getItem("wordleMonthlyScoresOnlyKnownUsers") ?? "{}",
    ),
    fetchingMonthlyScores: {
      "1997-1": false,
    },
    fetchingMonthlyScoresOnlyKnownUsers: {
      "1997-1": false,
    },
    onlyKnownUsers: JSON.parse(
      localStorage.getItem("onlyKnownUsersWordleMonthlyScores") ?? true,
    ),
  },
  mutations: {
    setSelectedMonth(state, month) {
      state.selectedMonth = month;
    },
    setSelectedYear(state, year) {
      state.selectedYear = year;
    },
    setOnlyKnownUsersWordleMonthlyScores(state, onlyKnownUsers) {
      state.onlyKnownUsers = onlyKnownUsers;
      setLocalStorageItem(
        "onlyKnownUsersWordleMonthlyScores",
        JSON.stringify(onlyKnownUsers),
      );
    },
    setMonthlyScores(state, { year, month, docs, onlyKnownUsers }) {
      let path = `${year}-${month}`;
      if (onlyKnownUsers) {
        state.monthlyScoresOnlyKnownUsers[path] = docs;
        setLocalStorageItem(
          "wordleMonthlyScoresOnlyKnownUsers",
          JSON.stringify(state.monthlyScoresOnlyKnownUsers),
        );
      } else {
        state.monthlyScores[path] = docs;
        setLocalStorageItem(
          "wordleMonthlyScores",
          JSON.stringify(state.monthlyScores),
        );
      }
    },
    setFetchingMonthlyScores(state, { year, month, fetching, onlyKnownUsers }) {
      let path = `${year}-${month}`;
      if (onlyKnownUsers) {
        state.fetchingMonthlyScoresOnlyKnownUsers[path] = fetching;
      } else {
        state.fetchingMonthlyScores[path] = fetching;
      }
    },
    clearWordleMonthlyScores(state) {
      state.monthlyScores = {};
      setLocalStorageItem("wordleMonthlyScores", "{}");
      state.onlyKnownUsers = true;
      setLocalStorageItem("onlyKnownUsersWordleMonthlyScores", "true");
      state.monthlyScoresOnlyKnownUsers = {};
      setLocalStorageItem("wordleMonthlyScoresOnlyKnownUsers", "{}");
    },
  },
  actions: {
    async fetchMonthlyScores({ state, commit, getters }) {
      const year = state.selectedYear;
      const month = state.selectedMonth;
      const onlyKnownUsers = state.onlyKnownUsers;
      let userFilter = [];
      if (state.onlyKnownUsers) {
        userFilter = getters.allGroupMembersUuids;
      }
      commit("setFetchingMonthlyScores", {
        year: year,
        month: month,
        fetching: true,
        onlyKnownUsers: onlyKnownUsers,
      });
      let docs = await fetchMonthlyScores(year, month, userFilter);
      commit("setMonthlyScores", {
        year: year,
        month: month,
        docs: docs,
        onlyKnownUsers: onlyKnownUsers,
      });
      commit("setFetchingMonthlyScores", {
        year: year,
        month: month,
        fetching: false,
        onlyKnownUsers: onlyKnownUsers,
      });
    },
  },
  getters: {
    getMonthlyScores(state) {
      const path = `${state.selectedYear}-${state.selectedMonth}`;
      if (state.onlyKnownUsers) {
        return (
          //Do not pass the original ref, it is not needed
          state.monthlyScoresOnlyKnownUsers[path]?.map((doc) => ({
            id: doc.id,
            data: doc.data,
          })) ?? []
        );
      } else {
        return (
          state.monthlyScores[path]?.map((doc) => ({
            id: doc.id,
            data: doc.data,
          })) ?? []
        );
      }
    },
    getFetchingMonthlyScores(state) {
      const path = `${state.selectedYear}-${state.selectedMonth}`;
      if (state.onlyKnownUsers) {
        return state.fetchingMonthlyScoresOnlyKnownUsers[path] ?? false;
      } else {
        return state.fetchingMonthlyScores[path] ?? false;
      }
    },
  },
};

import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
  increment,
  writeBatch,
} from "firebase/firestore";
import { firestore } from "@/helpers/firebase/firebase";
import store from "@/store/store";
import { handleFirestoreException } from "@/helpers/exceptions/handler";
import { GET_ACTIVE_GROUP } from "@/store/modules/user";
import { toRaw } from "vue";
import { docsConverter } from "./FirestoreService";

/**
 * Returns a reference to the quotes collection for a specific group.
 * @param {String} groupId
 * @returns {CollectionReference}
 */
function quotesCollection(groupId) {
  return collection(firestore, `groups/${groupId}/quotes`);
}

/**
 * Fetches quotes based on a query constraint.
 * @param {QueryConstraint} queryConstraint
 * @returns {Promise<Array>}
 */
async function fetchQuotes(queryConstraint) {
  const quoteQuery = query(
    quotesCollection(store.getters[GET_ACTIVE_GROUP]),
    ...queryConstraint,
  );
  try {
    return docsConverter(await getDocs(quoteQuery));
  } catch (e) {
    handleFirestoreException({ e: e });
  }
}

/**
 * Fetches a quote by its ID
 * @param {String} quoteId
 * @param {String} groupId
 * @returns {Promise<Object | null>}
 */
export async function fetchQuoteById(quoteId, groupId) {
  const quoteDocumentSnapshot = await getDoc(
    doc(quotesCollection(groupId), quoteId),
  );
  if (!quoteDocumentSnapshot.exists()) {
    return null;
  } else {
    return docsConverter(quoteDocumentSnapshot);
  }
}

/**
 * Fetches quotes by author UUID, starting with the newest
 * @param {String} authorFilterUuid
 * @returns {Promise<Array>}
 */
export async function fetchNewestQuotesByAuthorUuid(authorFilterUuid) {
  const queryConstraints = [
    orderBy("timestamp", "desc"),
    limit(6),
    where("authors", "array-contains", authorFilterUuid),
  ];
  return await fetchQuotes(queryConstraints);
}

/**
 * Fetches the newest quotes of the group
 * @returns {Array}
 */
export async function fetchNewestQuotes() {
  const queryConstraints = [orderBy("timestamp", "desc"), limit(6)];
  return await fetchQuotes(queryConstraints);
}

/**
 * Fetches more quotes starting after the provided quote
 * @param {QuerySnapshot} lastQuote
 * @returns {Promise<Array>}
 */
export async function fetchMoreQuotes(lastQuote) {
  const queryConstraints = [
    orderBy("timestamp", "desc"),
    startAfter(toRaw(lastQuote)),
    limit(10),
  ];
  return await fetchQuotes(queryConstraints);
}

/**
 * Fetches more quotes by author UUID starting after the provided quote
 * @param {String} authorFilterUuid
 * @param {QuerySnapshot} lastQuote
 * @returns {Promise<Array>}
 */

export async function fetchMoreQuotesByAuthorUuid(authorFilterUuid, lastQuote) {
  const queryConstraints = [
    orderBy("timestamp", "desc"),
    startAfter(toRaw(lastQuote)),
    limit(10),
    where("authors", "array-contains", authorFilterUuid),
  ];
  return await fetchQuotes(queryConstraints);
}

/**
 * Searches quotes of the group by a search string
 * @param {String} searchString
 * @returns {Promise<Array>}
 */
export async function searchQuotes(searchString) {
  let trigrams = {};
  let trimmedSearch = searchString.replaceAll(" ", "").toLowerCase();

  if (trimmedSearch.length >= 3) {
    for (let i = 0; i < trimmedSearch.length - 2; i++) {
      let trigram = trimmedSearch.slice(i, i + 3);
      trigrams[trigram] = true;
    }
  }

  const searchConstraints = Object.keys(trigrams).map((name) =>
    where(`trigrams.${name}`, "==", true),
  );
  return await fetchQuotes(searchConstraints);
}

/**
 * Adds a new quote to the group
 * @param {String} groupId
 * @param {Object} data
 * @returns {Promise<void>}
 */
export async function addQuote(groupId, data) {
  let batch = writeBatch(firestore);
  const quoteCollection = quotesCollection(groupId);
  let newQuoteRef = doc(quoteCollection);
  batch.set(newQuoteRef, data);
  let groupCollection = collection(firestore, "groups/");
  let groupRef = doc(groupCollection, groupId);
  batch.update(groupRef, {
    "general_data.number_of_quotes": increment(1),
  });
  return await batch.commit();
}

<template>
  <top-navigation
    :title="$t('settings.group_profile_settings')"
    :button-name="$t('global.inputs.labels.save')"
    :loading="status.updating"
    :disabled="!validated"
    @submit-click="update()"
  >
  </top-navigation>
  <div class="mx-auto min-h-screen pt-24">
    <information-text
      :information="
        $t('settings.this_profile_will_only_be_used_for_the_following_group') +
        ': ' +
        groupData.name
      "
    ></information-text>
    <div class="my-4 w-full">
      <profile-picture-input
        :label="$t('settings.profile_picture')"
        :url="
          profile_picture_file ? profile_picture_url : currentProfilePictureUrl
        "
        @file-changed="fileChanged"
      />
      <p v-show="validationErrors.profile_picture"></p>
    </div>
    <div class="my-4 w-full">
      <text-input
        v-model="name"
        :placeholder="$t('global.inputs.placeholders.your_name')"
        :label="$t('global.inputs.labels.name')"
      />
      <p v-show="validationErrors.name" class="mt-1 text-sm text-red-600">
        {{ $t("global.inputs.errors.name") }}
      </p>
    </div>
  </div>
</template>
<script>
import TopNavigation from "../../components/TopNavigation.vue";
import InformationText from "../../components/InformationText.vue";
import TextInput from "../../components/Inputs/TextInput.vue";
import ProfilePictureInput from "../../components/Inputs/ProfilePicutreInput.vue";
import {
  setSuccessToast,
  setWarningToast,
} from "@/helpers/notifications/toast";
import { SD } from "@/constants/image-sizes";
import { handleFirestoreException } from "@/helpers/exceptions/handler";
import { collection, doc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes } from "firebase/storage";
import { firebaseStorage, firestore } from "@/helpers/firebase/firebase";
import {
  CLEAR_GROUP_MEMBER_PROFILE_PICTURE_DATA,
  DOWNLOAD_GROUP_MEMBER_PROFILE_PICTURE_DATA,
  GET_CHECKED_GROUP_ID,
  GET_GROUP_DATA,
  GET_GROUP_DOC_ID,
  GET_GROUP_MEMBERS_PROFILE_PICTURE_DATA,
} from "@/store/modules/groups";
import { GET_AUTH_UUID } from "@/store/modules/auth";
import imageCompression from "browser-image-compression";
import { refreshAllUserGroupsData } from "@/helpers/data/fetchers";
import { globals } from "@/helpers/globals/globals";
export default {
  name: "GroupUserProfileSettingsView",
  components: {
    ProfilePictureInput,
    TextInput,
    InformationText,
    TopNavigation,
  },
  data: function () {
    return {
      SD: SD,
      status: {
        updating: false,
      },
      name: "",
      profile_picture_url: "",
      profile_picture_file: null,
    };
  },
  computed: {
    darkModeEnabled() {
      return !!globals.darkModeEnabled;
    },
    currentProfilePictureUrl() {
      if (
        this.groupMembersProfilePictureData &&
        this.groupMembersProfilePictureData[this.uuid] &&
        this.groupMembersProfilePictureData[this.uuid][SD] &&
        this.groupMembersProfilePictureData[this.uuid][SD]["url"]
      ) {
        return this.groupMembersProfilePictureData[this.uuid][SD]["url"];
      } else {
        return "";
      }
    },
    uuid() {
      return this.$store.getters[GET_AUTH_UUID];
    },
    nameValid() {
      return !!(this.name && this.name.length > 1);
    },
    validationErrors() {
      let errors = {};
      errors.name = !this.nameValid;
      return errors;
    },
    validated() {
      return !Object.values(this.validationErrors).some(
        (value) => value === true,
      );
    },
    groupDocId() {
      return this.$store.getters[GET_GROUP_DOC_ID];
    },
    groupData() {
      return this.$store.getters[GET_GROUP_DATA];
    },
    groupMembersProfilePictureData() {
      return this.$store.getters[GET_GROUP_MEMBERS_PROFILE_PICTURE_DATA];
    },
  },
  created() {
    this.name = this.groupData["members"][this.uuid].name;
    this.$store.dispatch(DOWNLOAD_GROUP_MEMBER_PROFILE_PICTURE_DATA, {
      format: SD,
      uuid: this.uuid,
    });
  },
  methods: {
    async update() {
      if (!this.validated) {
        setWarningToast({
          message: this.$t("global.toast.check_all_fields"),
        });
        return;
      }
      if (this.profile_picture_file) {
        try {
          this.status.updating = true;
          let memberPictureStorageRef = ref(
            firebaseStorage,
            "groups/" + this.groupDocId + "/profile_pictures/" + this.uuid,
          );

          let compressedFile = await imageCompression(
            this.profile_picture_file,
            {
              maxSizeMB: 1,
              maxWidthOrHeight: 3840,
              useWebWorker: false,
            },
          );
          await uploadBytes(memberPictureStorageRef, compressedFile);
          this.$store.commit(CLEAR_GROUP_MEMBER_PROFILE_PICTURE_DATA, {
            uuid: this.uuid,
            groupId: this.groupDocId,
          });
        } catch (e) {
          handleFirestoreException({ e: e });
        } finally {
          this.status.updating = false;
        }
      }
      try {
        this.status.updating = true;
        let groupCollection = collection(firestore, "groups");
        let groupDocRef = doc(
          groupCollection,
          this.$store.getters[GET_CHECKED_GROUP_ID],
        );
        await updateDoc(groupDocRef, {
          [`members.${this.uuid}.name`]: this.name,
        });

        setSuccessToast({
          message: this.$t("global.toast.profile_saved"),
        });
        await refreshAllUserGroupsData();
        this.$router.push({ name: "settings" });
      } catch (e) {
        handleFirestoreException({ e: e });
      } finally {
        this.status.updating = false;
      }
    },
    fileChanged(file) {
      if (file.size / 1020 / 1024 < 100) {
        this.profile_picture_file = file;
        this.profile_picture_url = URL.createObjectURL(file);
      } else {
        setWarningToast({
          message: this.$t("global.toast.file_too_big", [100]),
        });
      }
    },
  },
};
</script>

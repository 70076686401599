<template>
  <div
    class="rounded-xl border-2 border-black-dark bg-white p-2 shadow-blue-hard-sm dark:bg-kekkle-dark-light dark:text-slate-200"
  >
    <h1 class="text-center text-xl font-bold">
      {{ item.data.name ? item.data.name : "" }}
    </h1>
    <p class="italic">
      {{ $t("global.from") }}:
      <span class="not-italic">{{ from }} </span>
    </p>
    <p class="italic">
      {{ $t("global.till") }}:
      <span class="not-italic"> {{ till }}</span>
    </p>
    <p v-if="createdBy" class="italic">
      {{ $t("global.created_by") }}:
      <span class="not-italic">{{ createdBy }}</span>
    </p>
    <div class="border-b border-gray-400"></div>
    <br />
    <p class="font-medium">{{ $t("global.description") }}:</p>
    <p>
      {{
        item.data.description
          ? item.data.description
          : $t("global.no_description")
      }}
    </p>
    <br />
    <div class="w-full border-b border-gray-400"></div>
    <br />
    <h1 class="font-medium">
      {{ $t("components.agenda_items.presence") }}
    </h1>
    <div
      class="mb-2 flex w-max cursor-pointer items-center rounded-lg border-2 border-black-dark px-2 py-1 shadow-teal-hard-sm"
      @click="setAvailable()"
    >
      <div
        class="mr-2 h-4 w-4 rounded-full border border-black-dark"
        :class="isAvailable ? 'bg-green-500' : 'bg-gray-300'"
      ></div>
      <p class="italic">{{ $t("components.agenda_items.present") }}:</p>
    </div>
    <div
      v-if="item.data.available && item.data.available.length > 0"
      class="mb-2 flex flex-wrap"
    >
      <tiny-user-thumb
        v-for="(uuid, index) in item.data.available"
        :key="index"
        class="-mr-4"
        :uuid="uuid"
      ></tiny-user-thumb>
    </div>
    <div v-else class="mb-2">
      <p>{{ $t("components.agenda_items.nobody_present") }}</p>
    </div>

    <div
      class="mb-2 flex w-max cursor-pointer items-center rounded-lg border-2 border-black-dark px-2 py-1 shadow-teal-hard-sm"
      @click="setMaybe()"
    >
      <div
        class="mr-2 h-4 w-4 rounded-full border border-black-dark"
        :class="isMaybeAvailable ? 'bg-orange' : 'bg-gray-300'"
      ></div>
      <p class="italic">{{ $t("components.agenda_items.maybe") }}:</p>
    </div>
    <div
      v-if="item.data.maybe_available && item.data.maybe_available.length > 0"
      class="mb-2 flex flex-wrap"
    >
      <tiny-user-thumb
        v-for="(uuid, index) in item.data.maybe_available"
        :key="index"
        :uuid="uuid"
      ></tiny-user-thumb>
    </div>
    <div v-else class="mb-2">
      <p>{{ $t("components.agenda_items.nobody_maybe") }}</p>
    </div>

    <div
      class="mb-2 flex w-max cursor-pointer items-center rounded-lg border-2 border-black-dark px-2 py-1 shadow-teal-hard-sm"
      @click="setNonAvailable()"
    >
      <div
        class="mr-2 h-4 w-4 rounded-full border border-black-dark"
        :class="isNotAvailable ? 'bg-red-500' : 'bg-gray-300'"
      ></div>
      <p class="italic">{{ $t("components.agenda_items.absent") }}:</p>
    </div>
    <div
      v-if="item.data.non_available && item.data.non_available.length > 0"
      class="mb-2 flex flex-wrap"
    >
      <tiny-user-thumb
        v-for="(uuid, index) in item.data.non_available"
        :key="index"
        :uuid="uuid"
      ></tiny-user-thumb>
    </div>
    <div v-else class="mb-2">
      <p>{{ $t("components.agenda_items.nobody_absent") }}</p>
    </div>
    <default-button
      @click="
        $router.push({
          name: 'viewEvent',
          params: {
            eventId: item.id,
            groupId: checkedGroupId,
          },
        })
      "
      ><p>
        {{ $t("components.agenda_items.more_information") }}
      </p></default-button
    >
  </div>
</template>
<script>
import DefaultButton from "./Buttons/DefaultButton.vue";
import { updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import { handleFirestoreException } from "@/helpers/exceptions/handler";
import * as Sentry from "@sentry/vue";
import {
  GET_CHECKED_GROUP_ID,
  GET_GROUP_DATA,
  GET_GROUP_MEMBERS_PROFILE_PICTURE_DATA,
} from "@/store/modules/groups";
import { SD } from "@/constants/image-sizes";
import TinyUserThumb from "./TinyUserThumb.vue";
import { dateAgo } from "@/helpers/dates/date-ago";
import { GET_AUTH_UUID } from "@/store/modules/auth";

export default {
  name: "AgendaItem",
  components: { TinyUserThumb, DefaultButton },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      status: { updating: false },
      SD: SD,
    };
  },
  computed: {
    checkedGroupId() {
      return this.$store.getters[GET_CHECKED_GROUP_ID];
    },
    from() {
      return dateAgo(this.item.data.begin_timestamp.toDate());
    },
    till() {
      return dateAgo(this.item.data.end_timestamp.toDate());
    },
    userId() {
      return this.$store.getters[GET_AUTH_UUID];
    },
    members() {
      return this.$store.getters[GET_GROUP_DATA] &&
        this.$store.getters[GET_GROUP_DATA].members
        ? this.$store.getters[GET_GROUP_DATA].members
        : [];
    },
    membersProfilePicturesData() {
      return this.$store.getters[GET_GROUP_MEMBERS_PROFILE_PICTURE_DATA];
    },
    isAvailable() {
      return !!(
        this.item.data.available &&
        this.item.data.available.includes(this.userId)
      );
    },
    isMaybeAvailable() {
      return !!(
        this.item.data.maybe_available &&
        this.item.data.maybe_available.includes(this.userId)
      );
    },
    isNotAvailable() {
      return !!(
        this.item.data.non_available &&
        this.item.data.non_available.includes(this.userId)
      );
    },
    createdBy() {
      if (!this.item.data.created_by) {
        return "";
      }
      return this.members[this.item.data.created_by].name
        ? this.members[this.item.data.created_by].name
        : "";
    },
  },
  methods: {
    setAvailable() {
      if (!this.userId) {
        return;
      }

      let payload = {
        available: arrayUnion(this.userId),
        maybe_available: arrayRemove(this.userId),
        non_available: arrayRemove(this.userId),
      };
      this.updateDoc(payload);
    },
    setMaybe() {
      if (!this.userId) {
        return;
      }
      let payload = {
        available: arrayRemove(this.userId),
        maybe_available: arrayUnion(this.userId),
        non_available: arrayRemove(this.userId),
      };
      this.updateDoc(payload);
    },
    setNonAvailable() {
      if (!this.userId) {
        return;
      }
      let payload = {
        available: arrayRemove(this.userId),
        maybe_available: arrayRemove(this.userId),
        non_available: arrayUnion(this.userId),
      };
      this.updateDoc(payload);
    },
    async updateDoc(payload) {
      if (this.status.updating || !this.item) {
        return;
      }
      this.status.updating = true;
      try {
        await updateDoc(this.item.ref, payload);
        this.$store.dispatch("fetchCurrentMonthEvents");
      } catch (e) {
        handleFirestoreException({ e: e });
        Sentry.captureException(e);
      } finally {
        this.status.updating = false;
      }
    },
  },
};
</script>

<template>
  <transition name="fade"
    ><div
      v-show="show"
      class="min-w-screen fixed z-50 flex min-h-screen w-screen items-center justify-center overflow-hidden bg-kekkle-yellow text-center dark:bg-kekkle-dark"
    >
      <img alt="Kekkle Logo" class="mb-4 w-24" :src="logoAltTrans" />
    </div>
  </transition>
  <information-bar />

  <div class="mt-0 min-h-screen w-full select-none bg-kekkle-purple">
    <div class="relative mx-auto max-w-xl">
      <div
        v-if="!hasUserData && userFailed"
        class="align-center flex h-screen flex-col justify-center gap-2"
      >
        <h1 class="text-center text-4xl">👽🚫</h1>
        <p class="mx-auto max-w-xs text-center text-lg font-medium text-white">
          {{ $t("boot.not_loaded_correctly") }}
        </p>
        <default-button @click="reload">{{
          $t("boot.reload_page")
        }}</default-button>
      </div>
      <suspense v-else>
        <router-view />
      </suspense>
    </div>
  </div>
</template>

<script setup>
//register service worker
import { registerSW } from "virtual:pwa-register";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import InformationBar from "./components/InformationBar.vue";
import DefaultButton from "@/components/Buttons/DefaultButton.vue";
import { GET_USER_DATA, GET_USER_FAILED } from "@/store/modules/user";
import logoAltTrans from "@/assets/images/logo/logo-color-alt-trans.svg";

const store = useStore();
let show = ref(true);

if ("serviceWorker" in navigator) {
  try {
    registerSW({ immediate: true });
  } catch (e) {
    //
    // Sentry.captureException(e);
  }
}

setTimeout(() => {
  show.value = false;
}, 10);

function reload() {
  window.location.reload();
}

let userFailed = computed(() => {
  return store.getters[GET_USER_FAILED];
});

let hasUserData = computed(() => {
  return !!store.getters[GET_USER_DATA];
});
</script>

<style lang="scss">
@import "assets/scss/index.scss";

.fade-enter-active,
.fade-leave-active {
  transition: all 500ms;
  transition-timing-function: ease-in-out;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: scale(3, 3);
}
.firebase-emulator-warning {
  display: none;
}
</style>

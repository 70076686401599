<script setup>
import GameListItem from "@/components/GameListItem.vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();

const games = [
  {
    title: t("pages.games.dices_title"),
    description: t("pages.games.dices_explanation"),
    emoji: "🎲",
    nextRoute: "dice",
  },
  {
    title: t("pages.games.wordle_title"),
    description: t("pages.games.wordle_explanation"),
    emoji: "🤔",
    nextRoute: "wordle",
  },
];
</script>
<template>
  <h1
    class="fixed z-10 w-full max-w-xl border-b-2 border-b-kekkle-yellow bg-white py-4 text-center text-xl font-medium dark:bg-kekkle-dark-dark dark:text-slate-200"
  >
    {{ t("pages.games.title") }}
  </h1>
  <div class="container mx-auto min-h-screen px-2 pb-24">
    <h1
      class="w-full max-w-xl bg-white py-5 text-center text-xl font-medium opacity-0"
    >
      {{ t("pages.games.title") }}
    </h1>

    <div class="flex flex-col gap-4">
      <game-list-item v-for="game in games" :key="game.title" v-bind="game" />
    </div>
  </div>
</template>

import * as Sentry from "@sentry/vue";
import { globals } from "@/helpers/globals/globals";
import { registerMessageToken } from "@/helpers/messaging-token";

export function askFcmTokenFlutter() {
  try {
    // eslint-disable-next-line no-undef
    FlutterFcm.postMessage({});
  } catch (e) {
    Sentry.captureException(e);
  }
}

export function setNotificationPermissionStatus(status) {
  globals.hasMobileNotificationPermission = JSON.parse(status);
}

export function registerFcmToken(fcmToken) {
  registerMessageToken(fcmToken)
    .then(() => {
      //
    })
    .catch((e) => {
      //
    });
}

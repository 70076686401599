<template>
  <SimpleModal :visible="visible" @close="close">
    <slot></slot>
    <div class="mt-4">
      <default-button @click="close">{{
        $t("components.informationModal.close")
      }}</default-button>
    </div>
  </SimpleModal>
</template>
<script>
import DefaultButton from "../Buttons/DefaultButton.vue";
import SimpleModal from "@/components/Modals/SimpleModal.vue";

export default {
  name: "InformationModal",
  components: { SimpleModal, DefaultButton },
  props: {
    visible: Boolean,
  },
  emits: ["close"],
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

import { SD } from "@/constants/image-sizes";
import * as Sentry from "@sentry/vue";
import { fetchUserDoc, updateUserDoc } from "@/helpers/firestore/UserService";
import { getUserProfilePictureFromFirestorage } from "@/helpers/firestorage/UserPictureService";
import { GET_AUTH_UUID } from "@/store/modules/auth";
import { setLocalStorageItem } from "../../helpers/utils/local-storage";

const namespace = "user/";

//mutations
export const CLEAR_USER_DATA = namespace + "clear_user_data";
//actions

//getters
export const GET_USER_DATA = namespace + "get_user_data";
export const GET_HAS_USER_DOC = namespace + "get_has_user_doc";
export const GET_ACTIVE_GROUP = namespace + "get_active_group";
export const GET_USER_FAILED = namespace + "get_user_failed";

export const CLEAR_USER_PROFILE_PICTURE_DATA =
  namespace + "CLEAR_USER_PROFILE_PICTURE_DATA";

export const DOWNLOAD_USER_PROFILE_PICTURE_DATA =
  namespace + "DOWNLOAD_GROUP_MEMBER_PROFILE_PICTURE_DATA";

//setters
export const SET_USER_FAILED = namespace + "set_user_failed";
const SET_USER_DATA = namespace + "SET_USER_DATA";
export const SET_PARTIAL_USER_DATA = namespace + "SET_PARTIAL_USER_DATA";
const SET_USER_PROFILE_PICTURE_DATA =
  namespace + "SET_USER_PROFILE_PICTURE_DATA";
export const GET_USER_PROFILE_PICTURE_DATA =
  namespace + "GET_USER_PROFILE_PICTURE_DATA";

export const moduleUser = {
  namespaced: false,
  state: () => ({
    userFailed: false,
    userData: JSON.parse(localStorage.getItem("userData")) ?? null,
    userProfilePictureData: {
      original: {
        url: "",
        blob: Object,
      },
    },
  }),
  mutations: {
    [SET_PARTIAL_USER_DATA](state, data) {
      Object.keys(data).forEach((key) => {
        state.userData[key] = data[key];
      });
      setLocalStorageItem("userData", JSON.stringify(state.userData));
    },
    [SET_USER_DATA](state, data) {
      state.userData = data;
      setLocalStorageItem("userData", JSON.stringify(data));
    },
    [CLEAR_USER_DATA](state) {
      state.userFailed = false;
      state.userData = null;
      setLocalStorageItem("userData", JSON.stringify(null));
      state.userProfilePictureData = {
        original: {
          url: "",
          blob: Object,
        },
      };
    },
    [SET_USER_FAILED](state, failed) {
      state.userFailed = failed;
    },
    [SET_USER_PROFILE_PICTURE_DATA](state, { data, url, format }) {
      if (!state.userProfilePictureData[format]) {
        state.userProfilePictureData[format] = {};
      }
      state.userProfilePictureData[format]["blob"] = data;
      state.userProfilePictureData[format]["url"] = url;
    },
    [CLEAR_USER_PROFILE_PICTURE_DATA](state) {
      state.userProfilePictureData = {};
    },
  },
  actions: {
    async refreshUser({ commit }, { userUuid }) {
      try {
        let userDoc = await fetchUserDoc(userUuid);
        if (userDoc.exists) {
          commit(SET_USER_DATA, userDoc.data);
        } else {
          commit(SET_USER_DATA, null);
        }
        commit(SET_USER_FAILED, false);
      } catch (e) {
        commit(SET_USER_FAILED, true);
      }
    },
    async postUserLanguage({ commit, getters, state }, key) {
      if (getters[GET_USER_DATA] == null) {
        return;
      }
      try {
        await updateUserDoc(getters[GET_AUTH_UUID], {
          language: key,
        });
        commit(SET_PARTIAL_USER_DATA, { language: key });
      } catch (e) {
        //
      }
    },
    [DOWNLOAD_USER_PROFILE_PICTURE_DATA]({ commit, getters }, { format = SD }) {
      getUserProfilePictureFromFirestorage(getters[GET_AUTH_UUID], format)
        .then((result) => {
          commit(SET_USER_PROFILE_PICTURE_DATA, {
            data: result.blob,
            url: result.url,
            format: format,
          });
        })
        .catch((e) => {
          Sentry.captureException(e);
        });
    },
  },
  getters: {
    [GET_USER_FAILED](state) {
      return state.userFailed;
    },
    [GET_USER_PROFILE_PICTURE_DATA](state) {
      return state.userProfilePictureData;
    },
    [GET_USER_DATA](state) {
      return state.userData;
    },
    [GET_ACTIVE_GROUP](state) {
      if (state.userData && state.userData.active_group) {
        return state.userData.active_group;
      }
      return null;
    },
    [GET_HAS_USER_DOC](state) {
      return state.userData != null;
    },
  },
};

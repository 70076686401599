<template>
  <CheckboxInput
    v-model="store.state.WordleMonthlyScores.onlyKnownUsers"
    class="my-4 font-thin"
    :label="t('pages.wordle.only_known_users_checkbox')"
  ></CheckboxInput>
  <div class="flex items-center justify-between">
    <chevron-left-icon
      class="w-6 cursor-pointer text-gray-600 dark:text-white"
      @click="previousMonth"
    ></chevron-left-icon>
    <h1
      class="text-center text-lg font-medium text-kekkle-dark dark:text-white"
    >
      {{ t("pages.wordle.ranking_of") }} <br />
      <span class="font-bold text-kekkle-dark dark:text-white">
        {{
          translatedMonths[store.state.WordleMonthlyScores.selectedMonth - 1]
        }}
        {{ store.state.WordleMonthlyScores.selectedYear }}</span
      >
    </h1>
    <chevron-right-icon
      class="w-6 cursor-pointer text-gray-600 dark:text-white"
      @click="nextMonth"
    ></chevron-right-icon>
  </div>

  <spinner
    v-if="fetchingScores && !orderedScores.length"
    class="mx-auto mt-16 w-8 animate-spin text-black-dark dark:text-white"
  ></spinner>

  <div
    v-else
    class="w-full rounded border-2 border-black-dark bg-white p-4 text-kekkle-dark shadow-teal-hard-sm dark:bg-kekkle-dark-light dark:text-white"
  >
    <div>
      <div
        v-for="(score, index) in orderedScores"
        :key="score.id"
        :class="{
          'border-b border-black-dark':
            orderedScores[index + 1]?.new_score !== score.data.new_score,
        }"
        class="my-4"
      >
        <div class="flex items-center gap-4">
          <p class="text-3xl font-bold">
            <span
              v-if="store.getters[GET_AUTH_UUID] === score.data.user_id"
              class="pointer text-xl"
              >👉</span
            >
            <span v-if="score.position === 1"> 🥇 </span>
            <span v-else-if="score.position === 2"> 🥈 </span>
            <span v-else-if="score.position === 3"> 🥉 </span>
            <span v-else> {{ score.position }}</span>
          </p>
          <div>
            <p class="">
              {{ t("pages.wordle.name") }}
              <span class="font-bold">{{ score.data.username }}</span>
            </p>
            <p v-if="groupMembers[score.data.user_id]" class="">
              {{ t("pages.wordle.nick_name") }}
              <span class="font-bold">{{
                groupMembers[score.data.user_id].name
              }}</span>
            </p>
            <p class="">
              {{ t("pages.wordle.score") }}
              <span class="font-bold">{{ score.data.new_score }}</span>
            </p>
            <div class="flex gap-4">
              <p class="">
                {{ t("pages.wordle.played") }}
                <span class="font-bold">{{
                  score.data.matches_played.length
                }}</span>
              </p>
              <p class="">
                {{ t("pages.wordle.wins") }}
                <span class="font-bold">{{ score.data.has_won }}</span>
              </p>
              <p class="">
                {{ t("pages.wordle.loses") }}
                <span class="font-bold">{{ score.data.has_lost }}</span>
              </p>
            </div>
          </div>
        </div>
        <matches-graph class="my-2" :matches="score.data.match_tries">
        </matches-graph>
      </div>

      <p v-if="!orderedScores.length">
        {{ t("pages.wordle.no_scores_known") }}
      </p>
    </div>
  </div>
</template>
<script setup>
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/outline";
import { useStore } from "vuex";
import { computed, watch } from "vue";
import Spinner from "@/components/Spinner.vue";
import { GET_AUTH_UUID } from "@/store/modules/auth";
import { GET_GROUP_MEMBERS } from "@/store/modules/groups";
import { useI18n } from "vue-i18n";
import CheckboxInput from "@/components/Inputs/CheckboxInput.vue";
import { months } from "@/i18n/vueI18n";
import MatchesGraph from "@/views/games/wordle/components/MatchesGraph.vue";
const { t } = useI18n({ useScope: "global" });

const translatedMonths = months();
const store = useStore();

store.dispatch("fetchMonthlyScores");

function nextMonth() {
  //add 1 to the month, unless it it twelve, set it back to 1 but the year to next year
  let currentMonth = store.state.WordleMonthlyScores.selectedMonth;
  let currentYear = store.state.WordleMonthlyScores.selectedYear;
  if (currentMonth === 12) {
    store.commit("setSelectedMonth", 1);
    store.commit("setSelectedYear", currentYear + 1);
  } else {
    store.commit("setSelectedMonth", currentMonth + 1);
  }
  store.dispatch("fetchMonthlyScores");
}

function previousMonth() {
  //subtract 1 from the month, unless it is 1, set it to 12 but the year to the previous year
  let currentMonth = store.state.WordleMonthlyScores.selectedMonth;
  let currentYear = store.state.WordleMonthlyScores.selectedYear;
  if (currentMonth === 1) {
    store.commit("setSelectedMonth", 12);
    store.commit("setSelectedYear", currentYear - 1);
  } else {
    store.commit("setSelectedMonth", currentMonth - 1);
  }
  store.dispatch("fetchMonthlyScores");
}

watch(
  () => store.state.WordleMonthlyScores.onlyKnownUsers,
  (newValue) => {
    store.dispatch("fetchMonthlyScores");
    store.commit("setOnlyKnownUsersWorldeLeaderboard", newValue);
    store.commit("setOnlyKnownUsersWordleScores", newValue);
    store.commit("setOnlyKnownUsersWordleMonthlyScores", newValue);
  },
);

const orderedScores = computed(() => {
  let scores = store.getters.getMonthlyScores;
  let position = 0;
  let lastScore = null;

  scores.sort((a, b) => b.data.new_score - a.data.new_score);

  return scores.map((score) => {
    if (score.data.new_score !== lastScore) {
      position = position + 1;
      lastScore = score.data.new_score;
    }
    return {
      data: score.data,
      position: position,
    };
  });
});
const fetchingScores = computed(() => store.getters.getFetchingMonthlyScores);
const groupMembers = computed(() => store.getters[GET_GROUP_MEMBERS]);
</script>
<style lang="scss">
.pointer {
  font-size: 28px;
}
</style>

<template>
  <div class="relative h-60 w-full bg-kekkle-red-light">
    <transition name="opacity">
      <img
        v-if="pictureUrl"
        alt="group-picture"
        draggable="false"
        class="relative z-20 mx-auto h-full w-full object-cover object-center"
        :src="pictureUrl"
        @click="openImage"
      />
      <img
        v-else-if="!pictureUrl && blurUrl"
        alt="group-picture"
        draggable="false"
        class="relative z-20 mx-auto h-full w-full object-cover"
        :src="blurUrl"
      />
      <div v-else class="z-40 h-full w-full animate-pulse bg-kekkle-red"></div>
    </transition>

    <div
      class="absolute bottom-2 right-2 z-20 flex min-w-max transform cursor-pointer flex-col items-center rounded bg-white p-1 transition-transform hover:-translate-y-1"
      @click="$router.push({ name: 'groupProfileSettings' })"
    >
      <upload-icon
        :class="[!hasImageSet ? 'h-6 w-6 animate-bounce' : 'h-6 w-6']"
      >
      </upload-icon>
      <p v-if="!hasImageSet" class="w-full px-1">
        {{ $t("pages.group.upload_photo") }}
      </p>
    </div>
  </div>
  <div
    v-show="fullscreen"
    class="max-w-screen max-h-screen fixed left-0 top-0 z-50 flex min-h-screen w-screen flex-col items-center justify-center overflow-scroll bg-slate-700 bg-opacity-60"
    @click="closeImage"
  >
    <img
      v-if="pictureUrl"
      ref="zoomImage"
      alt="Foto-big"
      :src="pictureUrl"
      style="max-height: 50vh"
      class="h-full max-w-lg overflow-visible rounded border-2 border-black-dark object-contain"
      :class="hasFullHdImage ? '' : 'animate-pulse'"
      @click.stop
      @touchstart="touchStart"
      @touchmove="touchMove"
    />
  </div>
</template>
<script>
import {
  DOWNLOAD_GROUP_PICTURE_DATA,
  GET_GROUP_DATA,
  GET_GROUP_PICTURE_DATA,
} from "@/store/modules/groups";
import { FULL_HD, HD, SD, THUMB } from "@/constants/image-sizes";
import { UploadIcon } from "@heroicons/vue/outline";

import { decodeBlurhashToUrl } from "@/helpers/blurhash/decodeBlurhashToUrl";
import { GET_ACTIVE_GROUP } from "@/store/modules/user";
import store from "@/store/store";

export default {
  name: "GroupHeaderThumb",
  components: {
    UploadIcon,
  },
  data() {
    return {
      HD: HD,
      blurUrl: "",
      fullscreen: false,
      start: {
        x: 0,
        y: 0,
        dx: 0,
        dy: 0,
        distance: 0,
        currentScale: 1,
      },
    };
  },
  computed: {
    selectedGroup() {
      return this.$store.getters[GET_ACTIVE_GROUP];
    },
    hasFullHdImage() {
      return this.groupPictureData?.[FULL_HD]?.["url"];
    },
    hasImageSet() {
      return !!this.$store.getters[GET_GROUP_DATA]?.group_picture;
    },
    groupPictureData() {
      return this.$store.getters[GET_GROUP_PICTURE_DATA];
    },
    pictureUrl() {
      return (
        this.groupPictureData?.[FULL_HD]?.url ??
        this.groupPictureData?.[SD]?.url
      );
    },
  },
  watch: {
    async selectedGroup() {
      this.$store.dispatch(DOWNLOAD_GROUP_PICTURE_DATA, { format: SD });
      this.$store.dispatch(DOWNLOAD_GROUP_PICTURE_DATA, {
        format: HD,
      });
      this.blurUrl = decodeBlurhashToUrl(this.groupPictureData?.blurhash);
    },
  },
  created() {
    //check if there already is an url
    if (!this.pictureUrl) {
      this.$store.dispatch(DOWNLOAD_GROUP_PICTURE_DATA, { format: SD });
      this.$store.dispatch(DOWNLOAD_GROUP_PICTURE_DATA, {
        format: HD,
      });
    }
    this.blurUrl = decodeBlurhashToUrl(this.groupPictureData?.blurhash);
  },
  methods: {
    openImage() {
      if (!this.hasFullHdImage) {
        this.$store.dispatch(DOWNLOAD_GROUP_PICTURE_DATA, {
          format: FULL_HD,
        });
      }
      this.fullscreen = true;
    },
    closeImage() {
      this.fullscreen = false;
      this.$refs.zoomImage.style.WebkitTransform = "";
      this.$refs.zoomImage.style.transform = "";
      this.$refs.zoomImage.style.zIndex = "";
      this.start = {
        x: 0,
        y: 0,
        dx: 0,
        dy: 0,
        distance: 0,
        currentScale: 1,
      };
    },
    distance(event) {
      return Math.hypot(
        event.touches[0].pageX - event.touches[1].pageX,
        event.touches[0].pageY - event.touches[1].pageY,
      );
    },
    touchStart(event) {
      if (event.touches.length === 2) {
        event.preventDefault(); // Prevent page scroll
        // Calculate where the fingers have started on the X and Y axis
        this.start.x =
          (event.touches[0].pageX + event.touches[1].pageX) / 2 - this.start.dx;
        this.start.y =
          (event.touches[0].pageY + event.touches[1].pageY) / 2 - this.start.dy;
        this.start.distance = this.distance(event);
      }
    },
    touchMove(event) {
      if (event.touches.length === 2) {
        event.preventDefault();
        let scale;
        if (event.scale) {
          scale = event.scale;
        } else {
          scale = this.distance(event) / this.start.distance;
        }
        let imageElementScale = Math.min(
          Math.max(0.8, scale * this.start.currentScale),
          10,
        );

        // Calculate how much the fingers have moved on the X and Y axis
        const deltaX =
          event.touches[0].pageX + event.touches[1].pageX / 2 - this.start.x;
        const deltaY =
          event.touches[0].pageY + event.touches[1].pageY / 2 - this.start.y;
        this.start.dx = deltaX;
        this.start.dy = deltaY;

        // Transform the image to make it grow and move with fingers
        const transform = `translate3d(${deltaX}px, ${deltaY}px, 0) scale(${imageElementScale})`;

        this.$refs.zoomImage.style.transform = transform;
        this.$refs.zoomImage.style.WebkitTransform = transform;
        this.$refs.zoomImage.style.zIndex = "9999";
      }
    },
  },
};
</script>
<style scoped lang="scss">
.opacity-enter-active {
  transition: all 1s;
  transition-timing-function: ease;
}

.opacity-leave-active {
  position: absolute;
  transition: all 1s;
  transition-timing-function: ease;
}
.opacity-enter, .opacity-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transition: all 1s;
}
</style>

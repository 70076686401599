<template>
  <h1
    class="fixed z-10 w-full max-w-xl border-b-2 border-b-kekkle-red bg-white py-4 text-center text-xl font-medium dark:bg-kekkle-dark-dark dark:text-slate-200"
  >
    {{ $t("pages.quotes.title_of") }}
    {{
      quoteFilterAuthorName
        ? quoteFilterAuthorName
        : groupData.name
          ? groupData.name
          : ""
    }}
  </h1>
  <div class="container mx-auto min-h-screen px-2 pb-24">
    <h1
      class="w-full max-w-xl bg-white py-5 text-center text-xl font-medium opacity-0"
    >
      {{ $t("pages.quotes.title_of") }}
      {{ groupData.name ? groupData.name : "" }}
    </h1>
    <information-text
      v-if="quotes.length === 0 && !loadingInitialQuotes && !searchEnabled"
      class="mx-auto"
      :information="$t('pages.quotes.no_quotes_yet')"
    />
    <div
      v-if="quotes.length > 0 || searchEnabled"
      class="my-2 flex w-full snap-x flex-nowrap items-center justify-center gap-x-4 overflow-x-scroll scrollbar-hide"
    >
      <span
        class="cursor-pointer snap-start rounded border border-kekkle-red p-2 px-4"
        :class="
          allQuotesEnabled
            ? 'bg-kekkle-red text-white'
            : 'bg-white text-kekkle-red dark:bg-kekkle-dark-dark'
        "
        @click="enableAll"
        >{{ $t("pages.quotes.filter_everything") }}</span
      >
      <span
        v-if="!authorFilterUuid"
        class="cursor-pointer snap-start rounded border border-kekkle-red p-2 px-4"
        :class="
          searchEnabled
            ? 'bg-kekkle-red text-white'
            : 'bg-white text-kekkle-red dark:bg-kekkle-dark-dark'
        "
        @click="enableSearch"
        >{{ $t("pages.quotes.filter_search") }}</span
      >
      <span
        v-if="authorFilterUuid"
        class="cursor-pointer snap-start rounded border border-kekkle-red bg-kekkle-red p-2 px-4 text-white"
        @click="enableAll"
        >{{ quoteFilterAuthorName }}</span
      >
    </div>
    <div v-if="searchEnabled" class="mx-auto mb-2 max-w-md px-2">
      <text-input
        v-model="searchTerm"
        class="mb-2"
        :label="$t('global.inputs.labels.search_term')"
        :error-message="$t('global.inputs.errors.search_term')"
        :has-error="!validSearch"
        :placeholder="$t('global.inputs.placeholders.search_term')"
      >
      </text-input>
      <default-button :disabled="!validSearch" @click="searchQuotes()">{{
        $t("pages.quotes.search")
      }}</default-button>
    </div>
    <div>
      <transition-group name="list">
        <div v-for="quote in quotes" :key="quote.id">
          <quote
            :quote-data="quote.data"
            :quote-id="quote.id"
            @go-to-comments="goToComments"
          ></quote>
        </div>
      </transition-group>
    </div>
    <p
      v-if="loadingMoreQuotes"
      class="animate-pulse text-center text-lg font-medium dark:text-slate-200"
    >
      ⌚{{ $t("pages.quotes.loading_more_quotes") }}
    </p>
    <p
      v-if="searchingQuotes && searchEnabled"
      class="animate-pulse text-center text-lg font-medium dark:text-slate-200"
    >
      ⌚{{ $t("pages.quotes.searching_quotes") }}
    </p>

    <p
      v-if="hasAllQuotes && quotes.length > 0"
      class="text-center text-lg font-medium dark:text-slate-200"
    >
      {{ $t("pages.quotes.all_quotes_loaded") }} 😄
    </p>
    <p
      v-if="loadingInitialQuotes"
      class="animate-pulse text-center text-lg font-medium dark:text-slate-200"
    >
      ⌚ {{ $t("pages.fetching_first_quotes") }}
    </p>
  </div>
</template>
<script>
import {
  GET_GROUP_DATA,
  GET_GROUP_MEMBERS_SORTED,
} from "@/store/modules/groups";
import Quote from "../components/Quote.vue";
import InformationText from "../components/InformationText.vue";
import TextInput from "@/components/Inputs/TextInput.vue";
import DefaultButton from "@/components/Buttons/DefaultButton.vue";
import { mapState } from "vuex";

export default {
  name: "QuotesView",
  components: { DefaultButton, TextInput, InformationText, Quote },
  data() {
    return {
      searchTerm: "",
      canRefetchInitalQuotes: true,
    };
  },
  async created() {
    if (this.$route.params?.author) {
      this.$store.commit("setAuthorFilterUuid", this.$route.params?.author);
      this.$store.commit("clearQuotes");
    }
    if (this.authorFilterUuid) {
      this.$store.commit("setAllQuotesEnabled", false);
    }
    window.addEventListener("scroll", this.handleScroll);
  },
  mounted() {
    setTimeout(() => {
      if (window.scrollY < 250) {
        this.$store.dispatch("fetchInitialQuotes");
      }
    }, 20);
    if (this.quotes.length) {
      window.scrollTo({
        left: 0,
        top: this.quoteScrollPosition,
        behavior: "instant",
      });
    } else {
      this.$store.dispatch("fetchInitialQuotes");
    }
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    goToComments(e) {
      this.$store.commit("setQuoteScrollPosition", window.scrollY);
      this.$router.push({
        name: "commentQuote",
        params: {
          quoteId: e.quoteId,
          groupId: e.groupId,
        },
      });
    },
    handleScroll() {
      this.$store.commit("setQuoteScrollPosition", window.scrollY);
      if (
        window.scrollY + window.outerHeight + 180 >=
        document.body.scrollHeight
      ) {
        this.$store.dispatch("fetchMoreQuotes");
      } else if (window.scrollY < 50) {
        if (this.canRefetchInitalQuotes) {
          this.$store.dispatch("fetchInitialQuotes");
          this.canRefetchInitalQuotes = false;
        }
      } else {
        this.canRefetchInitalQuotes = true;
      }
    },
    async searchQuotes() {
      this.$store.commit("clearQuotes");
      if (this.$store.state.Quotes.searchingQuotes) {
        return;
      }
      this.$store.dispatch("searchQuotes", this.searchTerm);
    },
    enableSearch() {
      this.$store.commit("setSearchEnabled", true);
      this.$store.commit("setAllQuotesEnabled", false);
      this.$store.commit("clearQuotes");
    },
    enableAll() {
      this.$store.commit("setAllQuotesEnabled", true);
      this.$store.commit("setSearchEnabled", false);
      this.searchTerm = "";
      this.$store.commit("setAuthorFilterUuid", null);
      this.$store.commit("clearQuotes");
      this.$store.dispatch("fetchInitialQuotes");
    },
  },
  computed: {
    ...mapState({
      quotes: (state) => state.Quotes.quotes,
      loadingMoreQuotes: (state) => state.Quotes.loadingMoreQuotes,
      loadingInitialQuotes: (state) => state.Quotes.loadingInitialQuotes,
      searchingQuotes: (state) => state.Quotes.searchingQuotes,
      searchEnabled: (state) => state.Quotes.searchEnabled,
      hasAllQuotes: (state) => state.Quotes.hasAllQuotes,
      allQuotesEnabled: (state) => state.Quotes.allQuotesEnabled,
      authorFilterUuid: (state) => state.Quotes.authorFilterUuid,
      quoteScrollPosition: (state) => state.Quotes.quoteScrollPosition,
    }),
    groupData() {
      return this.$store.getters[GET_GROUP_DATA];
    },
    validSearch() {
      let trimmedSearch = this.searchTerm;
      trimmedSearch = trimmedSearch.replaceAll(" ", "");
      return !!(this, trimmedSearch.length >= 3);
    },
    quoteFilterAuthorName() {
      return this.$store.getters[GET_GROUP_MEMBERS_SORTED].find(
        (member) => member.uuid === this.authorFilterUuid,
      )?.name;
    },
  },
};
</script>
<style>
.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
  position: absolute;
}
</style>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 2"
    viewBox="0 0 100 100"
    x="0px"
    y="0px"
  >
    <path
      d="M77.91,75.9a5.82,5.82,0,0,0,5.81-5.82V56.88a5.82,5.82,0,1,0-11.63,0v13.2A5.83,5.83,0,0,0,77.91,75.9Z"
    />
    <path
      d="M59.3,75.9a5.83,5.83,0,0,0,5.82-5.82V26.16a5.82,5.82,0,1,0-11.63,0V70.08A5.82,5.82,0,0,0,59.3,75.9Z"
    />
    <path
      d="M40.7,75.9a5.82,5.82,0,0,0,5.81-5.82V13a5.82,5.82,0,1,0-11.63,0V70.08A5.83,5.83,0,0,0,40.7,75.9Z"
    />
    <path
      d="M22.09,75.9a5.83,5.83,0,0,0,5.82-5.82V38.7a5.82,5.82,0,1,0-11.63,0V70.08A5.82,5.82,0,0,0,22.09,75.9Z"
    />
    <path
      d="M89.18,81.21H10.82a5.82,5.82,0,0,0,0,11.64H89.18a5.82,5.82,0,0,0,0-11.64Z"
    />
  </svg>
</template>

<template>
  <div
    class="relative h-10 w-10 flex-none text-center"
    @click="
      $router.push({
        name: 'memberView',
        params: {
          groupId: activeGroupId,
          memberId: uuid,
        },
      })
    "
  >
    <transition name="opacity">
      <img
        v-if="pictureUrl"
        draggable="false"
        alt="Profile Picture"
        class="h-10 w-10 rounded-full border object-cover shadow-inner"
        :src="pictureUrl"
      />
      <div
        v-else
        class="h-10 w-10 animate-pulse rounded-full bg-gradient-to-br from-orange to-pink shadow"
      ></div>
    </transition>
  </div>
</template>
<script>
import {
  DOWNLOAD_GROUP_MEMBER_PROFILE_PICTURE_DATA,
  GET_GROUP_MEMBERS_PROFILE_PICTURE_DATA,
} from "@/store/modules/groups";
import { THUMB } from "@/constants/image-sizes";
import { GET_ACTIVE_GROUP } from "@/store/modules/user";
import { getObjectPathValue } from "@/helpers/utils/ObjectPath";

export default {
  name: "TinyUserThumb",
  props: {
    uuid: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      THUMB: THUMB,
    };
  },
  computed: {
    membersProfilePicturesData() {
      return this.$store.getters[GET_GROUP_MEMBERS_PROFILE_PICTURE_DATA];
    },
    activeGroupId() {
      return this.$store.getters[GET_ACTIVE_GROUP];
    },
    pictureUrl() {
      return getObjectPathValue(this.membersProfilePicturesData, [
        this.uuid,
        this.THUMB,
        "url",
      ]);
    },
  },
  created() {
    //check if there already is an url
    if (!this.pictureUrl) {
      this.$store.dispatch(DOWNLOAD_GROUP_MEMBER_PROFILE_PICTURE_DATA, {
        uuid: this.uuid,
        format: this.THUMB,
      });
    }
  },
};
</script>
<style scoped lang="scss">
.opacity-enter-active {
  transition: all 1s;
  transition-timing-function: ease;
}

.opacity-leave-active {
  position: absolute;
  left: 0;
  transition: all 1s;
  transition-timing-function: ease;
}
.opacity-enter, .opacity-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0.2;
}
</style>

import Store from "@/store/store";
import * as Sentry from "@sentry/vue";
import { signInWithCustomToken } from "firebase/auth";
import { firebaseAuth } from "@/helpers/firebase/firebase";

export function hasSupportForNativeAppleAuth() {
  return !!window.flutter && !!window.nativeAppleAuthSupported;
}

export function hasSupportForNativeGoogleAuth() {
  return !!window.flutter && !!window.nativeGoogleAuthSupported;
}

export function logoutFlutterAuthInstance() {
  try {
    // eslint-disable-next-line no-undef
    FlutterLogoutAuthInstance.postMessage({});
  } catch (e) {
    Sentry.captureException(e);
  }
}

export function askGoogleIdTokenFlutter() {
  try {
    // eslint-disable-next-line no-undef
    FlutterGoogleId.postMessage({});
  } catch (e) {
    Store.commit("cancelAuth");
    Sentry.captureException(e);
  }
}

export function askAppleIdTokenFlutter() {
  try {
    // eslint-disable-next-line no-undef
    FlutterAppleId.postMessage({});
  } catch (e) {
    Store.commit("cancelAuth");
    Sentry.captureException(e);
  }
}

export function cancelAppleAuth(message) {
  Sentry.captureMessage(message);
  Store.commit("cancelAuth");
}

export function cancelGoogleAuth(message) {
  Sentry.captureMessage(message);
  Store.commit("cancelAuth");
}

export function signInWithCustomAuthToken(idToken) {
  signInWithCustomToken(firebaseAuth, idToken)
    .then(() => {
      window.location.reload();
    })
    .catch((e) => {
      Sentry.captureException(e);
      window.location.reload();
    });
}

import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { firestore } from "@/helpers/firebase/firebase";
import { docsConverter } from "./FirestoreService";

/**
 * Fetches the wordle leaderboard, and filters on the userFilter
 * @param {Array} userFilter
 * @returns {Promise<Array>}
 */
export async function fetchLeaderBoard(userFilter = []) {
  let docs = [];
  //Firstore only support up to 30 in queries in a single query
  let userFilterBatch = [];

  // Split the userFilter into multiple arrays in the userFilterBatch array
  for (let i = 0; i < userFilter.length; i += 25) {
    userFilterBatch.push(userFilter.slice(i, i + 25));
  }

  // Loop through the userFilterBatch array
  for (let i = 0; i < userFilterBatch.length || i === 0; i++) {
    let scoresQuery = query(
      collection(firestore, "wordle_leaderboard"),
      orderBy("new_score", "desc"),
    );

    if (userFilterBatch[i]) {
      scoresQuery = query(
        scoresQuery,
        where("user_id", "in", userFilterBatch[i]),
      );
    }

    let documents = docsConverter(await getDocs(scoresQuery));
    docs = docs.concat(documents);
  }

  return docs;
}

<template>
  <div>
    <div class="relative flex w-full flex-col gap-0.5">
      <div
        v-for="row in filledRows.length"
        :key="row"
        class="flex w-full flex-nowrap justify-center gap-0.5"
      >
        <MiniWordleBoardCell
          v-for="(cell, index) in gameState[row - 1].length"
          :key="'cell-' + cell"
          :letter="gameState[row - 1][cell - 1]"
          :hidden="hideLetters"
          :index="index"
        >
        </MiniWordleBoardCell>
      </div>
    </div>
  </div>
</template>
<script setup>
import MiniWordleBoardCell from "@/views/games/wordle/components/MiniWordleBoardCell.vue";
import { computed } from "vue";

const props = defineProps({
  gameState: {
    type: Object,
    required: true,
  },
  hideLetters: {
    type: Boolean,
    default: false,
  },
});

const filledRows = computed(() => {
  return props.gameState.filter((row) => {
    return (
      row.filter((cell) => {
        return cell.character !== "";
      }).length === 5
    );
  });
});
</script>

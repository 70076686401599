import { createRouter, createWebHistory } from "vue-router";
import GroupView from "../views/Group.vue";
import Agenda from "../views/Agenda.vue";
import Quotes from "../views/Quotes.vue";
import AuthenticationLayout from "../layouts/Authentication.vue";
import NavigationLayout from "../layouts/Navigation.vue";
import SinglePageLayout from "../layouts/SinglePage.vue";
import AddEventView from "../views/AddEvent.vue";
import AddQuoteView from "../views/AddQuote.vue";
import EditEventView from "../views/EditEvent.vue";
import EditQuoteView from "../views/EditQuote.vue";
import EditPhotoView from "../views/editPhoto.vue";
import InviteMembersView from "../views/InviteMembers.vue";
import InviteMembersFollowupView from "../views/InviteMembersFollowup.vue";
import NotificationSettingsView from "../views/settings/NotificationSettings.vue";
import ViewEventView from "../views/ViewEvent.vue";
import SettingsView from "../views/Settings.vue";
import Dice from "../views/games/Dice.vue";
import Wordle from "@/views/games/wordle/Wordle.vue";
import DefaultUserProfileSettingsView from "../views/settings/DefaultUserProfileSettings.vue";
import GroupUserProfileSettingsView from "../views/settings/GroupUserProfileSettings.vue";
import GroupProfileSettingsView from "../views/settings/GroupProfileSettings.vue";
import LoginWithProviderView from "../views/LoginWithProvider.vue";
import MemberPermissionsView from "../views/settings/MemberPermissions.vue";
import MemberSettingsView from "../views/settings/MemberSettings.vue";
import CompleteProfile from "../views/CompleteProfile.vue";
import CreateSelectGroup from "@/views/CreateSelectGroup.vue";
import store from "../store/store";
import { translate as $t } from "@/i18n/vueI18n";
import {
  GET_ACTIVE_GROUP,
  GET_HAS_USER_DOC,
  GET_USER_DATA,
} from "@/store/modules/user";
import QuoteComments from "../views/QuoteComments.vue";
import { GET_AUTH_USER, GET_AUTH_UUID } from "@/store/modules/auth";
import { IS_ADMIN, IS_ORGANISER } from "@/store/modules/groups";
import LeaveGroupView from "../views/settings/LeaveGroup.vue";
import DeleteGroupView from "../views/settings/DeleteGroup.vue";
import LoginRegister from "../views/LoginRegister.vue";
import VerifyEmail from "../views/VerifyEmail.vue";
import Photos from "../views/Photos.vue";
import Games from "../views/games/GameList.vue";
import EmailAction from "../views/EmailAction.vue";
import AddPhotoView from "../views/addPhoto.vue";
import PhotoComments from "../views/PhotoComments.vue";
import { doc, updateDoc } from "firebase/firestore";
import {
  firebaseAnalytics,
  usersCollection,
} from "@/helpers/firebase/firebase";
import { logEvent } from "firebase/analytics";
import {
  refreshAllUserGroupsData,
  refreshUserData,
} from "@/helpers/data/fetchers";
import { setInfoToast } from "@/helpers/notifications/toast";
import MemberView from "@/views/MemberView.vue";
import DeleteAccount from "../views/DeleteAccount.vue";
import DeleteAccountInfo from "@/views/settings/DeleteAccountInfo.vue";
import PrivacyPolicyView from "@/views/PrivacyPolicyView.vue";
import EulaView from "@/views/EulaView.vue";
import { updateUserDoc } from "@/helpers/firestore/UserService";
import { setLocalStorageItem } from "../helpers/utils/local-storage";

const routes = [
  {
    // path: "*",
    path: "/:catchAll(.*)",
    name: "NotFound",
    meta: {
      catchAll: true,
    },
  },
  {
    path: "/invite-link",
    name: "InviteLinkFollowup",
    component: InviteMembersFollowupView,
    meta: {
      requiresAuth: true,
      requiresEmailVerified: true,
      requiresCompleteProfile: true,
    },
  },
  {
    path: "/auth",
    name: "Auth",
    component: AuthenticationLayout,
    meta: {},
    children: [
      {
        path: "/delete-account",
        name: "DeleteAccount",
        component: DeleteAccount,
        meta: {
          requiresAuth: true,
          requiresEmailVerified: true,
          requiresCompleteProfile: true,
          requiresEmptyGroupList: true,
        },
      },
      {
        path: "/start",
        name: "GroupSelector",
        component: CreateSelectGroup,
        meta: {
          requiresAuth: true,
          requiresEmailVerified: true,
          requiresCompleteProfile: true,
        },
      },
      {
        path: "/login",
        name: "Login",
        component: LoginWithProviderView,
        meta: {},
      },
      {
        path: "/login-email",
        name: "LoginEmail",
        component: LoginRegister,
        meta: {},
      },
      {
        path: "/complete-profile",
        name: "CompleteProfile",
        component: CompleteProfile,
        meta: {
          requiresAuth: true,
          requiresEmailVerified: true,
        },
      },
      {
        path: "/verify-email",
        name: "VerifyEmail",
        component: VerifyEmail,
        meta: { requiresAuth: true },
      },
      {
        path: "/email-action",
        name: "EmailAction",
        component: EmailAction,
      },
    ],
  },
  //
  {
    name: "PrivacyPolicy",
    path: "/privacy-policy",
    component: PrivacyPolicyView,
  },
  {
    name: "Eula",
    path: "/eula-policy",
    component: EulaView,
  },

  //Single pages inside the app
  {
    name: "SinglePages",
    path: "/single-page",
    component: SinglePageLayout,
    meta: {
      requiresAuth: true,
      requiresCompleteProfile: true,
      requiresGroup: true,
      requiresEmailVerified: true,
    },
    children: [
      {
        path: "/member/:groupId/:memberId",
        name: "memberView",
        component: MemberView,
        meta: {
          requiresAuth: true,
          requiresCompleteProfile: true,
          requiresGroup: true,
          requiresEmailVerified: true,
        },
        props: true,
      },
      {
        path: "/games/wordle",
        name: "wordle",
        component: Wordle,
        meta: {
          requiresAuth: true,
          requiresCompleteProfile: true,
          requiresGroup: true,
          requiresEmailVerified: true,
        },
      },
      {
        path: "/games/dice",
        name: "dice",
        component: Dice,
        meta: {
          requiresAuth: true,
          requiresCompleteProfile: true,
          requiresGroup: true,
          requiresEmailVerified: true,
        },
      },
      {
        path: "/agenda/view/:groupId/:eventId",
        name: "viewEvent",
        component: ViewEventView,
        props: (route) => ({
          ...route.params,
        }),
        meta: {
          requiresAuth: true,
          requiresCompleteProfile: true,
          requiresGroup: true,
          requiresEmailVerified: true,
        },
      },
      {
        path: "/agenda/toevoegen",
        name: "addEvent",
        component: AddEventView,
        meta: {
          requiresAuth: true,
          requiresCompleteProfile: true,
          requiresGroup: true,
          requiresEmailVerified: true,
        },
      },
      {
        path: "/agenda/aanpassen",
        name: "editEvent",
        component: EditEventView,
        meta: {
          requiresAuth: true,
          requiresCompleteProfile: true,
          requiresGroup: true,
          requiresEmailVerified: true,
        },
      },
      {
        path: "/invite",
        name: "InviteMembers",
        component: InviteMembersView,
        meta: {
          requiresAuth: true,
          requiresCompleteProfile: true,
          requiresGroup: true,
          requiresEmailVerified: true,
        },
      },
      {
        path: "/settings",
        name: "settings",
        component: SettingsView,
        meta: {
          requiresAuth: true,
          requiresCompleteProfile: true,
          requiresGroup: true,
          requiresEmailVerified: true,
        },
      },
      {
        path: "/settings/default-profile",
        name: "defaultUserProfileSettings",
        component: DefaultUserProfileSettingsView,
        meta: {
          requiresAuth: true,
          requiresCompleteProfile: true,
          requiresGroup: true,
          requiresEmailVerified: true,
        },
      },
      {
        path: "/settings/group-profile",
        name: "groupUserProfileSettings",
        component: GroupUserProfileSettingsView,
        meta: {
          requiresAuth: true,
          requiresCompleteProfile: true,
          requiresGroup: true,
          requiresEmailVerified: true,
        },
      },
      {
        path: "/settings/group",
        name: "groupProfileSettings",
        component: GroupProfileSettingsView,
        meta: {
          requiresAuth: true,
          requiresCompleteProfile: true,
          requiresGroup: true,
          requiresEmailVerified: true,
        },
      },
      {
        path: "/settings/permissions",
        name: "memberPermissions",
        component: MemberPermissionsView,
        meta: {
          requiresAuth: true,
          requiresCompleteProfile: true,
          requiresGroup: true,
          requiresEmailVerified: true,
        },
      },
      {
        path: "/settings/leave",
        name: "leaveGroup",
        component: LeaveGroupView,
        meta: {
          requiresAuth: true,
          requiresCompleteProfile: true,
          requiresGroup: true,
          requiresEmailVerified: true,
        },
      },
      {
        path: "/settings/delete",
        name: "deleteGroup",
        component: DeleteGroupView,
        meta: {
          requiresAuth: true,
          requiresCompleteProfile: true,
          requiresGroup: true,
          requiresEmailVerified: true,
        },
      },
      {
        path: "/settings/members",
        name: "memberSettings",
        component: MemberSettingsView,
        meta: {
          requiresAuth: true,
          requiresCompleteProfile: true,
          requiresGroup: true,
          requiresEmailVerified: true,
        },
      },
      {
        path: "/settings/notifications",
        name: "notificationSettingsView",
        component: NotificationSettingsView,
        meta: {
          requiresAuth: true,
          requiresCompleteProfile: true,
          requiresGroup: true,
          requiresEmailVerified: true,
        },
      },
      {
        path: "/settings/delete-account-information",
        name: "deleteAccountInfo",
        component: DeleteAccountInfo,
        meta: {
          requiresAuth: true,
          requiresCompleteProfile: true,
          requiresGroup: true,
          requiresEmailVerified: true,
        },
      },
      {
        path: "/quotes/toevoegen",
        name: "addQuote",
        component: AddQuoteView,
        meta: {
          requiresAuth: true,
          requiresCompleteProfile: true,
          requiresGroup: true,
          requiresEmailVerified: true,
        },
      },
      {
        path: "/piccas/toevoegen",
        name: "addPhoto",
        component: AddPhotoView,
        meta: {
          requiresAuth: true,
          requiresCompleteProfile: true,
          requiresGroup: true,
          requiresEmailVerified: true,
        },
      },

      {
        path: "/quotes/aanpassen",
        name: "editQuote",
        component: EditQuoteView,
        meta: {
          requiresAuth: true,
          requiresCompleteProfile: true,
          requiresGroup: true,
          requiresOrganiserRole: true,
          requiresEmailVerified: true,
        },
      },
      {
        path: "/piccas/aanpassen",
        name: "editPhoto",
        component: EditPhotoView,
        meta: {
          requiresAuth: true,
          requiresCompleteProfile: true,
          requiresGroup: true,
          requiresEmailVerified: true,
        },
      },
      {
        path: "/quotes/comments/:groupId/:quoteId",
        name: "commentQuote",
        component: QuoteComments,
        props: (route) => ({
          ...route.params,
        }),
        meta: {
          requiresAuth: true,
          requiresCompleteProfile: true,
          requiresGroup: true,
          requiresEmailVerified: true,
        },
      },
      {
        path: "/piccas/comments/:groupId/:photoId",
        name: "commentPhoto",
        component: PhotoComments,
        props: (route) => ({
          ...route.params,
        }),
        meta: {
          requiresAuth: true,
          requiresCompleteProfile: true,
          requiresGroup: true,
          requiresEmailVerified: true,
        },
      },
    ],
  },
  //Pages that have the navigation bar
  {
    path: "/",
    name: "NavigationPages",
    component: NavigationLayout,
    meta: {
      requiresAuth: true,
      requiresCompleteProfile: true,
      requiresGroup: true,
      requiresEmailVerified: true,
    },
    children: [
      {
        path: "/",
        name: "Group",
        component: GroupView,
        meta: {
          requiresAuth: true,
          requiresCompleteProfile: true,
          requiresGroup: true,
        },
      },
      {
        path: "/agenda",
        name: "Agenda",
        component: Agenda,
        meta: {
          requiresAuth: true,
          requiresCompleteProfile: true,
          requiresGroup: true,
        },
      },
      {
        path: "/quotes",
        name: "Quotes",
        component: Quotes,
        meta: {
          requiresAuth: true,
          requiresCompleteProfile: true,
          requiresGroup: true,
        },
      },
      {
        path: "/quotes/:author",
        name: "QuotesWithAuthor",
        component: Quotes,
        meta: {
          requiresAuth: true,
          requiresCompleteProfile: true,
          requiresGroup: true,
        },
      },
      {
        path: "/piccas",
        name: "Photos",
        component: Photos,
        meta: {
          requiresAuth: true,
          requiresCompleteProfile: true,
          requiresGroup: true,
        },
      },
      {
        path: "/games",
        name: "Games",
        component: Games,
        meta: {
          requiresAuth: true,
          requiresCompleteProfile: true,
          requiresGroup: true,
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});
router.beforeEach(async (to, from, next) => {
  let params = new URLSearchParams(document.location.search);
  let forceGroup = params.get("forceGroup");
  params.delete("forceGroup");
  if (forceGroup) {
    store.commit("setAuthorFilterUuid", null);
    if (store.getters[GET_USER_DATA]?.["groups"]?.includes(forceGroup)) {
      if (forceGroup !== store.getters[GET_USER_DATA].active_group) {
        await updateUserDoc(store.getters[GET_AUTH_UUID], {
          active_group: forceGroup,
        });
        await refreshUserData();
        refreshAllUserGroupsData();
        setInfoToast({ message: $t("global.toast.switched_group") });
      }
      next();
    } else {
      next();
    }
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.name === "InviteLinkFollowup") {
    //save the token
    if (to.query?.token) {
      setLocalStorageItem("joinToken", to.query.token);
    }
  }
  next();
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((x) => x.meta.requiresEmptyGroupList)) {
    if (store.getters[GET_USER_DATA]?.groups?.length > 0) {
      next({ name: "Group" });
    } else {
      next();
    }
  } else {
    next();
  }
});
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  if (requiresAuth && !store.getters[GET_AUTH_USER]) {
    next({ name: "Login" });
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  const requiresEmailVerified = to.matched.some(
    (x) => x.meta.requiresEmailVerified,
  );
  //TODO investigate with some apple provider sign ins do not have a verified email.
  const user = store.getters[GET_AUTH_USER];
  const providers = user?.providerData;
  const hasSignedInWithApple = providers?.some(
    (provider) => provider.providerId === "apple.com",
  );

  if (!requiresEmailVerified || hasSignedInWithApple || user?.emailVerified) {
    next();
  } else {
    next({ name: "VerifyEmail" });
  }
});

router.beforeEach((to, from, next) => {
  const requiresCompleteProfile = to.matched.some(
    (x) => x.meta.requiresCompleteProfile,
  );
  if (requiresCompleteProfile && !store.getters[GET_HAS_USER_DOC]) {
    next({ name: "CompleteProfile" });
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  const requiresOrganiserRole = to.matched.some(
    (x) => x.meta.requiresOrganiserRole,
  );
  if (
    requiresOrganiserRole &&
    !store.getters[IS_ORGANISER] &&
    !store.getters[IS_ADMIN]
  ) {
    next({ name: "Quotes" });
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((x) => x.meta.catchAll)) {
    next({ name: "Group" });
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  const requiresGroup = to.matched.some((x) => x.meta.requiresGroup);
  if (requiresGroup && !store.getters[GET_ACTIVE_GROUP]) {
    next({ name: "GroupSelector" });
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.name === "GroupSelector" && store.getters[GET_ACTIVE_GROUP]) {
    next({ name: "Group" });
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.name === "CompleteProfile" && store.getters[GET_HAS_USER_DOC]) {
    next({ name: "Group" });
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  //is already signed in for some reason.
  if (to.name === "LoginLink" && store.getters[GET_AUTH_USER]) {
    next({ name: "Group" });
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  //is already signed in for some reason.
  if (to.name === "Login" && store.getters[GET_AUTH_USER]) {
    next({ name: "Group" });
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  logEvent(firebaseAnalytics, "route_changed", {
    to: to?.name,
    from: from?.name,
  });
});

export default router;

<template>
  <div
    class="bg-red text flex flex-nowrap gap-x-2 rounded-lg border-2 p-4 pl-2 shadow"
    :class="colorClasses"
  >
    <component :is="icon" class="mt-1 h-6 w-6 shrink-0"> </component>
    <p v-html="information"></p>
  </div>
</template>

<script>
import {
  ExclamationCircleIcon,
  InformationCircleIcon,
  ExclamationIcon,
  CheckCircleIcon,
} from "@heroicons/vue/solid";

export default {
  name: "InformationText",
  props: {
    information: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: "teal",
    },
  },
  computed: {
    colorClasses() {
      switch (this.color) {
        case "teal":
          return "border-teal-dark bg-teal-super-light text-teal-dark ";
        case "red":
          return "border-red-600 bg-red-100 text-red-700";
        case "green":
          return "border-green-600 bg-green-100 text-green-700";
        case "orange":
          return "border-yellow-600 bg-yellow-100 text-yellow-700";
        default:
          return "";
      }
    },
    icon() {
      switch (this.color) {
        case "teal":
          return InformationCircleIcon;
        case "red":
          return ExclamationCircleIcon;
        case "green":
          return CheckCircleIcon;
        case "orange":
          return ExclamationIcon;
        default:
          return "";
      }
    },
  },
};
</script>

<template>
  <div class="mb-4 flex w-full flex-nowrap items-center gap-2">
    <tiny-user-thumb v-if="!simple" :uuid="authorId" />
    <div
      class="relative grow overflow-hidden rounded-lg border-2 border-solid border-black-dark bg-white shadow-purple-hard dark:bg-slate-600"
    >
      <div
        v-if="photoDoc.data().reported_by?.length > 0 || reported"
        class="relative left-0 top-0 flex h-full w-full items-center justify-center bg-white p-8 dark:bg-kekkle-dark-light dark:text-slate-200"
      >
        <p class="text-center font-medium">
          {{ $t("components.report.hasBeenReportedBy") }}
          {{ reportedNames }}.
          {{ $t("components.report.adminsWillDetermine") }}
        </p>
      </div>
      <div
        v-if="
          canEdit &&
          !simple &&
          !photoDoc.data().reported_by?.length > 0 &&
          !reported
        "
        class="absolute left-2 top-2 transform cursor-pointer rounded-full border-2 border-kekkle-dark bg-white p-1 shadow-purple-hard-sm duration-300 hover:-translate-y-1 hover:scale-105 dark:bg-slate-700"
        @click="goToEditView()"
      >
        <pencil-alt-icon
          class="h-4 w-4 flex-none text-kekkle-dark dark:text-white"
        ></pencil-alt-icon>
      </div>
      <div
        v-if="!simple"
        class="absolute right-2 top-2 transform cursor-pointer rounded-full border-2 border-kekkle-dark bg-white p-1 shadow-purple-hard-sm duration-300 hover:-translate-y-1 hover:scale-105 dark:bg-slate-700"
        @click="reportModalIsOpen = true"
      >
        <dots-vertical-icon
          class="h-4 w-4 flex-none text-kekkle-dark dark:text-white"
        ></dots-vertical-icon>
      </div>

      <transition
        v-if="!photoDoc.data().reported_by?.length > 0 && !reported"
        name="opacity"
      >
        <img
          v-if="imageUrl"
          alt="Foto"
          :src="imageUrl"
          class="w-full object-cover object-center"
          :class="simple ? 'aspect-video' : 'aspect-square'"
          @click="openImage"
        />
        <img
          v-else-if="!imageUrl && hashUrl"
          alt="Foto"
          class="w-full object-cover object-center"
          :class="simple ? 'aspect-video' : 'aspect-square'"
          :src="hashUrl"
        />
      </transition>
      <div
        v-if="caption"
        class="overflow-hidden p-2 italic dark:bg-kekkle-dark-light dark:text-slate-200"
      >
        {{ caption }}
      </div>

      <div
        class="px-2 text-right italic dark:bg-kekkle-dark-light dark:text-slate-200"
      >
        {{ timeAgoString }}
      </div>
      <div
        class="px-2 pb-2 text-right text-xs italic text-gray-500 dark:bg-kekkle-dark-light dark:text-slate-400"
      >
        {{ timeString }}
      </div>
    </div>

    <div
      v-if="!simple && !photoDoc.data().reported_by?.length > 0 && !reported"
      class="mx-1 flex flex-col justify-center"
      @click="goToComments"
    >
      <chat-alt2-icon
        class="w-6 transform duration-300 hover:-translate-y-2 dark:text-slate-200"
      />
      <p class="text-center text-lg font-medium dark:text-slate-200">
        {{ photoDoc.data().number_of_comments ?? 0 }}
      </p>
    </div>
    <ConformationModal
      safe-mode
      :visible="reportModalIsOpen"
      :confirm-text="$t('components.report.report')"
      :cancel-text="$t('components.report.close')"
      :loading="reporting"
      @cancel="reportModalIsOpen = false"
      @confirm="reportPhoto"
    >
      <h3 class="text-xl font-bold dark:text-slate-200">
        {{ $t("components.report.reportTitle") }}
      </h3>
      <p class="my-2">
        {{ $t("components.report.reportText") }}
      </p>
      <information-text
        class="my-2"
        color="orange"
        loa
        :information="$t('components.report.reportInformation')"
      ></information-text>
    </ConformationModal>
  </div>
  <div
    v-show="fullscreen && !simple"
    class="max-w-screen max-h-screen fixed left-0 top-0 z-50 flex min-h-screen w-screen flex-col items-center justify-center overflow-scroll bg-slate-700 bg-opacity-60"
    @click="closeImage"
  >
    <div class="h-full w-fit max-w-lg">
      <img
        v-if="imageUrl"
        ref="zoomImage"
        alt="Foto-big"
        :src="imageUrl"
        style="max-height: 50vh"
        class="h-full overflow-visible rounded object-contain"
        :class="hasImageQuality ? '' : 'animate-pulse'"
        @click.stop
        @touchstart="touchStart"
        @touchmove="touchMove"
      />
      <div class="w-full max-w-lg rounded bg-white p-2" @click.stop>
        <p class="mr-2 inline">{{ $t("pages.photo.quality") }} :</p>
        <select v-model="selectedQuality" class="inline">
          <option :value="HD">
            {{ $t("pages.photo.quality_normal") }}
          </option>
          <option :value="FULL_HD">
            {{ $t("pages.photo.quality_high") }}
          </option>
          <option :value="ULTRA_HD">
            {{ $t("pages.photo.quality_very_high") }}
          </option>
        </select>
        <a
          v-if="!isFlutter"
          :href="imageUrl"
          :download="fileSafeCaption"
          class="my-2 block"
          >{{ $t("pages.photo.download") }}</a
        >
        <a v-else class="my-2 block" @click="postImageToFlutter">
          {{ $t("pages.photo.download") }}
        </a>
      </div>
    </div>
  </div>
</template>
<script setup>
import {
  PencilAltIcon,
  ChatAlt2Icon,
  DotsVerticalIcon,
} from "@heroicons/vue/outline";
import { computed, defineProps, ref, watch, defineEmits } from "vue";
import { FULL_HD, HD, SD, ULTRA_HD } from "@/constants/image-sizes";
import { useStore } from "vuex";
import router from "../router/router.js";
import { isRunningInFlutter } from "@/helpers/flutter/FlutterInit";
import { saveFileFlutter } from "@/helpers/flutter/FlutterFile";

const store = useStore();

import TinyUserThumb from "../components/TinyUserThumb.vue";
import { decodeBlurhashToUrl } from "@/helpers/blurhash/decodeBlurhashToUrl";
import { timeAgo } from "@/helpers/dates/time-ago";
import { firebaseStorage, firestore } from "@/helpers/firebase/firebase";
import {
  GET_GROUP_DATA,
  GET_GROUP_DOC_ID,
  IS_ADMIN,
} from "@/store/modules/groups";
import { getBlob, ref as fireRef } from "firebase/storage";
import { GET_AUTH_UUID } from "@/store/modules/auth";
import { dateAgo } from "@/helpers/dates/date-ago";
import {
  arrayUnion,
  collection,
  doc,
  serverTimestamp,
  writeBatch,
} from "firebase/firestore";
import { setErrorToast, setSuccessToast } from "@/helpers/notifications/toast";
import InformationText from "@/components/InformationText.vue";
import ConformationModal from "@/components/Modals/ConformationModal.vue";
import { translate } from "@/i18n/vueI18n";
import { SET_SELECTED_PHOTO } from "@/store/modules/photos";

const fullscreen = ref(false);
const zoomImage = ref(null);
const emits = defineEmits(["goToComments"]);

const isFlutter = isRunningInFlutter();

let start = ref({
  x: 0,
  y: 0,
  dx: 0,
  dy: 0,
  distance: 0,
  currentScale: 1,
});

let selectedQuality = ref(SD);
let reportModalIsOpen = ref(false);
let reporting = ref(false);
let reported = ref(false);

watch(selectedQuality, () => {
  downloadImage();
});

const props = defineProps({
  photoDoc: null,
  simple: {
    required: false,
    default: false,
  },
});

let hashUrl = ref("");
let imageData = ref({});

const date = computed(() => {
  return props.photoDoc?.data?.().timestamp?.toDate?.();
});

const reportedNames = computed(() => {
  let names = "";
  if (!props.photoDoc?.data().reported_by) {
    return names;
  }
  props.photoDoc?.data().reported_by.forEach((uuid) => {
    if (
      store.getters[GET_GROUP_DATA].members[uuid] &&
      store.getters[GET_GROUP_DATA].members[uuid].name
    ) {
      names = names + store.getters[GET_GROUP_DATA].members[uuid].name + ", ";
    }
    if (!store.getters[GET_GROUP_DATA].members[uuid]) {
      names = names + this.$t("global.deleted_user") + ", ";
    }
  });
  return names;
});

const caption = computed(() => {
  return props.photoDoc?.data?.().caption;
});

//a computed property with the caption without emoji
const emoijStrippedCaption = computed(() => {
  return caption.value.replace(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g, "");
});

const fileSafeCaption = computed(() => {
  if (emoijStrippedCaption.value) {
    return emoijStrippedCaption.value;
  } else if (date.value) {
    return date.value.toTimeString();
  } else {
    return new Date().toTimeString();
  }
});

const groupDocId = computed(() => {
  return store.getters[GET_GROUP_DOC_ID];
});

const imageUrl = computed(() => {
  return (
    imageData.value[selectedQuality.value]?.url ?? imageData.value[SD]?.url
  );
});

const image = computed(() => {
  return imageData.value[selectedQuality.value] ?? imageData.value[SD];
});

const hasImageQuality = computed(() => {
  return !!imageData.value[selectedQuality.value]?.url;
});

const createdBy = computed(() => {
  return props.photoDoc?.data?.().created_by;
});

const canEdit = computed(() => {
  return (
    store.getters[IS_ADMIN] || createdBy.value === store.getters[GET_AUTH_UUID]
  );
});

function goToComments() {
  emits("goToComments", {
    photoId: props.photoDoc.id,
    groupId: store.getters[GET_GROUP_DOC_ID],
  });
}

async function postImageToFlutter() {
  const reader = new FileReader();
  await reader.readAsDataURL(image.value.data);
  reader.onloadend = function () {
    const base64data = reader.result;
    saveFileFlutter(
      base64data.toString(),
      fileSafeCaption.value,
      store.getters[GET_GROUP_DATA].name,
      "pages.photo.file_saved",
    );
  };
}

function openImage() {
  if (selectedQuality.value === SD) {
    selectedQuality.value = HD;
  }
  fullscreen.value = true;
}

function closeImage() {
  fullscreen.value = false;
  zoomImage.value.style.transform = "";
  zoomImage.value.style.WebkitTransform = "";
  zoomImage.value.style.zIndex = "";
  start.value = {
    x: 0,
    y: 0,
    dx: 0,
    dy: 0,
    distance: 0,
    currentScale: 1,
  };
}

downloadImage();

function goToEditView() {
  store.commit(SET_SELECTED_PHOTO, props.photoDoc);
  router.push({
    name: "editPhoto",
  });
}

function distance(event) {
  return Math.hypot(
    event.touches[0].pageX - event.touches[1].pageX,
    event.touches[0].pageY - event.touches[1].pageY,
  );
}

function touchStart(event) {
  if (event.touches.length === 2) {
    event.preventDefault(); // Prevent page scroll
    // Calculate where the fingers have started on the X and Y axis
    start.value.x =
      (event.touches[0].pageX + event.touches[1].pageX) / 2 - start.value.dx;
    start.value.y =
      (event.touches[0].pageY + event.touches[1].pageY) / 2 - start.value.dy;
    start.value.distance = distance(event);
  }
}

function touchMove(event) {
  if (event.touches.length === 2) {
    event.preventDefault(); // Prevent page scroll
    let scale;
    // Safari provides event.scale as two fingers move on the screen
    // For other browsers just calculate the scale manually
    if (event.scale) {
      scale = event.scale;
    } else {
      scale = distance(event) / start.value.distance;
    }

    let imageElementScale = Math.min(
      Math.max(0.8, scale * start.value.currentScale),
      10,
    );

    // Calculate how much the fingers have moved on the X and Y axis
    const deltaX =
      (event.touches[0].pageX + event.touches[1].pageX) / 2 - start.value.x; // x2 for accelarated movement
    const deltaY =
      (event.touches[0].pageY + event.touches[1].pageY) / 2 - start.value.y; // x2 for accelarated movement
    start.value.dx = deltaX;
    start.value.dy = deltaY;

    start.value.distance = distance(event);
    start.value.currentScale = imageElementScale;

    // Transform the image to make it grow and move with fingers
    const transform = `translate3d(${deltaX}px, ${deltaY}px, 0) scale(${imageElementScale})`;

    zoomImage.value.style.transform = transform;
    zoomImage.value.style.WebkitTransform = transform;
    zoomImage.value.style.zIndex = "9999";
  }
}

async function reportPhoto() {
  try {
    reporting.value = true;
    let batch = writeBatch(firestore);
    let quoteRef = doc(
      collection(firestore, `groups/${store.getters[GET_GROUP_DOC_ID]}/photos`),
      props.photoDoc.id,
    );

    batch.update(quoteRef, {
      reported_by: arrayUnion(store.getters[GET_AUTH_UUID]),
    });

    batch.set(doc(collection(firestore, `reports`)), {
      group_id: store.getters[GET_GROUP_DOC_ID],
      type: "photo",
      document_id: props.photoDoc.id,
      created_at: serverTimestamp(),
      created_by: store.getters[GET_AUTH_UUID],
      solved: false,
      solved_at: null,
    });

    await batch.commit();
    setSuccessToast({
      message: translate("components.report.toastReported"),
    });
    reported.value = true;
  } catch (e) {
    setErrorToast({
      message: translate("components.report.toastNotReported"),
    });
  } finally {
    reportModalIsOpen.value = false;
    reporting.value = false;
  }
}

async function downloadImage() {
  if (imageData.value?.[selectedQuality.value]?.url) {
    return;
  }
  let reference = fireRef(
    firebaseStorage,
    "groups/" +
      groupDocId.value +
      "/" +
      props.photoDoc?.data?.().picture_url +
      selectedQuality.value,
  );
  try {
    let blob = await getBlob(reference);
    imageData.value[selectedQuality.value] = {};
    imageData.value[selectedQuality.value].data = blob;
    imageData.value[selectedQuality.value].url = URL.createObjectURL(blob);
  } catch (e) {
    let originalReference = fireRef(
      firebaseStorage,
      "groups/" + groupDocId.value + "/" + props.photoDoc?.data?.().picture_url,
    );
    let blob = await getBlob(originalReference);
    imageData.value[ULTRA_HD] = {};
    imageData.value[ULTRA_HD].data = blob;
    imageData.value[ULTRA_HD].url = URL.createObjectURL(blob);
    selectedQuality.value = ULTRA_HD;
  }
}

const authorId = props.photoDoc?.data?.().created_by;
const timeString = dateAgo(date.value);
const timeAgoString = timeAgo(date.value);

hashUrl.value = decodeBlurhashToUrl(props.photoDoc?.data?.().blurhash);
</script>
<style lang="scss">
.opacity-enter-active {
  transition: all 0.3s;
  transition-timing-function: ease;
}

.opacity-leave-active {
  position: absolute;
  left: 0;
  right: 0;
  transition: opacity 0.3s;
  transition-timing-function: linear;
}
.opacity-enter, .opacity-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0.1;
}
</style>

import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { firestore } from "@/helpers/firebase/firebase";
import { docsConverter } from "./FirestoreService";

/**
 * Fetches the monthly scores
 * @param {Number} year
 * @param {Number} month
 * @param {Array} userFilter
 * @returns {Promise<Array>}
 */
export async function fetchMonthlyScores(year, month, userFilter = []) {
  let docs = [];
  let userFilterBatch = [];
  // Split the userFilter into multiple arrays in the userFilterBatch array
  for (let i = 0; i < userFilter.length; i += 25) {
    userFilterBatch.push(userFilter.slice(i, i + 25));
  }

  // Loop through the userFilterBatch array
  for (let i = 0; i < userFilterBatch.length || i === 0; i++) {
    let scoresQuery = query(
      collection(firestore, "wordle_monthly_scores"),
      where("month", "==", month),
      where("year", "==", year),
      orderBy("new_score", "desc"),
    );

    if (userFilterBatch[i]) {
      scoresQuery = query(
        scoresQuery,
        where("user_id", "in", userFilterBatch[i]),
      );
    }

    let documents = docsConverter(await getDocs(scoresQuery));
    documents.forEach((doc) => docs.push(doc));
  }
  return docs;
}

<template>
  <h1
    class="fixed z-10 w-full max-w-xl border-b-2 border-b-kekkle-purple bg-white py-4 text-center text-xl font-medium dark:bg-kekkle-dark-dark dark:text-slate-200"
  >
    {{ $t("pages.photo.title_of") }}
    {{ groupData.name ? groupData.name : "" }}
  </h1>
  <div class="container mx-auto min-h-screen px-2 pb-24">
    <h1
      class="w-full max-w-xl bg-white py-5 text-center text-xl font-medium opacity-0"
    >
      {{ $t("pages.photo.title_of") }}
      {{ groupData.name ? groupData.name : "" }}`
    </h1>
    <InformationText
      v-if="photos.length === 0 && !status.loadingInitialPhotos"
      class="mx-auto"
      :information="$t('pages.photo.no_photos_yet')"
    />
    <div class="">
      <transition-group name="list">
        <div v-for="(photo, index) in photos" :key="index">
          <photo :photo-doc="photo" @go-to-comments="goToComments"></photo>
        </div>
      </transition-group>
    </div>
    <p
      v-if="status.loadingMorePhotos"
      class="animate-pulse text-center text-lg font-medium dark:text-slate-200"
    >
      ⌚ {{ $t("pages.photo.loading_more_photos") }}
    </p>
    <p
      v-if="status.hasAllPhotos && photos.length > 0"
      class="text-center text-lg font-medium dark:text-slate-200"
    >
      {{ $t("pages.photo.all_photos_loaded") }}📷
    </p>
    <!--    loader-->
    <p
      v-if="status.loadingInitialPhotos"
      class="animate-pulse text-center text-lg font-medium dark:text-slate-200"
    >
      ⌚ {{ $t("pages.photo.loading_photos") }}
    </p>
    <!--    <div v-if="status.loadingInitialPhotos">-->
    <!--      <div-->
    <!--        class="mx-auto mb-8 h-32 max-w-md animate-pulse rounded-xl bg-gray-300 dark:bg-slate-700"-->
    <!--      ></div>-->
    <!--      <div-->
    <!--        class="mx-auto mb-8 h-28 max-w-md animate-pulse rounded-xl bg-gray-300 dark:bg-slate-700"-->
    <!--      ></div>-->
    <!--      <div-->
    <!--        class="mx-auto mb-8 h-32 max-w-md animate-pulse rounded-xl bg-gray-300 dark:bg-slate-700"-->
    <!--      ></div>-->
    <!--    </div>-->
  </div>
</template>
<script async setup>
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
} from "firebase/firestore";
import { firestore } from "@/helpers/firebase/firebase";
import { GET_GROUP_DATA, GET_GROUP_DOC_ID } from "@/store/modules/groups";
import { handleFirestoreException } from "@/helpers/exceptions/handler";
import { computed, toRaw } from "vue";
import { useStore } from "vuex";
import InformationText from "../components/InformationText.vue";
import { ref } from "vue";
import Photo from "./Photo.vue";
import router from "@/router/router";

const photos = ref([]);
const status = ref({
  loadingInitialPhotos: false,
  loadingMorePhotos: false,
  hasAllPhotos: false,
});
const photosCollection = ref(null);
const store = useStore();

photosCollection.value = collection(
  firestore,
  "groups/" + store.getters[GET_GROUP_DOC_ID] + "/photos",
);

//check if there are already quotes in cache (user openend comments or something similar)
if (window.CachePhotos && window.CachePhotosScrollY) {
  photos.value = window.CachePhotos;
  setTimeout(() => {
    window.scrollTo({
      left: 0,
      top: window.CachePhotosScrollY,
    });
    window.CachePhotosScrollY = null;
  }, 10);

  window.CachePhotos = null;
} else {
  fetchInitialPhotos();
}

window.addEventListener("scroll", handleScroll);

const groupData = computed(() => {
  return store.getters[GET_GROUP_DATA];
});

async function goToComments(e) {
  window.CachePhotos = photos.value;
  window.CachePhotosScrollY = window.scrollY;

  await router.push({
    name: "commentPhoto",
    params: {
      photoId: e.photoId,
      groupId: e.groupId,
    },
  });
}

async function fetchInitialPhotos() {
  if (photos.value.length === 0) {
    try {
      status.value.loadingInitialPhotos = true;
      let querySnapshot = await getDocs(
        query(photosCollection.value, orderBy("timestamp", "desc"), limit(6)),
      );
      querySnapshot.forEach((doc) => {
        photos.value.push(doc);
      });
      if (photos.value.length < 6) {
        status.value.hasAllPhotos = true;
      }
    } catch (e) {
      handleFirestoreException({ e: e });
    } finally {
      status.value.loadingInitialPhotos = false;
    }
  }
}

async function fetchMorePhotos() {
  if (
    photos.value.length === 0 ||
    status.value.loadingMorePhotos ||
    status.value.loadingInitialPhotos ||
    status.value.hasAllPhotos
  ) {
    return;
  }
  try {
    status.value.loadingMorePhotos = true;
    const lastVisibleDoc = photos.value[photos.value.length - 1];
    let querySnapshot = await getDocs(
      query(
        photosCollection.value,
        orderBy("timestamp", "desc"),
        startAfter(toRaw(lastVisibleDoc)),
        limit(10),
      ),
    );
    let pushed = false;
    querySnapshot.forEach((doc) => {
      photos.value.push(doc);
      pushed = true;
    });
    if (!pushed) {
      status.value.hasAllPhotos = true;
    }
  } catch (e) {
    handleFirestoreException({ e: e });
  } finally {
    status.value.loadingMorePhotos = false;
  }
}

function handleScroll() {
  if (window.scrollY + window.outerHeight + 30 >= document.body.scrollHeight) {
    fetchMorePhotos();
  }
}
</script>

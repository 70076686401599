<template>
  <button class="relative" @click="buttonClick">
    <div
      v-if="props.loading"
      class="absolute flex h-full w-full items-center justify-center"
    >
      <spinner class="h-6 animate-spin" style="color: black" />
    </div>
    <img
      :src="
        darkmodeEnabled
          ? 'img/social-signin/apple-signin-white.png'
          : '/img/social-signin/apple-signin.png'
      "
      :class="[
        props.loading ? 'cursor-wait opacity-25' : '',
        props.disabled ? 'cursor-not-allowed opacity-25' : '',
      ]"
      alt="Google login button"
    />
  </button>
</template>
<script setup>
import { defineEmits, defineProps } from "vue";
import { globals } from "@/helpers/globals/globals";
import Spinner from "@/components/Spinner.vue";

const emits = defineEmits(["click"]);
const props = defineProps({
  loading: Boolean,
  disabled: { type: Boolean, default: false },
});

let darkmodeEnabled = !!globals.darkModeEnabled;

function buttonClick() {
  if (!props.loading) {
    emits("click");
  }
}
</script>

import { getApp, getApps, initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";
import {
  defaultBucket,
  firebaseConfig,
  recaptchaKey,
} from "@/config/firebase.config";
import {
  collection,
  persistentLocalCache,
  persistentSingleTabManager,
  initializeFirestore,
  connectFirestoreEmulator,
} from "firebase/firestore";
import { getMessaging, isSupported, onMessage } from "firebase/messaging";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { setInfoToast } from "@/helpers/notifications/toast";
import router from "@/router/router";
import { isRunningInFlutter } from "@/helpers/flutter/FlutterInit";
import { markRaw } from "vue";

export let firebaseApp = null;
export let firestore = null;
export let firebaseAuth = null;

export let firebaseFunctions = null;
export let firebaseStorage = null;
export let firebaseMessaging = null;
export let firebaseAnalytics = null;
export let usersCollection = null;
export let groupsCollection = null;

export function initFirebase() {
  if (getApps().length === 0) {
    firebaseApp = initializeApp(firebaseConfig);
  } else {
    firebaseApp = getApp();
  }
  if (import.meta.env.MODE === "development") {
    self.FIREBASE_APPCHECK_DEBUG_TOKEN =
      import.meta.env.VITE_APP_APP_CHECK_DEBUG_TOKEN;
  }

  initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaV3Provider(recaptchaKey),
    isTokenAutoRefreshEnabled: true,
  });
  //initialise firebase methods
  firestore = markRaw(
    initializeFirestore(firebaseApp, {
      localCache: persistentLocalCache({
        tabManager: persistentSingleTabManager(),
      }),
    }),
  );
  firebaseAuth = getAuth(firebaseApp);
  firebaseAuth.useDeviceLanguage();
  firebaseFunctions = getFunctions(firebaseApp, "europe-west3");
  firebaseStorage = getStorage(firebaseApp, defaultBucket);
  firebaseAnalytics = getAnalytics(firebaseApp);

  isSupported().then((supported) => {
    //only supported browsers
    if (supported) {
      //Never run in flutter
      if (isRunningInFlutter()) {
        return;
      }
      try {
        firebaseMessaging = getMessaging(firebaseApp);
        onMessage(firebaseMessaging, (payload) => {
          setInfoToast({
            message:
              payload.notification.title + " " + payload.notification.body,
            timeout: 5000,
            click: () => {
              router.push(payload.fcmOptions.link);
            },
          });
        });
      } catch (e) {
        //
      }
    }
  });

  //Emulators
  if (import.meta.env.VITE_USE_EMULATORS === "true") {
    connectAuthEmulator(firebaseAuth, "http://127.0.0.1:9099");
    connectFirestoreEmulator(firestore, "127.0.0.1", 8082);
    connectFunctionsEmulator(firebaseFunctions, "127.0.0.1", 5001);
    connectStorageEmulator(firebaseStorage, "127.0.0.1", 9199);
  }
  usersCollection = collection(firestore, "users");
  groupsCollection = collection(firestore, "groups");
}

import { deleteToken } from "firebase/messaging";
import { firebaseAuth, firebaseMessaging } from "@/helpers/firebase/firebase";
import { logoutFlutterAuthInstance } from "@/helpers/flutter/FlutterAuth";

export async function logout() {
  try {
    await deleteToken(firebaseMessaging);
  } catch (e) {
    //
  }
  try {
    await firebaseAuth.signOut();
  } catch (e) {
    //
  }
  logoutFlutterAuthInstance();
  window.localStorage.clear();
  window.location.reload();
}

<template>
  <top-navigation :title="$t('settings.leave')"> </top-navigation>
  <div class="mx-auto flex min-h-screen items-center justify-center pt-24">
    <information-text
      v-if="isAdmin"
      color="red"
      :information="
        $t(
          'settings.you_can_only_leave_group_if_you_gave_the_admin_rights_to_someone_else',
        )
      "
    ></information-text>

    <default-button v-if="!isAdmin" :loading="status.leaving" @click="openModal"
      ><p>
        {{ $t("settings.leave_following_group") }}: {{ groupName }}
      </p></default-button
    >
  </div>
  <conformation-modal
    :loading="status.leaving"
    :visible="openPopup"
    :confirm-text="$t('settings.leave_group')"
    :cancel-text="$t('settings.dont_leave_group')"
    @cancel="closeModal()"
    @confirm="leaveGroup()"
  >
    <div class="mb-8">
      <h1>
        {{ $t("settings.are_you_sure_you_want_to_leave_the_following_group") }}
        <span class="font-medium italic text-blue-500">{{
          groupData.name
        }}</span>
      </h1>
      <information-text
        :information="
          $t('settings.you_can_join_again_later_with_an_invite_link')
        "
      />
    </div>
  </conformation-modal>
</template>
<script>
import TopNavigation from "../../components/TopNavigation.vue";
import InformationText from "../../components/InformationText.vue";

import { httpsCallable } from "firebase/functions";
import { firebaseFunctions } from "@/helpers/firebase/firebase";
import {
  GET_GROUP_DATA,
  GET_GROUP_DOC_ID,
  IS_ADMIN,
} from "@/store/modules/groups";

import { setErrorToast, setSuccessToast } from "@/helpers/notifications/toast";
import DefaultButton from "../../components/Buttons/DefaultButton.vue";
import ConformationModal from "../../components/Modals/ConformationModal.vue";
import {
  refreshAllUserGroupsData,
  refreshUserData,
} from "@/helpers/data/fetchers";
import { GET_AUTH_UUID } from "@/store/modules/auth";

export default {
  name: "LeaveGroupView",
  components: {
    ConformationModal,
    DefaultButton,
    InformationText,
    TopNavigation,
  },
  data: function () {
    return {
      status: {
        leaving: false,
      },
      openPopup: false,
      groupDataToLeave: null,
      groupIdToLeave: "",
    };
  },
  computed: {
    groupData() {
      return this.$store.getters[GET_GROUP_DATA];
    },
    groupName() {
      if (this.groupData) {
        return this.groupData.name;
      } else {
        return "";
      }
    },
    isAdmin() {
      return this.$store.getters[IS_ADMIN];
    },
  },
  methods: {
    openModal() {
      this.openPopup = true;
      this.groupDataToLeave = this.$store.getters[GET_GROUP_DATA];
      this.groupIdToLeave = this.$store.getters[GET_GROUP_DOC_ID];
    },
    closeModal() {
      this.openPopup = false;
      this.groupDataToLeave = null;
      this.groupIdToLeave = "";
    },
    async leaveGroup() {
      this.status.leaving = true;
      const leaveGroupFunction = await httpsCallable(
        firebaseFunctions,
        "leaveGroup",
      );
      try {
        await leaveGroupFunction({ groupId: this.groupIdToLeave });
        setSuccessToast({ message: this.$t("global.toast.left_group") });
        await refreshUserData();
        await refreshAllUserGroupsData();
        window.location.reload();
      } catch (e) {
        setErrorToast({
          message: this.$t("global.toast.error_leaving_group"),
        });
      } finally {
        this.status.leaving = false;
      }
    },
  },
};
</script>

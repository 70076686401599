<template>
  <div class="flex w-full flex-nowrap items-center justify-center gap-x-4">
    <text-input
      v-model="comment"
      label-disabled
      class="w-full"
      @keydown.enter="postComment"
    >
    </text-input>

    <default-button :loading="postingComment" @click="postComment">
      <p class="min-w-max grow">
        {{ $t("components.comment.send") }} 📨
      </p></default-button
    >
  </div>
</template>
<script setup>
import { defineEmits, defineProps, ref } from "vue";
import DefaultButton from "@/components/Buttons/DefaultButton.vue";
import TextInput from "@/components/Inputs/TextInput.vue";
const emits = defineEmits(["postComment"]);
defineProps({
  postingComment: {
    type: Boolean,
  },
});

let comment = ref("");

function postComment() {
  if (comment.value.length > 0) {
    emits("postComment", { comment: comment.value });
  }
  comment.value = "";
}
</script>

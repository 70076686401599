<template>
  <!--  the bar-->
  <!--  Add padding to div below-->
  <top-navigation :title="$t('pages.invite.title')"></top-navigation>

  <div class="mx-auto min-h-screen max-w-md pt-24 dark:text-slate-200">
    <p v-if="!hasActiveToken">
      {{ $t("pages.invite.no_active_link") }}
    </p>
    <div v-if="hasActiveToken">
      <information-text
        class="mb-4"
        :information="$t('pages.invite.link_explained')"
        >i</information-text
      >

      <p
        class="relative mb-4 cursor-pointer select-text break-words rounded-lg border-2 border-kekkle-dark-dark bg-gray-200 p-2 text-center text-2xl text-gray-500 dark:bg-kekkle-dark-light dark:text-slate-200"
        @click="copyToken()"
      >
        <clipboard-icon class="absolute right-2 top-3 h-6"></clipboard-icon>
        {{ joinToken }}
      </p>

      <p
        class="relative mb-4 cursor-pointer select-text break-words rounded-lg border-2 border-kekkle-dark-dark bg-gray-200 p-2 pr-6 text-gray-500 dark:bg-kekkle-dark-light dark:text-slate-200"
        @click="share()"
      >
        <span
          v-if="canShare || hasSupportForFlutterShare()"
          class="absolute right-2 top-2"
          >🚀</span
        >
        <clipboard-icon
          v-else
          class="absolute right-2 top-2 h-6"
        ></clipboard-icon>
        {{ inviteUrl }}
      </p>
      <p class="dark:text-slate-200">
        {{ $t("pages.invite.link_remaining_time", [timeLeft]) }}
      </p>
      <p
        v-if="!linkLocallyDeleted"
        class="w-min cursor-pointer truncate py-1 text-red-500"
        @click="deleteLink()"
      >
        {{ $t("pages.invite.delete_link") }}
        ❌
      </p>
    </div>
    <br />
    <div v-if="!status.hasGeneratedLink && !hasActiveToken">
      <h2 class="text-lg font-medium dark:text-slate-200">
        {{ $t("pages.invite.generate_link") }}:
      </h2>
      <div class="mb-4 flex">
        <p class="dark:text-slate-200">
          {{ $t("pages.invite.time_link_valid") }}:
        </p>
        <select v-model="timeToDie" class="mx-2 dark:text-slate-900">
          <option :value="1">1</option>
          <option :value="4">4</option>
          <option :value="12">12</option>
          <option :value="24">24</option>
        </select>
        <p class="dark:text-slate-200">
          {{ $t("pages.invite.hours") }}
        </p>
      </div>

      <default-button
        :disabled="status.loading"
        :loading="status.loading"
        @click="generateLink()"
        ><p class="font-medium">
          {{ $t("pages.invite.generate_link") }}:
        </p></default-button
      >
      <br />

      <information-text
        v-if="hasActiveToken"
        :information="$t('pages.invite.generating_will_delete')"
      ></information-text>
    </div>
  </div>
</template>
<script>
import topNavigation from "../components/TopNavigation.vue";
import DefaultButton from "../components/Buttons/DefaultButton.vue";
import { GET_GROUP_DATA, GET_GROUP_DOC_ID } from "@/store/modules/groups";
import { setErrorToast, setSuccessToast } from "@/helpers/notifications/toast";
import { httpsCallable } from "firebase/functions";
import {
  firebaseAnalytics,
  firebaseFunctions,
  firestore,
} from "@/helpers/firebase/firebase";

import { updateDoc, doc, collection, Timestamp } from "firebase/firestore";
import { handleFirestoreException } from "@/helpers/exceptions/handler";
import InformationText from "../components/InformationText.vue";
import { refreshAllUserGroupsData } from "@/helpers/data/fetchers";
import { ClipboardIcon } from "@heroicons/vue/outline";
import { logEvent } from "firebase/analytics";

import { copyToClipboardFlutter } from "@/helpers/flutter/FlutterClipboard";
import { isRunningInFlutter } from "@/helpers/flutter/FlutterInit";
import {
  hasSupportForFlutterShare,
  shareWithFlutter,
} from "@/helpers/flutter/FlutterShare";

export default {
  name: "InviteMembersView",
  components: {
    InformationText,
    DefaultButton,
    topNavigation,
    ClipboardIcon,
  },
  data() {
    return {
      isRunningInFlutter: isRunningInFlutter(),
      canShare: false,
      linkLocallyDeleted: false,
      timeToDie: 24,
      currentTime: new Date(),
      status: {
        loading: false,
        hasGeneratedLink: false,
      },
    };
  },

  computed: {
    joinToken() {
      if (this.$store.getters[GET_GROUP_DATA]["join_token"]) {
        return this.$store.getters[GET_GROUP_DATA]["join_token"];
      } else {
        return "";
      }
    },
    validTill() {
      if (this.$store.getters[GET_GROUP_DATA]["join_token_timestamp"]) {
        return new Timestamp(
          this.$store.getters[GET_GROUP_DATA]["join_token_timestamp"].seconds,
          this.$store.getters[GET_GROUP_DATA][
            "join_token_timestamp"
          ].nanoseconds,
        ).toDate();
      }
      return "";
    },
    timeLeft() {
      if (this.hasActiveToken) {
        let timeLeft = Math.abs(this.validTill - this.currentTime);
        return this.msToTime(timeLeft);
      } else {
        return "";
      }
    },
    hasActiveToken() {
      if (this.validTill && this.joinToken) {
        return this.validTill > this.currentTime;
      } else {
        return false;
      }
    },
    inviteUrl() {
      return window.location.origin + "/invite-link?token=" + this.joinToken;
    },
  },
  created() {
    if (navigator.share) {
      this.canShare = true;
    }
    setInterval(() => {
      this.currentTime = new Date();
    }, 1000);
  },
  methods: {
    hasSupportForFlutterShare,
    copyToken() {
      logEvent(firebaseAnalytics, "invite_members", {
        name: "copy_token",
      });
      if (isRunningInFlutter()) {
        copyToClipboardFlutter(this.joinToken, "global.toast.code_copied");
      } else {
        navigator.clipboard.writeText(this.joinToken);
        setSuccessToast({
          message: this.$t("global.toast.code_copied"),
        });
      }
    },
    copyLink() {
      logEvent(firebaseAnalytics, "invite_members", { name: "copy_link" });
      if (isRunningInFlutter()) {
        copyToClipboardFlutter(this.inviteUrl, "global.toast.link_copied");
      } else {
        navigator.clipboard.writeText(this.inviteUrl);
        setSuccessToast({
          message: this.$t("global.toast.link_copied"),
        });
      }
    },
    share() {
      if (hasSupportForFlutterShare()) {
        shareWithFlutter({
          title: this.inviteUrl,
          subject: this.$t("pages.invite.join_kekkle_title"),
        });
      } else if (this.canShare) {
        this.shareLink();
      } else {
        this.copyLink();
      }
    },
    async shareLink() {
      logEvent(firebaseAnalytics, "invite_members", {
        name: "shared_link",
      });
      try {
        await navigator.share({
          title: this.$t("pages.invite.join_kekkle_title"),
          url: this.inviteUrl,
          text:
            this.$t("pages.invite.you_are_invited_for") +
            (this.$store.getters[GET_GROUP_DATA]?.name ?? ""),
        });
      } catch (e) {
        this.canShare = false;
      }
    },
    async deleteLink() {
      logEvent(firebaseAnalytics, "invite_members", {
        name: "deleted_link",
      });
      this.linkLocallyDeleted = true;
      try {
        let groupCollection = collection(firestore, "groups/");
        let groupDoc = doc(
          groupCollection,
          this.$store.getters[GET_GROUP_DOC_ID],
        );
        await updateDoc(groupDoc, {
          join_token: null,
          join_token_timestamp: null,
        });
        refreshAllUserGroupsData();
        this.linkLocallyDeleted = false;
      } catch (e) {
        handleFirestoreException({ e: e });
      }
    },
    async generateLink() {
      logEvent(firebaseAnalytics, "invite_members", {
        name: "generated_link",
      });
      this.linkLocallyDeleted = false;
      this.status.loading = true;
      try {
        const generateLinkFunction = await httpsCallable(
          firebaseFunctions,
          "generateInviteToken",
        );
        await generateLinkFunction({
          groupId: this.$store.getters[GET_GROUP_DOC_ID],
          timeToDie: this.timeToDie,
        });
        this.status.hasGeneratedLink = true;
        refreshAllUserGroupsData();
      } catch (e) {
        setErrorToast({
          message: this.$t("global.toast.generate_link_failed"),
        });
      }
      this.status.loading = false;
    },
    msToTime(ms) {
      let seconds = (ms / 1000).toFixed(1);
      let minutes = (ms / (1000 * 60)).toFixed(1);
      let hours = (ms / (1000 * 60 * 60)).toFixed(1);
      let days = (ms / (1000 * 60 * 60 * 24)).toFixed(1);
      if (seconds < 60)
        return seconds + " " + this.$t("global.time.seconds", seconds);
      else if (minutes < 60)
        return minutes + " " + this.$t("global.time.minutes", minutes);
      else if (hours < 24)
        return hours + " " + this.$t("global.time.hours", hours);
      else return days + " " + this.$t("global.time.days", days);
    },
  },
};
</script>

import {
  checkRow,
  addLetter,
  removeLetter,
  resetWordle,
} from "@/logic/wordleLogic.js";
import { serverTimestamp } from "firebase/firestore";
import { GET_AUTH_UUID } from "@/store/modules/auth";
import {
  fetchGameState,
  fetchNewestGame,
  saveGameState,
} from "@/helpers/firestore/WordleGameService";
import { setLocalStorageItem } from "../../helpers/utils/local-storage";

export const moduleWordle = {
  state: {
    gameDoc: null,
    gameState: resetWordle(),
    possibleWords: JSON.parse(localStorage.getItem("possibleWords") ?? "[]"),
    invalidWord: false,
  },
  mutations: {
    clearWordle(state) {
      state.gameDoc = null;
      state.gameState = resetWordle();
      state.invalidWord = false;
      state.possibleWords = [];
      localStorage.removeItem("possibleWords");
    },
    setGameDoc(state, gameDoc) {
      state.gameDoc = gameDoc;
    },
    setGameState(state, gameState) {
      state.gameState = gameState;
    },
    setPossibleWords(state, possibleWords) {
      state.possibleWords = possibleWords;
    },
    setInvalidWord(state, invalidWord) {
      state.invalidWord = invalidWord;
      setTimeout(() => {
        state.invalidWord = false;
      }, 500);
    },
  },
  actions: {
    async saveGameState({ getters }) {
      await saveGameState(
        getters.getMatchId,
        getters[GET_AUTH_UUID],
        {
          game_state: JSON.stringify(getters.getGameState),
          user_id: getters[GET_AUTH_UUID],
          updated_at: serverTimestamp(),
          has_finished: getters.getHasFinished,
          has_won: getters.getHasWon,
          has_lost: getters.getHasLost,
          calculated_score: getters.oldGetCalculatedScore,
          new_calculated_score: getters.getCalculatedScore,
          number_of_tries_needed: getters.getNumberOfTriesNeeded,
        },
        getters.getGameState,
      );
    },
    async initWorlde({ dispatch }) {
      await dispatch("fetchGameState", { force: true });
      await dispatch("loadPossibleWords");
    },
    async fetchGameState({ commit, getters }, { matchId, force = false } = {}) {
      // if someone is typing, don't overwrite the gameState
      if (getters.getIsTyping && !force) {
        return;
      }
      if (!matchId) {
        let newestGameDoc = await fetchNewestGame();
        commit("setGameDoc", newestGameDoc);
        matchId = newestGameDoc.id;
      }
      let gameState = await fetchGameState(matchId, getters[GET_AUTH_UUID]);
      // if someone is typing, don't overwrite the gameState
      if (getters.getIsTyping && !force) {
        return;
      }
      commit("setGameState", gameState);
    },
    async loadPossibleWords({ commit, state }) {
      if (state.possibleWords.length > 0) {
        return;
      }
      const wordListModule = await import(
        "@/assets/data/worlde/five-letter-words-NL.txt?raw"
      );
      const wordList = wordListModule.default.split("\n");
      commit("setPossibleWords", wordList);
      setLocalStorageItem("possibleWords", JSON.stringify(wordList));
    },
    keyPressed({ commit, state, getters, dispatch }, key) {
      if (key === "backspace") {
        const newState = removeLetter(
          state.gameState,
          getters.getCurrentRow,
          getters.getCurrentColumn,
        );
        commit("setGameState", newState);
      } else if (key === "enter") {
        const row = state.gameState[getters.getCurrentRow];
        let word = "";
        for (let i = 0; i < row.length; i++) {
          const cell = row[i];
          word = word + cell.character;
        }
        if (
          getters.getPossibleWords.includes(word) ||
          word === getters.getCorrectWord
        ) {
          const newState = checkRow(
            state.gameState,
            getters.getCorrectWord,
            getters.getCurrentRow,
          );
          commit("setGameState", newState);
          dispatch("saveGameState");
        } else {
          commit("setInvalidWord", true);
        }
      } else {
        const newState = addLetter(
          state.gameState,
          getters.getCurrentRow,
          getters.getCurrentColumn,
          key,
        );
        commit("setGameState", newState);
      }
    },
  },
  getters: {
    getIsTyping: (state) => {
      // Check if in the current row there is a cell that is not locked
      return state.gameState.some((row) =>
        row.some((cell) => !cell.isLocked && cell.character !== ""),
      );
    },
    getCorrectWord: (state) => {
      return state.gameDoc?.data?.word ?? null;
    },
    getMatchId: (state) => {
      return state.gameDoc?.id ?? null;
    },
    getMatchDate: (state) => {
      return state?.gameDoc?.data?.date;
    },
    getInvalidWord: (state) => state.invalidWord,
    getPossibleWords: (state) => state.possibleWords,
    getRowIsFilled: (state, getters) =>
      state.gameState[getters.getCurrentRow]?.every(
        (cell) => cell.character !== "",
      ),
    getCurrentRow: (state) => {
      for (let i = 0; i < state.gameState.length; i++) {
        const row = state.gameState[i];
        if (row.some((cell) => !cell.isLocked)) {
          return i;
        }
      }
      return -1; // return -1 no not locked cell is found
    },
    getCurrentColumn: (state, getters) => {
      const currentRow = getters.getCurrentRow;
      if (currentRow === -1) {
        return -1; // return -1 if no empty and not locked cell is found
      }
      const row = state.gameState[currentRow];
      for (let i = 0; i < row.length; i++) {
        const cell = row[i];
        if (cell.character === "" && !cell.isLocked) {
          return i;
        }
      }
      //if last cell is not locked but filled return last cell
      if (!row[row.length - 1].isLocked) {
        return row.length - 1;
      }
      return -1; // return -1 if no empty and not locked cell is found
    },
    getGameState: (state) => state.gameState,
    getCorrectCharacters: function (state) {
      // loop through all rows
      let correctCharacters = [];
      for (let i = 0; i < state.gameState.length; i++) {
        const row = state.gameState[i];
        // loop through all cells in row
        for (let j = 0; j < row.length; j++) {
          const cell = row[j];
          if (cell.isCorrect && cell.isLocked) {
            correctCharacters.push(cell.character);
          }
        }
      }
      return correctCharacters;
    },
    getPresentCharacters: function (state) {
      // loop through all rows
      let presentCharacters = [];
      for (let i = 0; i < state.gameState.length; i++) {
        const row = state.gameState[i];
        // loop through all cells in row
        for (let j = 0; j < row.length; j++) {
          const cell = row[j];
          if (cell.isPresent && !cell.isCorrect && cell.isLocked) {
            presentCharacters.push(cell.character);
          }
        }
      }
      return presentCharacters;
    },
    getWrongCharacters: function (state) {
      // loop through all rows
      let wrongCharacters = [];
      for (let i = 0; i < state.gameState.length; i++) {
        const row = state.gameState[i];
        // loop through all cells in row
        for (let j = 0; j < row.length; j++) {
          const cell = row[j];
          if (!cell.isPresent && !cell.isCorrect && cell.isLocked) {
            wrongCharacters.push(cell.character);
          }
        }
      }
      return wrongCharacters;
    },
    getHasWon: (state) => {
      return state.gameState.some((row) =>
        row.every((cell) => cell.isCorrect && cell.isLocked),
      );
    },
    getHasFilled: (state) => {
      return state.gameState.every((row) => {
        return row.every((cell) => {
          return cell.isLocked;
        });
      });
    },
    getHasLost: (state, getters) => {
      return !!(getters.getHasFilled && !getters.getHasWon);
    },
    getHasFinished: (state, getters) => {
      return !!(getters.getHasWon || getters.getHasLost);
    },
    getNumberOfTriesNeeded(state) {
      return state.gameState.filter((row) => row.some((cell) => cell.isLocked))
        .length;
    },
    getCalculatedScore: (state, getters) => {
      if (!getters.getHasFinished) return 0;

      if (getters.getHasWon) {
        return (
          getters.getCorrectWord.length + 6 - getters.getNumberOfTriesNeeded
        );
      }

      return state.gameState[state.gameState.length - 1].reduce(
        (score, cell) => (cell.isCorrect ? score + 1 : score),
        0,
      );
    },
    oldGetCalculatedScore: (state, getters) => {
      //10 points for every correct letter
      //5 points for every present letter but at the wrong location
      //0 points for every wrong letter
      //10 bonus points if guessed in a single try
      //8 bonus points if guessed in two tries
      //6 bonus points if guessed in three tries
      //4 bonus points if guessed in four tries
      //2 bonus points if guessed in five tries
      if (!getters.getHasFinished) {
        return 0;
      }
      if (getters.getHasWon) {
        let score = getters.getCorrectWord.length * 10;
        let bonus = 12 - getters.getNumberOfTriesNeeded * 2;
        score = score + bonus;
        return score;
      } else {
        //look at the last row
        const lastRow = state.gameState[state.gameState.length - 1];
        let score = 0;
        lastRow.forEach((cell) => {
          if (cell.isCorrect) {
            score = score + 10;
          } else if (cell.isPresent) {
            score = score + 5;
          }
        });
        return score;
      }
    },
  },
};

<script setup>
import KeyboardKey from "@/views/games/wordle/components/KeyboardKey.vue";
import KeyboardRow from "@/views/games/wordle/components/KeyboardRow.vue";
import { ref } from "vue";

const keyboardRows = [
  ["q", "w", "e", "r", "t", "y", "u", "i", "o", "p"],
  ["a", "s", "d", "f", "g", "h", "j", "k", "l"],
  ["enter", "z", "x", "c", "v", "b", "n", "m", "backspace"],
];

let canPress = ref(true);

const emit = defineEmits(["clickKey"]);

function clickKey(key) {
  if (canPress.value) {
    canPress.value = false;
    emit("clickKey", key);
    setTimeout(() => {
      canPress.value = true;
    }, 20);
  }
}
</script>

<template>
  <div class="flex w-full flex-col gap-y-2">
    <KeyboardRow v-for="row in keyboardRows" :key="row[0]">
      <keyboard-key
        v-for="key in row"
        :key="key"
        :keyboard-key="key"
        @click="clickKey(key)"
      ></keyboard-key>
    </KeyboardRow>
  </div>
</template>

<template>
  <div
    class="flex min-h-screen w-full flex-col items-center justify-center overflow-hidden bg-kekkle-yellow text-center dark:bg-kekkle-dark-light"
  >
    <h1 class="animate-pulse text-3xl font-bold text-white">Kekkle</h1>
    <p class="animate-pulse text-xl text-white">
      🔍{{ $t("pages.invitemembersfollowup.searching") }}
    </p>
  </div>
</template>

<script>
import { firebaseFunctions } from "@/helpers/firebase/firebase";
import {
  setErrorToast,
  setInfoToast,
  setSuccessToast,
} from "@/helpers/notifications/toast";
import { httpsCallable } from "firebase/functions";
import { getIdToken, getIdTokenResult } from "firebase/auth";
import {
  GET_AUTH_USER,
  GET_AUTH_UUID,
  SET_AUTH_ID_TOKEN,
} from "@/store/modules/auth";
import store from "../store/store";
import {
  refreshAllUserGroupsData,
  refreshUserData,
} from "@/helpers/data/fetchers";
import { globals } from "@/helpers/globals/globals";
import { setLocalStorageItem } from "../helpers/utils/local-storage";

export default {
  name: "InviteMembersFollowupView",
  created() {
    window.localStorage.removeItem("joinToken");
    setLocalStorageItem("joinToken", "");
    if (!this.$route.query.token) {
      this.$router.push({ name: "Group" });
    } else {
      this.joinGroup(this.$route.query.token);
    }
  },
  methods: {
    async joinGroup(token) {
      try {
        const joinFunction = await httpsCallable(
          firebaseFunctions,
          "joinGroup",
        );
        await joinFunction({ token: token.toUpperCase() });
        await getIdToken(this.$store.getters[GET_AUTH_USER], true);
        let idtoken = await getIdTokenResult(
          this.$store.getters[GET_AUTH_USER],
          true,
        );
        globals.hasFetched = false;
        store.commit("setAuthorFilterUuid", null);
        store.commit(SET_AUTH_ID_TOKEN, idtoken);
        setSuccessToast({
          message: this.$t("pages.invitemembersfollowup.now_a_members"),
        });
        await refreshUserData();
        await refreshAllUserGroupsData();
        setTimeout(() => {
          this.$router.push({ name: "Group" });
        }, 3000);
      } catch (e) {
        if (e.message === "Group Full") {
          setInfoToast({
            message: this.$t("pages.invitemembersfollowup.group_full"),
          });
          setTimeout(() => {
            this.$router.push({ name: "Group" });
          }, 3000);
        } else if (e.message === "Group does not exist") {
          setInfoToast({
            message: this.$t("pages.invitemembersfollowup.does_not_exist"),
          });
          setTimeout(() => {
            this.$router.push({ name: "Group" });
          }, 3000);
        } else if (e.code === "functions/unavailable") {
          setInfoToast({
            message: this.$t("pages.invitemembersfollowup.already_a_members"),
          });
          setTimeout(() => {
            this.$router.push({ name: "Group" });
          }, 3000);
        } else {
          setErrorToast({
            message: this.$t("pages.invitemembersfollowup.joining_failed"),
          });
          setTimeout(() => {
            this.$router.push({ name: "Group" });
          }, 3000);
        }
      }
    },
  },
};
</script>

import * as SentryBrowser from "@sentry/browser";
import * as Sentry from "@sentry/vue";

let hasSentryBrowser = false;
let hasSentryVue = false;

export function initSentryBrowser() {
  if (import.meta.env.MODE !== "development" && !hasSentryBrowser) {
    new SentryBrowser.init({
      dsn: "https://78de61893708415db3aa8c6eb0d8882c@o922252.ingest.sentry.io/4504719548350464",
    });
    hasSentryBrowser = true;
  }
}

export function initSentryVue(app) {
  if (import.meta.env.MODE !== "development" && !hasSentryVue) {
    new Sentry.init({
      app,
      dsn: "https://7a309ff02985428b9072e1ee2e047e3e@o922252.ingest.sentry.io/6346038",
    });
    hasSentryVue = true;
  }
}

<template>
  <div class="mb-4 flex flex-wrap items-center gap-x-2 align-middle">
    <div
      v-for="(item, index) in selected"
      :key="index"
      class="mb-2 flex cursor-pointer flex-nowrap items-center gap-x-2 rounded-2xl bg-kekkle-blue p-1 px-2 align-middle"
      @click="removeItem(item)"
    >
      <div
        class="flex h-3 w-3 items-center justify-center rounded-full bg-kekkle-blue-dark p-2 text-center align-middle text-white"
      >
        <p class="text-xs">X</p>
      </div>
      <p>{{ label(item) }}</p>
    </div>
  </div>

  <div class="flex flex-wrap items-center gap-x-2 align-middle">
    <div
      v-for="(item, index) in notSelected"
      :key="index"
      class="mb-2 flex cursor-pointer flex-nowrap items-center gap-x-2 rounded-2xl bg-kekkle-yellow p-1 px-2 align-middle"
      @click="addItem(item)"
    >
      <div
        class="flex h-3 w-3 items-center justify-center rounded-full bg-kekkle-yellow-dark p-2 text-center align-middle text-white"
      >
        <p class="text-xs">+</p>
      </div>
      <p>{{ label(item) }}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "MultiSelector",
  props: {
    indexKey: {
      type: String,
    },
    labelKey: {
      type: String,
    },
    options: {
      type: Array,
    },
    modelValue: {
      type: Array,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    notSelected() {
      return this.options.filter(this.filterOutSelected);
    },
    selected() {
      return this.options.filter((e) => !this.filterOutSelected(e));
    },
  },
  methods: {
    removeItem(item) {
      let newValues = [];
      for (const value of this.modelValue) {
        if (value !== item[this.indexKey]) {
          newValues.push(value);
        }
      }
      this.$emit("update:modelValue", newValues);
    },
    addItem(item) {
      let newValues = this.modelValue;
      newValues.push(item[this.indexKey]);
      this.$emit("update:modelValue", newValues);
    },
    filterOutSelected(check) {
      for (const value of this.modelValue) {
        if (check[this.indexKey] === value) {
          return false;
        }
      }
      return true;
    },
    label(value) {
      return value[this.labelKey];
    },
    key(value) {
      return value[this.indexKey];
    },
  },
};
</script>

<template>
  <div
    class="flex w-full max-w-md flex-col rounded-xl border-4 border-black-dark bg-white px-8 pb-4 pt-6 shadow-indigo-hard dark:bg-kekkle-dark-light"
  >
    <h1 class="text-xl font-bold dark:text-slate-200">
      {{ $t("auth.check_email") }}
      📧
    </h1>
    <p class="mb-4 dark:text-slate-200">
      {{ $t("auth.click_mail_to_verify") }}
    </p>
    <default-button @click="next">{{ $t("auth.continue") }}</default-button>
    <p
      v-if="!resendEmailSend"
      class="my-4 cursor-pointer underline dark:text-slate-200"
      @click="resendEmail"
    >
      {{ $t("auth.no_mail_try_again_here") }}
    </p>
  </div>
  <p
    class="mx-auto mt-4 w-fit max-w-xs cursor-pointer rounded border border-red-500 p-2 text-center text-red-500 transition duration-300 hover:bg-red-500 hover:text-white"
    @click="logout()"
  >
    {{ $t("global.inputs.labels.logout") }}
  </p>
</template>
<script>
import { GET_AUTH, GET_AUTH_USER, SET_AUTH_USER } from "@/store/modules/auth";
import { sendEmailVerification } from "firebase/auth";
import {
  setSuccessToast,
  setWarningToast,
} from "@/helpers/notifications/toast";
import { logout } from "@/helpers/data/logout";
import DefaultButton from "@/components/Buttons/DefaultButton.vue";

export default {
  name: "VerifyEmail",
  components: { DefaultButton },
  data() {
    return {
      resendEmailSend: false,
    };
  },
  async created() {
    try {
      await this.$store.getters[GET_AUTH_USER].reload();
      this.router.push({ name: "Group" });
    } catch (e) {
      //
    }
  },
  methods: {
    logout() {
      logout();
    },
    async resendEmail() {
      try {
        await sendEmailVerification(this.$store.getters[GET_AUTH_USER]);
        setSuccessToast({ message: this.$t("auth.email_send") });
      } catch (e) {
        setWarningToast({ message: this.$t("auth.email_not_send") });
        this.$router.push({ name: "Login" });
      } finally {
        this.resendEmailSend = true;
      }
    },
    async next() {
      await this.$store.getters[GET_AUTH_USER].reload();
      await this.$store.commit(
        SET_AUTH_USER,
        this.$store.getters[GET_AUTH_USER],
      );
      this.$router.push({ name: "Group" });
    },
  },
};
</script>

<template>
  <!--  the bar-->
  <!--  Add padding to div below-->
  <top-navigation
    :title="$t('pages.photos.edit')"
    :disabled="!validated"
    :loading="status.submitting"
    :button-name="$t('global.inputs.labels.save')"
    @submit-click="submit()"
  />
  <div class="mx-auto min-h-screen pt-24">
    <div class="mb-4 w-full">
      <text-area-input
        v-model="caption"
        :has-error="validationErrors.caption"
        :error-message="$t('global.inputs.errors.photo_caption')"
        :label="$t('global.inputs.labels.photo_description') + '*'"
        :placeholder="$t('global.inputs.placeholders.photo_description')"
      />
    </div>

    <p class="dark:text-slate-200">
      {{ $t("pages.photo.date_time_of_picture") }}
      {{ timestamp ? dateTimeAgo(timestamp) : "" }}
    </p>

    <div v-if="true" class="mb-4 w-full">
      <p class="font-medium dark:text-slate-200">
        {{ $t("global.inputs.labels.date_time") }} *
      </p>
      <p v-show="validationErrors.timestamp" class="mt-1 text-sm text-red-600">
        {{ $t("global.inputs.errors.date_time") }}
      </p>
      <div class="flex w-full flex-col items-center">
        <date-input v-model="timestamp" date-time></date-input>
      </div>
    </div>
    <div class="mb-4 w-full">
      <default-button
        color="red"
        :disabled="status.updating"
        :loading="status.deleting"
        @click="openModal"
        ><p>
          {{ $t("global.inputs.labels.delete_photo") }}
        </p></default-button
      >
    </div>
  </div>
  <conformation-modal
    :danger="true"
    :confirm-text="$t('pages.photo.confirm_delete')"
    :loading="status.deleting"
    :cancel-text="$t('pages.photo.confirm_close')"
    :visible="openDeleteConformation"
    @cancel="openDeleteConformation = false"
    @confirm="this.delete()"
  >
    <p class="my-8 text-center font-medium">
      {{ $t("pages.photo.confirm_delete_text") }}
    </p>
  </conformation-modal>
</template>
<script>
import { DatePicker } from "v-calendar";
import { firebaseStorage, firestore } from "@/helpers/firebase/firebase";
import { collection, writeBatch, doc, increment } from "firebase/firestore";
import { deleteObject } from "firebase/storage";

import { GET_GROUP_DOC_ID } from "@/store/modules/groups";
import DefaultButton from "../components/Buttons/DefaultButton.vue";
import {
  setSuccessToast,
  setWarningToast,
} from "@/helpers/notifications/toast";
import { handleFirestoreException } from "@/helpers/exceptions/handler";
import TopNavigation from "../components/TopNavigation.vue";

import TextAreaInput from "@/components/Inputs/TextAreaInput.vue";
import { GET_GROUP_DATA } from "@/store/modules/groups";

import { dateTimeAgo } from "@/helpers/dates/date-time-ago";
import { ref } from "firebase/storage";
import {
  FULL_HD,
  HD,
  ORIGINAL,
  SD,
  THUMB,
  ULTRA_HD,
} from "@/constants/image-sizes";
import ConformationModal from "@/components/Modals/ConformationModal.vue";
import { globals } from "@/helpers/globals/globals";
import DateInput from "@/components/Inputs/DateInput.vue";
import { dateObjectToDateString } from "@/helpers/converts/date-converter";
import { GET_SELECTED_PHOTO, HAS_SELECTED_PHOTO } from "@/store/modules/photos";

export default {
  name: "EditPhotoView",
  components: {
    DateInput,
    ConformationModal,
    TextAreaInput,
    DefaultButton,
    TopNavigation,
  },
  data() {
    return {
      status: {
        submitting: false,
        deleting: false,
      },
      caption: "",
      timestamp: null,
      photoId: "",
      openDeleteConformation: false,
      absolute_picture_url: "",
    };
  },
  computed: {
    darkModeEnabled() {
      return !!globals.darkModeEnabled;
    },
    groupData() {
      return this.$store.getters[GET_GROUP_DATA];
    },
    timestampValid() {
      return this.timestamp;
    },
    captionValid() {
      if (this.caption) {
        return this.caption.length < 350;
      } else {
        return true;
      }
    },
    validationErrors() {
      let errors = {};
      errors.caption = !this.captionValid;
      errors.timestamp = !this.timestampValid;
      return errors;
    },
    validated() {
      return !Object.values(this.validationErrors).some(
        (value) => value === true,
      );
    },
  },
  created() {
    if (!this.$store.getters[HAS_SELECTED_PHOTO]) {
      this.$router.push({ name: "Photos" });
    } else {
      this.timestamp = dateObjectToDateString(
        this.$store.getters[GET_SELECTED_PHOTO].data().timestamp.toDate(),
        "yyyy-mm-ddThh:mm",
      );
      this.caption = this.$store.getters[GET_SELECTED_PHOTO].data().caption;
      this.photoId = this.$store.getters[GET_SELECTED_PHOTO].id;
      this.absolute_picture_url =
        this.$store.getters[GET_SELECTED_PHOTO].data().absolute_picture_url;
    }
  },
  methods: {
    dateTimeAgo(date) {
      if (date) {
        return dateTimeAgo(new Date(date));
      } else {
        return "";
      }
    },

    async submit() {
      this.status.submitting = true;

      if (!this.validated) {
        setWarningToast({
          message: this.$t("global.toast.check_all_fields"),
        });
        return;
      }

      let trigrams = {};
      let trimmedCaption = this.caption;
      trimmedCaption = trimmedCaption.replaceAll(" ", "").toLowerCase();
      if (trimmedCaption.length >= 3) {
        for (let i = 0; i < trimmedCaption.length - 2; i++) {
          let trigram = trimmedCaption.slice(i, i + 3);
          trigrams[trigram] = true;
        }
      }
      try {
        let batch = writeBatch(firestore);
        let groupPhotos = collection(
          firestore,
          "groups/" + this.$store.getters[GET_GROUP_DOC_ID] + "/photos",
        );
        let newPhotoRef = doc(groupPhotos, this.photoId);

        batch.update(newPhotoRef, {
          caption: this.caption,
          timestamp: new Date(this.timestamp),
          trigrams: trigrams,
        });

        await batch.commit();
        setSuccessToast({
          message: this.$t("global.toast.photo_saved"),
        });
        this.$router.push({ name: "Photos" });
      } catch (e) {
        handleFirestoreException({ e: e });
      } finally {
        this.status.submitting = false;
      }
    },
    async delete() {
      try {
        this.status.deleting = true;
        let groupPhotos = collection(
          firestore,
          "groups/" + this.$store.getters[GET_GROUP_DOC_ID] + "/photos",
        );
        let newPhotoRef = doc(groupPhotos, this.photoId);
        let groupCollection = collection(firestore, "groups/");
        let groupRef = doc(
          groupCollection,
          this.$store.getters[GET_GROUP_DOC_ID],
        );
        let batch = writeBatch(firestore);
        batch.delete(newPhotoRef);
        batch.update(groupRef, {
          "general_data.number_of_photos": increment(-1),
        });
        await batch.commit();
        setSuccessToast({
          message: this.$t("global.toast.deleted_successfully"),
        });

        await this.$router.push({ name: "Photos" });
        let formats = [ORIGINAL, THUMB, SD, HD, FULL_HD, ULTRA_HD];
        for (let i = 0; i < formats.length; i++) {
          let photosRef = ref(
            firebaseStorage,
            this.absolute_picture_url + formats[i],
          );
          try {
            await deleteObject(photosRef);
          } catch (e) {
            //
          }
        }
      } catch (e) {
        handleFirestoreException({ e: e });
      } finally {
        this.status.deleting = false;
      }
    },
    openModal() {
      this.openDeleteConformation = true;
    },
  },
};
</script>

const namespace = "information";

export const SET_INFORMATION = `${namespace}/SET_INFORMATION`;
export const GET_INFORMATION = `${namespace}/'GET_INFORMATION`;

export const moduleInformation = {
  state: () => ({
    information: "",
  }),
  mutations: {
    [SET_INFORMATION](state, information) {
      state.information = information;
    },
  },
  getters: {
    [GET_INFORMATION](state) {
      return state.information;
    },
  },
};

import store, { clearAllStoreData } from "@/store/store";
import router from "@/router/router";

import { initSentryBrowser, initSentryVue } from "@/helpers/sentry/sentry";

import { createApp } from "vue";
import App from "./App.vue";

import Toast, { POSITION } from "vue-toastification";

import { GET_GROUP_DATA } from "./store/modules/groups";
import { GET_USER_DATA } from "./store/modules/user";
import { SET_PWA_INSTALL_PROMPT } from "./store/modules/prompts";

import { initDarkmode } from "./helpers/darkmode";

import { onAuthStateChanged } from "firebase/auth";

import {
  refreshAllUserGroupsData,
  refreshUserData,
} from "@/helpers/data/fetchers";

import { translate, initializeI18n } from "@/i18n/vueI18n";

import { initFlutterFunctions } from "@/helpers/flutter/FlutterInit";

import { firebaseAuth, initFirebase } from "@/helpers/firebase/firebase";
import { setBootStatus } from "@/helpers/bootstatus/home-boot-status";
import {
  GET_AUTH_USER,
  SET_AUTH_ID_TOKEN,
  SET_AUTH_USER,
} from "@/store/modules/auth";
import { isRunningInFlutter } from "@/helpers/flutter/FlutterInit";

window.addEventListener("beforeinstallprompt", (e) => {
  e.preventDefault();
  store.commit(SET_PWA_INSTALL_PROMPT, e);
});

initFlutterFunctions();
initSentryBrowser();
initDarkmode();
initFirebase();
const vueI18nInstance = initializeI18n(); // Initialize vueI18n here

let vueInstance;
setBootStatus(translate("boot.login"));

if (
  store.getters[GET_AUTH_USER] &&
  store.getters[GET_USER_DATA] &&
  store.getters[GET_GROUP_DATA]
) {
  //boot with state data, auth state changed will trigger data refresh
  bootVue();
}

onAuthStateChanged(firebaseAuth, async function (user) {
  if (!user) {
    //if had user data, clear it
    if (store.getters[GET_AUTH_USER]) {
      clearAllStoreData();
      await router.push({ name: "Login" });
    }
  } else {
    store.commit(SET_AUTH_USER, user);
    store.commit(SET_AUTH_ID_TOKEN, await user.getIdTokenResult());
    if (store.getters[GET_USER_DATA] && store.getters[GET_GROUP_DATA]) {
      refreshUserData();
      refreshAllUserGroupsData();
    } else {
      setBootStatus(translate("boot.downloading_profile"));
      await refreshUserData();
      setBootStatus(translate("boot.downloading_group"));
      await refreshAllUserGroupsData();
    }
  }
  bootVue();
});

function bootVue() {
  if (!vueInstance) {
    vueInstance = createApp(App);
    vueInstance
      .use(store)
      .use(router)
      .use(Toast, { position: POSITION.TOP_CENTER })
      .use(vueI18nInstance)
      .mount("#app");
    initSentryVue(vueInstance);
  }
}

//Clicking on a notification when the app is in the background
if (!isRunningInFlutter()) {
  try {
    navigator?.serviceWorker?.addEventListener("message", (message) => {
      if (
        message?.data?.messageType === "notification-clicked" &&
        message?.data?.notification?.click_action
      ) {
        router.push(message.data.notification.click_action);
      }
    });
  } catch (e) {
    //
  }
}

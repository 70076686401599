<template>
  <top-navigation :title="$t('auth.delete_account_info_page_title')">
  </top-navigation>
  <div
    class="mx-auto flex min-h-screen flex-col items-center justify-center pt-24"
  >
    <information-text
      class="mb-2"
      :information="$t('auth.delete_account_info')"
    ></information-text>
  </div>
</template>
<script>
import TopNavigation from "../../components/TopNavigation.vue";
import InformationText from "../../components/InformationText.vue";

export default {
  name: "DeleteAccountInfo",
  components: {
    InformationText,
    TopNavigation,
  },
};
</script>

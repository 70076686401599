import { translate as $t } from "@/i18n/vueI18n";

export function timeAgo(date) {
  let currentDate = new Date();

  let secondsAgo = (currentDate.getTime() - date.getTime()) / 1000;
  let flipped = false;

  if (secondsAgo < 0) {
    flipped = true;
    secondsAgo = secondsAgo * -1;
  }
  if (secondsAgo < 2) {
    return flipped ? $t("global.time.soon") : $t("global.time.just_now");
  }
  if (secondsAgo <= 60) {
    if (flipped) {
      return (
        $t("global.time.in") +
        " " +
        Math.ceil(secondsAgo) +
        " " +
        $t("global.time.seconds", Math.ceil(secondsAgo))
      );
    }
    return (
      Math.round(secondsAgo) +
      " " +
      $t("global.time.seconds", Math.ceil(secondsAgo)) +
      " " +
      $t("global.time.ago")
    );
  }
  if (secondsAgo < 60 * 60) {
    if (flipped) {
      return (
        $t("global.time.in") +
        " " +
        Math.round(secondsAgo / 60) +
        $t("global.time.minutes", Math.round(secondsAgo / 60))
      );
    }
    return (
      Math.round(secondsAgo / 60) +
      " " +
      $t("global.time.minutes", Math.round(secondsAgo / 60)) +
      " " +
      $t("global.time.ago")
    );
  }

  if (secondsAgo < 60 * 60 * 24) {
    if (flipped) {
      return (
        $t("global.time.in") +
        " " +
        Math.round(secondsAgo / 60 / 60) +
        " " +
        $t("global.time.hours", Math.round(secondsAgo / 60 / 60))
      );
    }
    return (
      Math.round(secondsAgo / 60 / 60) +
      " " +
      $t("global.time.hours", Math.round(secondsAgo / 60 / 60)) +
      " " +
      $t("global.time.ago")
    );
  }

  if (secondsAgo < 60 * 60 * 24 * 31) {
    if (flipped) {
      return (
        $t("global.time.in") +
        " " +
        +Math.round(secondsAgo / 60 / 60 / 24) +
        " " +
        $t("global.time.days", Math.round(secondsAgo / 60 / 60 / 24))
      );
    }

    return (
      Math.round(secondsAgo / 60 / 60 / 24) +
      " " +
      $t("global.time.days", Math.round(secondsAgo / 60 / 60 / 24)) +
      " " +
      $t("global.time.ago")
    );
  }

  if (secondsAgo < 60 * 60 * 24 * 31) {
    if (flipped) {
      return (
        $t("global.time.in") +
        " " +
        +Math.round(secondsAgo / 60 / 60 / 24 / 7) +
        " " +
        $t("global.time.weeks", Math.round(secondsAgo / 60 / 60 / 24 / 7))
      );
    }
    return (
      Math.round(secondsAgo / 60 / 60 / 24 / 7) +
      " " +
      $t("global.time.weeks", Math.round(secondsAgo / 60 / 60 / 24 / 7)) +
      " " +
      $t("global.time.ago")
    );
  }

  if (secondsAgo < 60 * 60 * 24 * 31 * 12) {
    if (flipped) {
      return (
        $t("global.time.in") +
        " " +
        +Math.round(secondsAgo / 60 / 60 / 24 / 31) +
        " " +
        $t("global.time.months", Math.round(secondsAgo / 60 / 60 / 24 / 31))
      );
    }
    return (
      Math.round(secondsAgo / 60 / 60 / 24 / 31) +
      " " +
      $t("global.time.months", Math.round(secondsAgo / 60 / 60 / 24 / 31)) +
      " " +
      $t("global.time.ago")
    );
  } else {
    if (flipped) {
      return (
        $t("global.time.in") +
        " " +
        Math.round(secondsAgo / 60 / 60 / 24 / 365) +
        " " +
        $t("global.time.years", Math.round(secondsAgo / 60 / 60 / 24 / 365))
      );
    }
    return (
      Math.round(secondsAgo / 60 / 60 / 24 / 365) +
      " " +
      $t("global.time.years", Math.round(secondsAgo / 60 / 60 / 24 / 365)) +
      " " +
      $t("global.time.ago")
    );
  }
}

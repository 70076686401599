<template>
  <div class="fixed top-0 z-20 w-full">
    <div v-if="information" class="w-full bg-gray-500">
      <p class="text-s w-full text-center text-white">
        {{ information }}
      </p>
    </div>
  </div>
</template>
<script setup>
import { useStore } from "vuex";
import { computed } from "vue";
import { GET_INFORMATION } from "@/store/modules/information";

const store = useStore();
const information = computed(() => store.getters[GET_INFORMATION]);
</script>

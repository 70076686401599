import { useToast } from "vue-toastification";
const Toast = useToast();

export function setWarningToast({ message = "", click = () => {} }) {
  Toast.warning(message, { timeout: 3000, onClick: click });
}

export function setInfoToast({
  message = "",
  timeout = 3000,
  click = () => {},
}) {
  Toast.info(message, { timeout: timeout, onClick: click });
}

export function setSuccessToast({ message = "", click = () => {} }) {
  Toast.success(message, { timeout: 3000, onClick: click });
}

export function setErrorToast({
  message = "",
  timeout = 6000,
  click = () => {},
}) {
  Toast.error(message, { timeout: timeout, onClick: click });
}

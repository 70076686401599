import { dateObjectToDateString } from "@/helpers/converts/date-converter";
import { fetchGameIdPerDate } from "@/helpers/firestore/WordleGameService";
import { fetchGameScores } from "@/helpers/firestore/WorldeScoreService";
import { setLocalStorageItem } from "../../helpers/utils/local-storage";

export const moduleWordleScores = {
  state: {
    gamedIds: JSON.parse(
      localStorage.getItem("wordleGameIds") ?? '{"01-01-2021":"123"}',
    ),
    onlyKnownUsers: JSON.parse(
      localStorage.getItem("onlyKnownUsersWorldeScores") ?? "true",
    ),
    scoreDate: new Date(),
    scores: JSON.parse(localStorage.getItem("wordleScores") ?? '{"123":[]}'),
    fetchingScores: {
      123: false,
    },
    fetchingGameId: {
      "01-01-2021": false,
    },
  },
  mutations: {
    setOnlyKnownUsersWordleScores(state, onlyKnownUsers) {
      state.onlyKnownUsers = onlyKnownUsers;
      setLocalStorageItem(
        "onlyKnownUsersWorldeScores",
        JSON.stringify(onlyKnownUsers),
      );
    },
    clearWordleScores(state) {
      state.gamedIds = {};
      state.scoreDate = new Date();
      state.scores = {};
      state.fetchingScores = {};
      state.fetchingGameId = {};
      localStorage.removeItem("wordleGameIds");
      localStorage.removeItem("wordleScores");
      state.onlyKnownUsers = true;
      setLocalStorageItem("onlyKnownUsersWorldeScores", "true");
    },
    setScoreDate(state, date) {
      state.scoreDate = date;
    },
    setFetchingScores(state, { fetching, gameId }) {
      state.fetchingScores[gameId] = fetching;
    },
    setFetchingGameId(state, { fetching, date }) {
      state.fetchingGameId[dateObjectToDateString(date, "dd-mm-yyyy")] =
        fetching;
    },
    setScores(state, { scores, gameId }) {
      state.scores[gameId] = scores;
      setLocalStorageItem("wordleScores", JSON.stringify(state.scores));
    },
    setGameId(state, { gameId, date }) {
      state.gamedIds[dateObjectToDateString(date, "dd-mm-yyyy")] = gameId;
      setLocalStorageItem("wordleGameIds", JSON.stringify(state.gamedIds));
    },
  },
  actions: {
    async fetchScores({ state, commit, getters }, date) {
      let gameId = state.gamedIds[dateObjectToDateString(date, "dd-mm-yyyy")];
      if (!gameId) {
        commit("setFetchingGameId", {
          fetching: true,
          date: date,
        });
        gameId = await fetchGameIdPerDate(date);
        commit("setGameId", { gameId, date });
        commit("setFetchingGameId", {
          fetching: false,
          date: date,
        });
      }

      commit("setFetchingScores", { fetching: true, gameId });
      let userFilter = [];
      if (state.onlyKnownUsers) {
        userFilter = getters.allGroupMembersUuids;
      }
      let scores = await fetchGameScores(gameId, userFilter);
      scores.forEach((score) => {
        score.data.game_state = JSON.parse(score.data.game_state);
      });
      commit("setScores", { scores, gameId });
      commit("setFetchingScores", { fetching: false, gameId });
    },
  },
  getters: {
    getCurrentGameId(state) {
      return state.gamedIds[
        dateObjectToDateString(state.scoreDate, "dd-mm-yyyy")
      ];
    },
    getScoreDate(state) {
      return state.scoreDate;
    },
    getAllScores(state) {
      return state.scores;
    },
    getScores(state, getters) {
      if (!getters.getCurrentGameId) {
        return [];
      }
      if (!state.scores[getters.getCurrentGameId]) {
        return [];
      }
      return state.scores[getters.getCurrentGameId];
    },
    getFetchingScores(state, getters) {
      return state.fetchingScores[getters.getCurrentGameId];
    },
    getFetchingGameId(state) {
      return state.fetchingGameId[
        dateObjectToDateString(state.scoreDate, "dd-mm-yyyy")
      ];
    },
    getScoresFromFriends() {},
  },
};

<template>
  <!--  the bar-->
  <!--  Add padding to div below-->
  <top-navigation previous-route="Quotes" title="Comments" />
  <div class="mx-auto min-h-screen pb-12 pt-24">
    <div
      v-if="quoteDoc"
      class="sticky top-24 z-20 mb-8 w-full origin-top transition-all duration-500"
    >
      <quote :simple="true" :quote-id="quoteId" :quote-data="quoteDoc.data" />
    </div>

    <div v-if="quoteDoc">
      <comment
        v-for="(comment, index) in comments"
        :key="comment"
        :author-uuid="comment.author"
        :previous-date="
          index > 0
            ? comments[index - 1]?.created_at instanceof Date
              ? comments[index - 1]?.created_at
              : comments[index - 1].created_at?.toDate()
            : null
        "
        :date="
          comment.created_at instanceof Date
            ? comment.created_at
            : comment.created_at?.toDate()
        "
        :comment="comment.comment"
      />
    </div>
  </div>
  <div class="fixed bottom-0 left-0 flex w-full justify-center">
    <div class="w-full max-w-xl bg-white px-2 pb-4 dark:bg-kekkle-dark">
      <comment-input @post-comment="postNewComment"></comment-input>
    </div>
  </div>
</template>
<script>
import topNavigation from "../components/TopNavigation.vue";
import { firebaseAnalytics, firestore } from "@/helpers/firebase/firebase";
import {
  collection,
  doc,
  getDoc,
  increment,
  serverTimestamp,
  writeBatch,
} from "firebase/firestore";
import { handleFirestoreException } from "@/helpers/exceptions/handler";
import { GET_AUTH_UUID } from "@/store/modules/auth";
import * as Sentry from "@sentry/vue";
import { v1 as uuidv1 } from "uuid";
import { GET_USER_DATA } from "@/store/modules/user";
import {
  refreshAllUserGroupsData,
  refreshUserData,
} from "@/helpers/data/fetchers";
import Quote from "@/components/Quote.vue";
import CommentInput from "@/components/CommentInput.vue";
import Comment from "@/components/Comment.vue";
import quote from "@/components/Quote.vue";
import { GET_GROUP_DOC_ID } from "@/store/modules/groups";
import { logEvent } from "firebase/analytics";
import { fetchQuoteById } from "@/helpers/firestore/QuoteService";
import { updateUserDoc } from "@/helpers/firestore/UserService";

export default {
  name: "QuoteComments",
  components: {
    Comment,
    CommentInput,
    Quote,
    topNavigation,
  },
  props: {
    quoteId: {
      required: true,
    },
    groupId: {
      required: true,
    },
  },
  data() {
    return {
      quoteDoc: null,
      comments: [],
      status: {
        fetchingComments: false,
      },
    };
  },
  async created() {
    //Clear the quotes from the
    if (this.$store.getters[GET_USER_DATA]["groups"].includes(this.groupId)) {
      if (this.$store.getters[GET_GROUP_DOC_ID] !== this.groupId) {
        await updateUserDoc(this.$store.getters[GET_AUTH_UUID], {
          active_group: this.groupId,
        });
        await refreshUserData();
        await refreshAllUserGroupsData();
      }
      //we dont have to check for legit quote id, as without the comment will not post
      await this.fetchComments();
      await this.fetchQuote();
    } else {
      this.$router.push({ name: "Group" });
    }
  },
  methods: {
    quote() {
      return quote;
    },
    async fetchQuote() {
      //check if we already have the quote
      let quoteDoc = this.$store.state.Quotes.quotes.find(
        (quote) => quote.id === this.quoteId,
      );
      if (quoteDoc) {
        this.quoteDoc = quoteDoc;
      } else {
        try {
          this.quoteDoc = await fetchQuoteById(this.quoteId, this.groupId);
        } catch (e) {
          Sentry.captureException(e);
        }
      }
    },
    async fetchComments() {
      this.status.fetchingComments = true;
      try {
        let commentsDoc = await getDoc(
          doc(
            collection(firestore, `groups/${this.groupId}/quotes_comments`),
            this.quoteId,
          ),
        );
        if (!commentsDoc.exists()) {
          return;
        }
        //remove the 'affected_field' field
        let commentData = commentsDoc.data();
        delete commentData["affected_field"];

        let arrayWithComments = Object.values(commentData);
        arrayWithComments.sort((a, b) => {
          if (!a.created_at || !b.created_at) {
            return 0;
          }
          return (
            a.created_at.toDate().getTime() - b.created_at.toDate().getTime()
          );
        });
        this.comments = arrayWithComments;
      } catch (e) {
        Sentry.captureException(e);
      } finally {
        this.status.fetchingComments = false;
      }
    },
    async postNewComment(e) {
      logEvent(firebaseAnalytics, "added_comment", { for: "quote" });
      let comment = e.comment;
      let batch = writeBatch(firestore);
      let commentDoc = doc(
        collection(firestore, `groups/${this.groupId}/quotes_comments`),
        this.quoteId,
      );
      let id = uuidv1();
      batch.set(
        commentDoc,
        {
          [id]: {
            comment: comment,
            author: this.$store.getters[GET_AUTH_UUID],
            created_at: serverTimestamp(),
          },
          affected_field: id,
        },
        { merge: true },
      );

      let quoteRef = doc(
        collection(firestore, `groups/${this.groupId}/quotes`),
        this.quoteId,
      );
      batch.update(quoteRef, {
        number_of_comments: increment(1),
      });
      batch
        .commit()
        .then(() => {})
        .catch((e) => {
          Sentry.captureException(e);
          handleFirestoreException({ e: e });
        });

      this.fetchComments().catch(() => {
        //
      });

      this.comment = null;
      setTimeout(() => {
        window.scrollTo({
          behavior: "smooth",
          left: 0,
          top: document.body.scrollHeight,
        });
      }, 20);
    },
  },
};
</script>

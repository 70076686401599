<script setup>
import { useI18n } from "vue-i18n";
const { t } = useI18n({ useScope: "global" });
import { useStore } from "vuex";
const store = useStore();
</script>

<template>
  <div
    class="w-full rounded-xl border-2 border-black-dark bg-white p-4 py-8 text-center shadow-yellow-hard-sm dark:bg-kekkle-dark-light dark:text-slate-200"
  >
    <h1 class="text-2xl font-bold">
      {{
        store.getters.getHasWon
          ? t("pages.wordle.victory_title")
          : t("pages.wordle.lose_title")
      }}
    </h1>
    <p class="mb-4 text-lg font-light">
      {{
        store.getters.getHasWon
          ? t("pages.wordle.victory_explanation")
          : t("pages.wordle.lose_explanation")
      }}
    </p>
    <p class="text-md font-light">
      {{ t("pages.wordle.word_today") }}
      <span class="font-bold uppercase">{{
        store.getters.getCorrectWord
      }}</span>
    </p>
    <p class="my-4 font-light">
      {{ t("pages.wordle.score_try") }}
      <span class="text-3xl font-bold">{{
        store.getters.getCalculatedScore
      }}</span>
      {{ t("pages.wordle.points") }}
    </p>
  </div>
</template>

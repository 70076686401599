/**
 * Ensures that the specified path exists in the object.
 * If any part of the path is undefined, it will be initialized to an empty object `{}`.
 *
 * @param {Object} obj - The object to ensure the path in.
 * @param {Array} path - The path to ensure in the object.
 * @returns {Object} - The original object with the ensured path.
 */
export function ensureObjectPath(obj, path) {
  let current = obj;
  for (let i = 0; i < path.length; i++) {
    if (!current[path[i]]) {
      current[path[i]] = {};
    }
    current = current[path[i]];
  }
  return obj;
}

/**
 * Sets a value at a specified path in an object.
 * If any part of the path is undefined, it will be initialized to an empty object `{}`.
 *
 * @param {Object} obj - The object to set the value in.
 * @param {Array} path - The path to set the value at in the object.
 * @param {*} value - The value to set at the specified path in the object.
 * @returns {Object} - The original object with the value set at the specified path.
 */
export function setObjectPathValue(obj, path, value) {
  let current = obj;
  for (let i = 0; i < path.length; i++) {
    if (i === path.length - 1) {
      current[path[i]] = value;
    } else {
      current[path[i]] = current[path[i]] || {};
    }
    current = current[path[i]];
  }
  return obj;
}

/**
 * Retrieves the value at a specified path in an object.
 * If any part of the path is undefined, it will return undefined.
 *
 * @param {Object} obj - The object to get the value from.
 * @param {Array} path - The path to get the value from in the object.
 * @returns {*} - The value at the specified path in the object, or undefined if the path is not fully defined in the object.
 */
export function getObjectPathValue(obj, path) {
  if (!obj) return undefined;
  let current = obj;
  for (let i = 0; i < path.length; i++) {
    if (!current[path[i]]) {
      return undefined;
    }
    current = current[path[i]];
  }
  return current;
}

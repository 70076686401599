<template>
  <div
    class="flex w-full max-w-md flex-col rounded-xl border-4 border-black-dark bg-white px-8 pb-4 pt-6 shadow-indigo-hard dark:bg-slate-700"
  >
    <div
      v-if="mode === 'resetPassword'"
      class="flex flex-col items-center justify-center"
    >
      <h1 class="text-xl font-bold dark:text-slate-200">
        {{ $t("auth.reset_password") }}
      </h1>
      <text-input
        v-model="password"
        type="password"
        class="mb-4 w-full"
        autocomplete="password"
        :placeholder="$t('global.inputs.placeholders.password')"
        :label="$t('global.inputs.labels.password')"
        :error-message="$t('global.inputs.errors.password_not_strong')"
        :has-error="!passwordHasMinimumLength"
      ></text-input>
      <text-input
        v-model="passwordRepeat"
        type="password"
        class="mb-4 w-full"
        autocomplete="password"
        :placeholder="$t('global.inputs.placeholders.repeat_password')"
        :label="$t('global.inputs.labels.repeat_password')"
        :error-message="$t('global.inputs.errors.password_not_same')"
        :has-error="!passwordRepeatValid"
      ></text-input>
      <p
        v-if="passwordHasMinimumLength"
        class="w-full text-left text-green-500"
      >
        ✅ {{ $t("auth.minimum_8_chars") }}
      </p>
      <p v-if="!passwordHasMinimumLength" class="w-full text-left text-red-500">
        ❌{{ $t("auth.no_minimum_8_chars") }}
      </p>
      <p v-if="passwordHasNumber" class="w-full text-left text-green-500">
        ✅{{ $t("auth.contains_number") }}
      </p>
      <p v-if="!passwordHasNumber" class="w-full text-left text-red-500">
        ❌ {{ $t("auth.contains_no_number") }}
      </p>
      <p v-if="passwordHasSpecialChar" class="w-full text-left text-green-500">
        ✅ {{ $t("auth.contains_special_char") }}
      </p>
      <p v-if="!passwordHasSpecialChar" class="w-full text-left text-red-500">
        ❌{{ $t("auth.does_not_contain_special_char") }}
      </p>
      <p v-if="passwordRepeatValid" class="w-full text-left text-green-500">
        ✅ {{ $t("auth.password_same") }}
      </p>
      <p v-if="!passwordRepeatValid" class="w-full text-left text-red-500">
        ❌ {{ $t("auth.password_not_same") }}
      </p>

      <default-button
        class="mx-0"
        :disabled="!resetValid"
        :loading="resetting"
        @click="resetPassword()"
      >
        <p>{{ $t("auth.reset_password") }}</p>
      </default-button>
    </div>
    <div v-if="mode === 'verifyEmail'">
      <h1
        v-if="!successfullVerified"
        class="text-xl font-bold dark:text-slate-200"
      >
        {{ $t("auth.verifying_email") }}
      </h1>
      <h1
        v-if="successfullVerified"
        class="text-xl font-bold dark:text-slate-200"
      >
        {{ $t("auth.account_verified") }}
      </h1>
    </div>
    <!--    TOTO make recover email-->
    <div v-if="actionCode === 'recoverEmail'"></div>
  </div>
</template>
<script>
import { logout } from "@/helpers/data/logout";
import {
  applyActionCode,
  verifyPasswordResetCode,
  confirmPasswordReset,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { GET_AUTH_USER, GET_AUTH_UUID } from "@/store/modules/auth";
import { setErrorToast, setSuccessToast } from "@/helpers/notifications/toast";
import TextInput from "../components/Inputs/TextInput.vue";
import DefaultButton from "../components/Buttons/DefaultButton.vue";
import {
  refreshAllUserGroupsData,
  refreshUserData,
} from "@/helpers/data/fetchers";
import { firebaseAuth } from "@/helpers/firebase/firebase";

export default {
  name: "EmailAction",
  components: { TextInput, DefaultButton },
  data() {
    return {
      resetting: false,
      resendEmailSend: false,
      mode: "",
      actionCode: "",
      password: "",
      passwordRepeat: "",
      successfullVerified: false,
    };
  },
  computed: {
    passwordValid() {
      return this.email.length > 1;
    },
    passwordHasMinimumLength() {
      return this.password.length > 7;
    },
    passwordRepeatValid() {
      return this.password === this.passwordRepeat && this.password.length > 0;
    },
    passwordHasNumber() {
      return /\d/.test(this.password);
    },
    passwordHasSpecialChar() {
      const specialChars = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
      return specialChars.test(this.password);
    },
    resetValid() {
      return (
        this.passwordRepeatValid &&
        this.passwordHasMinimumLength &&
        this.passwordHasNumber &&
        this.passwordHasSpecialChar
      );
    },
  },
  created() {
    this.mode = decodeURIComponent(
      new URLSearchParams(window.location.search).get("mode"),
    );
    this.actionCode = decodeURIComponent(
      new URLSearchParams(window.location.search).get("oobCode"),
    );

    if (!this.mode || !this.actionCode) {
      this.$router.push({ name: "Login" });
    }
    if (this.mode === "verifyEmail") {
      this.verifyEmail();
    }
  },
  methods: {
    logout() {
      logout();
    },
    async verifyEmail() {
      try {
        await applyActionCode(firebaseAuth, this.actionCode);
        setSuccessToast({
          message: this.$t("global.toast.email_verified_successfully"),
        });
        this.successfullVerified = true;
      } catch (e) {
        setErrorToast({
          message: this.$t("global.toast.error_email_not_verified"),
        });
        this.$router.push({ name: "Login" });
      }
      try {
        await this.$store.getters[GET_AUTH_USER].reload();
        this.$router.push({ name: "Group" });
      } catch (e) {
        this.$router.push({ name: "Login" });
      }
    },
    async resetPassword() {
      try {
        this.resetting = true;
        let email = await verifyPasswordResetCode(
          firebaseAuth,
          this.actionCode,
        );
        try {
          await confirmPasswordReset(
            firebaseAuth,
            this.actionCode,
            this.password,
          );
          setSuccessToast({
            message: this.$t("global.toast.password_changed"),
          });
          try {
            await signInWithEmailAndPassword(
              firebaseAuth,
              email,
              this.password,
            );
            await refreshUserData();
            await refreshAllUserGroupsData();
            await this.$router.push({ name: "Group" });
          } catch (e) {
            this.$router.push({ name: "Login" });
          }
        } catch (e) {
          setErrorToast({
            message: this.$t("global.toast.password_not_changed"),
          });
          this.$router.push({ name: "Login" });
        }
      } catch (e) {
        setErrorToast({
          message: this.$t("global.toast.password_link_not_valid"),
        });
        this.$router.push({ name: "Login" });
      } finally {
        this.resetting = false;
      }
    },
  },
};
</script>

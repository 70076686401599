import {
  fetchMoreQuotes,
  fetchMoreQuotesByAuthorUuid,
  fetchNewestQuotes,
  fetchNewestQuotesByAuthorUuid,
  searchQuotes,
} from "@/helpers/firestore/QuoteService";

const namespace = "quotes";

export const SET_QUOTES = `${namespace}/SET_QUOTES`;
export const SET_SELECTED_QUOTE = `${namespace}/SET_SELECTED_QUOTE`;

export const GET_SELECTED_QUOTE = `${namespace}/GET_SELECTED_QUOTE`;
export const GET_SELECTED_QUOTE_DATA = `${namespace}/GET_SELECTED_QUOTE_DATA`;
export const HAS_SELECTED_QUOTE = `${namespace}/HAS_SELECTED_QUOTE`;

export const moduleQuotes = {
  state: () => ({
    loadingInitialQuotes: false,
    loadingMoreQuotes: false,
    hasAllQuotes: false,
    searchingQuotes: false,
    quoteScrollPosition: 0,
    authorFilterUuid: "",
    quotes: [],
    selectedQuote: {},
    searchEnabled: false,
    allQuotesEnabled: true,
  }),
  mutations: {
    [SET_QUOTES](state, quotes) {
      state.quotes = quotes;
    },
    [SET_SELECTED_QUOTE](state, quoteId) {
      state.selectedQuote = state.quotes.find((quote) => quote.id === quoteId);
    },
    setHasAllQuotes(state, hasAll) {
      state.hasAllQuotes = hasAll;
    },
    setLoadingInitialQuotes(state, loading) {
      state.loadingInitialQuotes = loading;
    },
    setAuthorFilterUuid(state, uuid) {
      state.authorFilterUuid = uuid;
    },
    clearQuotes(state) {
      state.quotes = [];
    },
    setAllQuotesEnabled(state, enabled) {
      state.allQuotesEnabled = enabled;
    },
    setQuoteScrollPosition(state, position) {
      state.quoteScrollPosition = position;
    },
    setLoadingMoreQuotes(state, loading) {
      state.loadingMoreQuotes = loading;
    },
    setSearchingQuotes(state, searching) {
      state.searchingQuotes = searching;
    },
    setSearchEnabled(state, enabled) {
      state.searchEnabled = enabled;
    },
  },
  actions: {
    async fetchInitialQuotes({ commit, state }) {
      if (state.loadingInitialQuotes) {
        return;
      }
      commit("setLoadingInitialQuotes", true);
      let quotes = null;
      if (state.authorFilterUuid) {
        quotes = await fetchNewestQuotesByAuthorUuid(state.authorFilterUuid);
      } else {
        quotes = await fetchNewestQuotes();
      }
      commit(SET_QUOTES, quotes);
      commit("setHasAllQuotes", false);
      if (quotes.size < 6) {
        commit("setHasAllQuotes", true);
      }
      commit("setLoadingInitialQuotes", false);
    },
    async fetchMoreQuotes({ state, commit }) {
      if (
        state.quotes.length === 0 ||
        state.loadingMoreQuotes ||
        state.loadingInitialQuotes ||
        state.hasAllQuotes ||
        state.searchEnabled
      ) {
        return;
      }
      commit("setLoadingMoreQuotes", true);
      let lastQuote = state.quotes[state.quotes.length - 1];
      let quotes = [];
      if (state.authorFilterUuid) {
        quotes = await fetchMoreQuotesByAuthorUuid(
          state.authorFilterUuid,
          lastQuote._firebase_document,
        );
      } else {
        quotes = await fetchMoreQuotes(lastQuote._firebase_document);
      }
      commit(SET_QUOTES, [...state.quotes, ...quotes]);
      if (quotes.size < 10) {
        commit("setHasAllQuotes", true);
      }
      commit("setLoadingMoreQuotes", false);
    },
    async searchQuotes({ commit }, searchTerm) {
      commit("setSearchingQuotes", true);
      let quotes = await searchQuotes(searchTerm);
      commit(SET_QUOTES, quotes);
      commit("setSearchingQuotes", false);
    },
  },
  getters: {
    [GET_SELECTED_QUOTE](state) {
      return state.selectedQuote;
    },
    [GET_SELECTED_QUOTE_DATA](state) {
      return state.selectedQuote?.data;
    },
    [HAS_SELECTED_QUOTE](state) {
      return Object.keys(state.selectedQuote).length > 0;
    },
  },
};

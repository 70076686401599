import { fetchEventsByDateRange } from "@/helpers/firestore/EventService";
import { handleFirestoreException } from "@/helpers/exceptions/handler";

export const moduleEvents = {
  state: () => ({
    selectedCalendarDate: null,
    calendarMonth: new Date(),
    eventDocs: [],
    selectedCalendarEventId: null,
  }),
  mutations: {
    setSelectedCalendarEventId(state, eventId) {
      state.selectedCalendarEventId = eventId;
    },
    setCalendarMonthDate(state, date) {
      state.calendarMonth = date;
    },
    setSelectedCalendarDate(state, date) {
      state.selectedCalendarDate = date;
    },
    addEvents(state, newEvents) {
      state.eventDocs = state.eventDocs.map(
        (eventDoc) =>
          newEvents.find((newEvent) => newEvent.id === eventDoc.id) || eventDoc,
      );
      newEvents.forEach((newEvent) => {
        if (!state.eventDocs.some((eventDoc) => eventDoc.id === newEvent.id)) {
          state.eventDocs.push(newEvent);
        }
      });
    },
    clearEvents(state) {
      state.eventDocs = [];
    },
  },
  actions: {
    async fetchCurrentMonthEvents({ state, commit }) {
      let startOfMonth = new Date(
        state.calendarMonth.getFullYear(),
        state.calendarMonth.getMonth(),
        1,
      );
      let endOfMonth = new Date(
        state.calendarMonth.getFullYear(),
        state.calendarMonth.getMonth() + 1,
        1,
      );

      try {
        let documents = await fetchEventsByDateRange(startOfMonth, endOfMonth);
        commit("addEvents", documents);
      } catch (e) {
        handleFirestoreException({ e: e });
      }
    },
  },
  getters: {
    getHasSelectedCalendarEvent(state, getters) {
      return (
        state.selectedCalendarEventId !== null &&
        getters.getSelectedCalendarEventDoc
      );
    },
    getSelectedCalendarEventDoc(state) {
      return state.eventDocs.find(
        (eventDoc) => eventDoc.id === state.selectedCalendarEventId,
      );
    },
    getCurrentMonthEvents(state) {
      //return all events that are in the same month as the selectedCalendarMonth
      return state.eventDocs.filter((eventDoc) => {
        let beginEventDate = eventDoc.data.begin_timestamp.toDate();
        let endEventDate = eventDoc.data.end_timestamp.toDate();
        return (
          (beginEventDate.getMonth() === state.calendarMonth.getMonth() &&
            beginEventDate.getFullYear() ===
              state.calendarMonth.getFullYear()) ||
          (endEventDate.getMonth() === state.calendarMonth.getMonth() &&
            endEventDate.getFullYear() === state.calendarMonth.getFullYear())
        );
      });
    },
    getCurrentDayEvents(state) {
      if (!state.selectedCalendarDate) return [];
      return state.eventDocs.filter((eventDoc) => {
        let beginEventDate = eventDoc.data.begin_timestamp.toDate();
        let endEventDate = eventDoc.data.end_timestamp.toDate();
        return (
          (beginEventDate.getDate() === state.selectedCalendarDate.getDate() &&
            beginEventDate.getMonth() ===
              state.selectedCalendarDate.getMonth() &&
            beginEventDate.getFullYear() ===
              state.selectedCalendarDate.getFullYear()) ||
          (endEventDate.getDate() === state.selectedCalendarDate.getDate() &&
            endEventDate.getMonth() === state.selectedCalendarDate.getMonth() &&
            endEventDate.getFullYear() ===
              state.selectedCalendarDate.getFullYear())
        );
      });
    },
    getHasEvents(state) {
      return state.eventDocs.length > 0;
    },
    getEventDates(state) {
      return state.eventDocs
        .filter(
          (event) =>
            event.data.begin_timestamp.toDate().toDateString() ===
            event.data.end_timestamp.toDate().toDateString(),
        )
        .map((event) => event.data.begin_timestamp.toDate());
    },
    getRangedEventDates(state) {
      return state.eventDocs
        .filter(
          (event) =>
            event.data.begin_timestamp.toDate().toDateString() !==
            event.data.end_timestamp.toDate().toDateString(),
        )
        .map((event) => ({
          start: event.data.begin_timestamp.toDate(),
          end: event.data.end_timestamp.toDate(),
        }));
    },
  },
};

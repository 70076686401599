<template>
  <div
    :class="[
      isCorrect && isLocked
        ? 'bg-green-700 text-white shadow-green-hard-sm'
        : isPresent && isLocked
          ? 'bg-kekkle-yellow-dark text-kekkle-dark shadow-yellow-hard-sm'
          : isWrong && isLocked
            ? 'bg-kekkle-dark-light text-white'
            : isEmpty
              ? 'shadow-hard bg-gray-300'
              : 'text-black animate-scale border-2 border-black-dark bg-white shadow-teal-hard-sm',
      isAnimating ? 'animate-scale' : '',
    ]"
    class="flex aspect-square h-full w-full max-w-[3.5rem] items-center justify-center rounded border-2 border-black-dark text-3xl font-medium capitalize transition-all"
  >
    {{ letter.character }}
  </div>
</template>
<script setup>
import { computed, ref, watch } from "vue";

const props = defineProps({ letter: Object, index: Number });

const isAnimating = ref(false);

const isCorrect = computed(() => {
  return props.letter.isCorrect;
});

const isPresent = computed(() => {
  return props.letter.isPresent;
});

const isEmpty = computed(() => {
  return props.letter.character === "";
});

const isLocked = computed(() => {
  return props.letter.isLocked;
});

const isWrong = computed(() => {
  return (
    !props.letter.isCorrect && !props.letter.isPresent && props.letter.isLocked
  );
});

watch([props.letter], () => {
  isAnimating.value = true;
  setTimeout(() => {
    isAnimating.value = false;
  }, 500); // The duration should match the CSS animation duration
});
</script>
<style>
@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.animate-scale {
  animation: scale 0.5s ease-in-out;
}
</style>

import { firebaseStorage } from "@/helpers/firebase/firebase";
import { HD } from "@/constants/image-sizes";
import { getBlob } from "firebase/storage";
import { ref } from "firebase/storage";

export async function getGroupPictureFromFirestorage(
  groupId,
  groupPicturePath,
  format = HD,
) {
  if (!groupId || !groupPicturePath) {
    throw new Error("groupId or groupPicturePath is missing");
  }

  let formatReference = ref(
    firebaseStorage,
    "groups/" + groupId + "/" + groupPicturePath + format,
  );
  let originalReference = ref(
    firebaseStorage,
    "groups/" + groupId + "/" + groupPicturePath,
  );
  try {
    let blob = await getBlob(formatReference);
    return {
      blob: blob,
      format: format,
      url: URL.createObjectURL(blob),
    };
  } catch (e) {
    if (e.code == "storage/object-not-found") {
      //could be that the image has not been resized yet
      let blob = await getBlob(originalReference);
      return {
        blob: blob,
        format: format,
        url: URL.createObjectURL(blob),
      };
    } else {
      throw e;
    }
  }
}

<template>
  <div>
    <h1 v-if="!labelDisabled" class="font-medium dark:text-slate-200">
      {{ label }}
    </h1>
    <input
      :id="label.toLowerCase()"
      :value="modelValue"
      :autocomplete="autocomplete"
      class="w-full appearance-none rounded border-2 border-kekkle-dark-dark px-3 py-2 text-kekkle-dark shadow-blue-hard-sm outline-none focus:ring focus:ring-kekkle-blue dark:bg-kekkle-dark-light dark:text-slate-200"
      :type="type"
      :placeholder="placeholder"
      @input="onInput"
      @focus="hasBeenFocused = true"
      @blur="hasBeenBlurred = true"
    />
    <p v-show="hasError && hasBeenFocused" class="mt-1 text-sm text-red-600">
      {{ errorMessage }}
    </p>
  </div>
</template>
<script>
export default {
  name: "TextInput",
  props: {
    type: { type: String, default: "text" },
    modelValue: String,
    placeholder: {
      type: String,
      default: "",
    },
    label: { type: String, default: "Label" },
    labelDisabled: { type: Boolean, default: false },
    autocomplete: String,
    errorMessage: String,
    hasError: { type: Boolean, default: false },
  },
  emits: ["update:modelValue"],
  data: function () {
    return {
      hasBeenFocused: false,
      hasBeenBlurred: false,
    };
  },
  methods: {
    onInput(event) {
      this.$emit("update:modelValue", event.target.value);
    },
  },
};
</script>

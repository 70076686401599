<template>
  <div>
    <h1 class="text-l font-medium dark:text-slate-200">{{ label }}</h1>
    <textarea
      :id="label.toLowerCase()"
      :value="modelValue"
      maxlength="500"
      rows="5"
      :autocomplete="autocomplete"
      class="text-grey-darker w-full appearance-none rounded border-2 border-kekkle-dark px-3 py-2 shadow-yellow-hard-sm outline-none focus:ring focus:ring-kekkle-yellow dark:bg-kekkle-dark-light dark:text-slate-200"
      :placeholder="placeholder"
      @input="onInput"
      @focus="hasBeenFocused = true"
      @blur="hasBeenBlurred = true"
    />
    <p
      v-if="hasBeenFocused && hasError && hasBeenBlurred"
      class="mt-1 text-sm text-red-600"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>
<script>
export default {
  name: "TextAreaInput",
  props: {
    modelValue: String,
    placeholder: { type: String, default: "Placeholder" },
    label: { type: String, default: "Label" },
    autocomplete: String,
    hasError: { type: Boolean, default: false },
    errorMessage: { type: String },
  },
  emits: ["update:modelValue"],
  data: function () {
    return {
      hasBeenFocused: false,
      hasBeenBlurred: false,
    };
  },
  methods: {
    onInput(event) {
      this.$emit("update:modelValue", event.target.value);
    },
  },
};
</script>

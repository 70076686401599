<template>
  <top-navigation
    :title="$t('settings.group_settings')"
    :button-name="$t('global.inputs.labels.save')"
    :loading="status.updating"
    :disabled="!validated"
    @submit-click="update()"
  >
  </top-navigation>
  <div class="mx-auto min-h-screen pt-24">
    <information-text
      :information="$t('settings.change_group') + ': ' + groupData.name"
    ></information-text>
    <div class="my-4 w-full">
      <profile-picture-input
        :square="true"
        :label="$t('global.inputs.labels.group_picture')"
        :url="group_picture_url ? group_picture_url : currentGroupPictureUrl"
        @file-changed="fileChanged"
      />
      <p v-show="validationErrors.group_picture"></p>
    </div>
    <div class="my-4 w-full">
      <text-input
        v-model="name"
        :placeholder="$t('global.inputs.placeholders.group_name')"
        :label="$t('global.inputs.labels.group_name') + '*'"
      />
      <p v-show="validationErrors.groupName" class="mt-1 text-sm text-red-600">
        {{ $t("global.inputs.errors.group_name") }}
      </p>
    </div>
    <div class="my-4 w-full">
      <text-area-input
        v-model="description"
        :placeholder="$t('global.inputs.placeholders.group_description')"
        :label="$t('global.inputs.labels.group_description')"
      />
      <p
        v-show="validationErrors.description"
        class="mt-1 text-sm text-red-600"
      >
        {{ $t("global.inputs.errors.group_description_too_long") }}
      </p>
    </div>
  </div>
</template>
<script>
import TopNavigation from "../../components/TopNavigation.vue";
import InformationText from "../../components/InformationText.vue";

import TextInput from "../../components/Inputs/TextInput.vue";
import ProfilePictureInput from "../../components/Inputs/ProfilePicutreInput.vue";
import { setWarningToast } from "@/helpers/notifications/toast";
import { SD } from "@/constants/image-sizes";
import {
  handleFirestorageException,
  handleFirestoreException,
} from "@/helpers/exceptions/handler";
import { doc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes } from "firebase/storage";
import { firebaseStorage, groupsCollection } from "@/helpers/firebase/firebase";
import {
  CLEAR_GROUP_PROFILE_PICTURE_DATA,
  DOWNLOAD_GROUP_PICTURE_DATA,
  GET_GROUP_DATA,
  GET_GROUP_DOC_ID,
  GET_GROUP_PICTURE_DATA,
} from "@/store/modules/groups";
import TextAreaInput from "../../components/Inputs/TextAreaInput.vue";
import imageCompression from "browser-image-compression";
import { encodeImageToBlurhash } from "@/helpers/blurhash/encodeImageToBlurhash";
import { refreshAllUserGroupsData } from "@/helpers/data/fetchers";
export default {
  name: "GroupProfileSettingsView",
  components: {
    TextAreaInput,
    ProfilePictureInput,
    TextInput,
    InformationText,
    TopNavigation,
  },
  data: function () {
    return {
      SD: SD,
      status: {
        updating: false,
      },
      name: "",
      description: "",
      group_picture_url: "",
      group_picture_file: null,
    };
  },
  computed: {
    currentGroupPictureUrl() {
      if (
        this.groupPictureData &&
        this.groupPictureData[SD] &&
        this.groupPictureData[SD]["url"]
      ) {
        return this.groupPictureData[SD]["url"];
      } else {
        return "";
      }
    },
    groupNameValid() {
      return !!(this.name && this.name.length > 1);
    },
    descriptionValid() {
      if (!this.description) {
        return true;
      }
      return this.description.length < 500;
    },
    validationErrors() {
      let errors = {};
      errors.groupName = !this.groupNameValid;
      errors.description = !this.descriptionValid;
      return errors;
    },
    validated() {
      return !Object.values(this.validationErrors).some(
        (value) => value === true,
      );
    },
    groupDocId() {
      return this.$store.getters[GET_GROUP_DOC_ID];
    },
    groupData() {
      return this.$store.getters[GET_GROUP_DATA];
    },
    groupPictureData() {
      return this.$store.getters[GET_GROUP_PICTURE_DATA];
    },
  },
  created() {
    this.name = this.groupData.name;
    this.description = this.groupData.description;
    this.$store.dispatch(DOWNLOAD_GROUP_PICTURE_DATA, {
      format: this.SD,
    });
  },
  methods: {
    async update() {
      if (!this.validated) {
        setWarningToast({
          message: this.$t("global.toast.check_all_fields"),
        });
        return;
      }
      if (this.group_picture_file) {
        try {
          this.status.updating = true;
          let imageName = this.groupDocId + new Date().toISOString();
          let groupPictureRef = ref(
            firebaseStorage,
            "groups/" + this.groupDocId + "/group_pictures/" + imageName,
          );
          let compressedFile = await imageCompression(this.group_picture_file, {
            maxSizeMB: 1,
            maxWidthOrHeight: 3840,
            useWebWorker: false,
          });
          let superCompressedFile = await imageCompression(compressedFile, {
            maxWidthOrHeight: 20,
            useWebWorker: false,
          });
          let blurHash = await encodeImageToBlurhash(
            URL.createObjectURL(superCompressedFile),
          );

          await uploadBytes(groupPictureRef, compressedFile);
          //update the doc
          try {
            let groupRef = doc(groupsCollection, this.groupDocId);
            await updateDoc(groupRef, {
              group_picture: "group_pictures/" + imageName,
              group_picture_blurhash: blurHash,
            });
            this.$store.commit(CLEAR_GROUP_PROFILE_PICTURE_DATA);
          } catch (e) {
            handleFirestoreException({ e: e });
          }
        } catch (e) {
          handleFirestorageException({ e: e });
        } finally {
          this.status.updating = false;
        }
      }
      //Update other fields
      try {
        this.status.updating = true;
        let groupRef = doc(groupsCollection, this.groupDocId);
        await updateDoc(groupRef, {
          name: this.name ? this.name : "",
          description: this.description ? this.description : "",
        });
      } catch (e) {
        handleFirestoreException({ e: e });
      } finally {
        this.status.updating = false;
      }
      await refreshAllUserGroupsData();
      this.$router.push({ name: "settings" });
    },
    fileChanged(file) {
      if (file.size / 1020 / 1024 < 100) {
        this.group_picture_file = file;
        this.group_picture_url = URL.createObjectURL(file);
      } else {
        setWarningToast({
          message: this.$t("global.toast.file_too_big", [100]),
        });
      }
    },
  },
};
</script>

<template>
  <div v-if="visible">
    <div
      class="fixed left-0 top-0 z-40 flex h-screen w-screen items-center justify-center bg-black-dark/40 p-4 backdrop-blur-sm"
      @click="close"
    >
      <div
        class="max-h-3/4 w-full max-w-md overflow-y-scroll rounded-lg border-2 border-black-dark bg-white p-4 shadow-teal-hard-sm dark:bg-kekkle-dark-light dark:text-slate-200"
        @click.stop
      >
        <div @click.stop>
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SimpleModal",
  props: {
    visible: Boolean,
  },
  emits: ["close"],
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

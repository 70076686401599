<template>
  <div
    class="mx-auto w-full max-w-md rounded-xl border-4 border-black-dark bg-white shadow-indigo-hard dark:bg-slate-700 dark:text-slate-300"
  >
    <div class="m-6">
      <h1 class="text-xl font-bold dark:text-slate-200">
        {{ $t("auth.Delete my account") }} : {{ userEmail }}
      </h1>
    </div>
    <div v-if="stepNumber === 0" class="m-6">
      <p>
        {{ $t("auth.Are you sure you want to delete your account?") }}
      </p>
      <div class="mt-6 flex">
        <default-button @click="$router.push({ name: 'Group' })">{{
          $t("auth.No go back")
        }}</default-button>
        <default-button color="red" @click="stepNumber++">{{
          $t("auth.Yes proceed")
        }}</default-button>
      </div>
    </div>
    <div v-if="stepNumber === 1" class="m-6">
      <p>
        {{
          $t(
            "auth.are you really sure you want to delete your account on this amazing app?",
          )
        }}
      </p>
      <div class="mt-6 flex">
        <default-button color="red" @click="stepNumber++">
          {{ $t("auth.Yes proceed") }}</default-button
        >
        <default-button @click="$router.push({ name: 'Group' })">{{
          $t("auth.No go back")
        }}</default-button>
      </div>
    </div>
    <div v-if="stepNumber === 2" class="m-6">
      <p>
        {{
          $t(
            "auth.Are you really sure you do not want to create some fun memories??",
          )
        }}
      </p>
      <div class="mt-6 flex">
        <default-button @click="$router.push({ name: 'Group' })">
          {{ $t("auth.No go back") }}</default-button
        >
        <default-button color="red" @click="stepNumber++">{{
          $t("auth.Yes proceed")
        }}</default-button>
      </div>
    </div>
    <div v-if="stepNumber === 3" class="m-6">
      <p>{{ $t("auth.Alright time to let go") }}</p>
      <div class="mt-6 flex">
        <default-button
          :loading="deleting"
          color="red"
          @click="deleteAccount"
          >{{ $t("auth.Delete my account forever") }}</default-button
        >
        <default-button
          v-if="!deleting"
          @click="$router.push({ name: 'Group' })"
          >{{ $t("auth.Fuck go back") }}</default-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import DefaultButton from "../components/Buttons/DefaultButton.vue";
import { httpsCallable } from "firebase/functions";
import { firebaseFunctions } from "@/helpers/firebase/firebase";
import { logout } from "@/helpers/data/logout";
import { setErrorToast, setSuccessToast } from "@/helpers/notifications/toast";
import { GET_AUTH_USER } from "@/store/modules/auth";
import * as Sentry from "@sentry/vue";

export default {
  name: "DeleteAccount",
  components: {
    DefaultButton,
  },
  data: function () {
    return {
      stepNumber: 0,
      deleting: false,
    };
  },
  computed: {
    userEmail() {
      return this.$store.getters[GET_AUTH_USER]?.email;
    },
  },
  methods: {
    async deleteAccount() {
      this.deleting = true;
      const deleteGroupFunction = await httpsCallable(
        firebaseFunctions,
        "deleteAccount",
      );
      try {
        await deleteGroupFunction();
        setSuccessToast({
          message: this.$t("auth.delete_account_success"),
        });
        setTimeout(async () => {
          await logout();
        }, 2000);
      } catch (e) {
        Sentry.captureException(e);
        setErrorToast({
          message: this.$t("auth.delete_account_failed"),
        });
      } finally {
        this.deleting = false;
      }
    },
  },
};
</script>

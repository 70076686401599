<template>
  <div
    class="mb-4 rounded-2xl border-2 border-black-dark bg-white p-2 shadow-indigo-hard-sm dark:bg-kekkle-dark-light dark:shadow-teal-hard-sm"
  >
    <div class="flex flex-nowrap justify-between">
      <div class="flex flex-nowrap items-center">
        <small-user-thumb class="shrink-0" :uuid="uuid" />
        <div class="mx-3">
          <h2 class="text-xl font-medium dark:text-slate-200">
            {{ member.name }}
          </h2>
          <p class="w-max rounded-3xl px-4" :class="memberClass">
            {{ memberText }}
          </p>
        </div>
      </div>
      <trash-icon
        v-if="canDelete"
        :class="
          canDelete
            ? 'cursor-pointer text-black-dark dark:text-slate-200'
            : 'cursor-not-allowed text-gray-300 dark:text-slate-700'
        "
        class="w-6 shrink-0"
        @click="deleteButton"
      ></trash-icon>
    </div>
  </div>
</template>
<script>
import SmallUserThumb from "./SmallUserThumb.vue";
import { TrashIcon } from "@heroicons/vue/outline";
import { IS_ADMIN } from "@/store/modules/groups";
export default {
  name: "MemberItem",
  components: { SmallUserThumb, TrashIcon },
  props: {
    member: Object,
    uuid: String,
  },
  emits: ["deleteMember"],
  computed: {
    memberClass() {
      switch (this.member.permission) {
        case "admin":
          return "bg-green-200 text-green-600";
        case "organiser":
          return "bg-orange-200 text-orange-600";
        case "member":
          return "bg-purple-200 text-purple-600";
        default:
          return "";
      }
    },
    memberText() {
      switch (this.member.permission) {
        case "admin":
          return this.$t("global.roles.admin");
        case "organiser":
          return this.$t("global.roles.organiser");
        case "member":
          return this.$t("global.roles.member");
        default:
          return "";
      }
    },
    canDelete() {
      return (
        this.$store.getters[IS_ADMIN] && this.member.permission !== "admin"
      );
    },
  },
  methods: {
    deleteButton() {
      if (this.canDelete) {
        this.$emit("deleteMember");
      }
    },
  },
};
</script>

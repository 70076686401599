import { collection, doc, getDoc } from "firebase/firestore";
import { firestore } from "@/helpers/firebase/firebase";
import { docsConverter } from "./FirestoreService";

/**
 * Return the groups collection reference
 * @returns {CollectionReference}
 */
function groupCollection() {
  return collection(firestore, "groups");
}

/**
 * Fetches groups based on the provided IDs
 * @param {Array} groupIds
 * @returns {Promise<Array>}
 */
export async function fetchGroupsById(groupIds) {
  const groups = [];
  for (const groupId of groupIds) {
    const groupDoc = await fetchGroupById(groupId);
    if (!groupDoc.exists) {
      continue;
    }
    groups.push(groupDoc);
  }
  return groups;
}

/**
 * Fetches a group by its ID
 * @param {String} groupId
 * @returns {Promise<Object>}
 */
export async function fetchGroupById(groupId) {
  const groupDoc = await getDoc(doc(groupCollection(), groupId));
  return docsConverter(groupDoc);
}

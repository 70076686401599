import { createStore } from "vuex";
import { CLEAR_AUTH, moduleAuth } from "./modules/auth";
import { CLEAR_GROUP_DATA, moduleGroup } from "./modules/groups";
import { CLEAR_USER_DATA, moduleUser } from "./modules/user";
import { modulePrompts } from "./modules/prompts";
import { moduleInformation } from "@/store/modules/information";
import { moduleQuotes } from "@/store/modules/quotes";
import { modulePhotos } from "@/store/modules/photos";
import { moduleWordle } from "@/store/modules/wordle";
import { moduleWordleScores } from "@/store/modules/wordle-scores";
import { moduleWordleLeaderboard } from "@/store/modules/worlde-leaderboard";
import { moduleWordleMonthlyScores } from "@/store/modules/wordle-monthly-scores";
import { moduleEvents } from "@/store/modules/events";

const store = createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    Auth: moduleAuth,
    User: moduleUser,
    Prompts: modulePrompts,
    Group: moduleGroup,
    Information: moduleInformation,
    Quotes: moduleQuotes,
    Photos: modulePhotos,
    Wordle: moduleWordle,
    WordleScores: moduleWordleScores,
    WordleLeaderBoard: moduleWordleLeaderboard,
    WordleMonthlyScores: moduleWordleMonthlyScores,
    Events: moduleEvents,
  },
});

export function clearAllStoreData() {
  store.commit(CLEAR_AUTH);
  store.commit(CLEAR_USER_DATA);
  store.commit(CLEAR_GROUP_DATA);
  store.commit("clearQuotes");
  store.commit("clearPhotos");
  store.commit("clearWordle");
  store.commit("clearWordleScores");
  store.commit("clearWorldeLeaderBoard");
  store.commit("clearWordleMonthlyScores");
  store.commit("clearEvents");
}

export default store;

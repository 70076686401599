<template>
  <!--  the bar-->
  <!--  Add padding to div below-->
  <top-navigation
    :title="$t('pages.event.add_event')"
    :disabled="!validated"
    :loading="submitting"
    :button-name="$t('global.inputs.labels.save')"
    @submit-click="submit()"
  />

  <div class="mx-auto min-h-screen pt-24">
    <div class="mb-4 w-full">
      <text-input
        v-model="name"
        :placeholder="$t('global.inputs.placeholders.event')"
        :label="$t('global.inputs.labels.name_event')"
        :has-error="validationErrors.name"
        :error-message="$t('global.inputs.errors.name_event')"
      />
    </div>
    <div class="mb-4 w-full">
      <text-area-input
        v-model="description"
        :has-error="validationErrors.description"
        :error-message="$t('global.inputs.errors.description_event')"
        :label="$t('global.inputs.labels.description_event')"
        :placeholder="$t('global.inputs.placeholders.event_description')"
      />
    </div>
    <div class="mb-4 w-full">
      <p class="font-medium dark:text-slate-200">
        {{ $t("global.inputs.labels.date_time") }}*
      </p>
      <p v-show="validationErrors.timestamp" class="mt-1 text-sm text-red-600">
        {{ $t("global.inputs.errors.date_time") }}
      </p>

      <div class="flex w-full flex-col items-center">
        <date-picker
          v-model.range="range"
          range
          is24hr
          mode="dateTime"
          class="mx-auto border-2 border-solid border-black-dark shadow-red-hard-sm dark:bg-kekkle-dark-light"
          show-iso-weeknumbers
          expanded
          :attributes="attributes"
          :is-dark="darkModeEnabled"
        ></date-picker>
      </div>
    </div>
  </div>
</template>
<script>
import { DatePicker } from "v-calendar";
import { firestore } from "@/helpers/firebase/firebase";
import { collection, increment, writeBatch, doc } from "firebase/firestore";
import { GET_GROUP_DOC_ID } from "@/store/modules/groups";
import {
  setSuccessToast,
  setWarningToast,
} from "@/helpers/notifications/toast";
import { handleFirestoreException } from "@/helpers/exceptions/handler";
import TopNavigation from "../components/TopNavigation.vue";
import TextInput from "@/components/Inputs/TextInput.vue";
import TextAreaInput from "@/components/Inputs/TextAreaInput.vue";
import { GET_AUTH_UUID } from "@/store/modules/auth";
import { GET_CHECKED_GROUP_ID } from "@/store/modules/groups";
import { globals } from "@/helpers/globals/globals";
import { addEvent } from "@/helpers/firestore/EventService";
import { GET_ACTIVE_GROUP } from "@/store/modules/user";

export default {
  name: "AddEventView",
  components: { TextAreaInput, TextInput, TopNavigation, DatePicker },
  data() {
    return {
      submitting: false,
      name: null,
      description: null,
      range: {
        start: null,
        end: null,
      },
    };
  },
  computed: {
    attributes() {
      return [
        {
          key: "today",
          highlight: {
            fillMode: "light",
            color: "orange",
          },
          dates: new Date(),
        },
      ];
    },
    darkModeEnabled() {
      return !!globals.darkModeEnabled;
    },
    nameValid() {
      return !!(this.name && this.name.length > 0);
    },
    descriptionValid() {
      return !!(this.description && this.description.length > 0);
    },
    timestampValid() {
      return !!(
        this.range.start &&
        this.range.end &&
        this.range.start.getTime() < this.range.end.getTime()
      );
    },
    validationErrors() {
      let errors = {};
      errors.name = !this.nameValid;
      errors.description = !this.descriptionValid;
      errors.timestamp = !this.timestampValid;
      return errors;
    },
    validated() {
      return !Object.values(this.validationErrors).some(
        (value) => value === true,
      );
    },
  },
  watch: {
    range(newrange, oldrange) {},
  },
  created() {
    this.range.start = window.selectedDate
      ? new Date(window.selectedDate.getTime())
      : new Date();
    this.range.start.setHours(12);
    this.range.end = window.selectedDate
      ? new Date(window.selectedDate.getTime())
      : new Date();
    this.range.end.setHours(13);
    window.selectedDate = null;
  },
  methods: {
    async submit() {
      if (!this.validated) {
        setWarningToast({
          message: this.$t("global.toast.check_all_fields"),
        });
        return;
      }

      try {
        this.submitting = true;
        await addEvent(this.$store.getters[GET_ACTIVE_GROUP], {
          name: this.name,
          description: this.description,
          begin_timestamp: this.range.start,
          end_timestamp: this.range.end,
          created_by: this.$store.getters[GET_AUTH_UUID],
        });
        this.name = null;
        this.description = null;
        this.range.start = null;
        this.range.end = null;
        setSuccessToast({
          message: this.$t("global.toast.event_saved"),
        });
        this.$router.push({ name: "Agenda" });
      } catch (e) {
        handleFirestoreException({ e: e });
      } finally {
        this.submitting = false;
      }
    },
  },
};
</script>

import { globals } from "@/helpers/globals/globals";

export function initDarkmode() {
  // On page load or when changing themes, best to add inline in `head` to avoid FOUC
  if (
    localStorage.theme === "dark" ||
    (!("theme" in localStorage) &&
      window.matchMedia("(prefers-color-scheme: dark)").matches)
  ) {
    globals.darkModeEnabled = true;
    document.documentElement.classList.add("dark");
  } else {
    globals.darkModeEnabled = false;
    document.documentElement.classList.remove("dark");
  }
}

export function dark() {
  localStorage.theme = "dark";
  initDarkmode();
}

export function light() {
  localStorage.theme = "light";
  initDarkmode();
}

export function auto() {
  localStorage.removeItem("theme");
  initDarkmode();
}

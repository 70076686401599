<template>
  <top-navigation
    :title="$t('settings.roles')"
    :button-name="isAdmin ? $t('global.inputs.labels.save') : 'button'"
    :loading="status.loading"
    :disabled="!madeChanges"
    @submit-click="update()"
  >
  </top-navigation>
  <div class="mx-auto min-h-screen pt-24">
    <information-text
      v-if="isAdmin"
      :information="$t('settings.permissions.as_admin_you_can_change_roles')"
    ></information-text>
    <information-text
      v-else
      color="orange"
      :information="
        $t('settings.permissions.as_non_admin_you_cannot_change_roles')
      "
    ></information-text>
    <br />
    <information-text
      :information="$t('settings.permissions_explained')"
    ></information-text>

    <div class="flex-no-wrap my-4 flex flex-row">
      <h2 class="w-1/3 font-medium dark:text-slate-200">
        {{ $t("roles.admin") }}
      </h2>
      <h2 class="w-1/3 text-center font-medium dark:text-slate-200">
        {{ $t("roles.organiser") }}
      </h2>
      <h2 class="w-1/3 text-right font-medium dark:text-slate-200">
        {{ $t("roles.member") }}
      </h2>
    </div>

    <div class="my-2">
      <div
        v-for="member in membersSorted"
        :key="member.uuid"
        class="flex-no-wrap relative my-4 mb-8 flex flex-row"
        :class="
          visiblePermission[member.uuid] === 'admin'
            ? 'justify-start'
            : visiblePermission[member.uuid] === 'organiser'
              ? 'justify-center'
              : 'justify-end'
        "
      >
        <div class="absolute top-4 h-8 w-full rounded bg-gray-300">
          <p
            class="mt-14 dark:text-slate-200"
            :class="
              visiblePermission[member.uuid] === 'admin'
                ? 'text-start'
                : visiblePermission[member.uuid] === 'organiser'
                  ? 'text-center'
                  : 'text-end'
            "
          >
            {{ member.name }}
          </p>
        </div>

        <div
          class="flex-no-wrap absolute top-4 flex h-8 w-full justify-between"
        >
          <div
            v-if="
              isAdmin &&
              (member.permission !== 'admin' || member.uuid === userUuid)
            "
            class="h-8 w-8 animate-pulse rounded bg-gray-500"
            @click="setModelData(member.uuid, 'admin')"
          ></div>
          <div
            v-if="
              isAdmin &&
              (member.permission !== 'admin' || member.uuid === userUuid)
            "
            class="h-8 w-8 animate-pulse rounded bg-gray-500"
            @click="setModelData(member.uuid, 'organiser')"
          ></div>
          <div
            v-if="
              isAdmin &&
              (member.permission !== 'admin' || member.uuid === userUuid)
            "
            class="h-8 w-8 animate-pulse rounded bg-gray-500"
            @click="setModelData(member.uuid, 'member')"
          ></div>
        </div>
        <div>
          <small-user-thumb :uuid="member.uuid" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TopNavigation from "../../components/TopNavigation.vue";
import InformationText from "../../components/InformationText.vue";
import SmallUserThumb from "../../components/SmallUserThumb.vue";
import { GET_AUTH_UUID } from "@/store/modules/auth";

import { httpsCallable } from "firebase/functions";
import { firebaseFunctions } from "@/helpers/firebase/firebase";
import {
  GET_GROUP_DATA,
  GET_GROUP_DOC_ID,
  GET_GROUP_MEMBERS_SORTED,
  IS_ADMIN,
} from "@/store/modules/groups";

import * as Sentry from "@sentry/vue";
import {
  setErrorToast,
  setSuccessToast,
  setWarningToast,
} from "@/helpers/notifications/toast";
import { refreshAllUserGroupsData } from "@/helpers/data/fetchers";

export default {
  name: "MemberPermissionsView",
  components: {
    SmallUserThumb,
    InformationText,
    TopNavigation,
  },
  data: function () {
    return {
      status: {
        loading: false,
      },
      visiblePermission: {},
    };
  },
  computed: {
    membersSorted() {
      return this.$store.getters[GET_GROUP_MEMBERS_SORTED];
    },
    members() {
      return this.$store.getters[GET_GROUP_DATA] &&
        this.$store.getters[GET_GROUP_DATA].members
        ? this.$store.getters[GET_GROUP_DATA].members
        : {};
    },
    groupDocId() {
      return this.$store.getters[GET_GROUP_DOC_ID];
    },
    isAdmin() {
      return this.$store.getters[IS_ADMIN];
    },
    userUuid() {
      return this.$store.getters[GET_AUTH_UUID];
    },
    madeChanges() {
      for (const [uuid, value] of Object.entries(this.members)) {
        if (this.visiblePermission[uuid] !== value["permission"]) {
          return true;
        }
      }
      return false;
    },
  },
  created() {
    for (const [key, value] of Object.entries(this.members)) {
      this.visiblePermission[key] = value.permission;
    }
  },
  methods: {
    setModelData(uuid, permission) {
      this.visiblePermission[uuid] = permission;
    },
    async update() {
      this.status.loading = true;

      let permissions = {};
      for (const [uuid, value] of Object.entries(this.members)) {
        if (this.visiblePermission[uuid] !== value["permission"]) {
          permissions[uuid] = this.visiblePermission[uuid];
        }
      }

      let payload = {};
      payload["groupId"] = this.groupDocId;
      payload["permissions"] = permissions;

      try {
        const changeGroupPermission = await httpsCallable(
          firebaseFunctions,
          "changeGroupPermission",
        );
        await changeGroupPermission(payload);
        setSuccessToast({
          message: this.$t("global.toast.saved_successfully"),
        });
        await refreshAllUserGroupsData();
        this.$router.push({ name: "settings" });
      } catch (e) {
        switch (e.message) {
          case "User has not enough permission":
            setWarningToast({
              message: "Je bent niet bevoegd dit te doen",
            });
            break;
          case "Needs one admin":
            setWarningToast({
              message: this.$t("global.toast.error_one_admin_minimum"),
            });
            break;
          default:
            setErrorToast({
              message: this.$t("global.toast.error_changing_permissions"),
            });
            Sentry.captureException(e);
            break;
        }
      } finally {
        this.status.loading = false;
      }
    },
  },
};
</script>

<template>
  <div
    class="flex w-full max-w-md flex-col rounded-xl border-4 border-black-dark bg-white px-2 pb-6 pt-6 shadow-purple-hard dark:bg-kekkle-dark"
  >
    <div class="mx-auto my-4 flex flex-col items-center justify-center gap-y-2">
      <google-login-button
        class="max-w-[18rem]"
        :loading="store.state.Auth.loadingGoogleAuth"
        :disabled="store.getters.loadingAuth"
        @click="googleLogin"
      ></google-login-button>
      <apple-login-button
        class="max-w-[18rem]"
        :loading="store.state.Auth.loadingAppleAuth"
        :disabled="store.getters.loadingAuth"
        @click="appleLogin"
      ></apple-login-button>
    </div>
    <p class="mb-6 text-center text-sm text-gray-700 dark:text-slate-200">
      {{ i18n.t("auth.terms.agreementText") }}
      <router-link class="underline" :to="{ name: 'PrivacyPolicy' }">{{
        i18n.t("auth.terms.privacyPolicy")
      }}</router-link>
      {{ i18n.t("auth.terms.and") }}
      <router-link class="underline" :to="{ name: 'Eula' }">{{
        i18n.t("auth.terms.eula")
      }}</router-link
      >.
    </p>
    <div v-if="!store.getters.loadingAuth" class="">
      <p
        class="my-2 cursor-pointer text-center underline dark:text-slate-200"
        @click="router.push({ name: 'LoginEmail' })"
      >
        {{ i18n.t("auth.login_register_with_email") }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { GET_AUTH } from "@/store/modules/auth";
import {
  GoogleAuthProvider,
  OAuthProvider,
  signInWithRedirect,
  getRedirectResult,
} from "firebase/auth";
import GoogleLoginButton from "@/components/Buttons/GoogleLoginButton.vue";
import AppleLoginButton from "@/components/Buttons/AppleLoginButton.vue";
import { firebaseAuth } from "@/helpers/firebase/firebase";
import {
  askAppleIdTokenFlutter,
  askGoogleIdTokenFlutter,
  hasSupportForNativeGoogleAuth,
  hasSupportForNativeAppleAuth,
} from "@/helpers/flutter/FlutterAuth";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const store = useStore();
const i18n = useI18n();
const router = useRouter();
async function googleLogin() {
  store.commit("setGoogleAuthLoading", true);
  if (hasSupportForNativeGoogleAuth()) {
    askGoogleIdTokenFlutter();
  } else {
    await googleRedirect();
  }
}
async function googleRedirect() {
  setTimeout(() => {
    store.commit("cancelAuth");
  }, 3000);
  let provider = new GoogleAuthProvider();
  provider.setCustomParameters({
    prompt: "select_account",
  });
  await signInWithRedirect(firebaseAuth, provider);
  await getRedirectResult(firebaseAuth);
}
async function appleLogin() {
  store.commit("setAppleAuthLoading", true);
  if (hasSupportForNativeAppleAuth()) {
    askAppleIdTokenFlutter();
  } else {
    await appleRedirect();
  }
}
async function appleRedirect() {
  //set loading back to false after 3 seconds, because a user can cancel the apple login
  setTimeout(() => {
    store.commit("cancelAuth");
  }, 3000);
  const provider = new OAuthProvider("apple.com");
  provider.addScope("email");
  provider.addScope("name");
  await signInWithRedirect(firebaseAuth, provider);
  await getRedirectResult(firebaseAuth);
}
</script>

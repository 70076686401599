import {
  collection,
  doc,
  getDocs,
  increment,
  query,
  where,
  writeBatch,
} from "firebase/firestore";
import { firestore } from "@/helpers/firebase/firebase";
import store from "@/store/store";
import { GET_ACTIVE_GROUP } from "@/store/modules/user";
import { docsConverter } from "./FirestoreService";

/**
 * Returns a reference to the events collection for a specific group.
 * @param {string} groupDocId - The ID of the group document.
 * @returns {CollectionReference} - A reference to the events collection.
 */
function eventsCollection(groupDocId) {
  return collection(firestore, `groups/${groupDocId}/events`);
}

/**
 * Fetches events based on a query constraint.
 * @param {Array} queryConstraint - The constraints for the query.
 * @returns {Promise<Array>} - A promise that resolves with the query snapshot.
 */
async function fetchEvents(queryConstraint) {
  const q = query(
    eventsCollection(store.getters[GET_ACTIVE_GROUP]),
    ...queryConstraint,
  );
  return docsConverter(await getDocs(q));
}

/**
 * Fetches events within a specific date range.
 * @param {Date} stateDate - The start date of the range.
 * @param {Date} endDate - The end date of the range.
 * @returns {Promise<Array>} - A promise that resolves with the query snapshot.
 */
export async function fetchEventsByDateRange(stateDate, endDate) {
  const queryConstraints = [
    where("begin_timestamp", "<", endDate),
    where("begin_timestamp", ">", stateDate),
  ];
  return await fetchEvents(queryConstraints);
}

/**
 * Deletes a specific event by its ID.
 * @param {string} eventId - The ID of the event.
 * @param {string} groupId - The ID of the group.
 * @returns {Promise<void>} - A promise that resolves when the batch has been committed.
 * @throws {Error} - If the group ID is not provided.
 */
export async function deleteEventById(eventId, groupId) {
  if (!groupId) {
    throw Error("Need group Id");
  }
  let eventRef = doc(eventsCollection(groupId), eventId);
  let batch = writeBatch(firestore);
  batch.delete(eventRef);

  let groupCollection = collection(firestore, "groups/");
  let groupRef = doc(groupCollection, groupId);
  batch.update(groupRef, {
    "general_data.number_of_events": increment(-1),
  });
  let icalRef = doc(
    collection(firestore, "groups/" + groupId + "/settings"),
    "ical",
  );
  batch.set(icalRef, { needs_update: true }, { merge: true });
  return await batch.commit();
}

/**
 * Adds a new event in the group.
 * @param {string} groupId - The ID of the group.
 * @param {Object} data - The data for the new event.
 * @returns {Promise<void>} - A promise that resolves when the batch has been committed.
 */
export async function addEvent(groupId, data) {
  let groupCollection = collection(firestore, "groups/");
  let groupRef = doc(groupCollection, groupId);
  let batch = writeBatch(firestore);
  let eventsRef = eventsCollection(groupId);
  let eventRef = doc(eventsRef);
  batch.set(eventRef, data);

  batch.update(groupRef, {
    "general_data.number_of_events": increment(1),
  });

  let icalRef = doc(
    collection(firestore, "groups/" + groupId + "/settings"),
    "ical",
  );
  batch.set(icalRef, { needs_update: true }, { merge: true });

  return await batch.commit();
}

/**
 * Updates a specific event by its ID.
 * @param {string} eventId - The ID of the event.
 * @param {string} groupId - The ID of the group.
 * @param {Object} data - The new data for the event.
 * @returns {Promise<void>} - A promise that resolves when the batch has been committed.
 * @throws {Error} - If the group ID is not provided.
 */
export async function updateEventById(eventId, groupId, data) {
  if (!groupId) {
    throw Error("Need group Id");
  }
  let eventRef = doc(eventsCollection(groupId), eventId);
  let batch = writeBatch(firestore);
  batch.update(eventRef, data);

  let icalRef = doc(
    collection(firestore, "groups/" + groupId + "/settings"),
    "ical",
  );
  batch.set(icalRef, { needs_update: true }, { merge: true });

  return await batch.commit();
}

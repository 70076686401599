import { translate } from "@/i18n/vueI18n.js";
export function getFirestoreExceptionMessage(e) {
  let message = e;
  if (e.code) {
    message = e.code;
  }
  switch (message) {
    case "cancelled":
      return translate("expectations.firestore.cancelled");
    case "unknown":
      return translate("expectations.firestore.unknown");
    case "already-exists":
      return translate("expectations.firestore.already_exists");
    case "permission-denied":
      return translate("expectations.firestore.permission_denied");
    case "unauthenticated":
      return translate("expectations.firestore.unauthenticated");
    default:
      return translate("expectations.firestore.default");
  }
}

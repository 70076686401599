import { decode, isBlurhashValid } from "blurhash";

export function decodeBlurhashToUrl(hashString) {
  if (!hashString) {
    return null;
  }
  if (!isBlurhashValid(hashString)) {
    return null;
  }
  const pixels = decode(hashString, 32, 32);
  const canvas = document.createElement("canvas");
  canvas.width = 32;
  canvas.height = 32;
  const ctx = canvas.getContext("2d");
  const imageData = ctx.createImageData(32, 32);
  imageData.data.set(pixels);
  ctx.putImageData(imageData, 0, 0);
  return canvas.toDataURL();
}

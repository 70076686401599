<template>
  <top-navigation
    :title="$t('settings.push.title')"
    :button-name="$t('global.inputs.labels.save')"
    :loading="status.updatingSettings"
    @submit-click="save"
  >
  </top-navigation>

  <div class="mx-auto pt-32">
    <information-text
      v-if="
        (isRunningInFlutter &&
          (!globals.hasMobileNotificationPermission || !globals.hasFcmToken)) ||
        (!isRunningInFlutter &&
          !status.updatedDeviceToken &&
          !status.fetchingDeviceToken)
      "
      color="orange"
      :information="$t('settings.push.getting_push_token_failed')"
    ></information-text>
    <information-text
      v-if="
        isRunningInFlutter &&
        globals.hasMobileNotificationPermission &&
        globals.hasFcmToken
      "
      color="green"
      :information="$t('settings.push.your_device_can_receive_notifications')"
    ></information-text>
    <information-text
      v-if="!isRunningInFlutter && status.updatedDeviceToken"
      color="green"
      :information="$t('settings.push.your_device_can_receive_notifications')"
    ></information-text>
    <div class="mt-4">
      <h1 class="my-4 text-xl font-medium dark:text-slate-200">
        {{ $t("settings.push.event_notifications") }}
      </h1>
      <div
        class="duration my-8 transform cursor-pointer rounded border-solid border-black-dark p-4 transition-all duration-500"
        :class="
          notificationSettings.event_create !== false
            ? 'text-black border-2 bg-white shadow-blue-hard dark:bg-kekkle-dark-light dark:text-slate-200'
            : 'translate-y-2 bg-gray-100 text-gray-400 dark:bg-kekkle-dark-dark dark:text-slate-500'
        "
        @click="
          notificationSettings.event_create = !notificationSettings.event_create
        "
      >
        {{ $t("settings.push.event_created") }}
      </div>
      <div
        class="duration my-8 transform cursor-pointer rounded border-solid border-black-dark p-4 transition-all duration-500"
        :class="
          notificationSettings.event_comment !== false
            ? 'text-black border-2 bg-white shadow-blue-hard dark:bg-kekkle-dark-light dark:text-slate-200'
            : 'translate-y-2 bg-gray-100 text-gray-400 dark:bg-kekkle-dark-dark dark:text-slate-500'
        "
        @click="
          notificationSettings.event_comment =
            !notificationSettings.event_comment
        "
      >
        {{ $t("settings.push.event_comment") }}
      </div>

      <h1 class="my-4 text-xl font-medium dark:text-slate-200">
        {{ $t("settings.push.quote_notifications") }}
      </h1>
      <div
        class="duration my-8 transform cursor-pointer rounded border-solid border-black-dark p-4 transition-all duration-500"
        :class="
          notificationSettings.quote_create !== false
            ? 'text-black border-2 bg-white shadow-blue-hard dark:bg-kekkle-dark-light dark:text-slate-200'
            : 'translate-y-2 bg-gray-100 text-gray-400 dark:bg-kekkle-dark-dark dark:text-slate-500'
        "
        @click="
          notificationSettings.quote_create = !notificationSettings.quote_create
        "
      >
        {{ $t("settings.push.quote_notifications_created") }}
      </div>
      <div
        class="duration my-8 transform cursor-pointer rounded border-solid border-black-dark p-4 transition-all duration-500"
        :class="
          notificationSettings.quote_comment !== false
            ? 'text-black border-2 bg-white shadow-blue-hard dark:bg-kekkle-dark-light dark:text-slate-200'
            : 'translate-y-2 bg-gray-100 text-gray-400 dark:bg-kekkle-dark-dark dark:text-slate-500'
        "
        @click="
          notificationSettings.quote_comment =
            !notificationSettings.quote_comment
        "
      >
        {{ $t("settings.push.quote_notifications_comment") }}
      </div>
      <h1 class="my-4 text-xl font-medium dark:text-slate-200">
        {{ $t("settings.push.photo_notifications") }}
      </h1>
      <div
        class="duration my-8 transform cursor-pointer rounded border-solid border-black-dark p-4 transition-all duration-500"
        :class="
          notificationSettings.photo_create !== false
            ? 'text-black border-2 bg-white shadow-blue-hard dark:bg-kekkle-dark-light dark:text-slate-200'
            : 'translate-y-2 bg-gray-100 text-gray-400 dark:bg-kekkle-dark-dark dark:text-slate-500'
        "
        @click="
          notificationSettings.photo_create = !notificationSettings.photo_create
        "
      >
        {{ $t("settings.push.photo_created") }}
      </div>
      <div
        class="duration my-8 transform cursor-pointer rounded border-solid border-black-dark p-4 transition-all duration-500"
        :class="
          notificationSettings.photo_comment !== false
            ? 'text-black border-2 bg-white shadow-blue-hard dark:bg-kekkle-dark-light dark:text-slate-200'
            : 'translate-y-2 bg-gray-100 text-gray-400 dark:bg-kekkle-dark-dark dark:text-slate-500'
        "
        @click="
          notificationSettings.photo_comment =
            !notificationSettings.photo_comment
        "
      >
        {{ $t("settings.push.photo_comment") }}
      </div>
      <h1 class="my-4 text-xl font-medium dark:text-slate-200">
        {{ $t("settings.push.group_notifications") }}
      </h1>
      <div
        class="duration my-8 transform cursor-pointer rounded border-solid border-black-dark p-4 transition-all duration-500"
        :class="
          notificationSettings.group_member_joined !== false
            ? 'text-black border-2 bg-white shadow-blue-hard dark:bg-kekkle-dark-light dark:text-slate-200'
            : 'translate-y-2 bg-gray-100 text-gray-400 dark:bg-kekkle-dark-dark dark:text-slate-500'
        "
        @click="
          notificationSettings.group_member_joined =
            !notificationSettings.group_member_joined
        "
      >
        {{ $t("settings.push.group_member_added") }}
      </div>
    </div>
  </div>
</template>
<script>
import TopNavigation from "../../components/TopNavigation.vue";
import InformationText from "../../components/InformationText.vue";

import * as Sentry from "@sentry/vue";
import { collection, setDoc, updateDoc, getDoc, doc } from "firebase/firestore";
import { firestore } from "@/helpers/firebase/firebase";
import { setErrorToast, setSuccessToast } from "@/helpers/notifications/toast";
import { GET_GROUP_DOC_ID } from "@/store/modules/groups";
import { GET_AUTH_UUID } from "@/store/modules/auth";

import { handleFirestoreException } from "@/helpers/exceptions/handler";
import { registerMessageToken } from "@/helpers/messaging-token";
import { GET_USER_DATA } from "@/store/modules/user";
import { askFcmTokenFlutter } from "@/helpers/flutter/FlutterFcm";
import { isRunningInFlutter } from "@/helpers/flutter/FlutterInit";
import { globals } from "@/helpers/globals/globals";

export default {
  name: "NotificationSettingsView",
  components: {
    InformationText,
    TopNavigation,
  },
  data: function () {
    return {
      status: {
        fetchingSettings: false,
        updatingSettings: false,
        fetchingDeviceToken: false,
        updatedDeviceToken: false,
      },
      notificationSettings: {
        photo_create: true,
        photo_comment: true,
        event_create: true,
        quote_create: true,
        group_member_joined: true,
        quote_comment: true,
        event_comment: true,
      },
      deviceToken: null,
      hasPermission: false,
      isRunningInFlutter: isRunningInFlutter(),
    };
  },
  computed: {
    globals() {
      return globals;
    },
  },
  created() {
    window.scrollTo({ top: 0, left: 0 });
    if (this.isRunningInFlutter) {
      askFcmTokenFlutter();
      globals.hasFetched = true;
    } else {
      this.pushDeviceToken();
    }
    this.fetchNotifications();
  },
  methods: {
    async fetchNotifications() {
      try {
        let settingsCollection = collection(
          firestore,
          "groups/" + this.$store.getters[GET_GROUP_DOC_ID] + "/settings",
        );
        let pushTokensDoc = await getDoc(doc(settingsCollection, "push"));
        if (!pushTokensDoc.exists()) {
          return;
        }
        if (
          pushTokensDoc.data() &&
          pushTokensDoc.data().settings &&
          pushTokensDoc.data().settings[this.$store.getters[GET_AUTH_UUID]]
        ) {
          this.notificationSettings =
            pushTokensDoc.data().settings[this.$store.getters[GET_AUTH_UUID]];
        }
      } catch (e) {
        setErrorToast({
          message: this.$t("global.toast.error_fetching_push_settings"),
        });
      } finally {
        this.status.fetchingSettings = false;
      }
    },
    async pushDeviceToken() {
      try {
        await Notification.requestPermission();
      } catch (e) {
        Sentry.captureException(e);
      }

      try {
        this.status.fetchingDeviceToken = true;
        await registerMessageToken();
        this.status.updatedDeviceToken = true;
        globals.hasFetched = true;
      } catch (e) {
        Sentry.captureMessage(JSON.stringify(e));
        Sentry.captureException(e);
      }
      this.status.fetchingDeviceToken = false;
    },
    async save() {
      try {
        this.status.updatingSettings = true;

        let usersCollection = collection(firestore, "users");
        let userDoc = doc(usersCollection, this.$store.getters[GET_AUTH_UUID]);
        await updateDoc(userDoc, {
          push_settings: this.notificationSettings,
        });
        let groups = this.$store.getters[GET_USER_DATA]?.groups || [];
        for (let i = 0; i < groups.length; i++) {
          try {
            let settingsCollection = collection(
              firestore,
              "groups/" + groups[i] + "/settings",
            );
            let pushTokensDoc = await getDoc(doc(settingsCollection, "push"));
            if (!pushTokensDoc.exists()) {
              await setDoc(doc(settingsCollection, "push"), {});
            }
            await updateDoc(doc(settingsCollection, "push"), {
              ["settings." + this.$store.getters[GET_AUTH_UUID]]:
                this.notificationSettings,
            });
          } catch (e) {
            Sentry.captureException(e);
            handleFirestoreException({ e: e });
          }
        }
        setSuccessToast({
          message: this.$t("global.toast.push_settings_saved"),
        });
      } catch (e) {
        handleFirestoreException({ e: e });
      } finally {
        this.status.updatingSettings = false;
      }
    },
  },
};
</script>

import { getFirestoreExceptionMessage } from "./messages/firestore-exceptions";
import { setErrorToast } from "../notifications/toast";
import { getStorageExceptionMessage } from "./messages/firestorage-exceptions";
import * as Sentry from "@sentry/vue";
export function handleFirestoreException({ e }) {
  let message = getFirestoreExceptionMessage(e);
  setErrorToast({ message: message });
  Sentry.captureException(e);
}

export function handleFirestorageException({ e }) {
  let message = getStorageExceptionMessage(e);
  setErrorToast({ message: message });
  Sentry.captureException(e);
}
